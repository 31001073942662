/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export default function IconVideoCamera({ onClick, disabled, id }) {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 50 50"
      id={id}
      type="button"
      style={{
        backgroundColor: disabled && 'white',
        pointerEvents: disabled && 'none',
      }}
      className={!disabled ? 'icon-videocamera' : 'icon-videocamera --disabled'}
      onClick={onClick}
    >
      <g
        id="Grupo_11167"
        data-name="Grupo 11167"
        transform="translate(9360 21570)"
      >
        <g
          id="Grupo_11167-2"
          data-name="Grupo 11167"
          transform="translate(-48.268 23.324)"
        >
          <path
            id="Unión_8"
            data-name="Unión 8"
            d="M0,13.83H0l0-1.065,19.274-.038V8.153a1.077,1.077,0,0,1-.012-.161V5.331a1.079,1.079,0,0,1,.012-.161V1.1l-18.1-.037-.02,10.506-1.065,0L.111.926A.931.931,0,0,1,1.038,0L19.413.039a.928.928,0,0,1,.926.927V4.085l3.987-2.321a1.077,1.077,0,0,1,1.619.93v7.858a1.077,1.077,0,0,1-1.607.937l-4-2.261v3.635a.928.928,0,0,1-.928.928L0,13.83Zm24.867-3.277V2.694L20.339,5.331V7.992Zm-16.7-.132a.552.552,0,0,1-.292-.489V3.9a.554.554,0,0,1,.555-.555.549.549,0,0,1,.3.091L13.32,6.451a.556.556,0,0,1,0,.928L8.733,10.4a.551.551,0,0,1-.3.091A.559.559,0,0,1,8.166,10.421ZM8.983,8.9l3.023-1.988L8.983,4.926Z"
            transform="translate(-9298.732 -21575.324)"
            fill={disabled ? '#000' : '#fff'}
            stroke="rgba(0,0,0,0)"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}

IconVideoCamera.defaultProps = {
  disabled: false,
};

IconVideoCamera.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
