import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SessionActions } from '../../actions';
import {
  FORM_SUPPORT,
  PHOTO_HUB,
  PROCESS_SUPPORT,
  TAKE_PHOTO,
} from '../../utils/navigationConstants';
import { ExpireSessionProvider } from './ExpireSessionProvider';
import { InactivityProvider } from './InactivityProvider';
import { InactivitySession } from '../../utils/session/InactivitySession';
import { getCountryCode } from '../../utils/NavigationUtils';

const AVALIABLE_ROUTES = [PHOTO_HUB, TAKE_PHOTO, FORM_SUPPORT, PROCESS_SUPPORT];

export const ActivityProvider = ({ children }) => {
  const {
    auth: { isAuthenticated },
    modalFlow: {
      faqModalDisplayed,
      displayExpiredSessionModal,
      displayInactivityTimerModal,
    },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  const pathName = window.location.pathname;

  const isAvailableProvider = useMemo(
    () => AVALIABLE_ROUTES.includes(pathName),
    [pathName],
  );

  const inactivitySessionInstance = useMemo(
    () => new InactivitySession(getCountryCode()),
    [],
  );

  const onTouchHandler = () => {
    if (
      !isAvailableProvider ||
      displayInactivityTimerModal ||
      displayExpiredSessionModal ||
      faqModalDisplayed
    )
      return;

    if (
      isAuthenticated &&
      !displayInactivityTimerModal &&
      !displayExpiredSessionModal
    ) {
      const nextTimestamp = inactivitySessionInstance.getNextTimerTimestamp();
      dispatch(SessionActions.onChangeMainInactivityTimer(nextTimestamp));
    }
  };

  const isExceptionPages =
    !isAuthenticated && [FORM_SUPPORT, PROCESS_SUPPORT].includes(pathName);

  useEffect(() => {
    if (isAuthenticated && !displayInactivityTimerModal) {
      const nextTimestamp = inactivitySessionInstance.getNextTimerTimestamp();
      dispatch(SessionActions.onChangeMainInactivityTimer(nextTimestamp));
    }
  }, [
    dispatch,
    isAuthenticated,
    displayInactivityTimerModal,
    inactivitySessionInstance,
  ]);

  return (
    <div onTouchStart={onTouchHandler}>
      <ExpireSessionProvider
        isExceptionPages={isExceptionPages}
        isAllowProvider={isAvailableProvider}
      />

      <InactivityProvider
        isExceptionPages={isExceptionPages}
        isAllowProvider={isAvailableProvider}
      />

      {children}
    </div>
  );
};

ActivityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActivityProvider;
