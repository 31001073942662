import React from 'react';
import Slider from 'react-slick';
import { Heading } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Image } from '..';

function renderHitsTexts(indexSlide, hints) {
  if (indexSlide === 0) {
    return hints.text;
  }
  return hints.text_2;
}

function OneStepFlow({ settings, hints, indexSlide, isImageLoaded }) {
  const {
    settings: {
      environment: { language },
    },
    takePhoto: { cameraMode },
  } = useSelector(store => store);

  const {
    TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT,
    PHOTO_HUB_VIDEO_SECTION_PANEL_HINT_NOTE,
  } = localizedStrings(language);

  return (
    <div>
      <div className="hints-modal-image-container">
        <Slider {...settings}>
          {hints.images.map(image => (
            <Image
              className="hints-modal-image"
              src={image}
              key={image}
              alt={TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT}
              onLoad={isImageLoaded}
            />
          ))}
        </Slider>
      </div>

      <Heading
        type="h4-light"
        className="hints-modal-heading auto-left-right-margin"
      >
        {hints.text_2 ? renderHitsTexts(indexSlide, hints) : hints.text}
      </Heading>
      {cameraMode === 'RECORD' && (
        <h4 className="important-notes">
          {PHOTO_HUB_VIDEO_SECTION_PANEL_HINT_NOTE}
        </h4>
      )}
    </div>
  );
}

OneStepFlow.propTypes = {
  settings: PropTypes.shape({
    arrows: PropTypes.bool.isRequired,
    dots: PropTypes.bool.isRequired,
    infinite: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
  }).isRequired,

  hints: PropTypes.shape({
    text: PropTypes.string.isRequired,
    text_2: PropTypes.string,
    images: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
  isImageLoaded: PropTypes.func.isRequired,
  indexSlide: PropTypes.number.isRequired,
};

export default OneStepFlow;
