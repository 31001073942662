import {
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  UPDATE_DISPLAY_HINTS,
  UPDATE_RETAKE,
  UPDATE_RESUME,
  UPDATE_INVALID_STATUS,
  CHANGE_LANGUAGE,
  ON_CHANGE_STATE_FULLSCREEN,
  SAVE_LASTPATH,
  HINT_IMAGES_LOADED,
} from '../actions/ActionTypes';
import { getEnvironmentConfig } from '../config/environments';
import { APP_VERSION } from '../utils/constants';
import { setLanguageInLocalstorage } from '../utils/languageUtils';

export const initialState = {
  environment: getEnvironmentConfig(),
  appVersion: APP_VERSION.V2,
  displayHints: true,
  appLoader: {
    displayed: false,
    customTitle: undefined,
    customSubtitle: undefined,
  },
  retake: undefined,
  resume: undefined,
  invalidStatus: undefined,
  isFullscreen: false,
  lastPath: undefined,
  hintImagesLoaded: false,
};

export default (
  state = initialState,
  { type, payload, customTitle, customSubtitle, value } = {},
) => {
  switch (type) {
    case SHOW_APP_LOADER:
      return {
        ...state,
        appLoader: {
          displayed: true,
          customTitle: customTitle || undefined,
          customSubtitle: customSubtitle || undefined,
        },
      };
    case HIDE_APP_LOADER:
      return {
        ...state,
        appLoader: {
          displayed: false,
          customTitle: undefined,
          customSubtitle: undefined,
        },
      };
    case UPDATE_DISPLAY_HINTS:
      return { ...state, displayHints: value };
    case UPDATE_RETAKE:
      return { ...state, retake: value };
    case UPDATE_RESUME:
      return { ...state, resume: value };
    case UPDATE_INVALID_STATUS:
      return { ...state, invalidStatus: value };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        environment: {
          ...state.environment,
          language: setLanguageInLocalstorage(payload.lang),
        },
      };
    case ON_CHANGE_STATE_FULLSCREEN:
      return {
        ...state,
        isFullscreen: payload.isFullscreen,
      };
    case SAVE_LASTPATH:
      return {
        ...state,
        lastPath: value,
      };
    case HINT_IMAGES_LOADED:
      return {
        ...state,
        hintImagesLoaded: true,
      };
    default:
      return state;
  }
};
