import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICON_TYPE_PANEL,
  PanelIconFactory,
} from '../../WizardPhotos/IconPanelFactory';
import { CAMERA_MODE } from '../../../constants/camera';
import VideoPreviewCard from '../VideoPreviewCard/VideoPreviewCard';
import { DefaultVideoPanel } from '../DefaultVideoPanel/DefaultVideoPanel';
import { ModalFlowActions } from '../../../actions';

function PanelVideoFlow({
  vehicleClass,
  photoConfig,
  navigate,
  previewPhoto,
  disabled,
  startCapturePhoto,
}) {
  const { photoId, title, body } = photoConfig;
  const {
    settings: {
      retake,
      environment: { language },
    },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  const isRecordMode = true;

  const getPanelIcon = type => (
    <PanelIconFactory
      isRetake={!!retake}
      photoId={photoId}
      disabled={disabled}
      vehicleClass={vehicleClass}
      onClick={startCapturePhoto}
      type={type}
    />
  );

  const getIconComponent = () => getPanelIcon(ICON_TYPE_PANEL.VIDEO_CAMERA);

  const onRepeatVideo = () => {
    dispatch(ModalFlowActions.onDisplayRepeatVideoModal(true));
  };

  const onPlayVideo = () => {
    dispatch(ModalFlowActions.onDisplayPlayVideoModal(true));
  };

  return (
    <>
      {previewPhoto ? (
        <VideoPreviewCard
          title={title}
          description={body}
          language={language}
          photoId={photoId}
          previewPhoto={previewPhoto}
          onPlayVideo={onPlayVideo}
          onRepeatVideo={onRepeatVideo}
        />
      ) : (
        <DefaultVideoPanel
          {...photoConfig}
          vehicleClass={vehicleClass}
          disabled={disabled}
          navigate={navigate}
          previewPhoto={previewPhoto}
          cameraMode={CAMERA_MODE.RECORD}
          isRecordMode={isRecordMode}
          IconComponent={getIconComponent()}
        />
      )}
    </>
  );
}

PanelVideoFlow.propTypes = {
  vehicleClass: PropTypes.string.isRequired,
  photoConfig: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    photoId: PropTypes.string.isRequired,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  previewPhoto: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
  // buttons: PropTypes.arrayOf(PropTypes.oneOf(['update', 'delete'])).isRequired,
  capturePhotoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default PanelVideoFlow;
