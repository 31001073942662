import {
  CONDITIONALLY_REQUIRED_PHOTO_IDS,
  REQUIRED_PHOTO_IDS,
} from '@lmig-latam/adil-api-common-lib/constants';
import localizedStrings from '../../utils/localizedStrings';
import requiredPhotosImages from '../requiredPhotosImages/Vehicle';
import conditionallyRequiredPhotosImages from '../conditionallyRequiredPhotosImages';
import { getCountryCode } from '../../utils/NavigationUtils';

const country = getCountryCode();

const getRequiredPhotosVehicleConfig = language => {
  const {
    PHOTO_HUB_STEP_01_TITLE,
    PHOTO_HUB_STEP_01_SUBTITLE,
    PHOTO_HUB_STEP_01_HINT,
    PHOTO_HUB_STEP_02_TITLE,
    PHOTO_HUB_STEP_02_SUBTITLE,
    PHOTO_HUB_STEP_02_HINT,
    PHOTO_HUB_STEP_03_TITLE,
    PHOTO_HUB_STEP_03_SUBTITLE,
    PHOTO_HUB_STEP_03_HINT,
    PHOTO_HUB_STEP_04_TITLE,
    PHOTO_HUB_STEP_04_SUBTITLE,
    PHOTO_HUB_STEP_04_HINT,
    PHOTO_HUB_STEP_05_TITLE,
    PHOTO_HUB_STEP_05_SUBTITLE,
    PHOTO_HUB_STEP_05_HINT,
    PHOTO_HUB_STEP_06_TITLE,
    PHOTO_HUB_STEP_06_SUBTITLE,
    PHOTO_HUB_STEP_06_HINT,
    PHOTO_HUB_STEP_07_TITLE,
    PHOTO_HUB_STEP_07_SUBTITLE,
    PHOTO_HUB_STEP_07_HINT,
    PHOTO_HUB_STEP_08_TITLE,
    PHOTO_HUB_STEP_08_SUBTITLE,
    PHOTO_HUB_STEP_08_HINT,
    PHOTO_HUB_STEP_09_TITLE,
    PHOTO_HUB_STEP_09_SUBTITLE,
    PHOTO_HUB_STEP_09_HINT,
    PHOTO_HUB_STEP_10_TITLE,
    PHOTO_HUB_STEP_10_SUBTITLE,
    PHOTO_HUB_STEP_10_HINT,
    PHOTO_HUB_STEP_11_TITLE,
    PHOTO_HUB_STEP_11_SUBTITLE,
    PHOTO_HUB_STEP_11_HINT,
    PHOTO_HUB_STEP_11_HINT_2,
    PHOTO_HUB_STEP_12_TITLE,
    PHOTO_HUB_STEP_12_SUBTITLE,
    PHOTO_HUB_STEP_12_HINT,
    PHOTO_HUB_STEP_13_TITLE,
    PHOTO_HUB_STEP_13_SUBTITLE,
    PHOTO_HUB_STEP_13_HINT,
    PHOTO_HUB_STEP_14_TITLE,
    PHOTO_HUB_STEP_14_SUBTITLE,
    PHOTO_HUB_STEP_14_HINT,
    PHOTO_HUB_STEP_15_TITLE,
    PHOTO_HUB_STEP_15_SUBTITLE,
    PHOTO_HUB_STEP_15_HINT,
    PHOTO_HUB_STEP_16_TITLE,
    PHOTO_HUB_STEP_16_SUBTITLE,
    PHOTO_HUB_STEP_16_HINT,
    PHOTO_HUB_STEP_17_TITLE,
    PHOTO_HUB_STEP_17_SUBTITLE,
    PHOTO_HUB_STEP_17_HINT,
    PHOTO_HUB_STEP_18_TITLE,
    PHOTO_HUB_STEP_18_SUBTITLE,
    PHOTO_HUB_STEP_18_HINT,
    PHOTO_HUB_STEP_19_TITLE,
    PHOTO_HUB_STEP_19_SUBTITLE,
    PHOTO_HUB_STEP_19_HINT,
    PHOTO_HUB_STEP_20_TITLE,
    PHOTO_HUB_STEP_20_SUBTITLE,
    PHOTO_HUB_STEP_20_HINT,
    PHOTO_HUB_STEP_21_TITLE,
    PHOTO_HUB_STEP_21_SUBTITLE,
    PHOTO_HUB_STEP_21_HINT,
    PHOTO_HUB_STEP_22_TITLE,
    PHOTO_HUB_STEP_22_SUBTITLE,
    PHOTO_HUB_STEP_22_HINT,
    PHOTO_HUB_STEP_23_TITLE,
    PHOTO_HUB_STEP_23_SUBTITLE,
    PHOTO_HUB_STEP_23_HINT,
    PHOTO_HUB_STEP_24_TITLE,
    PHOTO_HUB_STEP_24_SUBTITLE,
    PHOTO_HUB_STEP_24_HINT,
    PHOTO_HUB_STEP_25_TITLE,
    PHOTO_HUB_STEP_25_SUBTITLE,
    PHOTO_HUB_STEP_25_HINT,
    PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE,
    PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE,
    PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT,
  } = localizedStrings(language);

  const {
    INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
    INSPECTION_PHOTO_PROPERTY_CARD_BACK,
    INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE,
    INSPECTION_PHOTO_VEHICLE_FRONT,
    INSPECTION_PHOTO_VEHICLE_REAR,
    INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
    INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
    INSPECTION_PHOTO_VEHICLE_ENGINE,
    INSPECTION_PHOTO_VEHICLE_DASH,
    INSPECTION_PHOTO_VEHICLE_MILEAGE,
    INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER,
    INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER,
    INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE,
    INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP,
    INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP,
    INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTION,
    INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD,
    INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE,
    INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE,
    INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE,
    INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE,
    INSPECTION_PHOTO_VEHICLE_VN_ODOMETER,
    INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT,
    INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1,
    INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2,
  } = REQUIRED_PHOTO_IDS;

  const { INSPECTION_PHOTO_SPARE_WHEEL } = CONDITIONALLY_REQUIRED_PHOTO_IDS;

  return [
    {
      photoId: INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
      title: PHOTO_HUB_STEP_01_TITLE,
      body: PHOTO_HUB_STEP_01_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_01_HINT,
        images: requiredPhotosImages[country].step01,
      },
    },
    {
      photoId: INSPECTION_PHOTO_PROPERTY_CARD_BACK,
      title: PHOTO_HUB_STEP_02_TITLE,
      body: PHOTO_HUB_STEP_02_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_02_HINT,
        images: requiredPhotosImages[country].step02,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE,
      title: PHOTO_HUB_STEP_03_TITLE,
      body: PHOTO_HUB_STEP_03_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_03_HINT,
        images: requiredPhotosImages[country].step03,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_FRONT,
      title: PHOTO_HUB_STEP_04_TITLE,
      body: PHOTO_HUB_STEP_04_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_04_HINT,
        images: requiredPhotosImages[country].step04,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_REAR,
      title: PHOTO_HUB_STEP_05_TITLE,
      body: PHOTO_HUB_STEP_05_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_05_HINT,
        images: requiredPhotosImages[country].step05,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
      title: PHOTO_HUB_STEP_06_TITLE,
      body: PHOTO_HUB_STEP_06_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_06_HINT,
        images: requiredPhotosImages[country].step07,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
      title: PHOTO_HUB_STEP_07_TITLE,
      body: PHOTO_HUB_STEP_07_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_07_HINT,
        images: requiredPhotosImages[country].step06,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_ENGINE,
      title: PHOTO_HUB_STEP_08_TITLE,
      body: PHOTO_HUB_STEP_08_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_08_HINT,
        images: requiredPhotosImages[country].step08,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_DASH,
      title: PHOTO_HUB_STEP_09_TITLE,
      body: PHOTO_HUB_STEP_09_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_09_HINT,
        images: requiredPhotosImages[country].step09,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_MILEAGE,
      title: PHOTO_HUB_STEP_10_TITLE,
      body: PHOTO_HUB_STEP_10_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_10_HINT,
        images: requiredPhotosImages[country].step10,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER,
      title: PHOTO_HUB_STEP_11_TITLE,
      body: PHOTO_HUB_STEP_11_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_11_HINT,
        text_2: PHOTO_HUB_STEP_11_HINT_2,
        images: requiredPhotosImages[country].step11,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER,
      title: PHOTO_HUB_STEP_12_TITLE,
      body: PHOTO_HUB_STEP_12_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_12_HINT,
        images: requiredPhotosImages[country].step12,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE,
      title: PHOTO_HUB_STEP_13_TITLE,
      body: PHOTO_HUB_STEP_13_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_13_HINT,
        images: requiredPhotosImages[country].step13,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP,
      title: PHOTO_HUB_STEP_14_TITLE,
      body: PHOTO_HUB_STEP_14_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_14_HINT,
        images: requiredPhotosImages[country].step14,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP,
      title: PHOTO_HUB_STEP_15_TITLE,
      body: PHOTO_HUB_STEP_15_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_15_HINT,
        images: requiredPhotosImages[country].step15,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTION,
      title: PHOTO_HUB_STEP_16_TITLE,
      body: PHOTO_HUB_STEP_16_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_16_HINT,
        images: requiredPhotosImages[country].step16,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD,
      title: PHOTO_HUB_STEP_17_TITLE,
      body: PHOTO_HUB_STEP_17_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_17_HINT,
        images: requiredPhotosImages[country].step17,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE,
      title: PHOTO_HUB_STEP_18_TITLE,
      body: PHOTO_HUB_STEP_18_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_18_HINT,
        images: requiredPhotosImages[country].step18,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE,
      title: PHOTO_HUB_STEP_19_TITLE,
      body: PHOTO_HUB_STEP_19_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_19_HINT,
        images: requiredPhotosImages[country].step19,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE,
      title: PHOTO_HUB_STEP_20_TITLE,
      body: PHOTO_HUB_STEP_20_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_20_HINT,
        images: requiredPhotosImages[country].step20,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE,
      title: PHOTO_HUB_STEP_21_TITLE,
      body: PHOTO_HUB_STEP_21_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_21_HINT,
        images: requiredPhotosImages[country].step21,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_VN_ODOMETER,
      title: PHOTO_HUB_STEP_22_TITLE,
      body: PHOTO_HUB_STEP_22_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_22_HINT,
        images: requiredPhotosImages[country].step22,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT,
      title: PHOTO_HUB_STEP_23_TITLE,
      body: PHOTO_HUB_STEP_23_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_23_HINT,
        images: requiredPhotosImages[country].step23,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1,
      title: PHOTO_HUB_STEP_24_TITLE,
      body: PHOTO_HUB_STEP_24_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_24_HINT,
        images: requiredPhotosImages[country].step23,
      },
    },
    {
      photoId: INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2,
      title: PHOTO_HUB_STEP_25_TITLE,
      body: PHOTO_HUB_STEP_25_SUBTITLE,
      hints: {
        text: PHOTO_HUB_STEP_25_HINT,
        images: requiredPhotosImages[country].step23,
      },
    },
    ...(language === 'ec'
      ? [
          {
            photoId: INSPECTION_PHOTO_SPARE_WHEEL,
            title: PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE,
            body: PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE,
            hints: {
              text: PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT,
              images: conditionallyRequiredPhotosImages.spareTyreHint,
            },
          },
        ]
      : []),
  ];
};

export default getRequiredPhotosVehicleConfig;
