export class DamagePhotoHelper {
  constructor(stepsWizard) {
    this.step = null;
    this.stepsWizard = stepsWizard;
  }

  setStep(step) {
    this.step = step;
    return this;
  }

  getResult() {
    return this.stepsWizard;
  }

  storePhoto(incomingPhoto) {
    const { photoId } = incomingPhoto;
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep].photos;

    const isPhotoExists = photosInStep.damages.some(
      item => item.photoId === photoId,
    );

    if (isPhotoExists) {
      const photoStepListUpdated = photosInStep.damages.map(item =>
        item.photoId === photoId ? incomingPhoto : item,
      );
      this.stepsWizard[positionStep].photos.damages = [...photoStepListUpdated];
    } else {
      photosInStep.damages.push(incomingPhoto);
      this.stepsWizard[positionStep].photos = photosInStep;
    }

    return this;
  }

  removePhoto(photoId) {
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep].photos;
    const newDamagesArray = photosInStep.damages.filter(
      photo => photo.photoId !== photoId,
    );
    this.stepsWizard[positionStep].photos.damages = newDamagesArray;
    return this;
  }
}
