import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import supportRq from './assets/supportRq.svg';
import supportRqSelected from './assets/supportRqSelected.svg';
import faq from './assets/faq.svg';
import instructions from './assets/instructions.svg';
import { navigate } from '../../utils/NavigationUtils';
import { FORM_SUPPORT } from '../../utils/navigationConstants';
import {
  ENABLE_SUPPORT_REQUEST,
  SAVE_LASTPATH,
} from '../../actions/ActionTypes';
import history from '../../utils/history';
import { ModalFlowActions } from '../../actions';
import './styles.scss';

function MenuFooter() {
  const {
    settings: {
      environment: { language },
    },
    supportRequest: { supportRqEnabled },
    user: { vehicleClass },
  } = useSelector(store => store);

  const {
    SUPPORT_REQUEST_LABEL,
    FAQ_LABEL,
    INSTRUCTIONS_LABEL,
  } = localizedStrings(language);

  const dispatch = useDispatch();

  const getButtonId = buttonId => {
    const vehicle = vehicleClass.toUpperCase();

    return `${vehicle}-${buttonId}-ENABLED`;
  };

  const goSupportPage = () => {
    if (!supportRqEnabled) {
      dispatch({
        type: SAVE_LASTPATH,
        value: history.location.pathname,
      });
      dispatch({
        type: ENABLE_SUPPORT_REQUEST,
      });
      navigate(FORM_SUPPORT);
    }
  };
  return (
    <>
      <div className="menu-footer-container">
        <div
          className="menu-footer-item"
          role="button"
          id={getButtonId('SUPPORT-REQUEST')}
          onClick={goSupportPage}
        >
          <div className="menu-footer-item-image">
            <img
              src={supportRqEnabled ? supportRqSelected : supportRq}
              alt="supportRqIcon"
            />
          </div>

          <div className="menu-footer-item-text">{SUPPORT_REQUEST_LABEL}</div>
          {supportRqEnabled && <div className="menu-footer-item-subBar" />}
        </div>
        <div
          className="menu-footer-item"
          role="button"
          id={getButtonId('FAQ')}
          onClick={() => {
            dispatch(ModalFlowActions.changeStateFaqModal(true));
          }}
        >
          <div className="menu-footer-item-image">
            <img src={faq} alt="faqIcon" />
          </div>

          <div className="menu-footer-item-text">{FAQ_LABEL}</div>
        </div>
        <div
          className="menu-footer-item"
          role="button"
          id={getButtonId('INSTRUCTIONS')}
          onClick={() => {
            dispatch(ModalFlowActions.onDisplayInstructionsModal(true, 'menu'));
          }}
        >
          <div className="menu-footer-item-image">
            <img src={instructions} alt="instructionsIcon" />
          </div>
          <div className="menu-footer-item-text">{INSTRUCTIONS_LABEL}</div>
        </div>
      </div>
    </>
  );
}

MenuFooter.propTypes = {};

export default MenuFooter;
