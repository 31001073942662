import React from 'react';
import PropTypes from 'prop-types';
import ButtonRecording from './ButtonRecording';
import './styles.scss';

function VideoActionButtons({ disable, recording, handlerRecording }) {
  return (
    <div className="video-action-button__container">
      <div />
      <div>
        <ButtonRecording
          disable={disable}
          recording={recording}
          handlerRecording={handlerRecording}
        />
      </div>
      <div />
    </div>
  );
}

VideoActionButtons.propTypes = {
  disable: PropTypes.bool.isRequired,
  recording: PropTypes.bool.isRequired,
  handlerRecording: PropTypes.shape({
    onStartRecording: PropTypes.func.isRequired,
    onStopRecording: PropTypes.func.isRequired,
  }).isRequired,
  handlerFullScreen: PropTypes.shape({
    openFullScreen: PropTypes.func.isRequired,
    exitFullScreen: PropTypes.func.isRequired,
  }).isRequired,
};

export default VideoActionButtons;
