import React from 'react';
import PropTypes from 'prop-types';
import { store } from '../../utils/configureStore';
import localizedStrings from '../../utils/localizedStrings';
import libertyLogo from '../../assets/gifs/liberty-logo.svg';
import loaderSplash from '../../assets/gifs/loaderSplash.svg';

const DetectingScreen = props => {
  const { title } = props;
  const {
    settings: {
      environment: { language },
    },
  } = store.getState();

  const { LOADING_TEXT_DETECTING_BROWSER } = localizedStrings(language);
  return (
    <div className="detectingScreen">
      <div className="detectingScreen__load">
        <img
          className="detectingScreen__load__splash"
          src={loaderSplash}
          alt="loader - load splasher"
        />
        <img
          className="detectingScreen__load__libertyLogo"
          src={libertyLogo}
          alt="libertyLogo - load splasher"
        />
      </div>
      <h3 className="detectingScreen__text">
        {title || LOADING_TEXT_DETECTING_BROWSER}
      </h3>
    </div>
  );
};

DetectingScreen.propTypes = {
  title: PropTypes.string,
};

DetectingScreen.defaultProps = {
  title: undefined,
};

export default DetectingScreen;
