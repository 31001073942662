import React from 'react';
import PropTypes from 'prop-types';
import localizedStrings from '../../../utils/localizedStrings';
import { IconPlay } from '../../Icons/IconPlay';
import { PhotoHeaderCard } from '../../WizardPhotos';
import { RetakeIcon, SuccessIcon } from '../../Icons';
import Panel from '../../Panel';
import './styles.scss';

const VideoPreviewCard = ({
  title,
  description,
  language,
  previewPhoto,
  onPlayVideo,
  onRepeatVideo,
}) => {
  const { PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT } = localizedStrings(language);

  const getSuccessIcon = () => (
    <div style={{ marginRight: '-10px' }}>
      <SuccessIcon />
    </div>
  );

  return (
    <Panel className="video-panel">
      <PhotoHeaderCard
        title={title}
        description={description}
        IconComponent={getSuccessIcon()}
      />

      <div className="preview-video-container">
        <div className="play-button-container">
          <IconPlay
            onClick={() => onPlayVideo()}
            style={{ width: '80px', marginBottom: 20 }}
          />
        </div>

        <RetakeIcon
          className="video-panel__retake-icon"
          onClick={() => onRepeatVideo()}
        />

        <img src={previewPhoto} alt={PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT} />
      </div>
    </Panel>
  );
};

VideoPreviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  language: PropTypes.bool.isRequired,
  onPlayVideo: PropTypes.func.isRequired,
  onRepeatVideo: PropTypes.func.isRequired,
  previewPhoto: PropTypes.string.isRequired,
};

export default VideoPreviewCard;
