import { Heading } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { LoadingAnimation } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../utils/NavigationUtils';

const SimpleLoader = ({ language }) => {
  const { LOADING_TEXT } = localizedStrings(language);

  return (
    <div className="app-loader translucent">
      <div className="app-loader-animation auto-left-right-margin">
        <LoadingAnimation />
      </div>
      <Heading
        type="h4-light"
        className="app-loader-heading auto-left-right-margin"
      >
        {LOADING_TEXT}
      </Heading>
    </div>
  );
};

SimpleLoader.propTypes = {
  language: PropTypes.string,
};

SimpleLoader.defaultProps = {
  language: getCountryCode(),
};

export default SimpleLoader;
