import { useState, useEffect } from 'react';

export function useScreenRatio(screenSize) {
  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    const calculateRatio = () => {
      if (screenSize?.height && screenSize?.width) {
        const { width, height } = screenSize;
        const isLandscape = height <= width;
        const ratio = isLandscape ? width / height : height / width;
        setAspectRatio(ratio);
      }
    };
    calculateRatio();
  }, [screenSize]);

  return [aspectRatio];
}
