import React from 'react';

export default function CircleCameraIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97">
      <g
        id="GrupoCircleCameraIcon_5094"
        data-name="Grupo 5094"
        transform="translate(4.5 -0.5)"
      >
        <g
          id="GrupoCircleCameraIcon_4567"
          data-name="Grupo 4567"
          transform="translate(0.5)"
        >
          <circle
            id="Oval"
            cx="48"
            cy="48"
            r="48"
            transform="translate(-4.5 1)"
            fill="#f0fbfc"
            stroke="#99e5ea"
            strokeWidth="1"
          />
        </g>
        <g
          id="Icon_Account_Experience_Camera_64px"
          data-name="Icon/Account Experience/Camera/64px"
          transform="translate(18.5 54.815)"
        >
          <g
            id="CombinedCircleCameraIcon_Shape"
            data-name="Combined Shape"
            transform="translate(-0.5 -21.982)"
          >
            <path
              id="CombinedCircleCameraIcon_Shape-2"
              data-name="Combined Shape"
              d="M0,32.333V30.29H49.957V4.427c0-.012,0-.023,0-.035H2.844c-.072,0-.135,0-.188,0V27.023H.613V4.478a2.054,2.054,0,0,1,.467-1.61,2.372,2.372,0,0,1,1.765-.519H40.735V0h6.684V2.349h2.538A1.85,1.85,0,0,1,52,4.427v26a1.793,1.793,0,0,1-1.993,1.907Zm15.459-15.11A10.011,10.011,0,1,1,25.47,27.236,10.024,10.024,0,0,1,15.459,17.223Zm2.043,0A7.968,7.968,0,1,0,25.47,9.255,7.977,7.977,0,0,0,17.5,17.223ZM41.887,9.346a2.189,2.189,0,1,1,2.188,2.189A2.188,2.188,0,0,1,41.887,9.346Z"
              fill="none"
            />
            <path
              id="CombinedCircleCameraIcon_Shape-3"
              data-name="Combined Shape"
              d="M0,32.333V30.29H49.957V4.427c0-.012,0-.023,0-.035H2.844c-.072,0-.135,0-.188,0V27.023H.613V4.478a2.054,2.054,0,0,1,.467-1.61,2.372,2.372,0,0,1,1.765-.519H40.735V0h6.684V2.349h2.538A1.85,1.85,0,0,1,52,4.427v26a1.793,1.793,0,0,1-1.993,1.907Zm15.459-15.11A10.011,10.011,0,1,1,25.47,27.236,10.024,10.024,0,0,1,15.459,17.223Zm2.043,0A7.968,7.968,0,1,0,25.47,9.255,7.977,7.977,0,0,0,17.5,17.223ZM41.887,9.346a2.189,2.189,0,1,1,2.188,2.189A2.188,2.188,0,0,1,41.887,9.346Z"
              fill="none"
            />
            <path
              id="CombinedCircleCameraIcon_Shape-4"
              data-name="Combined Shape"
              d="M0,32.333V30.29H49.957V4.427c0-.012,0-.023,0-.035H2.844c-.072,0-.135,0-.188,0V27.023H.613V4.478a2.054,2.054,0,0,1,.467-1.61,2.372,2.372,0,0,1,1.765-.519H40.735V0h6.684V2.349h2.538A1.85,1.85,0,0,1,52,4.427v26a1.793,1.793,0,0,1-1.993,1.907Zm15.459-15.11A10.011,10.011,0,1,1,25.47,27.236,10.024,10.024,0,0,1,15.459,17.223Zm2.043,0A7.968,7.968,0,1,0,25.47,9.255,7.977,7.977,0,0,0,17.5,17.223ZM41.887,9.346a2.189,2.189,0,1,1,2.188,2.189A2.188,2.188,0,0,1,41.887,9.346Z"
              fill="none"
            />
            <path
              id="CombinedCircleCameraIcon_Shape-5"
              data-name="Combined Shape"
              d="M0,32.333V30.29H49.957V4.427c0-.012,0-.023,0-.035H2.844c-.072,0-.135,0-.188,0V27.023H.613V4.478a2.054,2.054,0,0,1,.467-1.61,2.372,2.372,0,0,1,1.765-.519H40.735V0h6.684V2.349h2.538A1.85,1.85,0,0,1,52,4.427v26a1.793,1.793,0,0,1-1.993,1.907Zm15.459-15.11A10.011,10.011,0,1,1,25.47,27.236,10.024,10.024,0,0,1,15.459,17.223Zm2.043,0A7.968,7.968,0,1,0,25.47,9.255,7.977,7.977,0,0,0,17.5,17.223ZM41.887,9.346a2.189,2.189,0,1,1,2.188,2.189A2.188,2.188,0,0,1,41.887,9.346Z"
              fill="none"
            />
            <path
              id="CombinedCircleCameraIcon_Shape-6"
              data-name="Combined Shape"
              d="M0,32.333V30.29H49.957V4.427c0-.012,0-.023,0-.035H2.844c-.072,0-.135,0-.188,0V27.023H.613V4.478a2.054,2.054,0,0,1,.467-1.61,2.372,2.372,0,0,1,1.765-.519H40.735V0h6.684V2.349h2.538A1.85,1.85,0,0,1,52,4.427v26a1.793,1.793,0,0,1-1.993,1.907Zm15.459-15.11A10.011,10.011,0,1,1,25.47,27.236,10.024,10.024,0,0,1,15.459,17.223Zm2.043,0A7.968,7.968,0,1,0,25.47,9.255,7.977,7.977,0,0,0,17.5,17.223ZM41.887,9.346a2.189,2.189,0,1,1,2.188,2.189A2.188,2.188,0,0,1,41.887,9.346Z"
              fill="none"
            />
            <path
              id="CombinedCircleCameraIcon_Shape-7"
              data-name="Combined Shape"
              d="M0,32.333V30.29H49.957V4.427c0-.012,0-.023,0-.035H2.844c-.072,0-.135,0-.188,0V27.023H.613V4.478a2.054,2.054,0,0,1,.467-1.61,2.372,2.372,0,0,1,1.765-.519H40.735V0h6.684V2.349h2.538A1.85,1.85,0,0,1,52,4.427v26a1.793,1.793,0,0,1-1.993,1.907Zm15.459-15.11A10.011,10.011,0,1,1,25.47,27.236,10.024,10.024,0,0,1,15.459,17.223Zm2.043,0A7.968,7.968,0,1,0,25.47,9.255,7.977,7.977,0,0,0,17.5,17.223ZM41.887,9.346a2.189,2.189,0,1,1,2.188,2.189A2.188,2.188,0,0,1,41.887,9.346Z"
              fill="#1a1446"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
