import { IconChevronDown, IconChevronUp } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const FAQModalSection = ({ questionText, answerText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenState = () => {
    setIsOpen(!isOpen);
  };

  const renderQuestionPanel = () => {
    const Chevron = isOpen ? IconChevronUp : IconChevronDown;

    return (
      <div role="button" onClick={toggleOpenState} className="faq-question">
        <div className="faq-question-text">{questionText}</div>
        <Chevron />
      </div>
    );
  };

  const renderAnswerPanel = () =>
    isOpen ? <p className="faq-answer">{answerText}</p> : '';

  return (
    <div>
      {renderQuestionPanel()}
      <div
        style={{
          textAlign: 'left',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
        }}
      >
        {renderAnswerPanel()}
      </div>
    </div>
  );
};

FAQModalSection.propTypes = {
  questionText: PropTypes.string.isRequired,
  answerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default FAQModalSection;
