import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const GuidedVisitStepper = ({ stepNumber, numberDots }) => (
  <div className="steps-circle__container">
    {numberDots !== 0 &&
      Array.from({ length: numberDots }, (_, i) => i + 1).map(i =>
        i <= stepNumber ? (
          <div key={i} className="circle" />
        ) : (
          <div key={i} className="circle--outline" />
        ),
      )}
  </div>
);

GuidedVisitStepper.defaultProps = {
  stepNumber: 1,
  numberDots: 0,
};

GuidedVisitStepper.propTypes = {
  stepNumber: PropTypes.number,
  numberDots: PropTypes.number,
};

export default GuidedVisitStepper;
