import React from 'react';
import { BodyText, Heading, IconAlert } from '@lmig/lmds-react';
import { Modal, CircleImage } from '../../../../components';

const ModalUploadFailed = (
  isOpen,
  title,
  subtitle,
  primaryButtonLabel,
  primaryButtonHandler,
  backButtonHandler,
) => (
  <Modal
    styles
    isOpen={isOpen}
    primaryButtonLabel={primaryButtonLabel}
    primaryButtonHandler={primaryButtonHandler}
    backButtonHandler={backButtonHandler}
    centerContent
  >
    <div className="photohub-upload-modal">
      <Heading type="h3-light" className="auto-left-right-margin">
        {title}
      </Heading>
      <BodyText>{subtitle}</BodyText>
      <div className="photohub-upload-modal-image auto-left-right-margin">
        <CircleImage>
          <IconAlert sizing="auto" />
        </CircleImage>
      </div>
    </div>
  </Modal>
);

export default ModalUploadFailed;
