import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import { CAMERA_MODE, FACING_MODE } from '../../../constants/camera';
import { logcodes, logger } from '../../../utils/logger';
import { TakePhotoActions } from '../../../actions';
import CameraOverlay from '../CameraOverlay';
import { logDeviceError } from '../../../api';
import SplashLoading from '../SplashLoading/SplashLoading';
import localizedStrings from '../../../utils/localizedStrings';
import './styles.scss';

const WebcamAndroid = () => {
  const [loading, setLoading] = useState();
  const [videoConstraints, setVideoConstraints] = useState(null);
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const {
    takePhoto: { isPressButtonTakePhoto, cameraMode, overlay, isFullscreen },
    settings: {
      environment: { language },
    },
  } = useSelector(store => store);

  const { LOADING_MESSAGE_CAMERA_PHOTO } = localizedStrings(language);

  const activateOverlay = () => cameraMode === CAMERA_MODE.PHOTO && overlay;

  const configCamera = useMemo(() => {
    const facingMode = FACING_MODE.BACK_FACING;

    return {
      facingMode,
    };
  }, []);

  const onUserMedia = useCallback(media => {
    setLoading(false);
    logger.log(logcodes.CAMV110, { media });
  }, []);

  const onUserMediaError = useCallback(async error => {
    logger.log(logcodes.CAMV10, { error });
    await logDeviceError({
      errorData: {
        type: error.name,
        message: error.message,
      },
    });
  }, []);

  const onCapturePhoto = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      dispatch(TakePhotoActions.setCurrentPhotoPreview(imageSrc));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isPressButtonTakePhoto) {
      onCapturePhoto();
      setTimeout(
        () => dispatch(TakePhotoActions.onPressButtonTakePhotoStatus(false)),
        500,
      );
    }
  }, [dispatch, isPressButtonTakePhoto, onCapturePhoto]);

  // Set camera dimensions settings when orientation changes
  useEffect(() => {
    let interval;
    function handleOrientationChange() {
      setVideoConstraints(null);
      setLoading(true);

      logger.log(logcodes.CAMV160, window.screen.orientation.angle);
      interval = setTimeout(() => {
        setVideoConstraints(configCamera);
        setLoading(false);
        logger.log(logcodes.CAMV150, window.screen.orientation.angle);
      }, 400);
    }

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      clearTimeout(interval);
    };
  }, [configCamera]);

  // Initial camera dimension settings
  useEffect(() => {
    const interval = setTimeout(() => {
      setVideoConstraints(configCamera);
      logger.log(logcodes.CAMV150, window.screen.orientation.angle);
    }, 1000);
    return () => clearTimeout(interval);
  }, [configCamera]);

  useEffect(() => setLoading(true), []);

  const classNameCamera = `${activateOverlay() ? 'overlayActived' : ''} ${
    isFullscreen ? 'fullscreen' : ''
  }`;

  return (
    <>
      {loading && (
        <SplashLoading translucid label={LOADING_MESSAGE_CAMERA_PHOTO} />
      )}
      <div className="video-container">
        {activateOverlay() && <CameraOverlay />}
        {videoConstraints && (
          <Webcam
            className={classNameCamera}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMediaError={onUserMediaError}
            onUserMedia={onUserMedia}
            screenshotQuality={1}
            forceScreenshotSourceSize
            imageSmoothing
            ref={webcamRef}
            audio={false}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(WebcamAndroid);
