import recordButton from './record_button.png';
import recordFinish from './record_finish.png';
import timerImage from './timer_image.png';

const images = {
  recordButton,
  recordFinish,
  timerImage,
};

export default images;
