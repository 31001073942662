import React from 'react';

export default function CircleNetworkIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97">
      <g
        id="GrupoCircleNetworkIcon_5094"
        data-name="Grupo 5094"
        transform="translate(4.5 -0.5)"
      >
        <g
          id="GrupoCircleNetworkIcon_4567"
          data-name="Grupo 4567"
          transform="translate(0.5)"
        >
          <circle
            id="OvalCircleNetworkIcon"
            cx="48"
            cy="48"
            r="48"
            transform="translate(-4.5 1)"
            fill="#f0fbfc"
            stroke="#99e5ea"
            strokeWidth="1"
          />
        </g>
        <g
          id="IconCircleNetworkIcon_Account_Experience_Wifi_64px"
          data-name="Icon/Account Experience/Wifi/64px"
          transform="translate(19.268 23.028)"
        >
          <g
            id="CombinedCircleNetworkIcon_Shape"
            data-name="Combined Shape"
            transform="translate(0.232 7.344)"
          >
            <path
              id="CombinedCircleNetworkIcon_Shape-2"
              data-name="Combined Shape"
              d="M19.719,32.975A4.282,4.282,0,1,1,24,37.255,4.287,4.287,0,0,1,19.719,32.975Zm1.889,0A2.393,2.393,0,1,0,24,30.581,2.395,2.395,0,0,0,21.608,32.975ZM32.34,24.642a11.694,11.694,0,0,0-8.333-3.46h-.012a11.719,11.719,0,0,0-8.344,3.451l-.668.668-1.335-1.336.668-.668a13.6,13.6,0,0,1,9.68-4h.013a13.574,13.574,0,0,1,9.67,4.016l.666.669-1.338,1.333Zm6.782-6.779a21.4,21.4,0,0,0-30.242,0l-.667.668L6.876,17.2l.667-.668a23.286,23.286,0,0,1,32.914,0l.668.668-1.337,1.335ZM46,10.978A31.162,31.162,0,0,0,2,10.978l-.668.667L0,10.309l.668-.668a33.053,33.053,0,0,1,46.665,0l.668.668-1.335,1.335Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="CombinedCircleNetworkIcon_Shape-3"
              data-name="Combined Shape"
              d="M19.719,32.975A4.282,4.282,0,1,1,24,37.255,4.287,4.287,0,0,1,19.719,32.975Zm1.889,0A2.393,2.393,0,1,0,24,30.581,2.395,2.395,0,0,0,21.608,32.975ZM32.34,24.642a11.694,11.694,0,0,0-8.333-3.46h-.012a11.719,11.719,0,0,0-8.344,3.451l-.668.668-1.335-1.336.668-.668a13.6,13.6,0,0,1,9.68-4h.013a13.574,13.574,0,0,1,9.67,4.016l.666.669-1.338,1.333Zm6.782-6.779a21.4,21.4,0,0,0-30.242,0l-.667.668L6.876,17.2l.667-.668a23.286,23.286,0,0,1,32.914,0l.668.668-1.337,1.335ZM46,10.978A31.162,31.162,0,0,0,2,10.978l-.668.667L0,10.309l.668-.668a33.053,33.053,0,0,1,46.665,0l.668.668-1.335,1.335Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="CombinedCircleNetworkIcon_Shape-4"
              data-name="Combined Shape"
              d="M19.719,32.975A4.282,4.282,0,1,1,24,37.255,4.287,4.287,0,0,1,19.719,32.975Zm1.889,0A2.393,2.393,0,1,0,24,30.581,2.395,2.395,0,0,0,21.608,32.975ZM32.34,24.642a11.694,11.694,0,0,0-8.333-3.46h-.012a11.719,11.719,0,0,0-8.344,3.451l-.668.668-1.335-1.336.668-.668a13.6,13.6,0,0,1,9.68-4h.013a13.574,13.574,0,0,1,9.67,4.016l.666.669-1.338,1.333Zm6.782-6.779a21.4,21.4,0,0,0-30.242,0l-.667.668L6.876,17.2l.667-.668a23.286,23.286,0,0,1,32.914,0l.668.668-1.337,1.335ZM46,10.978A31.162,31.162,0,0,0,2,10.978l-.668.667L0,10.309l.668-.668a33.053,33.053,0,0,1,46.665,0l.668.668-1.335,1.335Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="CombinedCircleNetworkIcon_Shape-5"
              data-name="Combined Shape"
              d="M19.719,32.975A4.282,4.282,0,1,1,24,37.255,4.287,4.287,0,0,1,19.719,32.975Zm1.889,0A2.393,2.393,0,1,0,24,30.581,2.395,2.395,0,0,0,21.608,32.975ZM32.34,24.642a11.694,11.694,0,0,0-8.333-3.46h-.012a11.719,11.719,0,0,0-8.344,3.451l-.668.668-1.335-1.336.668-.668a13.6,13.6,0,0,1,9.68-4h.013a13.574,13.574,0,0,1,9.67,4.016l.666.669-1.338,1.333Zm6.782-6.779a21.4,21.4,0,0,0-30.242,0l-.667.668L6.876,17.2l.667-.668a23.286,23.286,0,0,1,32.914,0l.668.668-1.337,1.335ZM46,10.978A31.162,31.162,0,0,0,2,10.978l-.668.667L0,10.309l.668-.668a33.053,33.053,0,0,1,46.665,0l.668.668-1.335,1.335Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="CombinedCircleNetworkIcon_Shape-6"
              data-name="Combined Shape"
              d="M19.719,32.975A4.282,4.282,0,1,1,24,37.255,4.287,4.287,0,0,1,19.719,32.975Zm1.889,0A2.393,2.393,0,1,0,24,30.581,2.395,2.395,0,0,0,21.608,32.975ZM32.34,24.642a11.694,11.694,0,0,0-8.333-3.46h-.012a11.719,11.719,0,0,0-8.344,3.451l-.668.668-1.335-1.336.668-.668a13.6,13.6,0,0,1,9.68-4h.013a13.574,13.574,0,0,1,9.67,4.016l.666.669-1.338,1.333Zm6.782-6.779a21.4,21.4,0,0,0-30.242,0l-.667.668L6.876,17.2l.667-.668a23.286,23.286,0,0,1,32.914,0l.668.668-1.337,1.335ZM46,10.978A31.162,31.162,0,0,0,2,10.978l-.668.667L0,10.309l.668-.668a33.053,33.053,0,0,1,46.665,0l.668.668-1.335,1.335Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="CombinedCircleNetworkIcon_Shape-7"
              data-name="Combined Shape"
              d="M19.719,32.975A4.282,4.282,0,1,1,24,37.255,4.287,4.287,0,0,1,19.719,32.975Zm1.889,0A2.393,2.393,0,1,0,24,30.581,2.395,2.395,0,0,0,21.608,32.975ZM32.34,24.642a11.694,11.694,0,0,0-8.333-3.46h-.012a11.719,11.719,0,0,0-8.344,3.451l-.668.668-1.335-1.336.668-.668a13.6,13.6,0,0,1,9.68-4h.013a13.574,13.574,0,0,1,9.67,4.016l.666.669-1.338,1.333Zm6.782-6.779a21.4,21.4,0,0,0-30.242,0l-.667.668L6.876,17.2l.667-.668a23.286,23.286,0,0,1,32.914,0l.668.668-1.337,1.335ZM46,10.978A31.162,31.162,0,0,0,2,10.978l-.668.667L0,10.309l.668-.668a33.053,33.053,0,0,1,46.665,0l.668.668-1.335,1.335Z"
              transform="translate(0 0)"
              fill="#1a1446"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
