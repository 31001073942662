import React from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';

const CircleLoadingPhoto = () => {
  const {
    takePhoto: { loadPhotoPercentage },
  } = useSelector(store => store);

  const getLoadPercentage = () =>
    loadPhotoPercentage ? `${loadPhotoPercentage}%` : '0%';

  return (
    <div className="spinner-container">
      <div className="spinner">
        <div className="percentage">{getLoadPercentage()}</div>
      </div>
    </div>
  );
};

export default CircleLoadingPhoto;
