import JWT from 'jsonwebtoken';
import { deleteCookie, setCookie } from '..';
import { getCookie } from '../NavigationUtils';
import { AUTH_TOKEN_COOKIE_NAME, TOKEN_TIMEOUT } from '../constants';

class AuthToken {
  constructor() {
    this.authTokenName = AUTH_TOKEN_COOKIE_NAME;
  }

  getTimestampExp() {
    const token = this.getAuthToken();

    const decoded = JWT.decode(token, { complete: true });

    if (!decoded) return null;

    const { exp } = decoded.payload;

    return new Date(exp * 1000);
  }

  setAuthToken(authorizationToken) {
    setCookie(this.authTokenName, authorizationToken, TOKEN_TIMEOUT);
  }

  getAuthToken() {
    return getCookie(this.authTokenName);
  }

  removeAuthToken() {
    deleteCookie(this.authTokenName);
  }
}

export default new AuthToken();
