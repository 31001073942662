import { combineReducers } from 'redux';
import photosReducer from './photosReducer';
import settingsReducer from './settingsReducer';
import userReducer from './userReducer';
import modalReducer from './modalReducer';
import guidedVisitReducer from './guidedVisitReducer';
import takePhotoReducer from './takePhotoReducer';
import permissionReducer from './permissionReducer';
import takeVideoReducer from './takeVideoReducer';
import deviceInfoReducer from './deviceInfoReducer';
import supportRequestReducer from './supportRequestReducer';
import authReducer from './authReducer';
import sessionReducer from './sessionReducer';
import scrollEffectsReducer from './scroollEffectsReducer';

export default combineReducers({
  photos: photosReducer,
  settings: settingsReducer,
  user: userReducer,
  modalFlow: modalReducer,
  guidedVisit: guidedVisitReducer,
  takePhoto: takePhotoReducer,
  takeVideo: takeVideoReducer,
  permissions: permissionReducer,
  deviceInfo: deviceInfoReducer,
  supportRequest: supportRequestReducer,
  session: sessionReducer,
  auth: authReducer,
  scroll: scrollEffectsReducer,
});
