/* eslint-disable no-param-reassign */
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import localizedStrings from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { formatPhotoConfig, getSinglePhotoConfig } from '../../utils';
import { store } from '../../utils/configureStore';
import { availableAccessories } from '../availableAccesories';
import getConditionallyRequiredPhotos from '../conditionallyRequiredPhotos';
import motorBikeConf from './getRequiredPhotosMotorBikeConfig';
import vehicleConf from './getRequiredPhotosVehicleConfig';
import { getCountryCode } from '../../utils/NavigationUtils';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStrings.getCurrentLanguage();

const country = getCountryCode();

const unformattedRequiredPhotos = (defaultPhotoConfigs, vehicleClass) => {
  const {
    countryRequiredPhotoIds,
    countryMotorBikeRequiredPhotosIds,
  } = getCountryConfig(country);

  let countryPhotosIds = countryRequiredPhotoIds;
  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
    countryPhotosIds = countryMotorBikeRequiredPhotosIds;
  }
  return countryPhotosIds.map(
    photoId =>
      defaultPhotoConfigs.filter(
        photoConfig => photoConfig.photoId === photoId,
      )[0],
  );
};

// We need to build a dynamic 'requiredPhotos' set to accommodate the 'retake' flow where a previously optional accessory photo needs to be retaken
export const buildRequiredPhotos = countryParam => {
  const {
    settings: { retake },
    user: { vehicleClass },
  } = store.getState();
  let requiredPhotos;
  const countryCode = countryParam || country;

  let requiredPhotosType = vehicleConf(countryCode);
  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
    requiredPhotosType = motorBikeConf();
  }

  if (retake) {
    requiredPhotos = [
      ...unformattedRequiredPhotos(requiredPhotosType, vehicleClass),
      ...availableAccessories(countryCode),
      ...getConditionallyRequiredPhotos(countryCode, vehicleClass),
    ].filter(({ photoId }) =>
      [
        ...retake.required,
        ...retake.accessories,
        ...retake.conditionallyRequired,
      ].includes(photoId),
    );
  } else {
    requiredPhotos = unformattedRequiredPhotos(
      requiredPhotosType,
      vehicleClass,
    );

    if (countryCode !== 'cl') {
      requiredPhotos = requiredPhotos.filter(
        item => item.photoId !== 'INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE',
      );
    }
  }

  return formatPhotoConfig(requiredPhotos);
};

export const getRequiredPhoto = (requestedId, lang) =>
  getSinglePhotoConfig(requestedId, buildRequiredPhotos(lang));

export const requiredPhotosPhotoIdList = () =>
  buildRequiredPhotos().map(({ photoId }) => photoId);

export default buildRequiredPhotos();
