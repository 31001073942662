import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading } from '@lmig/lmds-react';
import Modal from '../Modal';
import { availableAccessories } from '../../config';

const ModalAccessories = ({
  isOpen,
  title,
  language,
  primaryButtonLabel,
  hideAccessoriesModal,
  onAccessoryButtonClicked,
  onClickRadioButton,
  showContinueButton,
  startCapturePhoto,
}) => (
  <Modal
    isOpen={isOpen}
    closeButtonHandler={hideAccessoriesModal}
    backButtonHandler={hideAccessoriesModal}
  >
    <div className="pad-top-and-bottom accessories-section-modal-body">
      <Heading
        type="h4"
        className="accessories-section-modal-title align-center"
      >
        {title}
      </Heading>

      {availableAccessories(language).map(
        ({ photoId, title: accessoryTitle }) => {
          const radioButtonId = `radio_button_${photoId}`;

          return (
            <Button
              className="full-width-button left-align-contents disable-button-focus"
              key={photoId}
              onClick={onAccessoryButtonClicked}
              level="inverse"
            >
              <div>
                <input
                  type="radio"
                  id={radioButtonId}
                  value={photoId}
                  name="accessory"
                  onClick={onClickRadioButton}
                />
                <label name="radio-label" htmlFor={radioButtonId}>
                  {accessoryTitle}
                </label>
              </div>
            </Button>
          );
        },
      )}
    </div>

    <div className="align-center accessories-section-continue-button">
      <Button
        type="button"
        onClick={() => startCapturePhoto()}
        disabled={!showContinueButton}
        className="continue-button-accesory"
      >
        {primaryButtonLabel}
      </Button>
    </div>
  </Modal>
);

ModalAccessories.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  hideAccessoriesModal: PropTypes.func.isRequired,
  onAccessoryButtonClicked: PropTypes.func.isRequired,
  onClickRadioButton: PropTypes.func.isRequired,
  showContinueButton: PropTypes.bool.isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
};

export default ModalAccessories;
