import {
  ON_DISPLAY_MODAL_INFO_PERMISSIONS,
  SET_PERMISSION_CAMERA,
  TRY_TO_TAKE_PHOTO,
} from '../actions/ActionTypes';

export const initialState = {
  tryToTakePhotoStatus: undefined,
  hasPermissionCamera: undefined,
  displayModalPermissions: false,
  error: undefined,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_PERMISSION_CAMERA: {
      const { status } = payload;
      return {
        ...state,
        hasPermissionCamera: status,
        displayModalPermissions: !status,
      };
    }
    case ON_DISPLAY_MODAL_INFO_PERMISSIONS:
      return { ...state, displayModalPermissions: payload.status };
    case TRY_TO_TAKE_PHOTO:
      return {
        ...state,
        tryToTakePhotoStatus: true,
        displayModalPermissions: !state.hasPermissionCamera,
      };
    default:
      return state;
  }
};
