import { PhotoHubActions } from '../../../actions';
import { conditionallyRequiredPhotos } from '../../../config';
import { AllowPhotoCountry } from '../../../config/AllowPhotoCountry';
import { buildRequiredPhotos } from '../../../config/requiredPhotosConf';
import {
  WIZARD_PHOTO_STEP,
  getClassificationPhotos,
} from '../../../constants/wizardPhotos';
import { getCountryCode } from '../../NavigationUtils';
import { ContitionalPhotoValidation } from './ConditionalPhotoValidation';

export class StepValidation {
  constructor(retake, wizardPhotoHub) {
    this.retake = retake;
    this.wizardPhotoHub = wizardPhotoHub;
  }

  setVehicleClass(vehicleClass) {
    this.vehicleClass = vehicleClass;
    return this;
  }

  setStep(currentStep) {
    this.step = currentStep;
    return this;
  }

  setRequiredPhotosTaken(photos) {
    this.requiredPhotosTaken = photos;
    return this;
  }

  setVideoParameters(paramsVideo, isFinishedVideo) {
    this.videoParameters = paramsVideo;
    this.finishedVideo = isFinishedVideo;
    return this;
  }

  setConditionalPhotoStatus(isEnabled) {
    this.spareWheelToggleEnabled = isEnabled;
    return this;
  }

  setDispatch(dispatch) {
    this.useDispatch = dispatch;
    return this;
  }

  setCountryCode(code) {
    this.countryCode = code;
    return this;
  }

  // Private method
  getPhotoListConfig = (vehicleClass, currentStep, photosConfig) => {
    const classifiedPhotos = getClassificationPhotos(vehicleClass);

    const stepPhotos = classifiedPhotos[currentStep];
    const conditionallyPhotoConfig = conditionallyRequiredPhotos(
      this.countryCode,
    );

    const allowPhotoCountry = new AllowPhotoCountry(getCountryCode());
    return [...photosConfig, ...conditionallyPhotoConfig].filter(
      item =>
        stepPhotos.includes(item.photoId) &&
        allowPhotoCountry.isAllow(item.photoId),
    );
  };

  validate() {
    if (this.step === WIZARD_PHOTO_STEP.FOUR) return null;

    const conditionalPhotoValidator = new ContitionalPhotoValidation(
      this.retake,
      this.wizardPhotoHub,
    );

    const isCompletedConditionallyPhotos = conditionalPhotoValidator
      .setStep(this.step)
      .setCountryCode(this.countryCode)
      .setVehicleClass(this.vehicleClass)
      .setConditionalPhotoStatus(this.spareWheelToggleEnabled)
      .validate();

    const photoIdOfUploadPhotos = this.requiredPhotosTaken.map(
      photo => photo.photoId,
    );

    let isUploadAllRequiredPhotosByStep = true;

    this.photosConfig = buildRequiredPhotos(this.countryCode);

    const configList = this.getPhotoListConfig(
      this.vehicleClass,
      this.step,
      this.photosConfig,
    );

    configList.forEach(requiredPhoto => {
      if (!photoIdOfUploadPhotos.includes(requiredPhoto.photoId)) {
        isUploadAllRequiredPhotosByStep = false;
      }
    });

    const { requiredVideo } = this.videoParameters;

    if (requiredVideo && !this.finishedVideo && this.step === 2)
      isUploadAllRequiredPhotosByStep = false;

    const isPhotosComplete =
      isCompletedConditionallyPhotos && isUploadAllRequiredPhotosByStep;

    if (isPhotosComplete) {
      this.useDispatch(PhotoHubActions.onChangeStatusPhotosStep(true));
    } else {
      this.useDispatch(PhotoHubActions.onChangeStatusPhotosStep(false));
    }

    return isPhotosComplete;
  }
}
