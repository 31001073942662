import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function ButtonRecording({
  recording,
  handlerRecording: { onStartRecording, onStopRecording },
}) {
  const handlerActionButton = () => {
    if (!recording) onStartRecording();
    else onStopRecording();
  };

  return (
    <button
      type="button"
      aria-label="button"
      className="record-video"
      onClick={() => handlerActionButton()}
    >
      <div
        className={!recording ? 'record-video__start' : 'record-video__stop'}
      />
    </button>
  );
}

ButtonRecording.propTypes = {
  recording: PropTypes.bool.isRequired,
  handlerRecording: PropTypes.shape({
    onStartRecording: PropTypes.func.isRequired,
    onStopRecording: PropTypes.func.isRequired,
  }).isRequired,
};

export default ButtonRecording;
