/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryCode } from '../utils/NavigationUtils';
import { StepValidation } from '../utils/helpers/validations/StepValidation';
import { WIZARD_PHOTO_STEP } from '../constants/wizardPhotos';

export const useValidationByStep = ({
  currentStep,
  wizardPhotoHub,
  spareWheelToggleEnabled,
  requiredPhotosTaken,
  vehicleClass,
  retake,
}) => {
  const {
    takeVideo: { videoParameters, finishedVideo },
  } = useSelector(store => store);

  const [isCompleteStep, setIsCompleteStep] = useState(false);

  const indexStepInArray = currentStep - 1;

  const dispachers = [
    wizardPhotoHub[indexStepInArray],
    wizardPhotoHub[indexStepInArray]?.photos,
    wizardPhotoHub[indexStepInArray]?.photos?.length,
    wizardPhotoHub[WIZARD_PHOTO_STEP.THREE - 1]?.conditionalPhotos,
    wizardPhotoHub[WIZARD_PHOTO_STEP.THREE - 1]?.conditionalPhotos.length,
  ];

  const dispatch = useDispatch();

  const stepValidator = useMemo(
    () => new StepValidation(retake, wizardPhotoHub),
    [retake, wizardPhotoHub],
  );

  useEffect(() => {
    const isCompleteStep = stepValidator
      .setStep(currentStep)
      .setCountryCode(getCountryCode())
      .setVehicleClass(vehicleClass)
      .setRequiredPhotosTaken(requiredPhotosTaken)
      .setConditionalPhotoStatus(spareWheelToggleEnabled)
      .setVideoParameters(videoParameters, finishedVideo)
      .setDispatch(dispatch)
      .validate();

    setIsCompleteStep(isCompleteStep);
  }, [
    dispatch,
    isCompleteStep,
    setIsCompleteStep,
    retake,
    currentStep,
    vehicleClass,
    requiredPhotosTaken,
    wizardPhotoHub,
    finishedVideo,
    videoParameters,
    indexStepInArray,
    spareWheelToggleEnabled,
    stepValidator,
    ...dispachers,
  ]);

  return isCompleteStep;
};
