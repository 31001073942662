import {
  ON_CHANGE_FAQ_ANSWER,
  ON_DISPLAY_HINTS_MODAL,
  HIDDEN_SECONDARY_BUTTON_MODAL,
  ON_DISPLAY_FINISH_CONFIRMATION_MODAL,
  ON_DISPLAY_RETAKE_PHOTO_MODAL,
  ON_DISPLAY_INSTRUCTIONS_MODAL,
  ON_DISPLAY_EXPIRED_SESSION_MODAL,
  ON_DISPLAY_FAQ_MODAL,
  ON_DISPLAY_INACTIVITY_TIMER_MODAL,
  ON_DISPLAY_REPEAT_VIDEO_MODAL,
  ON_DISPLAY_HINTS_VIDEO_MODAL,
  ON_DISPLAY_PLAY_VIDEO_MODAL,
} from './ActionTypes';

export default {
  onDisplayHintsModal(state) {
    return async dispatch => {
      dispatch({
        type: ON_DISPLAY_HINTS_MODAL,
        value: { isOpen: state },
      });
    };
  },
  onDisplayFinishConfirmationModal(state) {
    return async dispatch => {
      dispatch({
        type: ON_DISPLAY_FINISH_CONFIRMATION_MODAL,
        value: { isOpen: state },
      });
    };
  },

  onDisplayRetakePhotoModal(state) {
    return async dispatch => {
      dispatch({
        type: ON_DISPLAY_RETAKE_PHOTO_MODAL,
        value: { isOpen: state },
      });
    };
  },

  changeStateFaqAnswer(state) {
    return async dispatch => {
      dispatch({
        type: ON_CHANGE_FAQ_ANSWER,
        value: { isOpen: state },
      });
    };
  },

  changeStateFaqModal(state) {
    return async dispatch => {
      dispatch({
        type: ON_DISPLAY_FAQ_MODAL,
        value: { isOpen: state },
      });
    };
  },

  hiddenSecondaryButtonModal(state) {
    return async dispatch => {
      dispatch({
        type: HIDDEN_SECONDARY_BUTTON_MODAL,
        value: { hiddenButtonSecondary: state },
      });
    };
  },

  onDisplayInstructionsModal(isOpen, location) {
    return {
      type: ON_DISPLAY_INSTRUCTIONS_MODAL,
      value: { isOpen, location },
    };
  },

  onDisplayExpiredSessionModal(isOpen) {
    return {
      type: ON_DISPLAY_EXPIRED_SESSION_MODAL,
      value: { isOpen },
    };
  },

  onDisplayInactivityTimerModal(isOpen) {
    return {
      type: ON_DISPLAY_INACTIVITY_TIMER_MODAL,
      value: { isOpen },
    };
  },

  onDisplayRepeatVideoModal(isOpen) {
    return {
      type: ON_DISPLAY_REPEAT_VIDEO_MODAL,
      value: { isOpen },
    };
  },

  onDisplayPlayVideoModal(isOpen) {
    return {
      type: ON_DISPLAY_PLAY_VIDEO_MODAL,
      value: { isOpen },
    };
  },

  onDisplayVideoHintsModal(isOpen) {
    return {
      type: ON_DISPLAY_HINTS_VIDEO_MODAL,
      value: { isOpen },
    };
  },
};
