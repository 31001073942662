import React, { useCallback, useState } from 'react';
import useSound from 'use-sound';
import PropTypes from 'prop-types';
import photoSound from '../../../assets/sounds/take-photo-sound.mp3';
import './styles.scss';

function ButtonTakePhoto({ capturePhoto }) {
  const [pressAnimationButton, setPressAnimationButton] = useState('');
  const [play, { stop }] = useSound(photoSound, {
    interrupt: true,
    playbackRate: 1.2,
  });

  const onCounterAnimationPhoto = useCallback(() => {
    const timer = setTimeout(() => {
      setPressAnimationButton('');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const onCapturePhoto = useCallback(
    event => {
      if (event.type === 'touchstart') {
        setPressAnimationButton('--onpress --animation');
        onCounterAnimationPhoto();
        play();
      }
      return () => stop();
    },
    [onCounterAnimationPhoto, play, stop],
  );

  const onPressButton = event => {
    onCapturePhoto(event);
  };

  return (
    <button
      type="button"
      aria-label="button"
      className="take-photo"
      onClick={() => capturePhoto()}
      onTouchStart={onPressButton}
      onTouchEnd={onPressButton}
    >
      <div className={`take-photo__touch ${pressAnimationButton}`} />
    </button>
  );
}

ButtonTakePhoto.propTypes = {
  capturePhoto: PropTypes.func.isRequired,
};

export default ButtonTakePhoto;
