import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const PreviewPhoto = ({ picture }) => (
  <img className="preview__photo" alt="" src={picture} />
);

PreviewPhoto.defaultProps = {
  picture: '',
};

PreviewPhoto.propTypes = {
  picture: PropTypes.string,
};
