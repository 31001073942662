/* eslint-disable consistent-return */
import { STATUS_TYPES } from '@lmig-latam/adil-api-common-lib/constants';
import {
  getSupportRequest,
  sendSupportRequest,
  getVendorSuport,
  validateEmailToken,
} from '../api';

const {
  STATUS_CUSTOMER_NEW,
  STATUS_CUSTOMER_IN_PROGRESS,
  STATUS_CUSTOMER_RETAKE,
} = STATUS_TYPES;

const getSupportRequestDb = async countryCode => {
  let troublesAndSolutions;
  try {
    troublesAndSolutions = await getSupportRequest(countryCode);
    return troublesAndSolutions;
  } catch (error) {
    return error;
  }
};

const sendSupportRequestApp = async arrayTroubles => {
  try {
    return await sendSupportRequest(arrayTroubles);
  } catch (error) {
    return error;
  }
};

const requestVendorSupport = async dataUser => {
  const response = await validateEmailToken();
  if (
    [
      STATUS_CUSTOMER_NEW,
      STATUS_CUSTOMER_IN_PROGRESS,
      STATUS_CUSTOMER_RETAKE,
    ].includes(response.status)
  )
    await getVendorSuport(dataUser);
};

export default {
  getSupportRequestDb,
  sendSupportRequestApp,
  requestVendorSupport,
};
