import React from 'react';

const TimerIcon = () => (
  <svg width="97" height="97" viewBox="0 0 97 97">
    <g id="Grupo_5094" data-name="Grupo 5094" transform="translate(4.5 -0.5)">
      <g id="Grupo_4567" data-name="Grupo 4567" transform="translate(0.5)">
        <circle
          id="Oval"
          cx="48"
          cy="48"
          r="48"
          transform="translate(-4.5 1)"
          fill="#f0fbfc"
          stroke="#99e5ea"
          strokeWidth="1"
        />
      </g>
      <g
        id="Icon_Account_Experience_Time_64px"
        data-name="Icon/Account Experience/Time/64px"
        transform="translate(19.442 24.987)"
      >
        <g
          id="Combined_Shape"
          data-name="Combined Shape"
          transform="translate(0.058 0.25)"
        >
          <path
            id="Combined_Shape-2"
            data-name="Combined Shape"
            d="M.474,23.763A23.656,23.656,0,0,1,8.258,6.176H0V4.3H10.752a.936.936,0,0,1,.936.936V15.992H9.816V7.3A21.885,21.885,0,1,0,24.237,1.871V0A23.763,23.763,0,1,1,.474,23.763ZM23.55,24.4a.93.93,0,0,1-.249-.634V9.62h1.871V23.4l7.692,8.333L31.49,33Z"
            fill="none"
          />
          <path
            id="Combined_Shape-3"
            data-name="Combined Shape"
            d="M.474,23.763A23.656,23.656,0,0,1,8.258,6.176H0V4.3H10.752a.936.936,0,0,1,.936.936V15.992H9.816V7.3A21.885,21.885,0,1,0,24.237,1.871V0A23.763,23.763,0,1,1,.474,23.763ZM23.55,24.4a.93.93,0,0,1-.249-.634V9.62h1.871V23.4l7.692,8.333L31.49,33Z"
            fill="none"
          />
          <path
            id="Combined_Shape-4"
            data-name="Combined Shape"
            d="M.474,23.763A23.656,23.656,0,0,1,8.258,6.176H0V4.3H10.752a.936.936,0,0,1,.936.936V15.992H9.816V7.3A21.885,21.885,0,1,0,24.237,1.871V0A23.763,23.763,0,1,1,.474,23.763ZM23.55,24.4a.93.93,0,0,1-.249-.634V9.62h1.871V23.4l7.692,8.333L31.49,33Z"
            fill="none"
          />
          <path
            id="Combined_Shape-5"
            data-name="Combined Shape"
            d="M.474,23.763A23.656,23.656,0,0,1,8.258,6.176H0V4.3H10.752a.936.936,0,0,1,.936.936V15.992H9.816V7.3A21.885,21.885,0,1,0,24.237,1.871V0A23.763,23.763,0,1,1,.474,23.763ZM23.55,24.4a.93.93,0,0,1-.249-.634V9.62h1.871V23.4l7.692,8.333L31.49,33Z"
            fill="none"
          />
          <path
            id="Combined_Shape-6"
            data-name="Combined Shape"
            d="M.474,23.763A23.656,23.656,0,0,1,8.258,6.176H0V4.3H10.752a.936.936,0,0,1,.936.936V15.992H9.816V7.3A21.885,21.885,0,1,0,24.237,1.871V0A23.763,23.763,0,1,1,.474,23.763ZM23.55,24.4a.93.93,0,0,1-.249-.634V9.62h1.871V23.4l7.692,8.333L31.49,33Z"
            fill="none"
          />
          <path
            id="Combined_Shape-7"
            data-name="Combined Shape"
            d="M.474,23.763A23.656,23.656,0,0,1,8.258,6.176H0V4.3H10.752a.936.936,0,0,1,.936.936V15.992H9.816V7.3A21.885,21.885,0,1,0,24.237,1.871V0A23.763,23.763,0,1,1,.474,23.763ZM23.55,24.4a.93.93,0,0,1-.249-.634V9.62h1.871V23.4l7.692,8.333L31.49,33Z"
            fill="#1a1446"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default TimerIcon;
