/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PhotoHubActions, TakePhotoActions } from '../../actions';
import { generatePhotoObjects } from '../../utils';
import { logcodes, logger } from '../../utils/logger';
import { getPhotoType } from '../../constants/wizardPhotos';
import { ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO } from '../../actions/ActionTypes';
import { PHOTO_TYPES } from '../../utils/constants';

const CapturePhoto = ({ webcamRef, photoHubActions, photoId }) => {
  const dispatch = useDispatch();

  const {
    photos: { presignedURLS, currentStep },
    deviceInfo: { geoLocation, GPSStatus },
  } = useSelector(store => store);

  const onUploadPregress = progressEvent => {
    const { loaded, total } = progressEvent;
    const percentCompleted = Math.round((loaded * 100) / total);
    dispatch(TakePhotoActions.onUpdateLoadPhotoPercentage(percentCompleted));
  };

  useEffect(() => {
    // reset the scroll at the beginning every time you change step
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [currentStep]);

  const uploadPhotoHandler = async (photoToUpload, photoToDisplay) => {
    try {
      // Step for searching in photos required array
      let presignedPhoto = presignedURLS.find(
        i => i.name === photoToUpload.photoId,
      );
      dispatch({
        type: ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO,
        payload: {
          photoError: undefined,
        },
      });

      if (!presignedPhoto) {
        // Steps for accesories
        const photoIdArray = [photoToUpload.photoId];
        const [presigned] = await photoHubActions.getPresignedURLArray(
          photoIdArray,
          true,
        );
        presignedPhoto = presigned;
      }

      if (!presignedPhoto) {
        logger.log(logcodes.UPF20);
        return;
      }

      const location = {
        ...geoLocation,
        status: GPSStatus?.message,
      };

      await photoHubActions.uploadPhoto(
        photoToUpload,
        photoToDisplay,
        presignedPhoto,
        location,
        onUploadPregress,
      );

      dispatch({
        type: ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO,
        payload: {
          photoError: undefined,
        },
      });
    } catch (error) {
      dispatch({
        type: ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO,
        payload: {
          photoError: photoToDisplay.photoType,
        },
      });
    } finally {
      dispatch(TakePhotoActions.onUpdateLoadPhotoPercentage(null));
    }
  };

  const onChange = async ({ currentTarget: { files = [] } }) => {
    dispatch(TakePhotoActions.onUpdateLoadPhotoPercentage(1));

    const photoType = getPhotoType(photoId);

    let compressValue = 0.6;
    if (
      photoId === 'INSPECTION_PHOTO_PROPERTY_CARD_FRONT' ||
      photoId === 'INSPECTION_PHOTO_PROPERTY_CARD_BACK'
    ) {
      compressValue = 0.1;
    }

    // surround in try catch for wrong file type
    const { photoToUpload, photoToDisplay } = await generatePhotoObjects({
      incomingFile: files[0],
      photoId,
      photoType,
      step: currentStep,
      compress: compressValue,
    });

    if (PHOTO_TYPES.DAMAGE_PHOTO === photoType) photoToDisplay.isDamage = true;

    await uploadPhotoHandler(photoToUpload, photoToDisplay);
    const component = document.getElementById(photoId);
    if (component) {
      window.scrollTo({
        behavior: 'smooth',
        top: component.offsetTop,
        left: 0,
      });
    }
  };

  return (
    <>
      <input
        className="adil-hide"
        type="file"
        ref={webcamRef}
        accept="image/*"
        capture
        onChange={onChange}
      />
    </>
  );
};

CapturePhoto.propTypes = {
  webcamRef: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
  photoId: PropTypes.string.isRequired,
  photoHubActions: PhotoHubActions.PropertyTypes.isRequired,
  retake: PropTypes.shape({}),
};

CapturePhoto.defaultProps = {
  retake: {},
};

const mapStateToProps = ({
  settings: {
    retake,
    environment: { language },
  },
}) => ({
  retake,
  language,
});

const mapDispatchToProps = dispatch => ({
  photoHubActions: bindActionCreators(PhotoHubActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CapturePhoto);
