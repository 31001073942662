import React from 'react';

function IconBackArrow() {
  return (
    <svg width="20.774" height="16" viewBox="0 0 20.774 16">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M7.407,15.755.255,8.6a.842.842,0,0,1-.128-.158h0l-.01-.017h0l-.01-.017h0L.1,8.39h0A.834.834,0,0,1,.006,8.1h0l0-.019V8.032s0-.007,0-.011S0,8.007,0,8s0-.014,0-.021,0-.007,0-.011V7.919l0-.02h0a.838.838,0,0,1,.259-.512L7.407.246A.839.839,0,0,1,8.593,1.433L2.866,7.16H19.935a.839.839,0,0,1,0,1.679H2.865l5.729,5.728a.839.839,0,1,1-1.186,1.187Z"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Color_Liberty_Blue" data-name="Color/Liberty Blue">
        <path
          id="Mask-2"
          data-name="Mask"
          d="M7.407,15.755.255,8.6a.842.842,0,0,1-.128-.158h0l-.01-.017h0l-.01-.017h0L.1,8.39h0A.834.834,0,0,1,.006,8.1h0l0-.019V8.032s0-.007,0-.011S0,8.007,0,8s0-.014,0-.021,0-.007,0-.011V7.919l0-.02h0a.838.838,0,0,1,.259-.512L7.407.246A.839.839,0,0,1,8.593,1.433L2.866,7.16H19.935a.839.839,0,0,1,0,1.679H2.865l5.729,5.728a.839.839,0,1,1-1.186,1.187Z"
          fill="#fff"
        />
        <g
          id="Color_Liberty_Blue-2"
          data-name="Color/Liberty Blue"
          clipPath="url(#clip-path)"
        >
          <g
            id="Color_Liberty_Blue-3"
            data-name="Color/Liberty Blue"
            transform="translate(-33.778 -36.444)"
          >
            <rect id="Rectangle" width="88.889" height="88.889" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconBackArrow;
