import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { store } from '../../utils/configureStore';
import libertyLogo from '../../assets/gifs/liberty-logo.svg';
import loaderSplash from '../../assets/gifs/loaderSplash.svg';

const AppLoader = ({ displayed, customTitle, customSubtitle }) => {
  const {
    settings: {
      environment: { language },
    },
  } = store.getState();
  const { MAIN_LOADER_TITLE, MAIN_LOADER_SUBTITLE } = localizedStrings(
    language,
  );
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
    }, 1000);

    if (!displayed) {
      clearTimeout(timer);
      setCounter(0);
    }
  }, [counter, displayed]);

  return (
    displayed && (
      <div className="mainLoader">
        <div className="mainLoader__container__load">
          <div className="mainLoader__load">
            <img
              className="mainLoader__load__splash"
              src={loaderSplash}
              alt="loader - load splasher"
            />
            <img
              className="mainLoader__load__libertyLogo"
              src={libertyLogo}
              alt="libertyLogo - load splasher"
            />
          </div>
        </div>
        <div className="mainLoader__container__text" id="textComponent">
          {counter > 7 && (
            <div>
              <p className="mainLoader__text">
                {customTitle || MAIN_LOADER_TITLE}
              </p>
              <p className="mainLoader__text">
                {customSubtitle || MAIN_LOADER_SUBTITLE}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

AppLoader.propTypes = {
  displayed: PropTypes.bool.isRequired,
  customTitle: PropTypes.string,
  customSubtitle: PropTypes.string,
};

AppLoader.defaultProps = {
  customTitle: undefined,
  customSubtitle: undefined,
};

const mapStateToProps = ({
  settings: {
    appLoader: { displayed, customSubtitle, customTitle },
  },
}) => ({
  displayed,
  customSubtitle,
  customTitle,
});

export default connect(mapStateToProps, null)(AppLoader);
