import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ProcessStepsButtons from '../ProcessStepsList/ProcessStepsButtons';
import { useValidationByStep } from '../../hooks/useValidationByStep';
import { useTakenPhoto, useValidationCompleteProcess } from '../../hooks';
import {
  WIZARD_PHOTO_STEP,
  initialWizardPhotoHub,
} from '../../constants/wizardPhotos';
import { ModalFlowActions, PhotoHubActions } from '../../actions';
import MenuFooter from '../MenuFooter/MenuFooter';
import { useScroll } from '../../hooks/useScroll';
import ScroollEffectsActions from '../../actions/ScroollEffectsActions';
import { getCountryCode } from '../../utils/NavigationUtils';
import { buildRequiredPhotos } from '../../config/requiredPhotosConf';
import './styles.scss';

const MAXIMUM_NUMBER_OF_STEPS = initialWizardPhotoHub.length;

const MainTools = ({ vehicleClass }) => {
  const dispatch = useDispatch();

  const {
    photos: {
      wizardPhotoHub,
      currentStep,
      stepsToShow,
      isAllowContinueStep,
      spareWheelToggleEnabled,
    },
    settings: { retake },
    scroll: { displayMenuFooter },
  } = useSelector(store => store);

  const photosConfig = buildRequiredPhotos(getCountryCode());

  const { requiredPhotosTaken = [] } = useTakenPhoto();

  const { scrollDirection, scrollValue } = useScroll();

  const isAllPhotoCompleted = useValidationCompleteProcess(
    currentStep,
    wizardPhotoHub,
    photosConfig,
    spareWheelToggleEnabled,
  );

  useValidationByStep({
    currentStep,
    wizardPhotoHub,
    photosConfig,
    spareWheelToggleEnabled,
    requiredPhotosTaken,
    vehicleClass,
    retake,
  });

  const changeSteps = goForward => {
    const index = stepsToShow.indexOf(currentStep);
    if (goForward) return stepsToShow[index + 1] || null;
    return stepsToShow[index - 1] || null;
  };

  const onContinue = () => {
    const nextStep = changeSteps(true);
    if (!nextStep || nextStep > MAXIMUM_NUMBER_OF_STEPS) {
      dispatch(ModalFlowActions.onDisplayFinishConfirmationModal(true)); // Finish process
      return;
    }
    dispatch(PhotoHubActions.onChangeCurrentStep(nextStep));
  };

  const onBack = () => {
    if (currentStep === stepsToShow[0]) return;
    dispatch(PhotoHubActions.onChangeCurrentStep(changeSteps(false)));
  };

  /*
    @isAllPhotoCompleted: This is the result of the validation of the hook to know if the user has already
    taken all the mandatory photos, this hook will only be executed in step 4.
   */
  useEffect(() => {
    if (WIZARD_PHOTO_STEP.FOUR === currentStep) {
      dispatch(PhotoHubActions.onChangeStatusPhotosStep(isAllPhotoCompleted));
    }
  }, [isAllPhotoCompleted, currentStep, dispatch]);

  useEffect(() => {
    const displayMenu = scrollDirection === 'up' || scrollValue === 0;
    dispatch(ScroollEffectsActions.onDisplayMenuFooter(displayMenu));
  }, [scrollDirection, scrollValue, dispatch]);

  return (
    <>
      <div className="main-tools">
        <ProcessStepsButtons
          isAllowBack={currentStep !== 1}
          isAllowContinue={isAllowContinueStep}
          showBackButton={currentStep === stepsToShow[0]}
          onBack={onBack}
          onContinue={onContinue}
          isLastStep={currentStep === stepsToShow[stepsToShow.length - 1]}
          currentStep={currentStep}
        />

        {displayMenuFooter && <MenuFooter />}
      </div>
    </>
  );
};

MainTools.propTypes = {
  vehicleClass: PropTypes.string.isRequired,
};

export default MainTools;
