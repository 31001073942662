import { Button, Content, GridCol, GridRow, Heading } from '@lmig/lmds-react';
import { IconSuccessAlert } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  MenuFooter,
} from '../../components';
import { isOutsideBusinessHours } from '../../utils';
import { ProcessCompleteActions } from '../../actions';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../utils/NavigationUtils';

const ProcessComplete = ({
  processCompleteRedirectUrl,
  language,
  processCompleteActions: { deleteAllCookies },
}) => {
  const {
    PROCESS_COMPLETE_TITLE,
    PROCESS_COMPLETE_SUBTITLE_ONE,
    PROCESS_COMPLETE_SUBTITLE_TWO,
    PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS,
    PROCESS_COMPLETE_CONTINUE_BUTTON,
  } = localizedStrings(language);

  const submit = async () => {
    // Navigate to Inspections Portal
    window.location.href = processCompleteRedirectUrl;
  };

  useEffect(() => {
    deleteAllCookies();
  }, [deleteAllCookies]);

  const renderFooterContents = () => (
    <GridRow className="process-complete-footer">
      <GridCol className="align-center">
        <Button type="button" onClick={submit}>
          {PROCESS_COMPLETE_CONTINUE_BUTTON}
        </Button>
      </GridCol>
    </GridRow>
  );

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader displaySubtitle />
      <Content className="adil-content process-complete">
        <CircleImage size="small">
          <IconSuccessAlert size="48" />
        </CircleImage>
        <Heading type="h3-light" className="auto-left-right-margin">
          {PROCESS_COMPLETE_TITLE}
        </Heading>
        <Heading
          type="h4-light"
          className="process-complete-subtitle auto-left-right-margin"
        >
          {isOutsideBusinessHours()
            ? PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS
            : PROCESS_COMPLETE_SUBTITLE_ONE}
        </Heading>
        <Heading
          type="h4-light"
          className="process-complete-subtitle auto-left-right-margin"
        >
          {isOutsideBusinessHours() ? '' : PROCESS_COMPLETE_SUBTITLE_TWO}
        </Heading>
        {renderFooterContents()}
        {renderFooterSection()}
      </Content>
      <MenuFooter />
    </div>
  );
};

ProcessComplete.propTypes = {
  processCompleteActions: ProcessCompleteActions.PropertyTypes.isRequired,
  processCompleteRedirectUrl: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  processCompleteActions: bindActionCreators(ProcessCompleteActions, dispatch),
});

const mapStateToProps = ({ settings: { environment } }) => {
  const { processCompleteRedirectUrl } = environment[getCountryCode()];
  return {
    processCompleteRedirectUrl,
    language: environment.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessComplete);
