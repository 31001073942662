/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@lmig/lmds-react';
import AccessoriesSection from '../AccessoriesSection';
import NotifyProcess from '../NotifyProcess/NotifyProcess';
import { RetakeAccessoryList } from './RetakeAccessoryList';

export const OptionalStepList = ({
  navigate,
  accessoryPhotosTaken,
  photosConfig,
}) => {
  const {
    settings: { retake },
  } = useSelector(store => store);

  return (
    <>
      <Content>
        {!retake ? (
          <>
            <NotifyProcess />
            <AccessoriesSection
              navigate={navigate}
              photosTaken={accessoryPhotosTaken}
            />
          </>
        ) : (
          <RetakeAccessoryList
            photosConfig={photosConfig}
            navigate={navigate}
            accessoryPhotosTaken={accessoryPhotosTaken}
          />
        )}
      </Content>
    </>
  );
};

OptionalStepList.propTypes = {
  accessoryPhotosTaken: PropTypes.arrayOf(PropTypes.object).isRequired,
  photosConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
};
