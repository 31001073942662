import PropTypes from 'prop-types';
import {
  CHANGE_LANGUAGE,
  HIDE_APP_LOADER,
  ON_CHANGE_STATE_FULLSCREEN,
  SHOW_APP_LOADER,
  UPDATE_DISPLAY_HINTS,
  HINT_IMAGES_LOADED,
} from './ActionTypes';

export default {
  PropertyTypes: PropTypes.shape({
    showAppLoader: PropTypes.func.isRequired,
    hideAppLoader: PropTypes.func.isRequired,
    updateDisplayHints: PropTypes.func.isRequired,
  }),

  showAppLoader(customTitle, customSubtitle) {
    return {
      type: SHOW_APP_LOADER,
      customTitle,
      customSubtitle,
    };
  },

  hideAppLoader() {
    return {
      type: HIDE_APP_LOADER,
    };
  },

  updateDisplayHints(displayHints) {
    return {
      type: UPDATE_DISPLAY_HINTS,
      value: displayHints,
    };
  },

  setLanguage(lang) {
    return {
      type: CHANGE_LANGUAGE,
      payload: { lang },
    };
  },

  changeStateFullScreen(isFullscreen) {
    return {
      type: ON_CHANGE_STATE_FULLSCREEN,
      payload: { isFullscreen },
    };
  },

  setHintImagesLoaded() {
    return {
      type: HINT_IMAGES_LOADED,
    };
  },
};
