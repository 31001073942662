import constants from '@lmig-latam/adil-api-common-lib/constants/constants';

const {
  LANGUAGES: { CO },
} = constants;

const code = CO;

const strings = {
  BROWSER_TAB_TITLE: 'Inspección digital Liberty Autos – Liberty Seguros',
  APP_HEADER_SUBTITLE: 'Inspección digital Liberty Autos',
  APP_HEADER_SUBTITLE_MOTORBIKE: 'Inspección digital Liberty Motos',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Liberty Seguros',
  LIBERTY_SECONDARY_LOGO_IMAGE: '',
  LIBERTY_SECONDARY_LOGO_IMAGE_ALT_TEXT: '',
  VIGILADO_IMAGE_ALT_TEXT: 'VIGILADO SUPERINTENDENCIA BANCARIA DE COLOMBIA',

  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: 'Ha ocurrido un error',
  ADMIN_SUPPORT_ERROR_GENERAL: 'Por favor, vuelva a intentar realizar la acción',

  MAIN_LOADER_TITLE: 'Este proceso puede tardar algunos segundos más.',
  MAIN_LOADER_SUBTITLE: 'Por favor espera mientras seguimos realizando la consulta.',

  LOADING_TEXT: 'Cargando',
  INLINE_LOADING_TEXT: '...Cargando',
  LOADING_TEXT_DETECTING_BROWSER: 'Detectando navegador y sistema operativo...',

  FLOAT_MENU_ITEM_1: 'Preguntas Frecuentes',
  FLOAT_MENU_ITEM_2: 'Cambiar lenguaje',
  FLOAT_MENU_ITEM_3: 'Inicio',

  APP_FOOTER_NEED_HELP_LINE_1: '¿Necesitas ayuda con tu inspección digital?',
  APP_FOOTER_NEED_HELP_LINE_2: 'Línea Nacional',
  APP_FOOTER_TERMS_CONDITIONS_LINK: 'Términos y condiciones',
  APP_FOOTER_PRIVACY_POLICY_LINK: 'Política de privacidad',
  APP_FOOTER_COPYRIGHT: 'Liberty Seguros {year}',
  APP_FOOTER_TITLE: 'Liberty {year}. Todos los derechos reservados',

  ERROR_MESSAGE_TITLE: 'Lo sentimos, el proceso ya fue iniciado o no funciona correctamente',
  NON_BROWSER_ERROR_MESSAGE_TITLE: 'Hemos detectado que estas usando un navegador que no es compatible con la aplicación',
  ERROR_MESSAGE_SUBTITLE: 'Si necesitas ayuda, puedes contáctarnos a través de nuestros canales de atención',
  NON_BROWSER_ERROR_MESSAGE_SUBTITLE: 'Te recomendamos copiar este link en alguno de los siguientes navegadores:',
  BUTTON_CLIPBOARD: 'Copiar',
  TEXT_COPIED: 'Copiado',

  NON_MOBILE_ERROR_HEADER_TITLE: 'Inspección fotográfica',
  NON_MOBILE_ERROR_MESSAGE_TITLE: 'Necesitamos que tomes las fotografías con celular',
  NON_MOBILE_ERROR_MESSAGE_SUBTITLE: 'Intenta realizar el proceso y tomar las fotografías con tu cámara del celular',
  NON_MOBILE_ERROR_PRIMARY_MESSAGE: 'Para más información comunícate desde Bogotá a la línea ',
  NON_MOBILE_ERROR_PRIMARY_PHONE_NUMBER: '315-8738849',
  NON_MOBILE_ERROR_OTHER_COUNTRY_MESSAGE: 'o desde cualquier lugar del país ',
  NON_MOBILE_ERROR_OTHER_COUNTRY_PHONE_NUMBER: '315-8738849',

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404: ¡Página no encontrada!',

  GPS_PERMISSION_DENIED: 'Permiso denegado',
  GPS_PERMISSION_ENABLED: 'Permiso habilitado',
  GPS_ERROR_NOT_SUPPORT: 'El navegador no tiene soporte para GPS',
  GPS_POSITION_ERROR_UNAVAILABLE: 'Posicion no disponible',
  GPS_POSITION_ERROR_TIMEOUT: 'Tiempo de espera de geolocalización',

  MODAL_PRIMARY_BUTTON_LABEL: 'Continuar',
  MODAL_LAST_BUTTON_LABEL: 'Cerrar',

  PHONE_NUMBER_CONFIRMATION_TITLE: 'Es necesario validar tu identidad para iniciar el proceso',
  PHONE_NUMBER_CONFIRMATION_SUBTITLE: 'Ingresa el código que acabamos de enviar a tu número celular',
  PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT: 'Celular y mensaje',
  PHONE_NUMBER_CONFIRMATION_INPUT_PLACEHOLDER: 'Ingresa el código aquí',
  PHONE_NUMBER_CONFIRMATION_INPUT_VALIDATION_MESSAGE: 'Por favor ingrese el código SMS de 6 dígitos',
  PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE: 'El código ingresado no coincide. Ingrésalo nuevamente',
  PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED: '¿No recibiste el código? ',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE: 'Reenviar',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS: 'Mensaje enviado',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE: 'Lo sentimos, ocurrió un error',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE: 'No fue posible enviar el mensaje de texto',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE_TWO: 'Inténtalo nuevamente',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL: 'Cerrar',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE: 'Lo sentimos has excedido el\nlimite de intentos  permitidos',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE: 'Puedes ingresar e intentar de nuevo\nen 10 minutos',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL: 'REENVIAR',
  PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON: 'VERIFICAR',
  PHONE_NUMBER_CONFIRMATION_LOADING_TEXT: 'Por favor, espera...',

  TERMS_CONDITIONS_TITLE: 'Bienvenido',
  TERMS_CONDITIONS_SUBTITLE: 'No olvides revisar los términos y condiciones para continuar',
  TERMS_CONDITIONS_SUBTITLE_2: 'Te damos la bienvenida al proceso de inspección digital para tu vehículo',
  TERMS_CONDITIONS_MOTORBIKE_SUBTITLE_2: 'Te damos la bienvenida al proceso de inspección digital para tu moto',
  TERMS_CONDITIONS_SHOW_MODAL_BUTTON: 'Términos de uso y privacidad',
  TERMS_CONDITIONS_DISCLAIMER: 'Al dar clic en comenzar, entendemos que estás de acuerdo con los términos y condiciones',
  TERMS_CONDITIONS_DISCLAIMER_2: 'Antes de comenzar debes aceptar los ',
  TERMS_CONDITIONS_CONTINUE_BUTTON: 'COMENZAR',
  TERMS_CONDITIONS_LOADING_TEXT: 'Por favor, espera...',

  HELP_SUPPORT_BUTTON: 'Solicitar soporte',

  TERMS_CONDITIONS_MODAL_CONTENT: {
    CO: [
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'TERMINOS DE USO Y PRIVACIDAD',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP:
          'Al acceder a esta Aplicación: Adil de propiedad de Liberty Seguros, el usuario asume el compromiso de seguir y cumplir los términos y condiciones que se indicarán a continuación (en adelante los "Términos y Condiciones"). En caso de que no esté de acuerdo con los Términos y Condiciones le rogamos que se abstenga de utilizarla. Estos Términos y Condiciones, así como el contenido, diseño y organización de la aplicación, podrán ser modificados en  cualquier momento. En caso de modificación de los Términos y Condiciones, su nueva redacción será inmediatamente publicada y se encontrará accesible en esta Aplicación, resultando aplicable desde ese momento. El uso de  la Aplicación, incluyendo el acceso por los usuarios y la navegación a  través del mismo, es libre y gratuito e implica el cumplimiento de los presentes Términos y Condiciones, de la legislación Colombiana y de las buenas costumbres y  los usos aceptados en el ámbito de Internet. Los presentes Términos y Condiciones sonaplicables a todos los usuarios que utilicen el Sitio Web. No obstante, en caso de que en el futuro se incluyan nuevas secciones en el Sitio Web que presten servicios específicos, Liberty podría incluir en las mismas Términos y Condiciones específicos, aplicables a dichas secciones de manera  preferente sobre sobre los presentes Términos y Condiciones, o bien complementando los mismos. En todas las referencias entiéndase por Liberty, la compañía HDI Seguros Colombia S.A.',
      },
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Exclusión de Responsabilidad',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP: 'El usuario acepta que él es el único responsable por las decisiones que adopte con base en la información o en los materiales de esta Aplicación o de sus vínculos o enlaces. Liberty no asume  responsabilidad alguna por los daños y perjuicios de toda naturaleza que pudieran derivarse de la utilización inadecuada o negligente de los servicios y de los contenidos por parte del usuario, ni por los que puedan derivarse de la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la información que el usuario proporciona a otros usuarios acerca de sí mismos y, en particular, aunque no de  forma  exclusiva, por los daños y perjuicios de toda naturaleza que puedan derivarse de la suplantación de la personalidad de un tercero  efectuada por un usuario en cualquier clase de comunicación realizada a través de ésta Aplicación.',
      },
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Privacidad',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP: 'Liberty por otra parte, garantiza a los usuarios de esta Aplicación que la información personal que estos depositen en nuestro servidor, no será divulgada por ningún medio salvo disposición legal, requerimiento de autoridadcompetente o   autorización expresa del titular. La Aplicación, será manejado por personal calificado y sujeto a las disposiciones y políticas que la Ley y Liberty exigen, hecho que   garantiza la confidencialidad y buen manejo de la información.',
      },
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Propiedad Intelectual',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP:
          'Todos los diseños, marcas, nombres, denominaciones, imágenes, logos, gráficos, iconos, nombres de dominio, aplicaciones, secuencias de  código autoejecutables y todo otro contenido de la Aplicación pertenecen a Liberty o, en su caso, Liberty cuenta con licencia para su uso, y gozan, en consecuencia, de la  protección  propia de  los derechos sobre la propiedad intelectual e industrial. Todos  los  derechos  de  propiedad intelectual y copyright de loscontenidos de  esta  Aplicación se  reservan  a Liberty y sus licenciantes  y en ningún caso el acceso a la Aplicación implica dejación alguna por parte de Liberty en dichos derechos a favor del usuario. Los usuarios de la Aplicación pueden hacer uso privado de la Aplicación y de su contenido. En ningún caso podrán hacer uso comercial de los mismos, ni alterarlos en cualquier manera, reproducirlos más allá de su uso privado, distribuirlos o comunicarlos públicamente. Para la distribución y/o comunicación pública de los diseños, marcas, nombres, denominaciones, imágenes, logos, gráficos, iconos, nombresde dominio, aplicaciones, secuencias de código autoejecutables y cualquier contenido del Sitio Web, el usuario requiere la autorización previa y por escrito por parte de Liberty.',
      },
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Autorización de Tratamiento de Datos Personales:',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP:
          'Autorizo a HDI Seguros Colombia S.A. para que con fines estadísticos, comerciales, financieros y administrativos, consulte, transfiera, comparta, solicite, procese, reporte, y divulgue con su matriz, filiales, subsidiarias, Fasecolda e Inverfaz y contratistas, la información confidencial (datos personales) que resulte de todas las operaciones que directa o indirectamente se hayan otorgado o se otorguenen el futuro en virtud de los servicios de esta relación comercial o contrato que declaro conocer y aceptar en todas sus partes. Declaro haber leído y acepto las Políticas de Tratamiento de Datos de nuestra Compañía, las cuales se encuentran disponible en www.libertycolombia.com.co.Que son facultativas las respuestas a las preguntas que me han hecho o me harán sobre datos personales sensibles, así como las preguntas sobre niños, niñas y adolescentes; y autorizo a Liberty, como responsable del tratamiento de mis datos personales y sensibles. Además, me comprometo a actualizar toda mi información en forma anual y autorizo a Liberty a actualizar o enriquecer toda mi información con aquella que reposa en las centrales de información financiera legalmenteconstituidas en Colombia, con fines de vinculación, estadísticos, de tarifación, de análisis de riesgo, de actualización de datos, control, supervisión y de información comercial, procese, conserve, consulte. Autorizo voluntariamente que información de  mis  pólizas  y/o información general de Liberty me sea enviada por algún medio electrónico (mensaje de texto al celular reportado como de mi uso o propiedad, correo electrónico, entre otros).',
      },
    ],
  },
  TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL: 'ACEPTAR Y CONTINUAR',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE: 'Documentación',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE: 'Exterior del vehículo',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE: 'Interior del vehículo',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE: 'Accesorios',

  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE: 'Documentación',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE: 'Exterior de la moto',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE: 'Instrumentos e identificación',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE: 'Accesorios',
  WIZARD_PHOTO_HUB_FINISH_TITLE: 'Finalizar y enviar',

  PHOTO_HUB_TITLE: 'Fotografías',
  PHOTO_HUB_SUBTITLE: 'Toma las fotografías teniendo en cuenta las indicaciones en cada una',
  PHOTO_HUB_HINTS_TOGGLE_ENABLED_LABEL: 'Desactivar instrucciones',
  PHOTO_HUB_HINTS_TOGGLE_DISABLED_LABEL: 'Activar instrucciones',
  PHOTO_HUB_FINISH_PROGRESS: '...finalizando',
  PHOTO_HUB_LOAD_TEST_IMAGES_PROGRESS: 'Comprimiendo y subiendo los datos de prueba...',
  PHOTO_HUB_FOOTER_PROGRESS: '{photosTaken} de {photosRequired} fotos tomadas',
  PHOTO_HUB_FOOTER_SUCCESS_VIDEO: 'Video completado',
  PHOTO_HUB_FOOTER_PENDING_VIDEO: 'Video no realizado',
  PHOTO_HUB_CONTINUE_BUTTON: 'Finalizar proceso',
  PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT: 'Avance',
  PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT: 'Volver a tomar la foto',
  PHOTO_HUB_SUBMIT_LOADER_TEXT: 'Enviando fotografías',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE: 'Ups, lo sentimos',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE: 'Algo ha ocurrido, no se ha podido enviar, inténtalo de nuevo',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL: 'Volver a procesar',
  PHOTO_HUB_LOAD_TEST_IMAGES_BUTTON_LABEL: 'Cargar datos de prueba',
  PHOTO_HUB_SHOW_CAMERA_BUTTON_LABE: 'Ver camara',
  PHOTO_HUB_UPLOAD_ERROR_MESSAGE: 'Ocurrió un error al subir la imagen. Vuelve a intentarlo',

  PHOTO_HUB_STEP_01_TITLE: 'Frente tarjeta de propiedad',
  PHOTO_HUB_STEP_01_SUBTITLE: 'La tarjeta de propiedad del vehículo que quieres inspeccionar.',
  PHOTO_HUB_STEP_01_HINT: 'Toma una foto de la parte frontal de la tarjeta de propiedad.',

  PHOTO_HUB_STEP_02_TITLE: 'Reverso tarjeta de propiedad',
  PHOTO_HUB_STEP_02_SUBTITLE: 'La tarjeta de propiedad del vehículo que quieres inspeccionar.',
  PHOTO_HUB_STEP_02_HINT: 'Toma una foto de la parte de atrás de la tarjeta de propiedad.',

  PHOTO_HUB_STEP_03_TITLE: 'Placa',
  PHOTO_HUB_STEP_03_SUBTITLE: 'La matrícula del vehículo que quieres inspeccionar.',
  PHOTO_HUB_STEP_03_HINT: 'Flexiona tus rodillas y ubica tu teléfono frente a la placa.',

  PHOTO_HUB_STEP_04_TITLE: 'Parte delantera del vehículo',
  PHOTO_HUB_STEP_04_SUBTITLE: 'Es la parte delantera del vehículo donde se encuentra el motor.',
  PHOTO_HUB_STEP_04_HINT: 'Mantén tu teléfono a la altura de tu cabeza e inclínalo hacia abajo un poco.',

  PHOTO_HUB_STEP_05_TITLE: 'Parte trasera del vehículo',
  PHOTO_HUB_STEP_05_SUBTITLE: 'Es la parte de atrás donde normalmente se encuentra el baúl.',
  PHOTO_HUB_STEP_05_HINT: 'Mantén tu teléfono a la altura de tu cabeza e inclínalo hacia abajo un poco.',

  PHOTO_HUB_STEP_06_TITLE: 'Lateral derecha',
  PHOTO_HUB_STEP_06_SUBTITLE: 'La parte derecha del vehículo en donde se se sienta el copiloto.',
  PHOTO_HUB_STEP_06_HINT: 'Mantén tu teléfono a la altura de tus hombros.',

  PHOTO_HUB_STEP_07_TITLE: 'Lateral izquierda',
  PHOTO_HUB_STEP_07_SUBTITLE: 'La parte izquierda del vehículo cuando estas sentado en el asiento del conductor.',
  PHOTO_HUB_STEP_07_HINT: 'Mantén tu teléfono a la altura de tus hombros.',

  PHOTO_HUB_STEP_08_TITLE: 'Habitáculo del motor',
  PHOTO_HUB_STEP_08_SUBTITLE: 'Corresponde al espacio donde se almacena el motor del vehículo.',
  PHOTO_HUB_STEP_08_HINT: 'Mantén tu teléfono a la altura de tu cabeza e inclina la toma un poco.',

  PHOTO_HUB_STEP_09_TITLE: 'Tablero de instrumentos',
  PHOTO_HUB_STEP_09_SUBTITLE: 'Imagen donde se aprecie todo el panel central, tablero completo delantero.',
  PHOTO_HUB_STEP_09_HINT: 'Siéntate en el puesto de atrás para lograr una toma completa de la parte delantera.',

  PHOTO_HUB_STEP_10_TITLE: 'Kilometraje',
  PHOTO_HUB_STEP_10_SUBTITLE: 'La cantidad de kilómetros que ha recorrido el vehículo, capturada del tablero de instrumentos.',
  PHOTO_HUB_STEP_10_HINT: 'Prende tu vehículo, espera 5 segundos con el motor en marcha y en posición neutral. Luego, enfoca y encuadra el indicador de kilometraje y toma la fotografía.',
  PHOTO_HUB_STEP_10_HINT_BEFORE_TAKE_PHOTO: 'Enciende el motor con precaución \nVerifica no tener una marcha puesta',
  PHOTO_HUB_STEP_10_HINT_BEFORE_TAKE_PHOTO_BUTTON: '¡Listo! Ya encendí el motor',

  PHOTO_HUB_STEP_11_TITLE: 'Serie o VIN Carrocería',
  PHOTO_HUB_STEP_11_SUBTITLE: 'Ubicado en vidrio panorámico delantero, parte inferior rincón izquierdo.',
  PHOTO_HUB_STEP_11_HINT: 'Puedes encontrarlo en la parte inferior izquierda del panorámico delantero.',
  PHOTO_HUB_STEP_11_HINT_2: 'Si no encuentras el VIN, puedes tomar foto del número de serie cerca de la puerta del conductor.',

  PHOTO_HUB_STEP_12_TITLE: 'Número de chasis',
  PHOTO_HUB_STEP_12_SUBTITLE: 'Si no sabes dónde encontrarlo sigue los tips de ubicación al tomar la fotografía.',
  PHOTO_HUB_STEP_12_HINT: 'Código alfanumérico de al menos 17 caracteres tallado en la carrocería del vehículo.',

  PHOTO_HUB_STEP_13_TITLE: 'N° de plaqueta panorámico',
  PHOTO_HUB_STEP_13_SUBTITLE: 'Es un número que encuentras marcado en el vidrio panorámico sobre una lámina metálica del lado del conductor',
  PHOTO_HUB_STEP_13_HINT: 'Puedes encontrarlo en la parte inferior izquierda del panorámico delantero.',

  PHOTO_HUB_STEP_14_TITLE: 'Faro Izquierdo',
  PHOTO_HUB_STEP_14_SUBTITLE: 'Faro Izquierdo',
  PHOTO_HUB_STEP_14_HINT: 'Faro Izquierdo',

  PHOTO_HUB_STEP_15_TITLE: 'Faro Derecho',
  PHOTO_HUB_STEP_15_SUBTITLE: 'Faro Derecho',
  PHOTO_HUB_STEP_15_HINT: 'Faro Derecho',

  PHOTO_HUB_STEP_16_TITLE: 'Certificado de Inspección',
  PHOTO_HUB_STEP_16_SUBTITLE: 'Certificado de Inspección',
  PHOTO_HUB_STEP_16_HINT: 'Certificado de Inspección',

  PHOTO_HUB_STEP_17_TITLE: 'Documento o tarjeta de Registro',
  PHOTO_HUB_STEP_17_SUBTITLE: 'Documento o tarjeta de Registro',
  PHOTO_HUB_STEP_17_HINT: 'Documento o tarjeta de Registro',

  PHOTO_HUB_STEP_18_TITLE: 'Ángulo frontal izquierdo',
  PHOTO_HUB_STEP_18_SUBTITLE: 'Ángulo frontal izquierdo',
  PHOTO_HUB_STEP_18_HINT: 'Ángulo frontal izquierdo',

  PHOTO_HUB_STEP_19_TITLE: 'Ángulo frontal derecho',
  PHOTO_HUB_STEP_19_SUBTITLE: 'Ángulo frontal derecho',
  PHOTO_HUB_STEP_19_HINT: 'Ángulo frontal derecho',

  PHOTO_HUB_STEP_20_TITLE: 'Ángulo trasero derecho',
  PHOTO_HUB_STEP_20_SUBTITLE: 'Ángulo trasero derecho',
  PHOTO_HUB_STEP_20_HINT: 'Ángulo trasero derecho',

  PHOTO_HUB_STEP_21_TITLE: 'Ángulo trasero izquierdo',
  PHOTO_HUB_STEP_21_SUBTITLE: 'Ángulo trasero izquierdo',
  PHOTO_HUB_STEP_21_HINT: 'Ángulo trasero izquierdo',

  PHOTO_HUB_STEP_22_TITLE: 'Kilometraje',
  PHOTO_HUB_STEP_22_SUBTITLE: 'Es el tablero donde puedes controlar el kilometraje, el nivel de combustible y la temperatura del vehículo. Arranque el motor (espere 30 segundos) y tome la foto del tablero',
  PHOTO_HUB_STEP_22_HINT: 'Encienda el motor, espere 30 segundos y luego tome la foto del tablero interno mientras está sentado en el asiento del conductor',

  PHOTO_HUB_STEP_23_TITLE: 'Identificación / Pasaporte',
  PHOTO_HUB_STEP_23_SUBTITLE: 'Identificación/Pasaporte Válido.',
  PHOTO_HUB_STEP_23_HINT: 'Por favor tome solo una foto de la parte frontal de la Identificación o la página de información del pasaporte.',

  PHOTO_HUB_STEP_24_TITLE: 'Licencia del primer conductor',
  PHOTO_HUB_STEP_24_SUBTITLE: 'Licencia de conducción Válida.',
  PHOTO_HUB_STEP_24_HINT: 'Por favor tome solo una foto de la parte frontal de la licencia de conducción del segundo conductor.',

  PHOTO_HUB_STEP_25_TITLE: 'Licencia del segundo conductor',
  PHOTO_HUB_STEP_25_SUBTITLE: 'Licencia de conducción Válida.',
  PHOTO_HUB_STEP_25_HINT: 'Por favor tome solo una foto de la parte frontal de la licencia de conducción del segundo conductor.',

  PHOTO_HUB_VIDEO_HINTS_TITLE: 'Video completo exterior vehículo',
  PHOTO_HUB_VIDEO_HINTS_DESCRIPTION: 'Realiza una grabación alrededor del vehículo partiendo desde el frente y rodeandolo desde el lado izquierdo hacia el derecho, hasta volver al frente.',
  PHOTO_HUB_VIDEO_HINTS_WARNING: 'Dispones de {time} {measureTime} para realizar el video. Ten en cuenta: una vez inicada la grabación no podrás pausarla',
  PHOTO_HUB_VIDEO_HINTS_PRIMARY_BUTTON: 'Entendido',
  PHOTO_HUB_VIDEO_HINTS_MINUTES: 'minutos',
  PHOTO_HUB_VIDEO_HINTS_MINUTE: 'minuto',

  PHOTO_HUB_EXAMPLE_TITLE: 'EJEMPLO',
  PHOTO_HUB_EXAMPLE_TITLE_VIDEO: 'UBIQUE LA CÁMARA ASÍ:',
  PHOTO_HUB_EXAMPLE_BUTTON: 'Entendido',

  PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON: 'Si, enviar',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON: 'No, seguir tomando fotos',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE: '¿Estás seguro de enviar las fotografías?',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE: 'Una vez enviada las fotografías comenzará la inspección',

  PHOTO_HUB_PHOTOS_TAKEN_MODAL_PRIMARY_BUTTON: 'Sí, enviar fotos',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SECONDARY_BUTTON: 'No, quiero agregar accesorios',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_TITLE: 'Has tomado las fotos requeridas',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SUBTITLE: '¿Deseas enviarlas a revisíon?',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_CONTINUE: 'Continuar',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_BACK: 'Regresar',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_FINISH: 'Finalizar',

  PHOTO_HUB_LOADING_PHOTO_LABEL: 'Cargando',

  PHOTO_HUB_VIDEO_SECTION_PANEL_TITLE: 'Video 360° vehículo',
  PHOTO_HUB_VIDEO_SECTION_PANEL_SUBTITLE: 'Realiza una grabacion en 360° del vehiculo.',
  PHOTO_HUB_VIDEO_SECTION_PANEL_HINT: 'Realiza una grabacion en 360° del vehiculo partiendo de la placa y rodeandolo desde el lado izquierdo hacia el derecho, hasta volver a la toma de la placa.',
  PHOTO_HUB_VIDEO_SECTION_PANEL_HINT_NOTE: 'Aviso: No será posible hacer pausa de la grabación',

  PHOTO_HUB_ACCESSORIES_SECTION_TITLE: 'Accesorios Extras',
  PHOTO_HUB_ACCESSORIES_SECTION_PANEL_TITLE: 'Agregar accesorio extra',
  PHOTO_HUB_ACCESSORIES_SECTION_PANEL_DESCRIPTION: 'Son aquellos componentes adicionales a la version original del vehículo. Selecciona uno del listado',
  PHOTO_HUB_ACCESSORIES_SECTION_PREVIEW_DESCRIPTION: 'El accesorio extra se agregó exitosamente',
  PHOTO_HUB_ACCESSORIES_SECTION_MODAL_TITLE: 'Selecciona un accesorio de la lista para fotografiar',
  PHOTO_HUB_ACCESSORIES_SECTION_MODAL_PRIMARY_BUTTON_LABEL: 'Continuar',

  PHOTO_HUB_DAMAGE_SECTION_TITLE: 'Daños preexistentes',
  PHOTO_HUB_DAMAGE_SECTION_PANEL_TITLE: 'Declarar daños preexistentes',
  PHOTO_HUB_DAMAGE_SECTION_PANEL_DESCRIPTION: 'Si el vehículo presenta algún daño (abolladura o preexistencia) que desees registrar, toma una imagen clara a la pieza afectada',
  PHOTO_HUB_DAMAGE_SECTION_PREVIEW_DESCRIPTION: 'El daño se agregó exitosamente',
  PHOTO_HUB_DAMAGE_SECTION_PREVIEW_TITLE: 'Daño',

  PHOTO_HUB_DELETE_TITLE: 'Vas a eliminar este registro fotográfico',
  PHOTO_HUB_DELETE_SUBTITLE: 'Esta acción es definitiva. La fotografía se borrará y no podrás recuperarla',
  PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL: 'ELIMINAR FOTO',
  PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL: 'REGRESAR',
  PHOTO_HUB_DELETE_IMAGE_ALT_TEXT: 'Eliminar foto',
  PHOTO_HUB_DELETE_CONFIRMATION_TITLE: 'La imagen se eliminó exitosamente',
  PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT: 'Foto Eliminada',
  PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL: 'CERRAR',

  PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE: 'Rueda de repuesto',
  PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_QUESTION: 'Incluir rueda de repuesto',
  PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE: 'Toma la fotografía de la llanta de repuesto y asegúrate que salga en su totalidad',
  PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT: 'La imagen debe aparecer con la luz suficiente para identificar el estado de la rueda',
  PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_ON: 'Si',
  PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_OFF: 'No',

  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_TITLE: 'Número de serie (en caso de aplicar)',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_QUESTION: 'Incluir número de serie',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_SUBTITLE: 'Es el número que encuentra en la parte lateral de la moto. Es una placa o calcomanía.',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_HINT: 'Lo puedes encontrar en la parte lateral o bajo al tanque de gasolina como código alfanumérico de 17 caracteres.',

  PHOTO_HUB_INFORMATION_IMAGE_ALT_TEXT: 'Información',

  PHOTO_HUB_MOTORBIKE_STEP_01_TITLE: 'Frente tarjeta de propiedad',
  PHOTO_HUB_MOTORBIKE_STEP_01_SUBTITLE: 'La tarjeta de propiedad de la moto que quieres inspeccionar.',
  PHOTO_HUB_MOTORBIKE_STEP_01_HINT: 'Toma una foto de la parte frontal de la tarjeta de propiedad.',

  PHOTO_HUB_MOTORBIKE_STEP_02_TITLE: 'Reverso tarjeta de propiedad',
  PHOTO_HUB_MOTORBIKE_STEP_02_SUBTITLE: 'La tarjeta de propiedad de la moto que quieres inspeccionar.',
  PHOTO_HUB_MOTORBIKE_STEP_02_HINT: 'Toma una foto de la parte de atrás de la tarjeta de propiedad.',

  PHOTO_HUB_MOTORBIKE_STEP_03_TITLE: 'Placa',
  PHOTO_HUB_MOTORBIKE_STEP_03_SUBTITLE: 'La matrícula de la moto que quieres inspeccionar',
  PHOTO_HUB_MOTORBIKE_STEP_03_HINT: 'Flexiona tus rodillas y ubica tu teléfono frente a la placa.',

  PHOTO_HUB_MOTORBIKE_STEP_04_TITLE: 'Parte delantera',
  PHOTO_HUB_MOTORBIKE_STEP_04_SUBTITLE: 'Enfocando el guarda fango, llanta delantera y farola.',
  PHOTO_HUB_MOTORBIKE_STEP_04_HINT: 'Mantén tu teléfono a la altura de tu cabeza e inclínalo hacia abajo un poco. Asegúrate que se vea el sillín.',

  PHOTO_HUB_MOTORBIKE_STEP_05_TITLE: 'Parte trasera',
  PHOTO_HUB_MOTORBIKE_STEP_05_SUBTITLE: 'Enfocando la placa de la moto.',
  PHOTO_HUB_MOTORBIKE_STEP_05_HINT: 'Mantén tu teléfono a la altura de tu cabeza e inclínalo hacia abajo un poco.',

  PHOTO_HUB_MOTORBIKE_STEP_06_TITLE: 'Lateral derecha',
  PHOTO_HUB_MOTORBIKE_STEP_06_SUBTITLE: 'Es el carenado o costado derecho de la moto según la vista del piloto.',
  PHOTO_HUB_MOTORBIKE_STEP_06_HINT: 'Toma la foto de un lado de la moto.',
  PHOTO_HUB_MOTORBIKE_STEP_06_HINT_02: 'Mantén tu teléfono a la altura de tus hombros.',

  PHOTO_HUB_MOTORBIKE_STEP_07_TITLE: 'Lateral izquierda',
  PHOTO_HUB_MOTORBIKE_STEP_07_SUBTITLE: 'Es el carenado o costado izquierdo de la moto según la vista del piloto.',
  PHOTO_HUB_MOTORBIKE_STEP_07_HINT: 'Toma la foto del otro costado de la motocicleta.',
  PHOTO_HUB_MOTORBIKE_STEP_07_HINT_02: 'Mantén tu teléfono a la altura de tus hombros.',

  PHOTO_HUB_MOTORBIKE_STEP_08_TITLE: 'Torre de dirección',
  PHOTO_HUB_MOTORBIKE_STEP_08_SUBTITLE: 'Imagen mostrando la llanta, el tanque y farola por el sector derecho de la moto.',
  PHOTO_HUB_MOTORBIKE_STEP_08_HINT: 'Mantén tu teléfono a la altura de tu cabeza e inclina la toma un poco.',

  PHOTO_HUB_MOTORBIKE_STEP_09_TITLE: 'Tablero de instrumentos',
  PHOTO_HUB_MOTORBIKE_STEP_09_SUBTITLE: 'Imagen mostrando todo el panel central y tablero completo delantero, incluyendo los espejos retrovisores.',
  PHOTO_HUB_MOTORBIKE_STEP_09_HINT: 'Móntate en la moto y captura el panel incluyendo los espejos.',

  PHOTO_HUB_MOTORBIKE_STEP_10_TITLE: 'Kilometraje',
  PHOTO_HUB_MOTORBIKE_STEP_10_SUBTITLE: 'Imagen del tablero de instrumentos para evidenciar la cantidad de kilómetros que ha recorrido la moto.',
  PHOTO_HUB_MOTORBIKE_STEP_10_HINT: 'Abre el switch, para que se muestre el kilometraje total y captura una fotografía.',

  PHOTO_HUB_MOTORBIKE_STEP_11_TITLE: 'Número de identificación',
  PHOTO_HUB_MOTORBIKE_STEP_11_SUBTITLE: 'Es el número de VIN o chasis que se encuentra en la torre de la dirección y que está estampado.',
  PHOTO_HUB_MOTORBIKE_STEP_11_HINT: 'En general está en la torre de dirección. En caso de las scooter está al levantar el asiento o frente a las rodillas.',

  PHOTO_HUB_MOTORBIKE_STEP_12_TITLE: 'Número de serie (en caso de aplicar)',
  PHOTO_HUB_MOTORBIKE_STEP_12_SUBTITLE: 'Es el número que encuentra en la parte lateral de la moto. Es una placa o calcomanía.',
  PHOTO_HUB_MOTORBIKE_STEP_12_HINT: 'Lo puedes encontrar en la parte lateral o bajo al tanque de gasolina como código alfanumérico de 17 caracteres.',

  TAKE_PHOTO_CONFIRM_PHOTO_BUTTON_LABEL: 'Guardar',
  TAKE_PHOTO_RETAKE_PHOTO_BUTTON_LABEL: 'Tomar otra foto',
  TAKE_PHOTO_MODAL_TITLE: 'Indicaciones',
  TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT: 'Indicación',
  TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL: 'ENTENDIDO',
  TAKE_PHOTO_RETAKE_MODAL_TITLE: 'Vas a modificar este registro fotográfico',
  TAKE_PHOTO_RETAKE_MODAL_SUBTITLE: 'Si tomas una nueva fotografía, automáticamente se borrará la imagen actual y no podrás recuperarla',
  TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL: 'Tomar otra foto',
  TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL: 'Olvidar',
  TAKE_PHOTO_PRIMARY_BUTTON: 'Cargar Foto',
  TAKE_PHOTO_PRIMARY_BUTTON_LOADING: 'Guardando',
  TAKE_PHOTO_SECONDARY_BUTTON: 'Repetir',

  SATISFACTION_SURVEY: 'Realizar encuesta de satisfacción',

  INACTIVITY_TIMER_MODAL_PRIMARY_BUTTON: 'Continuar',
  INACTIVITY_TIMER_MODAL_CLOSED_SESSION_PRIMARY_BUTTON: 'Iniciar sesión de nuevo',
  INACTIVITY_TIMER_MODAL_SECONDARY_BUTTON: 'Cerrar Sesión',
  INACTIVITY_TIMER_MODAL_TITLE: '¿Aún estás ahí? Tu sesión finalizará en:',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_TITLE: 'Cerramos sesión automáticamente por tu seguridad',
  INACTIVITY_TIMER_MODAL_HINT: 'Para retomar la toma de fotografías donde la dejaste, presiona continuar.',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_ONE: 'Detectamos inactividad en tu proceso de inspección y cerramos la sesión para proteger tus datos.',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_TWO: 'Ingresa nuevamente y retoma el proceso donde lo dejaste',

  EXPIRED_SESSION_MODAL_PRIMARY_BUTTON: 'Comenzar inspección',
  EXPIRED_SESSION_MODAL_SECONDARY_BUTTON: 'Salir',
  EXPIRED_SESSION_MODAL_TITLE: 'Excediste el tiempo para realizar la inspección',
  EXPIRED_SESSION_MODAL_HINT: 'Para comenzar nuevamente el proceso de inspección puedes acceder a través del siguiente botón o desde el link en tu correo electrónico.',

  TAKE_VIDEO_PRIMARY_BUTTON: 'Terminar',
  TAKE_VIDEO_FINISH_TITLE: 'Grabacion finalizada',
  TAKE_VIDEO_FINISH_DESCRIPTION: 'Has terminado de grabar el video en 360°',
  TAKE_VIDEO_FINISH_WAITING_DESCRIPTION: 'Estamos terminando de subir el video de tu vehículo. Por favor espera...',
  TAKE_VIDEO_FINISH_PRIMARY_BUTTON: 'Subir',
  TAKE_VIDEO_FINISH_SECUNDARY_BUTTON: 'Repetir',

  PERMISSION_CAMERA_MODAL_TITLE: 'No tienes habilitados los permisos de camara',
  PERMISSION_CAMERA_MODAL_DESCRIPTION: 'Es necesario que habilites los permisos de camara para tomar fotos',
  PERMISSION_CAMERA_MODAL_PRIMARY_BUTTON: 'Los activaré',

  PERMISSION_CAMERA_MODAL_INFO_TITLE: 'Permisos de cámara',
  PERMISSION_CAMERA_MODAL_INFO_DESCRIPTION: '¡Antes de empezar! Si no logras capturar correctamente la foto recuerda que debes tener los permisos de cámara en el navegador activados.',
  PERMISSION_CAMERA_MODAL_INFO_PRIMARY_BUTTON: 'Entendido',

  GUIDED_VISIT_INIT_TITLE: 'Iniciar una visita guiada',
  GUIDED_VISIT_INIT_DESCRIPTION: 'Te daremos un tour por las principales herramientas de la cámara para que logres sacarle el mayor provecho.',
  GUIDED_VISIT_FIRST_TITLE: 'Enfoca lo necesario',
  GUIDED_VISIT_FIRST_DESCRIPTION: 'Pulsa una sola vez en el lugar que quieres enfocar y capturar claramente.',
  GUIDED_VISIT_SECOND_TITLE: 'Activa/Desactiva el flash',
  GUIDED_VISIT_SECOND_DESCRIPTION: 'Activa el flash en condiciones de poca luz. Desactívalo si estás de día o en condiciones óptimas de luz.',
  GUIDED_VISIT_THIRD_TITLE: 'Pantalla completa',
  GUIDED_VISIT_THIRD_DESCRIPTION: 'Configura la cámara en toda la pantalla para obtener una vista más detallada de la imagen a capturar.',
  GUIDED_VISIT_FOURTH_TITLE: 'Captura en un toque',
  GUIDED_VISIT_FOURTH_DESCRIPTION: 'Pulsa el obturador en un solo toque y captura la mejor imagen para enviar.',
  GUIDED_VISIT_FIFTH_TITLE: 'Captura de nuevo',
  GUIDED_VISIT_FIFTH_DESCRIPTION: 'Si detectas errores en la captura de la imagen o quieres mejorarla, puedes regresar y tomar la foto nuevamente.',
  GUIDED_VISIT_SIXTH_TITLE: 'Envia en un toque',
  GUIDED_VISIT_SIXTH_DESCRIPTION: 'Cuando te sientes satisfecho con la fotografía, confirma y envía en un solo toque.',
  GUIDED_VISIT_SEVENTH_TITLE: 'Cierra la cámara',
  GUIDED_VISIT_SEVENTH_DESCRIPTION: 'Puedes volver a la plataforma de inspecciones en cualquier momento.',
  GUIDED_VISIT_INIT_BUTTON_LABEL: 'Iniciar',
  GUIDED_VISIT_OMIT_BUTTON_LABEL: 'Omitir',
  GUIDED_VISIT_NEXT_BUTTON_LABEL: 'Siguiente',
  GUIDED_VISIT_BACK_BUTTON_LABEL: 'Anterior',
  GUIDED_VISIT_DONE_BUTTON_LABEL: 'Hecho',

  GUIDED_VIDEO_VISIT_INIT_TITLE: 'Iniciar una visita guiada',
  GUIDED_VIDEO_VISIT_INIT_DESCRIPTION: 'Te daremos un tour por las principales herramientas de la cámara para que logres sacarle el mayor provecho.',
  GUIDED_VIDEO_VISIT_FIRST_TITLE: '¡Capta todo!',
  GUIDED_VIDEO_VISIT_FIRST_DESCRIPTION: 'Intenta utilizar el mayor espacio posible dentro del marco de la cámara.',
  GUIDED_VIDEO_VISIT_SECOND_TITLE: 'Botón de grabación',
  GUIDED_VIDEO_VISIT_SECOND_DESCRIPTION: 'Este botón permite iniciar la grabación.',
  GUIDED_VIDEO_VISIT_THIRD_TITLE: 'Botón de detener',
  GUIDED_VIDEO_VISIT_THIRD_DESCRIPTION: 'Este botón permite finalizar la grabación, recuerda que no será posible pausar la grabación.',
  GUIDED_VIDEO_VISIT_FOURTH_TITLE: 'Reloj',
  GUIDED_VIDEO_VISIT_FOURTH_DESCRIPTION: 'Este cronómetro indica cuanto tiempo dispones para realizar la grabación, una vez el tiempo acabe, la grabación será finalizada. Si requieres volver a intentarlo, basta con que ingreses nuevamente a esta sección e inicies la grabación.',
  GUIDED_VIDEO_VISIT_FIFTH_TITLE: 'Pantalla completa',
  GUIDED_VIDEO_VISIT_FIFTH_DESCRIPTION: 'Adapta a pantalla completa para obtener una vista más detallada del video a capturar.',
  GUIDED_VIDEO_VISIT_SIXTH_TITLE: 'Cierra la cámara',
  GUIDED_VIDEO_VISIT_SIXTH_DESCRIPTION: 'Puedes volver a la plataforma de inspecciones en cualquier momento.',
  GUIDED_VIDEO_VISIT_INIT_BUTTON_LABEL: 'Iniciar',
  GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL: 'Omitir',
  GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL: 'Siguiente',
  GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL: 'Anterior',
  GUIDED_VIDEO_VISIT_DONE_BUTTON_LABEL: 'Hecho',

  PROCESS_COMPLETE_TITLE: '¡Has enviado las fotos a nuestra área de inspección!',
  PROCESS_COMPLETE_SUBTITLE_ONE: 'Es posible que te solicitemos tomar nuevamente algunas fotos.​',
  PROCESS_COMPLETE_SUBTITLE_TWO: 'Está atento a tu correo electrónico pues allí te enviaremos las indicaciones​​',
  PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS: 'Nuestros agentes revisarán las fotografías de Lunes a Viernes en horario hábil. Te invitamos a conocer más sobre Liberty Autos y las ventajas que trae para ti',
  PROCESS_COMPLETE_CONTINUE_BUTTON: 'Ir a Liberty Autos',
  PROCESS_COMPLETE_ERROR_MESSAGE_TITLE: 'El proceso ya fue finalizado. En caso de dudas, escríbenos a inspeccionvehiculo@liberty.cl',
  PROCESS_COMPLETE_ERROR_MESSAGE_SUBTITLE: 'Un ejecutivo de Liberty se encuentra revisando tus fotos',

  PROCESS_SUPPORT_TITLE: 'Tu solicitud fue enviada exitosamente a nuestro equipo de soporte',
  PROCESS_SUPPORT_SUBTITLE_ONE: 'El horario de atención es de Lunes a Viernes de 8:00 a 17:00, los Sábados es de 8:00 a 12:00.',
  PROCESS_SUPPORT_SUBTITLE_TWO: 'También puedes consultar las secciones de ',
  PROCESS_SUPPORT_SUBTITLE_THREE: ' e ',
  PROCESS_SUPPORT_SUBTITLE_FOUR: ' para obtener información útil sobre el proceso de inspección.',
  PROCESS_SUPPORT_SUBTITLE_FIVE: 'Nuestros ejecutivos se pondrán en contacto contigo en breve. ',
  PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT: 'Preguntas Frecuentes',
  PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT: 'Instrucciones',
  PROCESS_SUPPORT_CONTINUE_BUTTON: 'Ir a Liberty Seguros',

  PROCESS_SUPPORT_IN_PROGRESS_MODAL_TITLE: 'Ya tienes una solicitud de soporte en curso',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_SUBTITLE: 'Nuestros ejecutivos se pondrán en contacto contigo en breve.',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_ATTENTION_SCHEDULE: 'Horarios de atención:',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_WORKING_HOURS: 'Lunes a Viernes de 8:00 a 17:00, los Sábados es de 8:00 a 12:00',

  FORM_SUPPORT_TITLE: 'Solicitud de soporte',
  FORM_SUPPORT_SUBTITLE: 'Cuéntanos el motivo de tu solicitud',
  FORM_SUPPORT_TEXT_AREA: 'Comparte los detalles de tu solicitud',
  FORM_SUPPORT_SEND_SUPPORT: 'Enviar solicitud',
  FORM_SUPPORT_CANCEL_SUPPORT: 'No enviar',
  FORM_SUPPORT_REQUIRED_INPUTS: 'Este campo no puede quedar vacío',
  FORM_SUPPORT_REQUIRED_INPUTS_ONLY_NUMBERS: 'Este campo debe ser numérico',
  FORM_SUPPORT_REQUIRED_INPUTS_TEXT_LENGTH: '{length} de 1.000 caracteres',

  FAQ_TITLE: 'Preguntas Frecuentes',
  FAQ_SUBTITLE: 'Encuentra la respuesta a las dudas más comunes de nuestros usuarios',

  FAQ_1_QUESTION: '¿Cómo funciona el proceso de inspección',
  FAQ_1_ANSWER: 'Una vez recibamos las fotografías de tu vehículo nuestro equipo especializado realiza las siguientes validaciones:\n\n\u25CF Análisis de estado físico del vehículo.\n\n\u25CF Consulta de bases de datos internas, externas y políticas de aceptación.\n\n\u25CF Se genera el resultado de la inspección digital.',

  FAQ_2_QUESTION: 'Si tomo una fotografía que no corresponde a la parte del vehículo indicada y envío las fotos a inspección, ¿qué debo hacer para tomarla de nuevo y enviarla?',
  FAQ_2_ANSWER_PART_1: 'Debes esperar a que las fotografías sean revisadas por nuestro equipo especializado. Sólo si el especialista determina que es necesario repetir alguna fotografía se comunicará contigo vía correo electrónico y te dará las indicaciones correspondientes.',
  FAQ_2_ANSWER_PART_2: 'Recuerda: luego de tomar cada foto puedes revisar si tiene la calidad necesaria y así aprobarla. En caso contrario, podrás cancelar y tomar la imagen nuevamente. De esta manera evitarás posibles devoluciones.',

  FAQ_3_QUESTION: 'Si tomo una fotografía que no tiene la mejor calidad o aparece borrosa y envío las fotos, ¿qué debo hacer para tomarla de nuevo y enviarla?',
  FAQ_3_ANSWER_PART_1: 'Debes esperar a que las fotografías sean revisadas por nuestro equipo especializado. Sólo si el especialista determina que es necesario repetir alguna fotografía se comunicará contigo vía correo electrónico y te dará las indicaciones correspondientes',
  FAQ_3_ANSWER_PART_2: 'Recuerda: luego de tomar cada foto puedes revisar si tiene la calidad necesaria y así aprobarla. En caso contrario, podrás cancelar y tomar la imagen nuevamente. De esta manera evitarás posibles devoluciones.',

  FAQ_4_QUESTION: 'Si la inspección es aprobada, ¿cuál es el siguiente paso del proceso?',
  FAQ_4_ANSWER: 'En el caso de aprobación, el proceso de expedición queda a cargo del intermediario de seguros.',

  FAQ_5_QUESTION: 'Al realizar el proceso de inspección a través de esta aplicación, ¿evito ir a un centro autorizado o CDA?',
  FAQ_5_ANSWER: 'Sí, este proceso reemplaza el trámite en centros autorizados o CDA. Al ser 100% digital, puedes realizar la inspección desde cualquier lugar, solo necesitas un teléfono celular con señal estable de internet.',

  FAQ_6_QUESTION: '¿Debo pagar algún valor o costo por el proceso de inspección?',
  FAQ_6_ANSWER: 'No, el proceso de inspección no tiene ningún costo, es totalmente gratuito',

  FAQ_7_QUESTION: '¿Cuál es el valor asegurado de mi vehículo?',
  FAQ_7_ANSWER_PART_1: 'El valor asegurado depende del valor comercial registrado en la guía Fasecolda según las características del vehículo.\n',
  FAQ_7_ANSWER_PART_2: 'Guía Fasecolda: ',
  FAQ_7_ANSWER_PART_3: 'Base de datos pública de valores comerciales de un vehículo. Están definidos por la Federación de Aseguradores de Colombia, FASECOLDA',

  FAQ_8_QUESTION: 'Si mis accesorios no se encuentran en el listado predeterminado, ¿cómo puedo incluirlos?',
  FAQ_8_ANSWER: 'Solo debes incluir los accesorios disponibles en el listado de esta aplicación.',

  FAQ_9_QUESTION: '¿Puedo tomar las fotografías de mi vehículo si se encuentra mojado?',
  FAQ_9_ANSWER: 'Lo ideal es que el automóvil esté seco para que nuestro equipo especializado pueda inspeccionar las fotografías sin ningún tipo de obstrucción visual.',

  FAQ_10_QUESTION: '¿Necesito acceso a internet para realizar el proceso de inspección digital?',
  FAQ_10_ANSWER: 'Sí, tanto para el ingreso a la herramienta como para enviar las fotografías es necesario tener acceso a una red de internet estable.',

  FAQ_11_QUESTION: '¿Puedo realizar el proceso de inspección digital desde cualquier región del país?',
  FAQ_11_ANSWER: 'Sí, sólo debes asegurarte de tener acceso a una red de internet estable para ingresar a la aplicación sin inconvenientes.',

  FAQ_12_QUESTION: '¿Puedo realizar la inspección digital desde diferentes ubicaciones?',
  FAQ_12_ANSWER: 'No, teniendo en cuenta que la ubicación está siendo monitoreada por el GPS del dispositivo móvil, debes mantenerte en el mismo lugar durante el proceso de inspección digital.',

  FAQ_13_QUESTION: '¿Puedo utilizar cualquier tipo de dispositivo móvil (tablets o computadores portátiles) para acceder a la aplicación?',
  FAQ_13_ANSWER: 'No. La aplicación solo es compatible con teléfonos celulares que funcionen bajo los sistemas operativos Android y iOS (iPhones). No aplica para tablets o computadores portátiles',

  FAQ_14_QUESTION: 'La aplicación no me permite tomar fotografías, ¿qué debo hacer?',
  FAQ_14_ANSWER: 'Debes asegurarte de no tener demasiadas aplicaciones o pestañas de tu buscador abiertas en simultáneo. Revisa que tu conexión a internet sea estable y fuerte e intenta nuevamente.',

  FAQ_15_QUESTION: 'Si tengo dudas o necesito ayuda, ¿cómo accedo al soporte de la aplicación de inspección digital?',
  FAQ_15_ANSWER: 'En la parte inferior de la aplicación está dispuesto permanentemente el botón de soporte. Desde allí, en caso de presentarse alguna contingencia o necesitar apoyo para su manejo, podrás escoger la ayuda que requieras. Incluso, podrás solicitar el acompañamiento de un especialista para garantizar la mejor experiencia posible.',

  FAQ_CONTACT_EMAIL: 'comunicaciones@libertycolombia.com',

  REQUEST_CALL_TEXT_1: '¿No encuentras la respuesta que necesitas?',
  REQUEST_CALL_TEXT_2: 'Habla con un agente de soporte',
  REQUEST_CALL_PHONE_NUMBER: '+573168314502',

  REQUEST_CALL_SCHEDULES_1: 'El horario de atención es de ',
  REQUEST_CALL_SCHEDULES_2: 'Lunes a Jueves de 9:00 a 18:00, los viernes es de 9:00 a 14:00',

  INSTRUCTIONS_LABEL: 'Instrucciones',
  FAQ_LABEL: 'FAQ',
  SUPPORT_REQUEST_LABEL: 'Soporte',

  AUTH_TOKEN_SESSION_TIMED_OUT: 'Lo lamentamos, la sesión ha expirado y debes confirmar tu identidad nuevamente',

  EMAIL_TOKEN_ERROR_MESSAGE_TITLE: 'Lo lamentamos, hemos tenido inconvenientes en el proceso y deberás intentarlo nuevamente',
  EMAIL_TOKEN_ERROR_MESSAGE_SUBTITLE: 'Por favor, selecciona el botón comenzar nuevamente, en el correo que recibiste previamente',

  INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE: 'Proceso en revisión de imágenes',
  INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE: 'Nuestros agentes se encuentran revisando las imágenes de tu vehículo.Nos comunicaremos vía correo electrónico lo más pronto posible.',
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE: 'La inspección está siendo revisada por nuestra área de inspecciones',
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE: 'Para más información de los próximos pasos con la emisión de la póliza, puedes contactarnos a través de nuestros canales de atención o ponerte en contacto con tu asesor.',
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE: 'Las fotos recibidas fueron rechazadas',
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE: 'Si necesitas ayuda, puedes contactarnos a través de nuestros canales de atención o ponerte en contacto con tu asesor.',
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE: 'La inspección ha expirado',
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE: 'El tiempo limite para completar tu inspección ha termiando. Si necesitas ayuda, ponte en contacto con un asesor',
  INVALID_STATUS_CALL_AN_ADVISOR_BUTTON: 'Llamar a un asesor',
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_TITLE: 'La inspección ha sido cancelada',
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_SUBTITLE: 'Para más información, ponte en contacto con tu Corredor',

  ERROR_CAMERA_SYSTEM_PERMISSION_DENIED: 'La aplicacion necesita acceso a la camara para poder continuar.',
  ERROR_CAMERA_USER_PERMISSION_DENIED: 'Denegaste el acceso a la camara. La aplicacion necesita acceso a la camara para poder continuar.',
  ERROR_CAMERA_COULD_NOT_START_VIDEO_SOURCE: 'Otra aplicacion esta haciendo uso de la camara',
  ERROR_NKNOWN_PERMISSION_CAMERA: 'Ups! Algo salio mal',
  CAMERA_PERMISSIONS_TITLE_ICON_1: 'FAQ',
  CAMERA_PERMISSIONS_TITLE_ICON_2: 'Permisos Camara',
  CAMERA_PERMISSIONS_IPHONE_CHROME: 'IPhone Chrome',
  CAMERA_PERMISSIONS_SELECT_SO: 'Selecciona la combinación correcta entre Sistema Operativo y navegador  para ver las instrucciones correspondientes:',
  CAMERA_PERMISSIONS_IPHONE_SAFARI: 'IPhone Safari',
  CAMERA_PERMISSIONS_RECOMMENDATIONS: 'Para hacer una inspección óptima, ten en cuenta las siguientes  recomendaciones ',
  CAMERA_PERMISSIONS_ANDROID_CHROME: 'Android y Chrome',
  CAMERA_PERMISSIONS_OTHERS: 'Otros',
  CAMERA_PERMISSIONS_INCORRECT_INFORMATION: 'No es la informacion correcta',
  CAMERA_PERMISSIONS_IPHONE_CHROME_ID: 'camera_permissions_IOS_CHROME',
  CAMERA_PERMISSIONS_IPHONE_SAFARI_ID: 'camera_permissions_IOS_SAFARI',
  CAMERA_PERMISSIONS_ANDROID_CHROME_ID: 'camera_permissions_ANDROID_CHROME',
  CAMERA_PERMISSIONS_OTHERS_ID: 'camera_permissions_others',
  CAMERA_PERMISSIONS_TITLE_BROWSER_1: 'Permite el uso de la cámara en tu navegador',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE: 'Para conceder permiso, haz lo siguiente:',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE: 'Configuración de Chrome > Configuración de sitios > Cámara > Habilitar cámara',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE: 'Habilita también el acceso de Chrome a la cámara en Android',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE: 'Configuración de Android > Aplicaciones > Chrome > Permisos > Cámara > Permitir',
  CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE: 'Permite que el navegador Safari tenga acceso a la cámara',
  CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE: 'Configuración de iPhone > Safari > Cámara > Permitir',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE: 'Permite que el navegador Chrome tenga acceso a la cámara',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE: 'Configuración de iPhone > Chrome > Habilita la cámara',
  TUTORIAL_STEP_FINAL: 'Comenzar',
  TUTORIAL_STEP_FINAL_READY: 'Listo',
  CAMERA_PERMISSIONS_TITLE_BROWSER_2: 'Habilita también el acceso de  Chrome a la cámara en Android',
  CAMERA_PERMISSIONS_TITLE_BROWSER_3: 'Permite que el navegador Safari  tenga acceso a la cámara',
  CAMERA_PERMISSIONS_TITLE_BROWSER_4: 'Permite que el navegador  Chrome tenga acceso a la cámara',
  CAMERA_PERMISSIONS_WIFI_HEADER: 'Asegúrate de tener buena señal  en tu conexión a internet.',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_1: 'Para conceder permiso, haz lo siguiente: Configuración de Chrome> Configuración de sitios> Cámara> Habilitar cámara',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_2: 'Para habilitarlo, haz lo siguiente:  Configuración de Android> Aplicaciones> Chrome Permisos > Cámara > Permitir',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_3: 'Para conceder permiso, haz lo siguiente:  Configuración de iPhone Safari  Cámara > Permitir',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_4: 'Para habilitarlo, haz lo siguiente:  Configuración de iPhone > Chrome  >Habilita la cámara',
  CAMERA_PERMISSIONS_WIFI_FOOTER: 'Al mantener una conexión estable a  internet, garantizas que las imágenes se carguen efectivamente en la plataforma.',
  CAMERA_PERMISSIONS_DETECTED_PHONE_1: 'Detectamos que tu celular es ',
  CAMERA_PERMISSIONS_DETECTED_PHONE_2: ' y tu navegador predefinido es ',

  MODAL_REPEAT_VIDEO_MESSAGE: 'Ten en cuenta que si tomas un nuevo video, el actual se borrará',
  MODAL_REPEAT_VIDEO_PRIMARY_BUTTON: 'REPETIR VIDEO',
  MODAL_REPEAT_VIDEO_SECONDARY_BUTTON: 'CANCELAR',

  LOADING_MESSAGE_CAMERA_PHOTO: 'Cargando cámara',

  FORM_SUPPORT_ASIAN_INFORMATION: 'Proporcione información para la cita previa a la inspección',
  FORM_SUPPORT_ASIAN_NAME: 'Nombre',
  FORM_SUPPORT_ASIAN_NUMBER: 'Número de teléfono',
  FORM_SUPPORT_ASIAN_PR_INSPECTION_TIME: 'Fecha y hora de la inspección',
  FORM_SUPPORT_ASIAN_ADDRESS: 'Dirección',
  FORM_SUPPORT_ASIAN_WE_WILL_CONTACT_WITH_YOU: 'Nos pondremos en contacto con usted pronto para el arreglo de inspección previa',

  PREPOSITION_OF: 'de',
  PASSSED_STEP: 'Paso',

  STEP_INSTRUCCION_TEXT: 'Captura las imágenes teniendo en cuenta las indicaciones',

  INSTRUCTIONS_MODAL_BUTTON_FINISH: 'ENTENDIDO',
  INSTRUCTIONS_MODAL_BUTTON_SHOW_INSTRUCTIONS_AGAIN: 'Ver instrucciones nuevamente',
  INSTRUCTIONS_MODAL_BUTTON_FINISH_INITIAL_PROCESS: 'COMENZAR INSPECCIÓN',
  INSTRUCTIONS_MODAL_BUTTON_OMIT: 'Omitir',
  INSTRUCTIONS_MODAL_TITLE: 'Para realizar la inspección, considera lo siguiente:',
  INSTRUCTIONS_MODAL_SUBTITLE_1: 'Toma las fotografías al vehículo directamente desde tu teléfono celular (smartphone). Este aplicativo solo funciona desde este dispositivo',
  INSTRUCTIONS_MODAL_SUBTITLE_2: 'El vehículo debe estar limpio, ubicado en un lugar amplio y con luz diurna',
  INSTRUCTIONS_MODAL_SUBTITLE_3: 'Ten a mano los documentos válidos del vehículo para la inspección: Tarjeta de propiedad o licencia de tránsito',
  INSTRUCTIONS_MODAL_SUBTITLE_4: 'Al momento de tomar las fotos exteriores del vehículo, procura capturar el vehículo completo.',
  INSTRUCTIONS_MODAL_SUBTITLE_5: 'Debes tener el motor encendido para capturar la fotografía del kilometraje',
  INSTRUCTIONS_MODAL_SUBTITLE_6: 'Debes tener buena conexión a internet y cerrar el resto de aplicaciones que tengas abiertas.',
  INSTRUCTIONS_MODAL_SUBTITLE_7: 'Dispones de 2 horas para completar el proceso de inspección. Si excedes el tiempo, perderás las fotos que hayas capturado y deberás volver a ingresar desde el link.',
  INSTRUCTIONS_MODAL_TITLE_FINISH_PAGE: '¿Quieres iniciar la inspección?',
  INSTRUCTIONS_MODAL_SUBTITLE_FINISH_PAGE: 'Ten en cuenta que dispones de 2 horas para completar el proceso de inspección. Si excedes el tiempo, perderás las fotos que hayas capturado y deberás volver a ingresar desde el link.',
};

export default { code, strings };
