import ImageCompressor from 'compressorjs';
import { logcodes, logger } from './logger';

export const compressImage = (
  photoFile,
  photoId,
  maxResolution = 1080,
  compress = 0.9,
) => {
  const startTime = Date.now();
  const originalPhotoSize = photoFile.size;
  const checkOrientation = originalPhotoSize > 3000000;

  return new Promise((resolve, reject) => {
    const options = {
      maxWidth: maxResolution,
      maxHeight: maxResolution,
      quality: compress,
      checkOrientation,
      convertSize: 'infinity',
      mimeType: 'image/jpeg',
      beforeDraw() {
        logger.log(logcodes.CAIMG020, {
          name: photoId,
          originalPhotoSize,
          ram: navigator.deviceMemory || 'UNKNOWN',
          type: photoFile.type,
          userAgent: navigator.userAgent,
        });
      },
      success: compressedImage => {
        const file = compressedImage;
        const compressedPhotoSize = compressedImage.size;
        const timeElapsed = Date.now() - startTime;

        logger.log(logcodes.CAIMG010, {
          name: photoId,
          timeElapsed,
          originalPhotoSize,
          compressedPhotoSize,
        });

        resolve(file);
      },
      error: ({ message, stack }) => {
        logger.log(logcodes.CAIMG110, {
          name: photoId,
          message,
          stack,
        });

        reject();
      },
    };
    return new ImageCompressor(photoFile, options);
  });
};

export default compressImage;
