import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const ContentFooter = ({ children }) => (
  <div>
    <div className="sticky" />
    <div className="content-footer">{children}</div>
  </div>
);

ContentFooter.propTypes = {
  children: PropTypes.node,
};

ContentFooter.defaultProps = {
  children: null,
};

export default ContentFooter;
