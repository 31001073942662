import { getConditionallyRequiredPhotosConfig } from '../../../config/conditionallyRequiredPhotos';
import { ConditionallyPhotoHelper } from '../photo/ConditionallyPhotoHelper';

export class ContitionalPhotoValidation {
  constructor(retake, wizardPhotoHub) {
    this.retake = retake;
    this.wizardPhotoHub = wizardPhotoHub;
  }

  setCountryCode(code) {
    this.countryCode = code;
    return this;
  }

  setVehicleClass(vehicleClass) {
    this.vehicleClass = vehicleClass;
    return this;
  }

  setStep(currentStep) {
    this.step = currentStep;
    return this;
  }

  setConditionalPhotoStatus(isEnabled) {
    this.spareWheelToggleEnabled = isEnabled;
    return this;
  }

  validate() {
    const missingConditionalPhotos = getConditionallyRequiredPhotosConfig(
      this.countryCode,
      this.vehicleClass,
    );

    if (missingConditionalPhotos.length > 0) {
      let missingPhoto = this.spareWheelToggleEnabled;

      if (this.retake?.conditionallyRequired.length === 0) {
        missingPhoto = false;
      }

      const helper = new ConditionallyPhotoHelper(this.wizardPhotoHub);

      return helper
        .setStep(this.step)
        .isCompletedConditionallyPhotos(
          missingPhoto,
          this.countryCode,
          this.vehicleClass,
        );
    }
    return true;
  }
}
