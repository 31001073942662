import React from 'react';
import PropTypes from 'prop-types';
import { PreviewVideo } from '../Camera/PreviewCamera/PreviewVideo';
import { IconClose } from '../Icons';
import { Modal } from '..';
import './styles.scss';

const ModalPlayerVideo = ({ isOpen, onClose, video, lang }) => (
  <Modal styles centerContent backgroundColor="black" isOpen={isOpen}>
    <div className="modal-player-video__container">
      <div className="modal-player-video__close">
        <IconClose onClick={() => onClose()} />
      </div>
      <PreviewVideo readOnly lang={lang} video={video} />
    </div>
  </Modal>
);

ModalPlayerVideo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  video: PropTypes.any.isRequired,
};

export default ModalPlayerVideo;
