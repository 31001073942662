// PHOTO HUB ACTIONS
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const GET_PRESIGNED_URL_ARRAY = 'GET_PRESIGNED_URL_ARRAY';
export const RESET_PRESIGNED_URL_ARRAY = 'RESET_PRESIGNED_URL_ARRAY';
export const ON_CHANGE_CURRENT_STEP = 'ON_CHANGE_CURRENT_STEP';
export const SET_WIZARD_STATE_BY_VEHICLE_CLASS =
  'SET_WIZARD_STATE_BY_VEHICLE_CLASS';
export const ON_CLICK_PHOTO_HUB_BUTTON_STEP = 'ON_CLICK_PHOTO_HUB_BUTTON_STEP';

// TAKE PHOTO ACTIONS
export const STORE_PHOTO = 'STORE_PHOTO';
export const ON_DISPLAY_CAMERA = 'ON_DISPLAY_CAMERA';
export const SET_INCOMING_PHOTO = 'SET_INCOMING_PHOTO';
export const SET_NEXT_INCOMING_PHOTO = 'SET_NEXT_INCOMING_PHOTO';
export const ON_FLASH_BACKGROUND_PHOTO = 'ON_FLASH_BACKGROUND_PHOTO';
export const SET_CURRENT_PHOTO_IN_PREVIEW = 'SET_CURRENT_PHOTO_IN_PREVIEW';
export const ON_CHANGE_STATE_FULLSCREEN = 'ON_CHANGE_STATE_FULLSCREEN';
export const ON_CHANGE_CAMERA_TYPE = 'ON_CHANGE_CAMERA_TYPE';
export const ON_CHANGE_CAMERA_MODE = 'ON_CHANGE_CAMERA_MODE';
export const ON_CHANGE_STATE_OVERLAY = 'ON_CHANGE_STATE_OVERLAY';
export const ON_PRESS_TAKE_PHOTO = 'ON_PRESS_TAKE_PHOTO';
export const SET_NAMED_DRIVERS = 'SET_NAMED_DRIVERS';
export const ON_UPDATE_LOAD_PHOTO_PERCENTAGE =
  'ON_UPDATE_LOAD_PHOTO_PERCENTAGE';
export const ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO =
  'ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO';

export const ON_CHANGE_SPARE_WHEEL_TOGGLE = 'ON_CHANGE_SPARE_WHEEL_TOGGLE';

// VIDEO ACTIONS
export const ON_DISPLAY_MODAL_FINISH_VIDEO = 'ON_DISPLAY_MODAL_FINISH_VIDEO';
export const DISPLAY_VIDEO_SECTION = 'DISPLAY_VIDEO_SECTION';
export const ON_COUNTER_RECORDS_CAMERA = 'ON_COUNTER_RECORDS_CAMERA';
export const ON_COUNTER_SECONS_RECORDING = 'ON_COUNTER_SECONS_RECORDING';
export const ON_RECORDING_CAMERA = 'ON_RECORDING_CAMERA';
export const ON_FINISH_VIDEO = 'ON_FINISH_VIDEO';
export const RESET_VIDEO_SECTION = 'RESET_VIDEO_SECTION';
export const VIDEO_PARAMETERS = 'VIDEO_PARAMETERS';
export const ADD_PHOTO_TO_VIDEO_STATUS_LIST = 'ADD_PHOTO_TO_VIDEO_STATUS_LIST';
export const ON_CAPTURE_PHOTO_FROM_VIDEO = 'ON_CAPTURE_PHOTO_FROM_VIDEO';
export const ON_CHANGE_REQUIRE_VIDEO = 'ON_CHANGE_REQUIRE_VIDEO';
export const GET_PHOTOS_PREVIEW_VIDEO = 'GET_PHOTOS_PREVIEW_VIDEO';
export const ON_DISPLAY_PREVIEW_VIDEO = 'ON_DISPLAY_PREVIEW_VIDEO';
export const RESET_PHOTOS_PREVIEW_VIDEO = 'RESET_PHOTOS_PREVIEW_VIDEO';
export const ON_DISPLAY_REPEAT_VIDEO_MODAL = 'ON_DISPLAY_REPEAT_VIDEO_MODAL';
export const ON_DISPLAY_PLAY_VIDEO_MODAL = 'ON_DISPLAY_PLAY_VIDEO_MODAL';
export const ON_DISPLAY_HINTS_VIDEO_MODAL = 'ON_DISPLAY_HINTS_VIDEO_MODAL';

// SETTINGS ACTIONS
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER';
export const UPDATE_DISPLAY_HINTS = 'UPDATE_DISPLAY_HINTS';
export const UPDATE_RETAKE = 'UPDATE_RETAKE';
export const UPDATE_RESUME = 'UPDATE_RESUME';
export const UPDATE_INVALID_STATUS = 'UPDATE_INVALID_STATUS';
export const SAVE_LASTPATH = 'SAVE_LASTPATH';
export const HINT_IMAGES_LOADED = 'HINT_IMAGES_LOADED';

// PHONE NUMBER CONFIRMATION ACTIONS
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

// MODAL HITS PHOTOS
export const ON_DISPLAY_HINTS_MODAL = 'ON_DISPLAY_HINTS_MODAL';
export const HIDDEN_SECONDARY_BUTTON_MODAL = 'HIDDEN_SECONDARY_BUTTON_MODAL';

export const ON_DISPLAY_FINISH_CONFIRMATION_MODAL =
  'ON_DISPLAY_FINISH_CONFIRMATION_MODAL';

export const ON_DISPLAY_RETAKE_PHOTO_MODAL = 'ON_DISPLAY_RETAKE_PHOTO_MODAL';
// MODAL FAC
export const ON_CHANGE_FAQ_ANSWER = 'ON_CHANGE_FAQ_ANSWER';
export const ON_DISPLAY_FAQ_MODAL = 'ON_DISPLAY_FAQ_MODAL';

export const ON_DISPLAY_INSTRUCTIONS_MODAL = 'ON_DISPLAY_INSTRUCTIONS_MODAL';

export const ON_DISPLAY_EXPIRED_SESSION_MODAL =
  'ON_DISPLAY_EXPIRED_SESSION_MODAL';

export const ON_DISPLAY_INACTIVITY_TIMER_MODAL =
  'ON_DISPLAY_INACTIVITY_TIMER_MODAL';

// CHANGE LANGUAGE
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// GUIDED VISIT
export const ON_CHANGE_STEP_GUIDED_VISIT = 'ON_CHANGE_STEP_GUIDED_VISIT';
export const SET_GUIDED_VISIT_ITEM = 'SET_GUIDED_VISIT_ITEM';
export const ON_CHANGE_STATE_GUIDED_VISIT = 'ON_CHANGE_STATE_GUIDED_VISIT';
export const DESTROY_STEPS_GUIDE = 'DESTROY_STEPS_GUIDE';

// PERMISSIONS
export const SET_PERMISSION_CAMERA = 'SET_PERMISSION_CAMERA';
export const ON_DISPLAY_MODAL_INFO_PERMISSIONS =
  'ON_DISPLAY_MODAL_INFO_PERMISSIONS';
export const TRY_TO_TAKE_PHOTO = 'TRY_TO_TAKE_PHOTO';

// INFORMATION DEVICE
export const SET_INFORMATION_MEMORY_HEAD = 'SET_INFORMATION_MEMORY_HEAD';
export const SET_CURRENT_LOCATION_GPS = 'SET_CURRENT_LOCATION_GPS';
export const SET_GPS_STATUS = 'SET_GPS_STATUS';
export const SET_USER_AGENT_DEVICE = 'SET_USER_AGENT_DEVICE';
export const ON_CLOSING_SESSION_DEVICE = 'ON_CLOSING_SESSION_DEVICE';
export const DECREMENT_INACTIVITY_TIMER_AUTO_CLOSE_SESSION =
  'DECREMENT_INACTIVITY_TIMER_AUTO_CLOSE_SESSION';
export const ON_CHANGE_MAIN_INACTIVITY_TIMER =
  'ON_CHANGE_MAIN_INACTIVITY_TIMER';
export const ON_RESET_ALL_SESSION_TIMER = 'ON_RESET_ALL_SESSION_TIMER';
export const SET_PARAMETERS_INACTIVITY_TIMER =
  'SET_PARAMETERS_INACTIVITY_TIMER';

// SUPPORT REQUEST
export const GET_REGISTERS_SUPPORT_REQUEST = 'GET_REGISTERS_SUPPORT_REQUEST';
export const ENABLE_SUPPORT_REQUEST = 'ENABLE_SUPPORT_REQUEST';
export const DISABLE_SUPPORT_REQUEST = 'DISABLE_SUPPORT_REQUEST';

// AUTHENTICATION
export const SET_AUTHENTICATION_STATUS = 'SET_AUTHENTICATION_STATUS';
export const ON_REVOKE_AUTHENTICATION = 'ON_REVOKE_AUTHENTICATION';

// SESSION
export const SET_TIMELIMIT_TO_EXPIRE_SESSION =
  'SET_TIMELIMIT_TO_EXPIRE_SESSION';
export const START_TIMER_TO_EXPIRE_SESSION = 'START_TIMER_TO_EXPIRE_SESSION';
export const RESET_TIMELIMIT_EXPIRE_SESSION = 'RESET_TIMELIMIT_EXPIRE_SESSION';
export const SET_LOGOUT_TYPE = 'SET_LOGOUT_TYPE';

// NAVIGATION PHOTOHUB
export const ON_DISPLAY_MENU_FOOTER = 'ON_DISPLAY_MENU_FOOTER';
export const ON_DISPLAY_HEADER = 'ON_DISPLAY_HEADER';
