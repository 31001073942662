import {
  ON_DISPLAY_HEADER,
  ON_DISPLAY_MENU_FOOTER,
} from '../actions/ActionTypes';

export const initialState = {
  displayHeader: true,
  displayMenuFooter: true,
};

export default (state = initialState, { payload, type } = {}) => {
  switch (type) {
    case ON_DISPLAY_MENU_FOOTER:
      return { ...state, displayMenuFooter: payload.status };
    case ON_DISPLAY_HEADER:
      return { ...state, displayHeader: payload.status };
    default:
      return state;
  }
};
