import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Panel = props => {
  const { children, disabled, className } = props;

  return (
    <div
      {...props}
      className={classNames('adil-panel', 'adil-box-shadow', className, {
        'adil-panel-disabled': disabled,
      })}
    >
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Panel.defaultProps = {
  disabled: false,
  className: [],
};

export default Panel;
