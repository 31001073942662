/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { VIDEO_SECTION } from '@lmig-latam/adil-api-common-lib/constants';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import { ulid } from 'ulid';
import { TakeVideoActions } from '../../../actions';
import { uploadPhotoRecord360 } from '../../../actions/PhotoHubActions';
import { convertdataURItoBlob, generatePhotoObjects } from '../../../utils';
import { logcodes, logger } from '../../../utils/logger';
import SplashLoading from '../SplashLoading/SplashLoading';
import { logDeviceError } from '../../../api';
import localizedStrings from '../../../utils/localizedStrings';
import './styles.scss';

const WebcamCaptureVideo = React.memo(() => {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const {
    takeVideo: {
      isCapturingPhotoFromVideo,
      videoParameters: { compressPhotosVideo },
    },
    settings: {
      environment: { language },
    },
  } = useSelector(store => store);

  const { LOADING_MESSAGE_CAMERA_PHOTO } = localizedStrings(language);

  const videoConstraints = {
    facingMode: { ideal: 'environment' },
  };

  const onCaptureMultiPhoto = useCallback(async () => {
    if (webcamRef.current) {
      const base64Image = webcamRef.current.getScreenshot();
      let blobImage;
      let blob;
      try {
        blobImage = convertdataURItoBlob(base64Image);
      } catch (error) {
        logger.log(logcodes.CAUPL150, error);
      }

      if (blobImage) {
        const { INSPECTION_VIDEO_PHOTOS_VEHICLE: photoId } = VIDEO_SECTION;
        const { photoToUpload } = await generatePhotoObjects({
          incomingFile: blobImage,
          photoId,
          isOptional: false,
          isConditionallyRequired: false,
          compress: 0.8,
        });

        if (compressPhotosVideo)
          blob = await fetch(photoToUpload.blobUrl).then(r => r.blob());

        dispatch(
          uploadPhotoRecord360({
            id: ulid(),
            image: compressPhotosVideo ? blob : blobImage,
          }),
        );
      }
    }
  }, [compressPhotosVideo, dispatch]);

  const onUserMedia = useCallback(media => {
    setLoading(false);
    logger.log(logcodes.CAMV140, { media });
  }, []);

  const onUserMediaError = useCallback(async error => {
    logger.log(logcodes.CAMV10, { error });
    await logDeviceError({
      errorData: {
        type: error.name,
        message: error.message,
      },
    });
  }, []);

  useEffect(() => {
    if (isCapturingPhotoFromVideo) {
      onCaptureMultiPhoto();
      setTimeout(() => {
        dispatch(TakeVideoActions.onCapturePhotoFromVideo(false));
      }, 500);
    }
  }, [dispatch, isCapturingPhotoFromVideo, onCaptureMultiPhoto]);

  useEffect(() => setLoading(true), []);

  return (
    <>
      {loading && (
        <SplashLoading translucid label={LOADING_MESSAGE_CAMERA_PHOTO} />
      )}
      <div className="video-container">
        <Webcam
          imageSmoothing
          forceScreenshotSourceSize
          className="overlayActived"
          screenshotFormat="image/webp"
          videoConstraints={videoConstraints}
          onUserMediaError={onUserMediaError}
          onUserMedia={onUserMedia}
          screenshotQuality={1}
          ref={webcamRef}
          audio={false}
        />
      </div>
    </>
  );
});

export default WebcamCaptureVideo;
