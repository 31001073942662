import React from 'react';

export const IconPlay = props => (
  <svg width="81.738" height="81.743" viewBox="0 0 81.738 81.743" {...props}>
    <g id="Combined_Shape" data-name="Combined Shape">
      <path
        id="Combined_Shape-2"
        data-name="Combined Shape"
        d="M10.2,67.859l2.885-2.541a36.913,36.913,0,1,0-4.177-5.77L5.59,61.492A40.983,40.983,0,1,1,10.2,67.859ZM27.454,55.714S48.583,43.9,53.881,40.945C50.341,38.9,40.8,33.392,34.67,29.859L30.424,27.41V48.5H26.58l0-22.454c-.014-1.317-.023-2.562,1.155-3.25s2.239-.085,3.568.678l5.291,3.056C43.63,30.59,55.168,37.244,56.743,38.159c1.531.895,2.576,1.5,2.558,2.86-.018,1.338-1.054,1.9-2.055,2.454l-.417.228c-1.94,1.082-27.5,15.371-27.5,15.371Z"
        fill="none"
      />
      <path
        id="Combined_Shape-3"
        data-name="Combined Shape"
        d="M10.2,67.859l2.885-2.541a36.913,36.913,0,1,0-4.177-5.77L5.59,61.492A40.983,40.983,0,1,1,10.2,67.859ZM27.454,55.714S48.583,43.9,53.881,40.945C50.341,38.9,40.8,33.392,34.67,29.859L30.424,27.41V48.5H26.58l0-22.454c-.014-1.317-.023-2.562,1.155-3.25s2.239-.085,3.568.678l5.291,3.056C43.63,30.59,55.168,37.244,56.743,38.159c1.531.895,2.576,1.5,2.558,2.86-.018,1.338-1.054,1.9-2.055,2.454l-.417.228c-1.94,1.082-27.5,15.371-27.5,15.371Z"
        fill="none"
      />
      <path
        id="Combined_Shape-4"
        data-name="Combined Shape"
        d="M10.2,67.859l2.885-2.541a36.913,36.913,0,1,0-4.177-5.77L5.59,61.492A40.983,40.983,0,1,1,10.2,67.859ZM27.454,55.714S48.583,43.9,53.881,40.945C50.341,38.9,40.8,33.392,34.67,29.859L30.424,27.41V48.5H26.58l0-22.454c-.014-1.317-.023-2.562,1.155-3.25s2.239-.085,3.568.678l5.291,3.056C43.63,30.59,55.168,37.244,56.743,38.159c1.531.895,2.576,1.5,2.558,2.86-.018,1.338-1.054,1.9-2.055,2.454l-.417.228c-1.94,1.082-27.5,15.371-27.5,15.371Z"
        fill="none"
      />
      <path
        id="Combined_Shape-5"
        data-name="Combined Shape"
        d="M10.2,67.859l2.885-2.541a36.913,36.913,0,1,0-4.177-5.77L5.59,61.492A40.983,40.983,0,1,1,10.2,67.859ZM27.454,55.714S48.583,43.9,53.881,40.945C50.341,38.9,40.8,33.392,34.67,29.859L30.424,27.41V48.5H26.58l0-22.454c-.014-1.317-.023-2.562,1.155-3.25s2.239-.085,3.568.678l5.291,3.056C43.63,30.59,55.168,37.244,56.743,38.159c1.531.895,2.576,1.5,2.558,2.86-.018,1.338-1.054,1.9-2.055,2.454l-.417.228c-1.94,1.082-27.5,15.371-27.5,15.371Z"
        fill="none"
      />
      <path
        id="Combined_Shape-6"
        data-name="Combined Shape"
        d="M10.2,67.859l2.885-2.541a36.913,36.913,0,1,0-4.177-5.77L5.59,61.492A40.983,40.983,0,1,1,10.2,67.859ZM27.454,55.714S48.583,43.9,53.881,40.945C50.341,38.9,40.8,33.392,34.67,29.859L30.424,27.41V48.5H26.58l0-22.454c-.014-1.317-.023-2.562,1.155-3.25s2.239-.085,3.568.678l5.291,3.056C43.63,30.59,55.168,37.244,56.743,38.159c1.531.895,2.576,1.5,2.558,2.86-.018,1.338-1.054,1.9-2.055,2.454l-.417.228c-1.94,1.082-27.5,15.371-27.5,15.371Z"
        fill="none"
      />
      <path
        id="Combined_Shape-7"
        data-name="Combined Shape"
        d="M10.2,67.859l2.885-2.541a36.913,36.913,0,1,0-4.177-5.77L5.59,61.492A40.983,40.983,0,1,1,10.2,67.859ZM27.454,55.714S48.583,43.9,53.881,40.945C50.341,38.9,40.8,33.392,34.67,29.859L30.424,27.41V48.5H26.58l0-22.454c-.014-1.317-.023-2.562,1.155-3.25s2.239-.085,3.568.678l5.291,3.056C43.63,30.59,55.168,37.244,56.743,38.159c1.531.895,2.576,1.5,2.558,2.86-.018,1.338-1.054,1.9-2.055,2.454l-.417.228c-1.94,1.082-27.5,15.371-27.5,15.371Z"
        fill="#fff"
      />
    </g>
  </svg>
);
