import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getClassificationPhotos } from '../constants/wizardPhotos';
import { conditionallyRequiredPhotos } from '../config';
import { AllowPhotoCountry } from '../config/AllowPhotoCountry';
import { getCountryCode } from '../utils/NavigationUtils';

export const usePhotoConfigByStep = (requiredPhotos, vehicleClass) => {
  const { currentStep } = useSelector(store => store.photos);

  return useMemo(() => {
    const classifiedPhotos = getClassificationPhotos(vehicleClass);

    const stepPhotos = classifiedPhotos[currentStep];
    const conditionallyPhotoConfig = conditionallyRequiredPhotos(
      getCountryCode(),
    );

    const allowPhotoCountry = new AllowPhotoCountry(getCountryCode());
    return [...requiredPhotos, ...conditionallyPhotoConfig].filter(
      item =>
        stepPhotos.includes(item.photoId) &&
        allowPhotoCountry.isAllow(item.photoId),
    );
  }, [requiredPhotos, currentStep, vehicleClass]);
};
