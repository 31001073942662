import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '@lmig-latam/adil-api-common-lib/adlib-deps/logger';
import { useInterval } from '../../hooks';
import { ModalInactivityTimer } from '../ModalInactivityTimer';
import { AuthActions, ModalFlowActions, SessionActions } from '../../actions';
import CloseSession from '../../utils/session/CloseSession';
import { getCountryCode } from '../../utils/NavigationUtils';
import { InactivitySession } from '../../utils/session/InactivitySession';
import { logcodes } from '../../utils/logger';
import { DISABLE_SUPPORT_REQUEST } from '../../actions/ActionTypes';
import { LOGOUT_TYPE } from '../../constants/session';

export const InactivityProvider = ({ isExceptionPages, isAllowProvider }) => {
  const {
    auth: { isAuthenticated },
    session: { inactivityTimestampLimit },
    modalFlow: { displayInactivityTimerModal, displayExpiredSessionModal },
    settings: { environment },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  const { language } = environment;

  const inactivitySessionInstance = useMemo(
    () => new InactivitySession(getCountryCode()),
    [],
  );

  const onClosingUserSession = useCallback(() => {
    logger.log(logcodes.SESS010);
    dispatch({ type: DISABLE_SUPPORT_REQUEST });
    dispatch(AuthActions.setAuthenticationStatus(false));
    dispatch(SessionActions.setLogoutType(LOGOUT_TYPE.INACTIVITY_SESSION));
    dispatch(SessionActions.onResetAllSessionTimer());
  }, [dispatch]);

  const onCloseInactivityModalHandler = () => {
    dispatch(ModalFlowActions.onDisplayInactivityTimerModal(false));
    inactivitySessionInstance.redirectByInactivityTo();
    onClosingUserSession();
    CloseSession.clearSessionByInactivity();
    dispatch(ModalFlowActions.onDisplayInstructionsModal(false));
  };

  const onSuccessInactivityModal = () => {
    // isAuthenticated: false. If don't user authenticated should be close sesion and redirect
    // to homepage (Terms of conditions or number code confirmation page)
    if (!isAuthenticated) {
      onClosingUserSession();
      CloseSession.clearSessionByInactivity();
      inactivitySessionInstance.redirectByInactivityTo();
      dispatch(ModalFlowActions.onDisplayInactivityTimerModal(false));
      return;
    }

    // Continue user navigation
    dispatch(ModalFlowActions.onDisplayInactivityTimerModal(false));

    const nextTimestamp = inactivitySessionInstance.getNextTimerTimestamp();
    dispatch(SessionActions.onChangeMainInactivityTimer(nextTimestamp));
  };

  const { stopInterval: stopInactivityInterval } = useInterval(
    'InactivityTimeout',
    () => {
      const userInspectionStarted = sessionStorage.getItem(
        'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
      );

      if (
        isExceptionPages ||
        displayExpiredSessionModal ||
        displayInactivityTimerModal ||
        !userInspectionStarted
      ) {
        stopInactivityInterval();
        return;
      }

      if (!isAllowProvider) {
        stopInactivityInterval();
        return;
      }

      if (!isAuthenticated && !displayInactivityTimerModal) {
        if (isAllowProvider) {
          dispatch(ModalFlowActions.onDisplayInactivityTimerModal(true));
          stopInactivityInterval();
          return;
        }

        stopInactivityInterval();
        dispatch(ModalFlowActions.onDisplayInactivityTimerModal(false));
        return;
      }

      if (!inactivityTimestampLimit) {
        stopInactivityInterval();
        return;
      }
      const currentTimestamp = new Date();
      if (currentTimestamp >= inactivityTimestampLimit) {
        stopInactivityInterval();
        dispatch(SessionActions.onChangeMainInactivityTimer(null));
        dispatch(ModalFlowActions.onDisplayInactivityTimerModal(true));
      }
    },
    1000,
  );

  return (
    <>
      {displayInactivityTimerModal && (
        <ModalInactivityTimer
          isOpen={displayInactivityTimerModal}
          language={language}
          onCloseModal={onCloseInactivityModalHandler}
          onSuccessModal={onSuccessInactivityModal}
          onClosingUserSession={onClosingUserSession}
        />
      )}
    </>
  );
};

InactivityProvider.propTypes = {
  isExceptionPages: PropTypes.bool.isRequired,
  isAllowProvider: PropTypes.bool.isRequired,
};
