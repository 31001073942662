import React from 'react';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { PHOTO_HUB_LOADING_PHOTO_LABEL } = localizedStrings();
function ProgressBar({ bgcolor, completed, isError }) {
  const containerStyles = {
    width: `${completed}%`,
    backgroundColor: bgcolor,
  };
  const fillerStyles = {
    backgroundColor: bgcolor,
  };

  const ProgressBarLabel = isError
    ? ''
    : `${PHOTO_HUB_LOADING_PHOTO_LABEL} ${completed || 0}%`;

  return (
    <div className="progress-bar">
      <div className="progress-bar__label-container">
        <label>{ProgressBarLabel}</label>
      </div>

      <div className="progress-bar__container" style={containerStyles}>
        <div className="progress-bar__filler" style={fillerStyles} />
      </div>
    </div>
  );
}

ProgressBar.defaultProps = {
  bgcolor: '#99E5EA',
  completed: 0,
  isError: false,
};

ProgressBar.propTypes = {
  bgcolor: PropTypes.string,
  completed: PropTypes.number,
  isError: PropTypes.bool,
};

export default ProgressBar;
