import { getCountryCode } from './NavigationUtils';
import { VehicleClass } from './VehicleClass';

export const BUTTON_ACTION = {
  ADD: 'ADD',
  REPEAT: 'REPEAT',
  REMOVE: 'REMOVE',
};

export class FingerprintButton {
  constructor(photoId) {
    this.photoId = photoId;
  }

  getId() {
    const isRetake = this.isRetake ? 'RETAKE-' : '';
    const vehicleClassString = `${this.getVehicle(this.vehicleClass)}-`;
    const photoIdString = this.photoId ? `${this.photoId}-` : 'UNKNOWN-';
    const actionString = this.action ? `${this.action}` : 'UNKNOWN';
    return vehicleClassString + isRetake + photoIdString + actionString;
  }

  setIsRetake(isRetake) {
    this.isRetake = isRetake;
    return this;
  }

  setAction(action) {
    this.action = action;
    return this;
  }

  setVehicleClass(vehicleClass) {
    this.vehicleClass = vehicleClass;
    return this;
  }

  getVehicle = vehicleClass => {
    const vehicle = new VehicleClass(getCountryCode());
    return vehicle.getVehicle(vehicleClass);
  };
}
