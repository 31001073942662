import { deletePhoto } from '../api';
import {
  InvalidStatusError,
  TokenError,
  handleInvalidStatusError,
  handleTokenMissingError,
} from '../utils/customErrors';
import {
  DECREMENT_INACTIVITY_TIMER_AUTO_CLOSE_SESSION,
  HIDE_APP_LOADER,
  ON_CHANGE_MAIN_INACTIVITY_TIMER,
  ON_RESET_ALL_SESSION_TIMER,
  RESET_TIMELIMIT_EXPIRE_SESSION,
  SET_PARAMETERS_INACTIVITY_TIMER,
  SET_TIMELIMIT_TO_EXPIRE_SESSION,
  START_TIMER_TO_EXPIRE_SESSION,
  SET_LOGOUT_TYPE,
} from './ActionTypes';

function setTimelimitToExpireSession(seconds) {
  return {
    type: SET_TIMELIMIT_TO_EXPIRE_SESSION,
    payload: { seconds },
  };
}

function startTimerToExpireSession() {
  return {
    type: START_TIMER_TO_EXPIRE_SESSION,
  };
}

function resetTimelimitExpireSession() {
  return {
    type: RESET_TIMELIMIT_EXPIRE_SESSION,
  };
}

export const onDecrementInactivityTimerAutoCloseSession = () => ({
  type: DECREMENT_INACTIVITY_TIMER_AUTO_CLOSE_SESSION,
});

export const setDefaultInactivityTimers = (mainTimer, modalTimer) => ({
  type: SET_PARAMETERS_INACTIVITY_TIMER,
  payload: { mainTimer, modalTimer },
});

export const onChangeMainInactivityTimer = timestamp => ({
  type: ON_CHANGE_MAIN_INACTIVITY_TIMER,
  payload: { timestamp },
});

export const onResetAllSessionTimer = () => ({
  type: ON_RESET_ALL_SESSION_TIMER,
});

export const setLogoutType = topic => ({
  type: SET_LOGOUT_TYPE,
  payload: { topic },
});

function deleteAllPhotos() {
  return async dispatch => {
    try {
      await deletePhoto(null, null, true);
    } catch (error) {
      dispatch({ type: HIDE_APP_LOADER });
      if (error instanceof TokenError) {
        handleTokenMissingError(error);
      } else if (error instanceof InvalidStatusError) {
        handleInvalidStatusError(error);
      }
    }
  };
}

export default {
  setTimelimitToExpireSession,
  startTimerToExpireSession,
  resetTimelimitExpireSession,
  onDecrementInactivityTimerAutoCloseSession,
  onChangeMainInactivityTimer,
  onResetAllSessionTimer,
  setDefaultInactivityTimers,
  deleteAllPhotos,
  setLogoutType,
};
