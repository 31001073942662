import PropTypes from 'prop-types';
import {
  ON_CHANGE_STATE_FULLSCREEN,
  ON_FLASH_BACKGROUND_PHOTO,
  ON_DISPLAY_CAMERA,
  SET_CURRENT_PHOTO_IN_PREVIEW,
  SET_INCOMING_PHOTO,
  SET_NEXT_INCOMING_PHOTO,
  STORE_PHOTO,
  ON_CHANGE_CAMERA_TYPE,
  ON_CHANGE_CAMERA_MODE,
  ON_CHANGE_STATE_OVERLAY,
  ON_PRESS_TAKE_PHOTO,
  ON_UPDATE_LOAD_PHOTO_PERCENTAGE,
} from './ActionTypes';

const storePhoto = photo => async dispatch => {
  dispatch({
    type: STORE_PHOTO,
    photo,
  });
};

const loadPreviusPhotos = photo => ({
  type: STORE_PHOTO,
  photo,
});

const onDisplayCamera = displayCamera => ({
  type: ON_DISPLAY_CAMERA,
  payload: { displayCamera },
});

const setIncomingPhoto = photoId => ({
  type: SET_INCOMING_PHOTO,
  payload: { photoId },
});

const setNextIncomingPhoto = photoId => ({
  type: SET_NEXT_INCOMING_PHOTO,
  payload: { photoId },
});

const onFlashInBackgroundPhoto = isFlashing => ({
  type: ON_FLASH_BACKGROUND_PHOTO,
  payload: { isFlashing },
});

const setCurrentPhotoPreview = photo => ({
  type: SET_CURRENT_PHOTO_IN_PREVIEW,
  payload: { photo },
});

const onChangeStateFullscreen = isFullscreen => ({
  type: ON_CHANGE_STATE_FULLSCREEN,
  payload: { isFullscreen },
});

const onChangeCameraType = cameraType => ({
  type: ON_CHANGE_CAMERA_TYPE,
  payload: { cameraType },
});

const onChangeCameraMode = cameraMode => ({
  type: ON_CHANGE_CAMERA_MODE,
  payload: { cameraMode },
});

const onChangeStateOverlay = overlay => ({
  type: ON_CHANGE_STATE_OVERLAY,
  payload: { overlay },
});

const onPressButtonTakePhotoStatus = status => ({
  type: ON_PRESS_TAKE_PHOTO,
  payload: { status },
});

const onUpdateLoadPhotoPercentage = percentage => ({
  type: ON_UPDATE_LOAD_PHOTO_PERCENTAGE,
  payload: { percentage },
});

export default {
  storePhoto,
  loadPreviusPhotos,
  setIncomingPhoto,
  setNextIncomingPhoto,
  onFlashInBackgroundPhoto,
  setCurrentPhotoPreview,
  onChangeStateFullscreen,
  onDisplayCamera,
  onChangeCameraType,
  onChangeCameraMode,
  onChangeStateOverlay,
  onPressButtonTakePhotoStatus,
  onUpdateLoadPhotoPercentage,
  PropertyTypes: PropTypes.shape({
    storePhoto: PropTypes.func.isRequired,
    setIncomingPhoto: PropTypes.func.isRequired,
    onFlashInBackgroundPhoto: PropTypes.func.isRequired,
    setCurrentPhotoPreview: PropTypes.func.isRequired,
  }),
};
