import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../styles/images/close.svg';
import { Image } from '..';

function TooltipHeader({ title, onClose }) {
  return (
    <div className="tooltip__header">
      <h2 className="tooltip__header title">{title}</h2>
      <Image
        alt=""
        src={CloseIcon}
        className="tooltip__header--close"
        onClick={() => onClose()}
      />
    </div>
  );
}

TooltipHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TooltipHeader;
