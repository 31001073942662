import React from 'react';

function IconHelp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <g
        id="Grupo_11169"
        data-name="Grupo 11169"
        transform="translate(-18 -313)"
      >
        <circle
          id="Elipse_419"
          data-name="Elipse 419"
          cx="17"
          cy="17"
          r="17"
          transform="translate(18 313)"
          fill="#343741"
          opacity="0.3"
          // style="mix-blend-mode: multiply;isolation: isolate"
        />
        <g
          id="Grupo_5170"
          data-name="Grupo 5170"
          transform="translate(21150.135 17783.203)"
        >
          <circle
            id="Oval"
            cx="10"
            cy="10"
            r="10"
            transform="translate(-21125 -17463.203)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          />
          <g
            id="Color_Liberty_Blue"
            data-name="Color/Liberty Blue"
            transform="translate(-21118.334 -17459.314)"
          >
            <path
              id="Mask"
              d="M2.651,10.936a.839.839,0,1,1,.839.839A.839.839,0,0,1,2.651,10.936Zm.142-1.782v-2.9a.671.671,0,0,1,.671-.671A2.122,2.122,0,1,0,1.342,3.464H0a3.464,3.464,0,1,1,4.135,3.4V9.154Z"
              fill="#13112b"
            />
            <g id="Grupo_5169" data-name="Grupo 5169">
              <path
                id="Fill_2597"
                data-name="Fill 2597"
                d="M4.134,9.154H2.793v-2.9a.671.671,0,0,1,.671-.671A2.122,2.122,0,1,0,1.342,3.464H0a3.464,3.464,0,1,1,4.134,3.4Z"
                fill="#fff"
              />
              <path
                id="Fill_2599"
                data-name="Fill 2599"
                d="M.839,1.679A.839.839,0,1,1,1.679.839.839.839,0,0,1,.839,1.679Z"
                transform="translate(2.65 10.096)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconHelp;
