import React from 'react';

function TrashIcon(props) {
  return (
    <svg width="50" height="50" viewBox="0 0 44 44" {...props}>
      <defs>
        <filter
          id="Elipse_243"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feFlood floodOpacity="0.4" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Componente_1686_3" transform="translate(4 4)">
        <g
          transform="matrix(1, 0, 0, 1, -4, -4)"
          filter="url(#Elipse_243)"
          style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
        >
          <circle
            id="Elipse_243-2"
            data-name="Elipse 243"
            cx="16"
            cy="16"
            r="16"
            transform="translate(4 4)"
            fill="#c0bfc0"
          />
        </g>
        <g
          id="Icon_System_Trash_16px"
          data-name="Icon/System/Trash/16px"
          transform="translate(10.25 8.002)"
        >
          <g
            id="Combined_Shape"
            data-name="Combined Shape"
            transform="translate(-0.25 -0.002)"
          >
            <path
              id="Combined_Shape-2"
              data-name="Combined Shape"
              d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
              fill="none"
            />
            <path
              id="Combined_Shape-3"
              data-name="Combined Shape"
              d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
              fill="none"
            />
            <path
              id="Combined_Shape-4"
              data-name="Combined Shape"
              d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
              fill="none"
            />
            <path
              id="Combined_Shape-5"
              data-name="Combined Shape"
              d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
              fill="none"
            />
            <path
              id="Combined_Shape-6"
              data-name="Combined Shape"
              d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
              fill="none"
            />
            <path
              id="Combined_Shape-7"
              data-name="Combined Shape"
              d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

TrashIcon.propTypes = {};

export default TrashIcon;
