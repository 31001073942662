import constants from '@lmig-latam/adil-api-common-lib/constants/constants';

const {
  LANGUAGES: { EN },
} = constants;

const code = EN;

const strings = {
  BROWSER_TAB_TITLE: 'Liberty Motor Digital Inspection – Liberty Insurance',
  APP_HEADER_SUBTITLE: 'Liberty Motor Digital Inspection',
  APP_HEADER_SUBTITLE_MOTORBIKE: 'Liberty Motorbike Digital Inspection',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Liberty Insurance',
  LIBERTY_SECONDARY_LOGO_IMAGE: '',
  LIBERTY_SECONDARY_LOGO_IMAGE_ALT_TEXT: '',
  VIGILADO_IMAGE_ALT_TEXT: 'VIGILADO SUPERINTENDENCIA BANCARIA DE COLOMBIA',

  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: 'An error has occurred',
  ADMIN_SUPPORT_ERROR_GENERAL: 'Please try the action again',

  MAIN_LOADER_TITLE: 'This process may take a few more seconds.',
  MAIN_LOADER_SUBTITLE: 'Please wait while we continue querying.',

  LOADING_TEXT: 'Loading',
  INLINE_LOADING_TEXT: '...Loading',
  LOADING_TEXT_DETECTING_BROWSER: 'Detecting browser and operating system...',

  FLOAT_MENU_ITEM_1: 'Frequent questions',
  FLOAT_MENU_ITEM_2: 'Switch to',
  FLOAT_MENU_ITEM_3: 'Home',

  APP_FOOTER_NEED_HELP_LINE_1: 'Do you need help with your digital inspection?',
  APP_FOOTER_NEED_HELP_LINE_2: 'Customer Service Centre',
  APP_FOOTER_TERMS_CONDITIONS_LINK: 'Terms and conditions',
  APP_FOOTER_PRIVACY_POLICY_LINK: 'Privacy policy',
  APP_FOOTER_COPYRIGHT: 'Liberty Insurance {year}',
  APP_FOOTER_TITLE: 'Liberty {year}. All rights reserved.',

  ERROR_MESSAGE_TITLE: 'Sorry, the process has already started or does not work correctly',
  NON_BROWSER_ERROR_MESSAGE_TITLE: 'We have detected that you are using a browser that is not compatible with the application',
  ERROR_MESSAGE_SUBTITLE: 'If you need help, you can contact us through our service channels',
  NON_BROWSER_ERROR_MESSAGE_SUBTITLE: 'We recommend you copy this link in one of the following browsers:',
  BUTTON_CLIPBOARD: 'Copy',
  TEXT_COPIED: 'Copied',

  NON_MOBILE_ERROR_HEADER_TITLE: 'Car Photo Inspection',
  NON_MOBILE_ERROR_MESSAGE_TITLE: 'We need you to take the pictures with cell phone',
  NON_MOBILE_ERROR_MESSAGE_SUBTITLE: 'Try to perform the process and take the pictures with your cell phone camera',
  NON_MOBILE_ERROR_PRIMARY_MESSAGE: 'For more information, call this number ',
  NON_MOBILE_ERROR_PRIMARY_PHONE_NUMBER: '028 381 25103',
  NON_MOBILE_ERROR_OTHER_COUNTRY_MESSAGE: '',
  NON_MOBILE_ERROR_OTHER_COUNTRY_PHONE_NUMBER: '',

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404: Page not found!',

  GPS_PERMISSION_DENIED: 'Denied permission',
  GPS_PERMISSION_ENABLED: 'Allowed permission',
  GPS_ERROR_NOT_SUPPORT: 'Navigator not support geolocation',
  GPS_POSITION_ERROR_UNAVAILABLE: 'Unavailable position',
  GPS_POSITION_ERROR_TIMEOUT: 'Timeout',

  MODAL_PRIMARY_BUTTON_LABEL: 'Continue',
  MODAL_LAST_BUTTON_LABEL: 'Close',

  PHONE_NUMBER_CONFIRMATION_TITLE: 'To start the process we must validate your identity',
  PHONE_NUMBER_CONFIRMATION_SUBTITLE: 'We have sent a token to your cell phone',
  PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT: 'Cellphone and message',
  PHONE_NUMBER_CONFIRMATION_INPUT_PLACEHOLDER: 'Enter code here',
  PHONE_NUMBER_CONFIRMATION_INPUT_VALIDATION_MESSAGE: 'Please enter the 6 digit SMS code',
  PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE: 'The entered token does not correspond, please retry',
  PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED: 'I have not received it yet.',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE: 'Resend',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS: 'Sent',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE: "Oops, we're sorry",
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE: 'Something has happened, your code could not be sent',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE_TWO: 'Please try again',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL: 'Close',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE: 'You have reached the maximum number of attempts',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE: 'Please try again in 10 minutes',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL: 'Resend',
  PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON: 'Continue',
  PHONE_NUMBER_CONFIRMATION_LOADING_TEXT: 'Please wait...',

  TERMS_CONDITIONS_TITLE: 'Welcome',
  TERMS_CONDITIONS_SUBTITLE: 'Do not forget to check the terms and conditions to continue',
  TERMS_CONDITIONS_SUBTITLE_2: 'We welcome you to the digital inspection process for your vehicle',
  TERMS_CONDITIONS_MOTORBIKE_SUBTITLE_2: 'We welcome you to the digital inspection procss for your motorbike',
  TERMS_CONDITIONS_SHOW_MODAL_BUTTON: 'Terms and conditions',
  TERMS_CONDITIONS_DISCLAIMER: 'By clicking on start, we understand that you agree with the terms and conditions',
  TERMS_CONDITIONS_DISCLAIMER_2: 'Before starting you must read and accept the ',
  TERMS_CONDITIONS_CONTINUE_BUTTON: 'Start',
  TERMS_CONDITIONS_LOADING_TEXT: 'Please wait...',

  HELP_SUPPORT_BUTTON: 'Request support',

  TERMS_CONDITIONS_MODAL_CONTENT: {
    VN: [
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Terms and conditions',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP:
          'Dear User\n\n Liberty Insurance Limited (“Liberty”) warmly welcomes you to the ADIL (Liberty Digital Auto Inspection) application for inspection of your vehicle(s). Please read carefully the following conditions of use and privacy policies of this application, since by the use made of this, the terms and conditions that regulate it and that are indicated below are expressly accepted. If you do not want to accept the conditions and terms that regulate the site, you must immediately refrain from continuing to use this platform / application.\n\nADIL is an application that will allow the company to know the current status of the vehicle through the information entered and the photographs taken by you. Any information and pictures provided by you are the basis of insurance policy between you and Liberty. Any incorrect or non-disclosure information and/or pictures could make your policy become invalid and your claim(s) may not be paid.\n\nThe personal information that you provide through the application will be considered confidential. Please read carefully our privacy policies at:\n <p style="overflow: auto;">http://www.libertyinsurance.com.vn/en/internet-privacy-policy.</p> \nBy clicking to the below button, you have expressly understood and agreed with the above-mentioned conditions.',
      },
    ],
    TH: [
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Terms and conditions',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP:
          'Dear User\n\n LMG Insurance Public Company Limited (“LMG”) warmly welcomes you to the ADIL (Liberty Digital Auto Inspection) application for inspection of your vehicle(s). Please read carefully the following conditions of use and privacy policies of this application, since by the use made of this, the terms and conditions that regulate it and that are indicated below are expressly accepted. If you do not want to accept the conditions and terms that regulate the site, you must immediately refrain from continuing to use this platform/application.\n\nADIL is an application that will allow the company to know the current status of the vehicle through the information entered and the photographs taken by you. Any information and pictures provided by you are the basis of insurance policy between you and LMG. Any incorrect or non-disclosure information and/or pictures could make your policy become invalid and your claim(s) may not be paid. If you provide inaccurate or misled picture, it may be deemed you made fraudulent information and may lead to legal action.\n\nThe personal information that you provide through the application will be considered confidential. Please read carefully our privacy policies at:\n <p style="overflow: auto;">https://www.lmginsurance.co.th/en/Documents/Our_Company/Privacy%20Principles_LMG.pdf</p> \nBy clicking to the below button, you have expressly understood and agreed with the above-mentioned conditions.',
      },
    ],
  },
  TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL: 'Continue',

  WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE: 'Documentation photos',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE: 'Vehicle Exterior',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE: 'Vehicle Interior',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE: 'Accessories',

  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE: 'Documentation photos',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE: 'Motorbike Exterior',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE: 'Instruments and identification',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE: 'Accessories',
  WIZARD_PHOTO_HUB_FINISH_TITLE: 'Finish and send',

  PHOTO_HUB_TITLE: 'Photographs',
  PHOTO_HUB_SUBTITLE: 'Take the photographs taking into account the indications in each',
  PHOTO_HUB_HINTS_TOGGLE_ENABLED_LABEL: 'Disable instructions',
  PHOTO_HUB_HINTS_TOGGLE_DISABLED_LABEL: 'Enable instructions',
  PHOTO_HUB_FINISH_PROGRESS: '...finishing',
  PHOTO_HUB_LOAD_TEST_IMAGES_PROGRESS: 'Compressing & Uploading test images...',
  PHOTO_HUB_FOOTER_PROGRESS: '{photosTaken} of {photosRequired} photos taken',
  PHOTO_HUB_FOOTER_SUCCESS_VIDEO: 'Completed video',
  PHOTO_HUB_FOOTER_PENDING_VIDEO: 'Pending video',
  PHOTO_HUB_CONTINUE_BUTTON: 'Finalize process',
  PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT: 'Preview',
  PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT: 'Retake Photo',
  PHOTO_HUB_SUBMIT_LOADER_TEXT: 'Sending photographs',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE: "Oops, we're sorry",
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE: 'Something has happened, your photos could not be uploaded, try again',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL: 'Retry',
  PHOTO_HUB_LOAD_TEST_IMAGES_BUTTON_LABEL: 'Load Test Images',
  PHOTO_HUB_SHOW_CAMERA_BUTTON_LABE: 'View camera',

  PHOTO_HUB_STEP_01_TITLE: 'Front property card',
  PHOTO_HUB_STEP_01_SUBTITLE: 'The property card of the vehicle you want to inspect.',
  PHOTO_HUB_STEP_01_HINT: 'Take a picture of the front of the drivers license.',

  PHOTO_HUB_STEP_02_TITLE: 'Back property card',
  PHOTO_HUB_STEP_02_SUBTITLE: 'The property card of the vehicle you want to inspect.',
  PHOTO_HUB_STEP_02_HINT: 'Take a picture of the back of the drivers license.',

  PHOTO_HUB_STEP_03_TITLE: 'Number plate',
  PHOTO_HUB_STEP_03_SUBTITLE: 'The license plate of the vehicle you want to inspect.',
  PHOTO_HUB_STEP_03_HINT: 'Bend your knees and place your phone in front of the number plate.',

  PHOTO_HUB_STEP_04_TITLE: 'Vehicle front',
  PHOTO_HUB_STEP_04_SUBTITLE: 'It is the front part of the vehicle where the engine is located.',
  PHOTO_HUB_STEP_04_HINT: 'Hold your phone at your head and tilt it down a bit.',

  PHOTO_HUB_STEP_05_TITLE: 'Rear of the vehicle',
  PHOTO_HUB_STEP_05_SUBTITLE: 'It is the back where the trunk is normally located.',
  PHOTO_HUB_STEP_05_HINT: 'Hold your phone at your head and tilt it down a bit.',

  PHOTO_HUB_STEP_06_TITLE: 'Right side',
  PHOTO_HUB_STEP_06_SUBTITLE: "The right part of the vehicle when you are sitting in the driver's seat.",
  PHOTO_HUB_STEP_06_HINT: 'Hold your phone at shoulder height.',

  PHOTO_HUB_STEP_07_TITLE: 'Left side',
  PHOTO_HUB_STEP_07_SUBTITLE: "The left side of the vehicle when you are sitting in the driver's seat.",
  PHOTO_HUB_STEP_07_HINT: 'Hold your phone at shoulder height.',

  PHOTO_HUB_STEP_08_TITLE: 'Engine compartment',
  PHOTO_HUB_STEP_08_SUBTITLE: 'Corresponds to the space where the vehicle engine is stored.',
  PHOTO_HUB_STEP_08_HINT: 'Hold your phone at your head and tilt it a little.',

  PHOTO_HUB_STEP_09_TITLE: 'Milometer or dashboard',
  PHOTO_HUB_STEP_09_SUBTITLE: 'It is the board where you can control the mileage, the fuel level and the temperature of the vehicle.',
  PHOTO_HUB_STEP_09_HINT: 'Sit in the back seat to get a full shot of the front.',

  PHOTO_HUB_STEP_10_TITLE: 'Mileage',
  PHOTO_HUB_STEP_10_SUBTITLE: 'The number of kilometers traveled by the vehicle, captured from the dashboard.',
  PHOTO_HUB_STEP_10_HINT: 'Turn on the dashboard light so you can better capture the mileage.',
  PHOTO_HUB_STEP_10_HINT_BEFORE_TAKE_PHOTO: 'Start the engine with caution Verify that you are not in gear',
  PHOTO_HUB_STEP_10_HINT_BEFORE_TAKE_PHOTO_BUTTON: 'Ready! I already started the engine',

  PHOTO_HUB_STEP_11_TITLE: 'Sticker or serial plate',
  PHOTO_HUB_STEP_11_SUBTITLE: 'Also known as VIN, it is the serial number of your vehicle.',
  PHOTO_HUB_STEP_11_HINT: 'You can find it in the left or right central post, the left metal dust cover or on the bulkhead panel.',

  PHOTO_HUB_STEP_12_TITLE: 'Chassis number',
  PHOTO_HUB_STEP_12_SUBTITLE: 'If you do not know where to find it, follow the location tips when taking the picture.',
  PHOTO_HUB_STEP_12_HINT: 'You can find it under the passenger seat, on the bulkhead panel, on the spars or beams of the chassis or the floor of the trunk.',
  PHOTO_HUB_STEP_11_HINT_2: 'If you cant find the VIN, you can take a photo of the serial number near the drivers door..',

  PHOTO_HUB_STEP_13_TITLE: 'Panoramic plate',
  PHOTO_HUB_STEP_13_SUBTITLE: 'It is a number that you find marked on a metal sheet.',
  PHOTO_HUB_STEP_13_HINT: 'You can find it in the lower left part of the front windscreen.',

  PHOTO_HUB_STEP_14_TITLE: 'Left headlamp',
  PHOTO_HUB_STEP_14_SUBTITLE: 'Left headlamp',
  PHOTO_HUB_STEP_14_HINT: 'Left headlamp',

  PHOTO_HUB_STEP_15_TITLE: 'Right headlamp',
  PHOTO_HUB_STEP_15_SUBTITLE: 'Right headlamp',
  PHOTO_HUB_STEP_15_HINT: 'Right headlamp',

  PHOTO_HUB_STEP_16_TITLE: 'The certificate of inspection',
  PHOTO_HUB_STEP_16_SUBTITLE: 'The certificate of inspection',
  PHOTO_HUB_STEP_16_HINT: 'The certificate of inspection',

  PHOTO_HUB_STEP_17_TITLE: 'The registration card',
  PHOTO_HUB_STEP_17_SUBTITLE: 'The registration card',
  PHOTO_HUB_STEP_17_HINT: 'The registration card',

  PHOTO_HUB_STEP_18_TITLE: 'Left front angle',
  PHOTO_HUB_STEP_18_SUBTITLE: 'Left front side in 45° angle',
  PHOTO_HUB_STEP_18_HINT: 'Please take the photo while standing next to the front left headlight',
  PHOTO_HUB_STEP_19_TITLE: 'Right front angle',
  PHOTO_HUB_STEP_19_SUBTITLE: 'Right front side in 45° angle',

  PHOTO_HUB_STEP_19_HINT: 'Please take the photo while standing next to the front right headlight',
  PHOTO_HUB_STEP_20_TITLE: 'Right back angle',
  PHOTO_HUB_STEP_20_SUBTITLE: 'Right back side in 45° angle',
  PHOTO_HUB_STEP_20_HINT: 'Please take the photo while standing next to the back right backlamp',
  PHOTO_HUB_STEP_21_TITLE: 'Left back angle',
  PHOTO_HUB_STEP_21_SUBTITLE: 'Left back side in 45° angle',

  PHOTO_HUB_STEP_21_HINT: 'Please take the photo while standing next to the back left backlamp',
  PHOTO_HUB_STEP_22_TITLE: 'Odometer',
  PHOTO_HUB_STEP_22_SUBTITLE: 'It is the board where you can control the mileage, the fuel level and the temperature of the vehicle. Start the engine (wait 30s) and take the picture of dashboard',
  PHOTO_HUB_STEP_22_HINT: 'Please start the engine, wait for 30 seconds and then take the photo of the internal dashboard while sitting in the driver seat',

  PHOTO_HUB_STEP_23_TITLE: 'ID Card/ Passport',
  PHOTO_HUB_STEP_23_SUBTITLE: 'Valid ID Card/ Passport.',
  PHOTO_HUB_STEP_23_HINT: 'Please take only the photo of the front-side of the ID Card or the information page of passport',

  PHOTO_HUB_STEP_24_TITLE: 'Driver License/ IDP for 1st named driver',
  PHOTO_HUB_STEP_24_SUBTITLE: 'Valid Driver License.',
  PHOTO_HUB_STEP_24_HINT: 'Please take only the photo of the front-side of the Driver License.',

  PHOTO_HUB_STEP_25_TITLE: 'Driver License/ IDP for 2nd named driver',
  PHOTO_HUB_STEP_25_SUBTITLE: 'Valid Driver License.',
  PHOTO_HUB_STEP_25_HINT: 'Please take only the photo of the front-side of the Driver License.',

  PHOTO_HUB_VIDEO_HINTS_TITLE: 'Complete vehicle exterior video',
  PHOTO_HUB_VIDEO_HINTS_DESCRIPTION: 'Make a recording around the vehicle, starting from the front and going around it from the left side to the right, until you return to the front.',
  PHOTO_HUB_VIDEO_HINTS_WARNING: 'You have {time} {measureTime} to make the video. Please note: once recording has started you will not be able to pause it',
  PHOTO_HUB_VIDEO_HINTS_PRIMARY_BUTTON: 'Got it',
  PHOTO_HUB_VIDEO_HINTS_MINUTES: 'minutes',
  PHOTO_HUB_VIDEO_HINTS_MINUTE: 'minute',

  PHOTO_HUB_EXAMPLE_TITLE: 'EXAMPLE',
  PHOTO_HUB_EXAMPLE_TITLE_VIDEO: 'POSITION THE CAMERA LIKE THIS:',
  PHOTO_HUB_EXAMPLE_BUTTON: 'Accept',

  PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON: 'Yes, continue',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON: 'No, take more photos',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE: 'Do you want to finish the process?',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE: 'Continuing will complete the process',

  PHOTO_HUB_PHOTOS_TAKEN_MODAL_PRIMARY_BUTTON: 'Yes, send photos',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SECONDARY_BUTTON: 'No, I want to add accessories',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_TITLE: 'Congratulations!\nYou have uploaded the required photos',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SUBTITLE: 'Do you want to finish your inspection process?',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_CONTINUE: 'Continue',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_BACK: 'Back',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_FINISH: 'Finish',

  PHOTO_HUB_LOADING_PHOTO_LABEL: 'Loading',

  PHOTO_HUB_VIDEO_SECTION_PANEL_TITLE: 'Video 360° vehícle',
  PHOTO_HUB_VIDEO_SECTION_PANEL_SUBTITLE: 'Make a 360° recording of the vehicle.',
  PHOTO_HUB_VIDEO_SECTION_PANEL_HINT: 'Make a 360° recording of the vehicle starting from the license plate and going around it from the left side to the right, until returning to the license plate shot.',
  PHOTO_HUB_VIDEO_SECTION_PANEL_HINT_NOTE: 'Note: It will not be possible to pause the recording',

  PHOTO_HUB_ACCESSORIES_SECTION_TITLE: 'Extra Accessories',
  PHOTO_HUB_ACCESSORIES_SECTION_PANEL_TITLE: 'Add extra accessory',
  PHOTO_HUB_ACCESSORIES_SECTION_PANEL_DESCRIPTION: 'They are those additional components to the original version of the vehicle. Select one from the list',
  PHOTO_HUB_ACCESSORIES_SECTION_PREVIEW_DESCRIPTION: 'The extra accessory was added successfully',
  PHOTO_HUB_ACCESSORIES_SECTION_MODAL_TITLE: 'Choose the accessory which you want to add photos for',
  PHOTO_HUB_ACCESSORIES_SECTION_MODAL_PRIMARY_BUTTON_LABEL: 'Continue',

  PHOTO_HUB_DAMAGE_SECTION_TITLE: 'Pre-existing damages',
  PHOTO_HUB_DAMAGE_SECTION_PANEL_TITLE: 'Declare pre-existing damages',
  PHOTO_HUB_DAMAGE_SECTION_PANEL_DESCRIPTION: 'If the vehicle has any damage (dent, scratch, chip, break or similar), take a photo closer to the affected part',
  PHOTO_HUB_DAMAGE_SECTION_PREVIEW_DESCRIPTION: 'Damage was added successfully',
  PHOTO_HUB_DAMAGE_SECTION_PREVIEW_TITLE: 'Damage',

  PHOTO_HUB_DELETE_TITLE: 'Are you sure you want to delete this photo?',
  PHOTO_HUB_DELETE_SUBTITLE: 'This action is definitve. The photo will be deleted and will not be recovered.',
  PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL: 'Delete',
  PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL: 'Cancel',
  PHOTO_HUB_DELETE_IMAGE_ALT_TEXT: 'Delete photo',
  PHOTO_HUB_DELETE_CONFIRMATION_TITLE: 'The image was deleted successfully',
  PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT: 'Image deleted',
  PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL: 'CLOSE',

  PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE: 'Spare Wheel',
  PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_QUESTION: 'Does the car have a spare wheel?',
  PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE: 'Toma la fotografía de la llanta de repuesto y asegúrate que salga en su totalidad',
  PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT: 'The image should appear with sufficient light to identify the state of the wheel',
  PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_ON: 'Yes',
  PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_OFF: 'No',

  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_TITLE: 'Serial number (if applicable)',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_QUESTION: 'Include serial number',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_SUBTITLE: 'It is the number you find on the side of the motorcycle. It is a plate or decal.',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_HINT: 'You can find it on the side or under the gas tank as a 17-character alphanumeric code.',

  PHOTO_HUB_INFORMATION_IMAGE_ALT_TEXT: 'Information',

  PHOTO_HUB_MOTORBIKE_STEP_01_TITLE: 'Front property card',
  PHOTO_HUB_MOTORBIKE_STEP_01_SUBTITLE: 'The ownership card of the motorcycle you want to inspect.',
  PHOTO_HUB_MOTORBIKE_STEP_01_HINT: 'Take a photo of the front of the property card.',

  PHOTO_HUB_MOTORBIKE_STEP_02_TITLE: 'Reverse side of ownership card',
  PHOTO_HUB_MOTORBIKE_STEP_02_SUBTITLE: 'The ownership card of the motorcycle you want to inspect.',
  PHOTO_HUB_MOTORBIKE_STEP_02_HINT: 'Take a picture of the back of the property card.',

  PHOTO_HUB_MOTORBIKE_STEP_03_TITLE: 'Plate',
  PHOTO_HUB_MOTORBIKE_STEP_03_SUBTITLE: 'The license plate of the motorcycle you want to inspect',
  PHOTO_HUB_MOTORBIKE_STEP_03_HINT: 'Bend your knees and place your phone in front of the plate.',

  PHOTO_HUB_MOTORBIKE_STEP_04_TITLE: 'Front part',
  PHOTO_HUB_MOTORBIKE_STEP_04_SUBTITLE: 'Focusing on the mudguard, front tire and lamppost.',
  PHOTO_HUB_MOTORBIKE_STEP_04_HINT: 'Hold your phone at the level of your head and tilt it down a little. Make sure the saddle is visible.',

  PHOTO_HUB_MOTORBIKE_STEP_05_TITLE: 'Rear part',
  PHOTO_HUB_MOTORBIKE_STEP_05_SUBTITLE: 'Focusing on the motorcycle license plate.',
  PHOTO_HUB_MOTORBIKE_STEP_05_HINT: 'Hold your phone at the level of your head and tilt it down a little.',

  PHOTO_HUB_MOTORBIKE_STEP_06_TITLE: 'Right side',
  PHOTO_HUB_MOTORBIKE_STEP_06_SUBTITLE: 'It is the fairing or right side of the motorcycle as seen by the rider.',
  PHOTO_HUB_MOTORBIKE_STEP_06_HINT: 'Take the photo from the side of the bike.',
  PHOTO_HUB_MOTORBIKE_STEP_06_HINT_02: 'Keep your phone at shoulder height.',

  PHOTO_HUB_MOTORBIKE_STEP_07_TITLE: 'Left side',
  PHOTO_HUB_MOTORBIKE_STEP_07_SUBTITLE: 'It is the fairing or left side of the motorcycle as seen by the rider.',
  PHOTO_HUB_MOTORBIKE_STEP_07_HINT: 'Take the photo of the other side of the motorcycle.',
  PHOTO_HUB_MOTORBIKE_STEP_07_HINT_02: 'Keep your phone at shoulder height.',

  PHOTO_HUB_MOTORBIKE_STEP_08_TITLE: 'Steering tower',
  PHOTO_HUB_MOTORBIKE_STEP_08_SUBTITLE: 'Image showing the tire, tank and lamp on the right side of the motorcycle.',
  PHOTO_HUB_MOTORBIKE_STEP_08_HINT: 'Hold your phone at the level of your head and tilt the shot slightly.',

  PHOTO_HUB_MOTORBIKE_STEP_09_TITLE: 'Instrument panel',
  PHOTO_HUB_MOTORBIKE_STEP_09_SUBTITLE: 'Image showing the entire center panel and complete front dashboard, including rear view mirrors.',
  PHOTO_HUB_MOTORBIKE_STEP_09_HINT: 'Get on the bike and capture the panel including the mirrors.',

  PHOTO_HUB_MOTORBIKE_STEP_10_TITLE: 'Mileage',
  PHOTO_HUB_MOTORBIKE_STEP_10_SUBTITLE: 'Image of the instrument panel to show the number of kilometers the motorcycle has traveled.',
  PHOTO_HUB_MOTORBIKE_STEP_10_HINT: 'Open the switch to display the total mileage and take a picture.',

  PHOTO_HUB_MOTORBIKE_STEP_11_TITLE: 'Identification number',
  PHOTO_HUB_MOTORBIKE_STEP_11_SUBTITLE: 'This is the VIN or chassis number found on the steering tower and is stamped.',
  PHOTO_HUB_MOTORBIKE_STEP_11_HINT: 'In general, it is on the steering tower. In the case of scooters, it is when the seat is raised or in front of the knees.',

  PHOTO_HUB_MOTORBIKE_STEP_12_TITLE: 'Serial number (if applicable)',
  PHOTO_HUB_MOTORBIKE_STEP_12_SUBTITLE: 'This is the number you find on the side of the motorcycle. It is a plate or decal.',
  PHOTO_HUB_MOTORBIKE_STEP_12_HINT: 'You can find it on the side or under the gas tank as a 17-character alphanumeric code.',

  TAKE_PHOTO_CONFIRM_PHOTO_BUTTON_LABEL: 'Confirm',
  TAKE_PHOTO_RETAKE_PHOTO_BUTTON_LABEL: 'Retake photo',
  TAKE_PHOTO_MODAL_TITLE: 'Hints',
  TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT: 'Hint',
  TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL: 'Continue',
  TAKE_PHOTO_RETAKE_MODAL_TITLE: 'Vas a modificar este registro fotográfico',
  TAKE_PHOTO_RETAKE_MODAL_SUBTITLE: 'Once you take the picture, the one you currently have will automatically be deleted',
  TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL: 'Take another picture',
  TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL: 'Cancel',
  TAKE_PHOTO_PRIMARY_BUTTON: 'Upload Photo',
  TAKE_PHOTO_PRIMARY_BUTTON_LOADING: 'Saving',
  TAKE_PHOTO_SECONDARY_BUTTON: 'Repeat',
  PHOTO_HUB_UPLOAD_ERROR_MESSAGE: 'An error occurred while uploading the image. Retry',

  SATISFACTION_SURVEY: 'Submit satisfaction survey',

  INACTIVITY_TIMER_MODAL_PRIMARY_BUTTON: 'Continue',
  INACTIVITY_TIMER_MODAL_CLOSED_SESSION_PRIMARY_BUTTON: 'Signin again',
  INACTIVITY_TIMER_MODAL_SECONDARY_BUTTON: 'Close session',
  INACTIVITY_TIMER_MODAL_TITLE: 'Are you still there? Your session will end in:',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_TITLE: 'We log out automatically for your security',
  INACTIVITY_TIMER_MODAL_HINT: 'If you do not wish to continue, we will contact you to finalize your process.',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_ONE: 'We detect inactivity in your inspection process and log you out to protect your data.',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_TWO: 'Log in again and resume the process where you left off.',

  EXPIRED_SESSION_MODAL_PRIMARY_BUTTON: 'Start inspection',
  EXPIRED_SESSION_MODAL_SECONDARY_BUTTON: 'Close',
  EXPIRED_SESSION_MODAL_TITLE: 'You exceeded the time to perform the inspection',
  EXPIRED_SESSION_MODAL_HINT: 'To start the inspection process again you can access through the following button or from the link in your email.',

  TAKE_VIDEO_PRIMARY_BUTTON: 'Done',
  TAKE_VIDEO_FINISH_TITLE: 'Finished record',
  TAKE_VIDEO_FINISH_DESCRIPTION: 'You have finished recording the 360° video',
  TAKE_VIDEO_FINISH_WAITING_DESCRIPTION: 'We are finishing uploading the video of your vehicle. Please wait...',
  TAKE_VIDEO_FINISH_PRIMARY_BUTTON: 'Upload',
  TAKE_VIDEO_FINISH_SECUNDARY_BUTTON: 'Repeat',

  PERMISSION_CAMERA_MODAL_TITLE: 'You do not have camera permissions enabled',
  PERMISSION_CAMERA_MODAL_DESCRIPTION: 'You need to enable camera permissions to take photos',
  PERMISSION_CAMERA_MODAL_PRIMARY_BUTTON: 'I will activate them',

  PERMISSION_CAMERA_MODAL_INFO_TITLE: 'Camera permissions',
  PERMISSION_CAMERA_MODAL_INFO_DESCRIPTION: 'Before starting! If you cannot capture the photo correctly, remember that you must have the camera permissions activated in the browser.',
  PERMISSION_CAMERA_MODAL_INFO_PRIMARY_BUTTON: 'Got it',

  GUIDED_VISIT_INIT_TITLE: 'Start a guided tour',
  GUIDED_VISIT_INIT_DESCRIPTION: 'We will give you a tour of the main camera tools so that you can get the most out of it.',
  GUIDED_VISIT_FIRST_TITLE: 'Focus on what is necessary',
  GUIDED_VISIT_FIRST_DESCRIPTION: 'Press once on the place you want to focus and capture clearly.',
  GUIDED_VISIT_SECOND_TITLE: 'Turn on/off the flash',
  GUIDED_VISIT_SECOND_DESCRIPTION: 'Activate the flash in low light conditions. Deactivate it if you are during the day or in optimal light conditions.',
  GUIDED_VISIT_THIRD_TITLE: 'Fullscreen',
  GUIDED_VISIT_THIRD_DESCRIPTION: 'Set the camera to full screen to get a more detailed view of the image to be captured.',
  GUIDED_VISIT_FOURTH_TITLE: 'One Touch Capture',
  GUIDED_VISIT_FOURTH_DESCRIPTION: 'Press the shutter in one touch and capture the best image to send.',
  GUIDED_VISIT_FIFTH_TITLE: 'Capture again',
  GUIDED_VISIT_FIFTH_DESCRIPTION: 'If you detect errors in the image capture or want to improve it, you can go back and take the photo again.',
  GUIDED_VISIT_SIXTH_TITLE: 'Send in one touch',
  GUIDED_VISIT_SIXTH_DESCRIPTION: 'When you are satisfied with the photo, confirm and send in one touch.',
  GUIDED_VISIT_SEVENTH_TITLE: 'Close the camera',
  GUIDED_VISIT_SEVENTH_DESCRIPTION: 'You can return to the inspection platform at any time.',
  GUIDED_VISIT_INIT_BUTTON_LABEL: 'Start',
  GUIDED_VISIT_OMIT_BUTTON_LABEL: 'Omit',
  GUIDED_VISIT_NEXT_BUTTON_LABEL: 'Next',
  GUIDED_VISIT_BACK_BUTTON_LABEL: 'Previous',
  GUIDED_VISIT_DONE_BUTTON_LABEL: 'Done',

  GUIDED_VIDEO_VISIT_INIT_TITLE: 'Start a guided tour',
  GUIDED_VIDEO_VISIT_INIT_DESCRIPTION: 'We will give you a tour of the main camera tools so that you can get the most out of it.',
  GUIDED_VIDEO_VISIT_FIRST_TITLE: 'Catch it all!',
  GUIDED_VIDEO_VISIT_FIRST_DESCRIPTION: 'Try to use as much space as possible within the camera frame.',
  GUIDED_VIDEO_VISIT_SECOND_TITLE: 'Record button',
  GUIDED_VIDEO_VISIT_SECOND_DESCRIPTION: 'This button allows you to start recording.',
  GUIDED_VIDEO_VISIT_THIRD_TITLE: 'Record button',
  GUIDED_VIDEO_VISIT_THIRD_DESCRIPTION: 'This button allows you to end the recording, remember that it will not be possible to pause the recording.',
  GUIDED_VIDEO_VISIT_FOURTH_TITLE: 'Timer',
  GUIDED_VIDEO_VISIT_FOURTH_DESCRIPTION: 'This timer indicates how much time you have to make the recording, once the time is up, the recording will be finished. If you need to try again, just enter this section again and start the recording.',
  GUIDED_VIDEO_VISIT_FIFTH_TITLE: 'Fullscreen',
  GUIDED_VIDEO_VISIT_FIFTH_DESCRIPTION: 'Adapt to full screen to get a more detailed view of the video to be captured.',
  GUIDED_VIDEO_VISIT_SIXTH_TITLE: 'Close the camera',
  GUIDED_VIDEO_VISIT_SIXTH_DESCRIPTION: 'You can return to the inspection platform at any time.',
  GUIDED_VIDEO_VISIT_INIT_BUTTON_LABEL: 'Start',
  GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL: 'Omit',
  GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL: 'Next',
  GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL: 'Previous',
  GUIDED_VIDEO_VISIT_DONE_BUTTON_LABEL: 'Done',

  PROCESS_COMPLETE_TITLE: 'The photo-taking process has been successfully completed',
  PROCESS_COMPLETE_SUBTITLE_ONE: 'We will inform you shortly about the result of your inspection',
  PROCESS_COMPLETE_SUBTITLE_TWO: '',
  PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS: 'Our agents will review the photographs from Monday to Friday during business hours',
  PROCESS_COMPLETE_CONTINUE_BUTTON: 'Go to Liberty Insurance',
  PROCESS_COMPLETE_ERROR_MESSAGE_TITLE: 'Our records show that you’ve already completed the process',
  PROCESS_COMPLETE_ERROR_MESSAGE_SUBTITLE: 'An agent is currently reviewing your photos',

  PROCESS_SUPPORT_CONTINUE_BUTTON: 'Go to Liberty Insurance',

  PROCESS_SUPPORT_TITLE: 'Your support request was sent successfuly to our team.',
  PROCESS_SUPPORT_SUBTITLE_ONE: 'The opening hours are Monday to friday between 8:00 am and 5:30 pm.',
  PROCESS_SUPPORT_SUBTITLE_TWO: 'You can also consult the sections of ',
  PROCESS_SUPPORT_SUBTITLE_THREE: ' and ',
  PROCESS_SUPPORT_SUBTITLE_FOUR: ' to get util information about the process.',
  PROCESS_SUPPORT_SUBTITLE_FIVE: 'You will receive a response within a maximum of 2 business hours. ',
  PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT: 'FAQ',
  PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT: 'Instructions',

  FORM_SUPPORT_TITLE: 'Support Request',
  FORM_SUPPORT_SUBTITLE: 'Tell us what request you have or problems that arose:',
  FORM_SUPPORT_TEXT_AREA: 'Please share more details with us or if it corresponds to another request',
  FORM_SUPPORT_SEND_SUPPORT: 'Send request',
  FORM_SUPPORT_CANCEL_SUPPORT: 'Do not send',
  FORM_SUPPORT_REQUIRED_INPUTS: 'This field is required',
  FORM_SUPPORT_REQUIRED_INPUTS_ONLY_NUMBERS: 'This field accept only numbers',
  FORM_SUPPORT_REQUIRED_INPUTS_TEXT_LENGTH: '{length} of 1.000 characters',

  FAQ_TITLE: 'FAQS',
  FAQ_SUBTITLE: 'We know you have doubts, so here answers to the most common question',

  FAQ_1_QUESTION: 'How is the inspection process?',
  FAQ_1_ANSWER: 'Once we receive the photographs of your vehicle our specialized team carries out the following validations: \n\n\u2713 Analysis of the physical state of the vehicle.\n\n\u2713 Consultation of internal and external databases.\n\n\u2713 Result of the inspection.',

  FAQ_2_QUESTION: 'If I take a photograph that does not correspond to the indicated part of the vehicle and send the photos, what should I do to retake it?',
  FAQ_2_ANSWER_PART_1: 'You must wait for an email from our team (',
  FAQ_2_ANSWER_PART_2: ') indicating if you should take some pictures again.',

  FAQ_3_QUESTION: 'If I take a photograph that does not have the best quality or appears blurred and I send the photos, what should I do to retake it?',
  FAQ_3_ANSWER_PART_1: 'You must wait for an email from our team (',
  FAQ_3_ANSWER_PART_2: ') indicating if you should take some pictures again.',

  FAQ_4_QUESTION: 'If the inspection is approved, what is the next step of the process?',
  FAQ_4_ANSWER: 'Once inspected, the issuing process is available to the insurance broker.',

  FAQ_5_QUESTION: 'By carrying out the inspection process through this application, do I avoid going to an authorized center or CDA?',
  FAQ_5_ANSWER: 'Yes, you can perform the inspection from anywhere for your convenience.',

  FAQ_6_QUESTION: 'Does the inspection have any cost?',
  FAQ_6_ANSWER: 'This process is completely free.',

  FAQ_7_QUESTION: 'What value insured would my vehicle have?',
  FAQ_7_ANSWER_PART_1: 'The insured value depends on the commercial value registered in the phased guide according to the characteristics of the vehicle.',
  FAQ_7_ANSWER_PART_2: 'Fasecolda Guide',
  FAQ_7_ANSWER_PART_3: ': Public database of commercial values of a vehicle defined by the Federation of Insurers of Colombia, FASECOLDA.',

  FAQ_8_QUESTION: 'If my accessories are not in the default list, how can I include them?',
  FAQ_8_ANSWER: 'If it does not exist, you can not include it.',

  FAQ_9_QUESTION: 'Can I inspect my motorcycle for this application?',
  FAQ_9_ANSWER: 'No, you can only inspect private cars.',

  FAQ_10_QUESTION: 'Can I take pictures of my vehicle with the wet car?',
  FAQ_10_ANSWER: 'Ideally, the car should be dry.',

  FAQ_11_QUESTION: 'Can I perform the inspection without Internet access?',
  FAQ_11_ANSWER: 'It is not possible, since access to the tool requires access to the Internet.',

  FAQ_12_QUESTION: 'Can I perform the inspection in any region of the country?',
  FAQ_12_ANSWER: 'Yes, since you have access to the application there is no problem.',

  FAQ_13_QUESTION: 'Is there any kind of restriction for my mobile operating system to access the application?',
  FAQ_13_ANSWER: 'No, both Android and iOS mobile devices can access without any restriction.',

  FAQ_14_QUESTION: 'The application does not allow me to take pictures. What should I do?',
  FAQ_14_ANSWER: 'You must make sure that you do not have too many applications or tabs of your search engine open and try again.',

  FAQ_15_QUESTION: '',
  FAQ_15_ANSWER: '',

  FAQ_CONTACT_EMAIL: 'comunicaciones@libertycolombia.com',

  REQUEST_CALL_TEXT_1: 'Cannot find the answer you need?',
  REQUEST_CALL_TEXT_2: 'Talk with a support agent',
  REQUEST_CALL_PHONE_NUMBER: '',

  REQUEST_CALL_SCHEDULES_1: 'Opening hours are',
  REQUEST_CALL_SCHEDULES_2: 'Monday to Thursday from 9:00 to 18:00, Fridays from 9:00 to 14:00',

  INSTRUCTIONS_LABEL: 'Instructions',
  FAQ_LABEL: 'FAQ',
  SUPPORT_REQUEST_LABEL: 'Support',

  AUTH_TOKEN_SESSION_TIMED_OUT: "Sorry, your session has expired and you'll need to confirm your identity again",

  EMAIL_TOKEN_ERROR_MESSAGE_TITLE: "Sorry, there's been a problem during the process and you'll need to retry",
  EMAIL_TOKEN_ERROR_MESSAGE_SUBTITLE: 'Please click the link in your welcome email to start again',

  INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE: 'Image review process',
  INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE: 'Our agents are reviewing the images of your vehicle and we will communicate via email as soon as possible.',
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE: 'The inspection is being reviewed by our inspection area',
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE: 'For more information on the next steps with the issuance of the policy, you can contact us through our service channels or contact your advisor',
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE: 'The received photos were rejected',
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE: 'If you need help, you can contact us through our service channels or contact your advisor.',
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE: 'Inspection has expired',
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE: 'The time limit for completing your inspection is over. If you need help, contact an advisor',
  INVALID_STATUS_CALL_AN_ADVISOR_BUTTON: 'Call an Advisor',
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_TITLE: 'The inspection has been cancelled.',
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_SUBTITLE: 'If you need help, you can contact us through our service channels or contact your advisor.',

  ERROR_CAMERA_SYSTEM_PERMISSION_DENIED: 'The application needs access to the camera in order to continue.',
  ERROR_CAMERA_USER_PERMISSION_DENIED: 'You denied access to the camera. The application needs access to the camera in order to continue.',
  ERROR_CAMERA_COULD_NOT_START_VIDEO_SOURCE: 'Another application is using the camera',
  ERROR_NKNOWN_PERMISSION_CAMERA: 'oops! Something went wrong',

  CAMERA_PERMISSIONS_TITLE_ICON_1: 'FAQ',
  CAMERA_PERMISSIONS_TITLE_ICON_2: 'Camera Permissions',
  CAMERA_PERMISSIONS_IPHONE_CHROME: 'IPhone Chrome',
  CAMERA_PERMISSIONS_SELECT_SO: 'Select the correct combination between Operating System and browser to see the corresponding instructions:',
  CAMERA_PERMISSIONS_IPHONE_SAFARI: 'IPhone Safari',
  CAMERA_PERMISSIONS_RECOMMENDATIONS: 'To make an optimal inspection, keep in mind the following recommendations ',
  CAMERA_PERMISSIONS_ANDROID_CHROME: 'Android y Chrome',
  CAMERA_PERMISSIONS_OTHERS: 'Others',
  CAMERA_PERMISSIONS_INCORRECT_INFORMATION: 'not the correct information',
  CAMERA_PERMISSIONS_IPHONE_CHROME_ID: 'camera_permissions_IOS_CHROME',
  CAMERA_PERMISSIONS_IPHONE_SAFARI_ID: 'camera_permissions_IOS_SAFARI',
  CAMERA_PERMISSIONS_ANDROID_CHROME_ID: 'camera_permissions_ANDROID_CHROME',
  CAMERA_PERMISSIONS_OTHERS_ID: 'camera_permissions_others',
  CAMERA_PERMISSIONS_TITLE_BROWSER_1: 'Allow the use of the camera in your browser',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE: 'To grant permission, do the following:',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE: 'Chrome Settings > Site Settings > Camera > Enable Camera',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE: 'Also enable Chrome access to the camera on Android',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE: 'Android Settings > Apps > Chrome > Permissions > Camera > Allow',
  CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE: 'Allow the Safari browser to access the camera',
  CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE: 'iPhone Settings > Safari > Camera > Allow',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE: 'Allow Chrome browser to access the camera',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE: 'iPhone Settings > Chrome > Enable Camera',
  TUTORIAL_STEP_FINAL: 'Start',
  TUTORIAL_STEP_FINAL_READY: 'Ready',
  CAMERA_PERMISSIONS_TITLE_BROWSER_2: 'Also enable Chrome access to the camera on Android',
  CAMERA_PERMISSIONS_TITLE_BROWSER_3: 'Allow the Safari browser to access the camera',
  CAMERA_PERMISSIONS_TITLE_BROWSER_4: 'Allow Chrome browser to access the camera',
  CAMERA_PERMISSIONS_WIFI_HEADER: 'Make sure you have a good signal on your internet connection.',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_1: 'To grant permission, do the following: Chrome Settings > Site Settings > Camera > Enable Camera',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_2: 'To enable it, do the following: Android Settings > Apps > Chrome Permissions > Camera > Allow',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_3: 'To grant permission, do the following: iPhone Settings Safari Camera > Allow',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_4: 'To enable it, do the following: iPhone Settings > Chrome > Enable Camera',
  CAMERA_PERMISSIONS_WIFI_FOOTER: 'By maintaining a stable internet connection, ensure that images are effectively uploaded to the platform.',
  CAMERA_PERMISSIONS_DETECTED_PHONE_1: 'We detected that your cell phone is ',
  CAMERA_PERMISSIONS_DETECTED_PHONE_2: ' and your default browser is ',

  MODAL_REPEAT_VIDEO_MESSAGE: 'Note that if you take a new video, the current one will be deleted.',
  MODAL_REPEAT_VIDEO_PRIMARY_BUTTON: 'Repeat video',
  MODAL_REPEAT_VIDEO_SECONDARY_BUTTON: 'Cancel',

  LOADING_MESSAGE_CAMERA_PHOTO: 'Loading camera',

  PROCESS_SUPPORT_IN_PROGRESS_MODAL_TITLE: 'You already have a support request in progress',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_SUBTITLE: 'Our team will be in contact with you as soon as possible.',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_ATTENTION_SCHEDULE: 'Attention schedule:',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_WORKING_HOURS: 'Monday to Thursday from 9:00 to 18:00 Fridays from 9:00 to 14:00',

  FORM_SUPPORT_ASIAN_INFORMATION: 'Please provide information for Pre-inspection appointment',
  FORM_SUPPORT_ASIAN_NAME: 'Contact person',
  FORM_SUPPORT_ASIAN_NUMBER: 'Phone number',
  FORM_SUPPORT_ASIAN_PR_INSPECTION_TIME: 'Pre-inspection Time',
  FORM_SUPPORT_ASIAN_ADDRESS: 'Address',
  FORM_SUPPORT_ASIAN_WE_WILL_CONTACT_WITH_YOU: 'We will contact you soon for Pre-inspection arrangement',

  PREPOSITION_OF: 'of',
  PASSSED_STEP: 'Passed',

  STEP_INSTRUCCION_TEXT: 'Captura las imágenes teniendo en cuenta las indicaciones',

  INSTRUCTIONS_MODAL_BUTTON_FINISH: 'DONE',
  INSTRUCTIONS_MODAL_BUTTON_SHOW_INSTRUCTIONS_AGAIN: 'See instructions again',
  INSTRUCTIONS_MODAL_BUTTON_FINISH_INITIAL_PROCESS: 'Start inspection',
  INSTRUCTIONS_MODAL_BUTTON_OMIT: 'Omit',
  INSTRUCTIONS_MODAL_TITLE: 'To carry out the inspection, consider the following:',
  INSTRUCTIONS_MODAL_SUBTITLE_1: 'Take photographs of the vehicle directly from your cell phone (smartphone). This application only works from this device',
  INSTRUCTIONS_MODAL_SUBTITLE_2: 'The vehicle must be clean, located in a spacious place and with daylight.',
  INSTRUCTIONS_MODAL_SUBTITLE_3: 'The vehicle must be clean, located in a spacious place and with daylight.',
  INSTRUCTIONS_MODAL_SUBTITLE_4: 'When taking exterior photos of the vehicle, try to capture the entire vehicle.',
  INSTRUCTIONS_MODAL_SUBTITLE_5: 'You must have the engine running to capture the mileage photo',
  INSTRUCTIONS_MODAL_SUBTITLE_6: 'You must have a good internet connection and close the rest of the applications you have open.',
  INSTRUCTIONS_MODAL_SUBTITLE_7: 'You have 2 hours to complete the inspection process. If you exceed the time, you will lose the photos you have captured and you will have to re-enter from the link.',
  INSTRUCTIONS_MODAL_TITLE_FINISH_PAGE: '¿Do you want to start the inspection?',
  INSTRUCTIONS_MODAL_SUBTITLE_FINISH_PAGE: 'Please note that you have 2 hours to complete the inspection process. If you exceed the time, you will lose the photos you have captured and you will have to re-enter from the link.',
};

export default { code, strings };
