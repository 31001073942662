import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Button, Heading } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { CautionImage } from '../../utils';
import { Image } from '..';
import './styles.scss';

function Step({ settings, step, onNextStep, index, total, isImageLoaded }) {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const { TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT } = localizedStrings(language);

  const showButton = () => step.button && index < total - 1;

  const onlyText = () => index + 1 === total;

  return (
    <div key={step.text}>
      <Slider {...settings}>
        {step.images.map(img => (
          <Image
            className="hints-modal-image"
            src={img}
            key={img}
            alt={TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT}
            onLoad={isImageLoaded}
          />
        ))}
      </Slider>

      <Heading type="h4-light" className="hints-modal-heading heading-step ">
        {!onlyText() ? (
          <div className="heading-step__container">
            <Image
              className="heading-step caution-image"
              src={CautionImage}
              alt={TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT}
            />
            {step.text}
            <Image
              className="heading-step caution-image"
              src={CautionImage}
              alt={TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT}
            />
          </div>
        ) : (
          step.text
        )}
      </Heading>

      {showButton() && (
        <Button
          type="button"
          level="secondary"
          onClick={() => onNextStep()}
          disabled={false}
        >
          {step.button}
        </Button>
      )}
    </div>
  );
}

Step.propTypes = {
  settings: PropTypes.shape({
    arrows: PropTypes.bool.isRequired,
    dots: PropTypes.bool.isRequired,
    infinite: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
  }).isRequired,
  step: PropTypes.shape({
    text: PropTypes.string.isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
    button: PropTypes.string,
  }).isRequired,
  onNextStep: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isImageLoaded: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default Step;
