/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WIZARD_PHOTO_STEP } from '../constants/wizardPhotos';

export const useTakenPhoto = () => {
  const {
    photos: { wizardPhotoHub, currentStep },
  } = useSelector(store => store);

  const positionOptionalStepInArray = WIZARD_PHOTO_STEP.FOUR - 1;
  const requiredStepInArray = currentStep - 1;

  const {
    photos: { damages, accessories },
  } = wizardPhotoHub[positionOptionalStepInArray];

  const conditionalPhotosTaken = useMemo(
    () => wizardPhotoHub[WIZARD_PHOTO_STEP.THREE - 1]?.conditionalPhotos,
    [
      wizardPhotoHub,
      wizardPhotoHub[WIZARD_PHOTO_STEP.THREE - 1],
      wizardPhotoHub[WIZARD_PHOTO_STEP.THREE - 1]?.conditionalPhotos,
    ],
  );

  const requiredPhotosTaken = useMemo(
    () => wizardPhotoHub[requiredStepInArray]?.photos || [],
    [
      currentStep,
      wizardPhotoHub,
      requiredStepInArray,
      wizardPhotoHub[currentStep - 1],
      wizardPhotoHub[requiredStepInArray]?.photos,
    ],
  );

  return {
    requiredPhotosTaken,
    accessoryPhotosTaken: accessories,
    damagePhotosTaken: damages,
    conditionalPhotosTaken,
  };
};
