import PropTypes from 'prop-types';
import { deleteCookie } from '../utils';
import {
  EMAIL_TOKEN_COOKIE_NAME,
  AUTH_TOKEN_COOKIE_NAME,
  RETAKE_DATA_COOKIE_NAME,
  RESUME_DATA_COOKIE_NAME,
} from '../utils/constants';
import { HIDE_APP_LOADER } from './ActionTypes';

export default {
  PropertyTypes: PropTypes.shape({
    deleteAllCookies: PropTypes.func.isRequired,
  }),

  deleteAllCookies() {
    return async dispatch => {
      sessionStorage.removeItem('VEHICLE_CLASS');
      localStorage.removeItem('CAMERA_TYPE');
      localStorage.removeItem('STORAGE_CAMERA_MODE');
      localStorage.removeItem('STORAGE_ID_PHOTO');
      deleteCookie(EMAIL_TOKEN_COOKIE_NAME);
      deleteCookie(AUTH_TOKEN_COOKIE_NAME);
      deleteCookie(RETAKE_DATA_COOKIE_NAME);
      deleteCookie(RESUME_DATA_COOKIE_NAME);
      dispatch({
        type: HIDE_APP_LOADER,
      });
    };
  },
};
