import { BodyText, Content, Heading, IconAlert } from '@lmig/lmds-react';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SettingsActions } from '../../actions';
import {
  AppHeader,
  CircleImage,
  BrowserIcons,
  MenuFooter,
  AppFooter,
} from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { isSupportedBrowsers } from '../../utils/index';
import './styles.scss';
import history from '../../utils/history';
import { DEFAULT } from '../../utils/navigationConstants';
import { getCountryCode } from '../../utils/NavigationUtils';

const GenericError = ({ settingsActions: { hideAppLoader } }) => {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const {
    ERROR_MESSAGE_TITLE,
    NON_BROWSER_ERROR_MESSAGE_TITLE,
    ERROR_MESSAGE_SUBTITLE,
    NON_BROWSER_ERROR_MESSAGE_SUBTITLE,
    PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE,
    PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE,
  } = localizedStrings(language);

  const customLabels = {
    [DEFAULT]: {
      title: `${PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE}. ${PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE}`,
    },
  };

  const supportedBrowsers = isSupportedBrowsers();
  const screenSource = history.location.from || '/app';

  const {
    state: {
      title = supportedBrowsers
        ? customLabels[screenSource]?.title || ERROR_MESSAGE_TITLE
        : NON_BROWSER_ERROR_MESSAGE_TITLE,
      subtitle = supportedBrowsers
        ? customLabels[screenSource]?.subtitle || ERROR_MESSAGE_SUBTITLE
        : NON_BROWSER_ERROR_MESSAGE_SUBTITLE,
    } = {},
  } = useLocation();

  const renderBrowserList = () => {
    if (!supportedBrowsers) {
      return <BrowserIcons />;
    }
    return <></>;
  };

  useEffect(() => {
    // Hide App Loader
    // (In case it was displayed prior to navigating here)
    hideAppLoader();
  }, [hideAppLoader]);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader displaySubtitle />
      <Content className="adil-content generic-error-content">
        <CircleImage>
          <IconAlert sizing="auto" />
        </CircleImage>
        <Heading type="h4-light" className="auto-left-right-margin">
          {title}
        </Heading>
        <BodyText className="gray-text">{subtitle}</BodyText>
        {renderBrowserList()}
        {renderFooterSection()}
      </Content>
      <MenuFooter />
    </div>
  );
};

GenericError.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(GenericError);
