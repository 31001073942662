import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tridi from 'react-tridi';
import PlayButton from './PlayButton';
import './styles.scss';

const ViewerVideo = ({ photos }) => {
  const tridiRef = useRef(null);
  const [imagesList, setImageList] = useState([]);

  const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);

  useEffect(() => {
    const array = photos.map(item => item.url);
    setImageList(array);
  }, [photos]);

  const onPlay = () => {
    tridiRef.current.toggleAutoplay(!isAutoPlayRunning);
  };

  return (
    <div className="viewer-video">
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <PlayButton isPlayRunning={isAutoPlayRunning} onClick={onPlay} />
        <Tridi
          ref={tridiRef}
          images={imagesList}
          autoplaySpeed={300}
          onAutoplayStart={() => setIsAutoPlayRunning(true)}
          onAutoplayStop={() => setIsAutoPlayRunning(false)}
        />
      </div>
    </div>
  );
};

ViewerVideo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  photos: PropTypes.any.isRequired,
};

export default ViewerVideo;
