import { UPDATE_USER_DETAILS } from '../actions/ActionTypes';

const getVehicleClassInStorage = () => {
  const vehicleClass = sessionStorage.getItem('VEHICLE_CLASS');
  return vehicleClass || 'Automóvil';
};

export const initialState = {
  authToken: '',
  maskedCellPhoneNumber: '',
  userInspectionStatus: '',
  vehicleClass: getVehicleClassInStorage(),
  lastCustomerStatus: undefined,
};

export default (state = initialState, { type, value } = {}) => {
  if (type === UPDATE_USER_DETAILS) {
    return {
      ...state,
      ...(value.maskedCellPhoneNumber && {
        maskedCellPhoneNumber: value.maskedCellPhoneNumber,
      }),
      ...(value.userInspectionStatus && {
        userInspectionStatus: value.userInspectionStatus,
      }),
      ...(value.vehicleClassInspection && {
        vehicleClass: value.vehicleClassInspection,
      }),
      ...(value.lastCustomerStatus && {
        lastCustomerStatus: value.lastCustomerStatus,
      }),
    };
  }
  return state;
};
