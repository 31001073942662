import {
  ON_CHANGE_STATE_GUIDED_VISIT,
  ON_CHANGE_STEP_GUIDED_VISIT,
  SET_GUIDED_VISIT_ITEM,
  DESTROY_STEPS_GUIDE,
} from './ActionTypes';

const setGuidedVisitItems = guidedVisitItems => ({
  type: SET_GUIDED_VISIT_ITEM,
  payload: { guidedVisitItems },
});

const onChangeStateGuidedVisit = isActive => ({
  type: ON_CHANGE_STATE_GUIDED_VISIT,
  payload: { isActive },
});

const onChangeStepGuidadVisit = guidedVisitItemID => ({
  type: ON_CHANGE_STEP_GUIDED_VISIT,
  payload: { guidedVisitItemID },
});

const destroyStepsGuides = () => ({
  type: DESTROY_STEPS_GUIDE,
});

export default {
  onChangeStepGuidadVisit,
  setGuidedVisitItems,
  onChangeStateGuidedVisit,
  destroyStepsGuides,
};
