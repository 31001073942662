const lang = localStorage.getItem('lang');
const countryCode = window.location.host.split('.').pop();

export default {
  cl: {
    termsConditionsUrl: '',
    privacyPolicyUrl: 'https://www.liberty.cl/terminos-de-uso.html',
    processCompleteRedirectUrl:
      'https://www.liberty.cl/?utm_source=adil&utm_medium=finalButton',
    satisfactionSurveyRedirectUrl:
      'https://adil.libertyseguros.cl/app/complete?utm_source=adil&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    phoneNumbers: [{ number: '(+56) 22 397 5005', extension: '' }],
    advisorPhoneNumber: '(+56) 223975005',
  },
  co: {
    termsConditionsUrl:
      'https://www.libertycolombia.com.co/terminos-de-uso-y-privacidad',
    privacyPolicyUrl:
      'https://www.libertycolombia.com.co/terminos-de-uso-y-privacidad',
    processCompleteRedirectUrl:
      'https://www.libertyseguros.co/personas/seguros-autos?utm_source=adil&utm_medium=finalButton',
    satisfactionSurveyRedirectUrl:
      'https://adil.libertyseguros.co/app/complete?utm_source=adil&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: true,
    phoneNumbers: [{ number: '(+57) 315 873 8849', extension: '' }],
    advisorPhoneNumber: '(+57) 302 290 5806',
  },
  ec: {
    termsConditionsUrl:
      'https://www.libertyseguros.ec/terminos-de-uso-y-privacidad',
    privacyPolicyUrl:
      'https://www.libertyseguros.ec/terminos-de-uso-y-privacidad',
    processCompleteRedirectUrl:
      'https://www.libertyseguros.ec/personas/liberty-auto?utm_source=adil&utm_medium=finalButton',
    satisfactionSurveyRedirectUrl:
      'https://adil.libertyseguros.ec/app/complete?utm_source=adil&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    phoneNumbers: [
      { number: '(+593) 2 393 2000', extension: '2550' },
      { number: '(+593) 2 393 2000', extension: '2508' },
    ],
    advisorPhoneNumber: '(+593) 98-434-6506',
  },
  vn: {
    agentApiDomain: 'https://adil-agent-api.libertyinsurance.com.vn/prod',
    termsConditionsUrl:
      'https://www.libertyinsurance.com.vn/terms-a-conditions',
    privacyPolicyUrl:
      'https://www.libertyinsurance.com.vn/internet-privacy-policy',
    processCompleteRedirectUrl:
      'https://www.libertyinsurance.com.vn/?utm_source=adil&utm_medium=finalButton',
    satisfactionSurveyRedirectUrl: '',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    phoneNumbers: [{ number: '028 381 25103', extension: '' }],
    advisorPhoneNumber: '028 381 25103',
  },
  en: {
    termsConditionsUrl: '',
    privacyPolicyUrl: 'https://www.liberty.cl/terminos-de-uso.html',
    processCompleteRedirectUrl:
      'https://www.liberty.cl/?utm_source=adil&utm_medium=finalButton',
    satisfactionSurveyRedirectUrl:
      'https://adil-dev.libertyseguros.cl/app/complete?utm_source=adil&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    phoneNumbers: [{ number: '028 381 25103', extension: '' }],
    advisorPhoneNumber: '028 381 25103',
  },
  th: {
    termsConditionsUrl:
      'https://www.lmginsurance.co.th/personal-data-collection-consent-policy',
    privacyPolicyUrl: 'https://www.lmginsurance.co.th/privacy-policy',
    processCompleteRedirectUrl:
      'https://www.lmginsurance.co.th/?utm_source=adil&utm_medium=finalButton',
    satisfactionSurveyRedirectUrl: '',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    phoneNumbers: [{ number: '02 302 7788', extension: '' }],
    advisorPhoneNumber: '02 302 7788',
  },
  enablePhotoAutofill: false,
  language: lang || countryCode,
};
