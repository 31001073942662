import {
  AccessoryPhotoHelper,
  RequiredPhotoHelper,
  DamagePhotoHelper,
  ConditionallyPhotoHelper,
} from './helpers';
import { WIZARD_PHOTO_STEP } from '../constants/wizardPhotos';
import { PHOTO_TYPES } from './constants';

export class PhotoHubBuilder {
  constructor(stepsWizard) {
    this.step = null;
    this.stepsWizard = stepsWizard;
    this.resultPhotos = [];
    this.requiredPhotoHelper = new RequiredPhotoHelper(stepsWizard);
    this.accessoryPhotoHelper = new AccessoryPhotoHelper(stepsWizard);
    this.damagePhotoHelper = new DamagePhotoHelper(stepsWizard);
    this.conditionallyPhotoHelper = new ConditionallyPhotoHelper(stepsWizard);
  }

  setPhotoType(photoType) {
    this.photoType = photoType;
    return this;
  }

  setStep(step) {
    this.step = step;
    return this;
  }

  storePhoto(incomingPhoto) {
    switch (this.photoType) {
      case PHOTO_TYPES.REQUIRED_PHOTO: {
        const result = this.requiredPhotoHelper
          .setStep(this.step)
          .storePhoto(incomingPhoto)
          .getResult();
        this.stepsWizard = result;
        break;
      }
      case PHOTO_TYPES.ACCESSORY_PHOTO: {
        this.stepsWizard = this.accessoryPhotoHelper
          .setStep(WIZARD_PHOTO_STEP.FOUR)
          .storePhoto(incomingPhoto)
          .getResult();
        break;
      }
      case PHOTO_TYPES.DAMAGE_PHOTO:
        this.stepsWizard = this.damagePhotoHelper
          .setStep(WIZARD_PHOTO_STEP.FOUR)
          .storePhoto(incomingPhoto)
          .getResult();
        break;
      case PHOTO_TYPES.CONDITIONAL_REQUIRED_PHOTO: {
        this.stepsWizard = this.conditionallyPhotoHelper
          .setStep(WIZARD_PHOTO_STEP.THREE)
          .storePhoto(incomingPhoto)
          .getResult();
        break;
      }
      default:
        break;
    }

    return this;
  }

  removePhoto(photoId) {
    switch (this.photoType) {
      case PHOTO_TYPES.ACCESSORY_PHOTO:
        this.stepsWizard = this.accessoryPhotoHelper
          .setStep(WIZARD_PHOTO_STEP.FOUR)
          .removePhoto(photoId)
          .getResult();
        break;
      case PHOTO_TYPES.DAMAGE_PHOTO:
        this.stepsWizard = this.damagePhotoHelper
          .setStep(WIZARD_PHOTO_STEP.FOUR)
          .removePhoto(photoId)
          .getResult();
        break;
      default:
        break;
    }
    return this;
  }

  getPhotoArray() {
    return this.stepsWizard;
  }
}
