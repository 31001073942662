import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const CircleImage = ({ children, className, size }) => (
  <div className={classNames('circle-image-container', size)}>
    <div
      className={classNames(
        'circle-image-content',
        'auto-left-right-margin',
        className,
      )}
    >
      {children}
    </div>
  </div>
);

CircleImage.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

CircleImage.defaultProps = {
  size: 'small',
  className: [],
};

export default CircleImage;
