import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from '../../hooks';
import {
  AuthActions,
  ModalFlowActions,
  PhotoHubActions,
  SessionActions,
} from '../../actions';
import CloseSession from '../../utils/session/CloseSession';
import { InactivitySession } from '../../utils/session/InactivitySession';
import { getCountryCode } from '../../utils/NavigationUtils';
import { ModalExpiredSession } from '../ModalExpiredSession';
import { LOGOUT_TYPE } from '../../constants/session';

export const ExpireSessionProvider = ({
  isExceptionPages,
  isAllowProvider,
}) => {
  const dispatch = useDispatch();

  const {
    auth: { isAuthenticated },
    session: { timestampToExpireSession },
    modalFlow: { displayExpiredSessionModal },
    settings: { environment },
  } = useSelector(store => store);

  const { language } = environment;

  const inactivitySessionInstance = useMemo(
    () => new InactivitySession(getCountryCode()),
    [],
  );

  const runStepsToExpireSession = useCallback(
    stopInterval => {
      stopInterval();
      dispatch(SessionActions.deleteAllPhotos());
      dispatch(PhotoHubActions.onChangeCurrentStep(1, [1, 2, 3, 4]));
      dispatch(ModalFlowActions.onDisplayExpiredSessionModal(true));
      dispatch(SessionActions.setLogoutType(LOGOUT_TYPE.EXPIRED_SESSION));
      dispatch(ModalFlowActions.onDisplayInstructionsModal(false));
      dispatch(ModalFlowActions.onDisplayInactivityTimerModal(false));
      dispatch(AuthActions.setAuthenticationStatus(false));
      CloseSession.clearStorage();
    },
    [dispatch],
  );

  const { stopInterval: stopExpiredSessionInterval } = useInterval(
    'ExpiredSessionTimeout',
    () => {
      const userInspectionStarted = sessionStorage.getItem(
        'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
      );

      if (displayExpiredSessionModal || !userInspectionStarted) {
        stopExpiredSessionInterval();
        return;
      }

      if (isExceptionPages || !isAllowProvider) {
        stopExpiredSessionInterval();
        return;
      }

      const islogoutByExpiration =
        CloseSession.getLogoutType() === LOGOUT_TYPE.EXPIRED_SESSION;

      if (
        !isAuthenticated &&
        !displayExpiredSessionModal &&
        islogoutByExpiration
      ) {
        dispatch(ModalFlowActions.onDisplayExpiredSessionModal(true));
        dispatch(ModalFlowActions.onDisplayInactivityTimerModal(false));
        stopExpiredSessionInterval();
      }

      const currentTimestamp = new Date();
      if (currentTimestamp >= new Date(timestampToExpireSession)) {
        runStepsToExpireSession(stopExpiredSessionInterval);
      }
    },
    1000,
  );

  const onRedirectToExternalPage = () => {
    const { processCompleteRedirectUrl } = environment[getCountryCode()];
    window.location.href = processCompleteRedirectUrl;
  };

  const onSuccessExpiredSessionModal = () => {
    dispatch(ModalFlowActions.onDisplayExpiredSessionModal(false));
    dispatch(AuthActions.setAuthenticationStatus(false));
    dispatch(SessionActions.onChangeMainInactivityTimer(null));
    inactivitySessionInstance.redirectByInactivityTo();
    CloseSession.clearStorage();
  };

  return (
    <>
      {displayExpiredSessionModal && (
        <ModalExpiredSession
          isOpen={displayExpiredSessionModal}
          language={language}
          onCloseModal={onRedirectToExternalPage}
          onSuccessModal={onSuccessExpiredSessionModal}
        />
      )}
    </>
  );
};

ExpireSessionProvider.propTypes = {
  isExceptionPages: PropTypes.bool.isRequired,
  isAllowProvider: PropTypes.bool.isRequired,
};
