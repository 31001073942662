import image0101 from './01/01_01.jpg';
import image0101CL from './01/01_01_CL.jpg';
import image0201 from './02/02_01.jpg';
import image0201CL from './02/02_01_CL.jpg';
import image0301 from './03/03_01.jpg';
import image0301EC from './03/03_01_EC.jpg';
import image0301CL from './03/03_01_CL.jpg';
import image0401 from './04/04_01.jpg';
import image0401CL from './04/04_01_CL.jpg';
import image0401EC from './04/04_01_EC.jpg';
import image0501 from './05/05_01.jpg';
import image0501CL from './05/05_01_CL.jpg';
import image0501EC from './05/05_01_EC.jpg';
import image0601 from './06/06_01.jpg';
import image0701 from './07/07_01.jpg';
import image0801 from './08/08_01.jpg';
import image0801CL from './08/08_01_CL.jpg';
import image0801EC from './08/08_01_EC.jpg';
import image0901 from './09/09_01.jpg';
import image1001 from './10/10_01.gif';
import image1101 from './11/11_01.gif';
import image1201CL from './12/chasis_hint_cl.gif';
import image1201 from './12/chasis_hint.gif';
import image1301 from './13/13_01.jpg';

const defaultPhotoImages = {
  step01: [image0101],
  step02: [image0201],
  step03: [image0301],
  step04: [image0401],
  step05: [image0501],
  step06: [image0601],
  step07: [image0701],
  step08: [image0801],
  step09: [image0901],
  step10: [image1001],
  step11: [image1101],
  step12: [image1201],
  step13: [image1301],
};

export default {
  en: {
    ...defaultPhotoImages,
  },
  co: {
    ...defaultPhotoImages,
  },
  cl: {
    ...defaultPhotoImages,
    step01: [image0101CL],
    step02: [image0201CL],
    step03: [image0301CL],
    step04: [image0401CL],
    step05: [image0501CL],
    step08: [image0801CL],
    step12: [image1201CL],
  },
  ec: {
    ...defaultPhotoImages,
    step03: [image0301EC],
    step04: [image0401EC],
    step05: [image0501EC],
    step08: [image0801EC],
  },
};
