import React from 'react';
import PropTypes from 'prop-types';
import { IconLiberty } from '../../Icons';
import './styles.scss';

function SplashLoading({ progress, label, translucid }) {
  const progressContent = `${progress}%`;
  return (
    <div
      className="loading"
      style={{
        backgroundColor: !translucid ? '#0f0c25e5' : 'rgba(0, 0, 131, 0.5)',
      }}
    >
      <div className="loading__container">
        <div className="loading__icon">
          <IconLiberty />
          {progress && <h1 className="loading__label">{progressContent}</h1>}
          {label && <p className="loading__label">{label}</p>}
        </div>
      </div>
    </div>
  );
}

SplashLoading.defaultProps = {
  progress: undefined,
  label: undefined,
  translucid: false,
};

SplashLoading.propTypes = {
  label: PropTypes.string,
  progress: PropTypes.number,
  translucid: PropTypes.bool,
};

export default SplashLoading;
