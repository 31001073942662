import localizedStringsCommonlib from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { VEHICLE_FLOW_CLASS } from './constants';

export class VehicleClass {
  constructor(countryCode) {
    this.countryCode = countryCode;
  }

  getVehicle = vehicleClass => {
    const {
      INSPECTION_DROPDOWN_DEFAULT_VALUES,
    } = localizedStringsCommonlib.getCurrentLanguage(this.countryCode);
    const { class: defaultVehicleClass } = INSPECTION_DROPDOWN_DEFAULT_VALUES;

    const [CAR, MOTORBIKE] = defaultVehicleClass;
    const result = {
      [MOTORBIKE]: VEHICLE_FLOW_CLASS.MOTORBIKE,
      [CAR]: VEHICLE_FLOW_CLASS.CAR,
    };
    return result[vehicleClass] || 'UNKNOWN';
  };
}
