import React from 'react';

export default function CircleSunIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97">
      <g id="Grupo_5094" data-name="Grupo 5094" transform="translate(4.5 -0.5)">
        <g id="Grupo_4567" data-name="Grupo 4567" transform="translate(0.5)">
          <circle
            id="Oval"
            cx="48"
            cy="48"
            r="48"
            transform="translate(-4.5 1)"
            fill="#f0fbfc"
            stroke="#99e5ea"
            strokeWidth="1"
          />
        </g>
        <g
          id="Icon_Weather_Sunny_32px"
          data-name="Icon/Weather/Sunny/32px"
          transform="translate(20 25)"
        >
          <g
            id="Combined_Shape"
            data-name="Combined Shape"
            transform="translate(0 0)"
          >
            <path
              id="Combined_Shape-2"
              data-name="Combined Shape"
              d="M23.789,48l-.9-.005.042-7.529,2.25.014L25.138,48Zm7.5-8.921L33.069,37.7,38.2,44.278,36.42,45.662ZM10.145,44.068l5.072-6.487,1.771,1.386-5.071,6.487Zm-.094-19.974A14.191,14.191,0,1,1,24.242,38.388,14.259,14.259,0,0,1,10.051,24.093Zm2.25,0A11.942,11.942,0,1,0,24.242,12.049,12.006,12.006,0,0,0,12.3,24.093ZM37.852,33.5l1.1-1.963,7.2,4.024-1.1,1.964ZM2.306,35.6l6.616-5.227,1.4,1.766L3.7,37.363ZM0,25.126V24l.012-1.125L8,22.965l-.026,2.25Zm40.483-2.161,7.5-.089L48,24.059v1.066l-7.49.088Zm-38.149-9.7,1.1-1.964,7.089,3.961-1.1,1.966Zm35.6,1.951L45.5,10.986l1.1,1.966-7.564,4.225ZM31.579,9.266l5.1-6.519,1.773,1.386-5.1,6.519ZM10.018,3.831l1.773-1.385,5.264,6.732-1.773,1.386ZM23.118,7.7,23.162,0h1l1.25.008-.045,7.708Z"
              fill="none"
            />
            <path
              id="Combined_Shape-3"
              data-name="Combined Shape"
              d="M23.789,48l-.9-.005.042-7.529,2.25.014L25.138,48Zm7.5-8.921L33.069,37.7,38.2,44.278,36.42,45.662ZM10.145,44.068l5.072-6.487,1.771,1.386-5.071,6.487Zm-.094-19.974A14.191,14.191,0,1,1,24.242,38.388,14.259,14.259,0,0,1,10.051,24.093Zm2.25,0A11.942,11.942,0,1,0,24.242,12.049,12.006,12.006,0,0,0,12.3,24.093ZM37.852,33.5l1.1-1.963,7.2,4.024-1.1,1.964ZM2.306,35.6l6.616-5.227,1.4,1.766L3.7,37.363ZM0,25.126V24l.012-1.125L8,22.965l-.026,2.25Zm40.483-2.161,7.5-.089L48,24.059v1.066l-7.49.088Zm-38.149-9.7,1.1-1.964,7.089,3.961-1.1,1.966Zm35.6,1.951L45.5,10.986l1.1,1.966-7.564,4.225ZM31.579,9.266l5.1-6.519,1.773,1.386-5.1,6.519ZM10.018,3.831l1.773-1.385,5.264,6.732-1.773,1.386ZM23.118,7.7,23.162,0h1l1.25.008-.045,7.708Z"
              fill="none"
            />
            <path
              id="Combined_Shape-4"
              data-name="Combined Shape"
              d="M23.789,48l-.9-.005.042-7.529,2.25.014L25.138,48Zm7.5-8.921L33.069,37.7,38.2,44.278,36.42,45.662ZM10.145,44.068l5.072-6.487,1.771,1.386-5.071,6.487Zm-.094-19.974A14.191,14.191,0,1,1,24.242,38.388,14.259,14.259,0,0,1,10.051,24.093Zm2.25,0A11.942,11.942,0,1,0,24.242,12.049,12.006,12.006,0,0,0,12.3,24.093ZM37.852,33.5l1.1-1.963,7.2,4.024-1.1,1.964ZM2.306,35.6l6.616-5.227,1.4,1.766L3.7,37.363ZM0,25.126V24l.012-1.125L8,22.965l-.026,2.25Zm40.483-2.161,7.5-.089L48,24.059v1.066l-7.49.088Zm-38.149-9.7,1.1-1.964,7.089,3.961-1.1,1.966Zm35.6,1.951L45.5,10.986l1.1,1.966-7.564,4.225ZM31.579,9.266l5.1-6.519,1.773,1.386-5.1,6.519ZM10.018,3.831l1.773-1.385,5.264,6.732-1.773,1.386ZM23.118,7.7,23.162,0h1l1.25.008-.045,7.708Z"
              fill="none"
            />
            <path
              id="Combined_Shape-5"
              data-name="Combined Shape"
              d="M23.789,48l-.9-.005.042-7.529,2.25.014L25.138,48Zm7.5-8.921L33.069,37.7,38.2,44.278,36.42,45.662ZM10.145,44.068l5.072-6.487,1.771,1.386-5.071,6.487Zm-.094-19.974A14.191,14.191,0,1,1,24.242,38.388,14.259,14.259,0,0,1,10.051,24.093Zm2.25,0A11.942,11.942,0,1,0,24.242,12.049,12.006,12.006,0,0,0,12.3,24.093ZM37.852,33.5l1.1-1.963,7.2,4.024-1.1,1.964ZM2.306,35.6l6.616-5.227,1.4,1.766L3.7,37.363ZM0,25.126V24l.012-1.125L8,22.965l-.026,2.25Zm40.483-2.161,7.5-.089L48,24.059v1.066l-7.49.088Zm-38.149-9.7,1.1-1.964,7.089,3.961-1.1,1.966Zm35.6,1.951L45.5,10.986l1.1,1.966-7.564,4.225ZM31.579,9.266l5.1-6.519,1.773,1.386-5.1,6.519ZM10.018,3.831l1.773-1.385,5.264,6.732-1.773,1.386ZM23.118,7.7,23.162,0h1l1.25.008-.045,7.708Z"
              fill="none"
            />
            <path
              id="Combined_Shape-6"
              data-name="Combined Shape"
              d="M23.789,48l-.9-.005.042-7.529,2.25.014L25.138,48Zm7.5-8.921L33.069,37.7,38.2,44.278,36.42,45.662ZM10.145,44.068l5.072-6.487,1.771,1.386-5.071,6.487Zm-.094-19.974A14.191,14.191,0,1,1,24.242,38.388,14.259,14.259,0,0,1,10.051,24.093Zm2.25,0A11.942,11.942,0,1,0,24.242,12.049,12.006,12.006,0,0,0,12.3,24.093ZM37.852,33.5l1.1-1.963,7.2,4.024-1.1,1.964ZM2.306,35.6l6.616-5.227,1.4,1.766L3.7,37.363ZM0,25.126V24l.012-1.125L8,22.965l-.026,2.25Zm40.483-2.161,7.5-.089L48,24.059v1.066l-7.49.088Zm-38.149-9.7,1.1-1.964,7.089,3.961-1.1,1.966Zm35.6,1.951L45.5,10.986l1.1,1.966-7.564,4.225ZM31.579,9.266l5.1-6.519,1.773,1.386-5.1,6.519ZM10.018,3.831l1.773-1.385,5.264,6.732-1.773,1.386ZM23.118,7.7,23.162,0h1l1.25.008-.045,7.708Z"
              fill="none"
            />
            <path
              id="Combined_Shape-7"
              data-name="Combined Shape"
              d="M23.789,48l-.9-.005.042-7.529,2.25.014L25.138,48Zm7.5-8.921L33.069,37.7,38.2,44.278,36.42,45.662ZM10.145,44.068l5.072-6.487,1.771,1.386-5.071,6.487Zm-.094-19.974A14.191,14.191,0,1,1,24.242,38.388,14.259,14.259,0,0,1,10.051,24.093Zm2.25,0A11.942,11.942,0,1,0,24.242,12.049,12.006,12.006,0,0,0,12.3,24.093ZM37.852,33.5l1.1-1.963,7.2,4.024-1.1,1.964ZM2.306,35.6l6.616-5.227,1.4,1.766L3.7,37.363ZM0,25.126V24l.012-1.125L8,22.965l-.026,2.25Zm40.483-2.161,7.5-.089L48,24.059v1.066l-7.49.088Zm-38.149-9.7,1.1-1.964,7.089,3.961-1.1,1.966Zm35.6,1.951L45.5,10.986l1.1,1.966-7.564,4.225ZM31.579,9.266l5.1-6.519,1.773,1.386-5.1,6.519ZM10.018,3.831l1.773-1.385,5.264,6.732-1.773,1.386ZM23.118,7.7,23.162,0h1l1.25.008-.045,7.708Z"
              fill="#1a1446"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
