import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lmig/lmds-react';

function TooltipFooter({
  disable,
  omitButton,
  secundaryButton,
  primaryButton,
  handlerButtons,
}) {
  return (
    <div>
      <div className="hidnfo__footer">
        <div className="hidnfo__footer__omit-button">
          {omitButton && (
            <button
              disabled={disable}
              type="button"
              className="disable-decoration-button"
              onClick={() => handlerButtons('omit')}
            >
              <b className="tooltip__omit-label-button">{omitButton}</b>
            </button>
          )}
        </div>

        <div className="hidnfo__footer__buttons">
          {secundaryButton && (
            <Button
              disabled={disable}
              type="button"
              level="secondary"
              className="button-border-secondary"
              onClick={() => handlerButtons('left')}
            >
              <b className="">{secundaryButton}</b>
            </Button>
          )}

          {primaryButton && (
            <Button
              disabled={disable}
              type="button"
              level="primary"
              className="button-border-primary"
              onClick={() => handlerButtons('right')}
            >
              <b className="">{primaryButton}</b>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

TooltipFooter.defaultProps = {
  omitButton: null,
  primaryButton: null,
  secundaryButton: null,
  disable: false,
};

TooltipFooter.propTypes = {
  disable: PropTypes.bool,
  omitButton: PropTypes.string,
  primaryButton: PropTypes.string,
  secundaryButton: PropTypes.string,
  handlerButtons: PropTypes.func.isRequired,
};
export default TooltipFooter;
