/* eslint-disable react/forbid-prop-types */
import { Button, IconAdd } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessoriesPhotoList, CapturePhoto, IconButton, Modal } from '..';
import {
  ModalFlowActions,
  PhotoHubActions,
  TakePhotoActions,
} from '../../actions';
import { validateEmailToken } from '../../api';
import { CAMERA_MODE, CAMERA_TYPE } from '../../constants/camera';
import history from '../../utils/history';
import localizedStrings from '../../utils/localizedStrings';
import { TAKE_PHOTO } from '../../utils/navigationConstants';
import ModalAccessories from '../ModalAccessories/ModalAccessories';
import { DefaultPhotoPanel } from '../WizardPhotos';
import IconTriangleAlert from '../../assets/icons/IconTriangleAlert.svg';
import IconTrashcan from '../../assets/icons/IconTrashcan.svg';
import './styles.scss';
import { PHOTO_TYPES } from '../../utils/constants';
import {
  BUTTON_ACTION,
  FingerprintButton,
} from '../../utils/FingerprintButton';

const AccessoriesSection = ({ photosTaken, navigate }) => {
  const {
    takePhoto: { defaultCamera, photoError },
    settings: {
      environment: { language },
      retake,
    },
    user: { vehicleClass },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  const {
    PHOTO_HUB_ACCESSORIES_SECTION_PANEL_TITLE,
    PHOTO_HUB_ACCESSORIES_SECTION_PANEL_DESCRIPTION,
    PHOTO_HUB_ACCESSORIES_SECTION_MODAL_TITLE,
    PHOTO_HUB_ACCESSORIES_SECTION_MODAL_PRIMARY_BUTTON_LABEL,
    PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL,
    PHOTO_HUB_DELETE_TITLE,
    PHOTO_HUB_DELETE_SUBTITLE,
    PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL,
    PHOTO_HUB_DELETE_IMAGE_ALT_TEXT,
    PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT,
    PHOTO_HUB_DELETE_CONFIRMATION_TITLE,
    PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL,
  } = localizedStrings(language);

  const { ACCESSORY_PHOTO } = PHOTO_TYPES;
  const enableErrorStyles = [ACCESSORY_PHOTO].includes(photoError);

  // This will be used to get a handle on the <input> element inside CapturePhoto
  const capturePhotoRef = useRef();

  const [accessoriesModalDisplayed, setAccessoriesModalDisplayed] = useState(
    false,
  );
  const [selectedAccessory, setSelectedAccessory] = useState('');
  const [deleteModalDisplayed, setDeleteModalDisplayed] = useState(false);
  const [
    deleteConfirmationModalDisplayed,
    setDeleteConfirmationModalDisplayed,
  ] = useState(false);
  const [accessoryToDelete, setAccessoryToDelete] = useState(undefined);

  const getButtonId = useMemo(
    () => () =>
      new FingerprintButton(accessoryToDelete)
        .setVehicleClass(vehicleClass)
        .setAction(BUTTON_ACTION.REMOVE)
        .setIsRetake(!!retake)
        .getId(),
    [accessoryToDelete, vehicleClass, retake],
  );

  const showAccessoriesModal = async () => {
    dispatch(TakePhotoActions.onChangeCameraMode(CAMERA_MODE.PHOTO));
    const { camera } = await validateEmailToken();
    if (camera) dispatch(TakePhotoActions.onChangeCameraType(camera));
    setAccessoriesModalDisplayed(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalDisplayed(false);
    setAccessoryToDelete(undefined);
  };

  const showDeleteModal = event => {
    setAccessoryToDelete(event);
    setDeleteModalDisplayed(true);
  };

  const hideDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDisplayed(false);
  };

  const hideAccessoriesModal = () => {
    setAccessoriesModalDisplayed(false);
  };

  const onDeleteAccessory = async () => {
    dispatch(PhotoHubActions.deletePhoto(accessoryToDelete));
    setDeleteConfirmationModalDisplayed(true);
    setDeleteModalDisplayed(false);
    setAccessoryToDelete(undefined);
  };

  const openCapturePhotoComponent = () => {
    hideAccessoriesModal();

    switch (defaultCamera) {
      case CAMERA_TYPE.FRAME:
        history.push({
          pathname: TAKE_PHOTO,
          search: history.location.search,
        });
        break;
      case CAMERA_TYPE.NATIVE:
        capturePhotoRef.current.click();
        break;
      default:
    }
  };

  const onAccessoryButtonClicked = event => {
    const radioButtonId = event.currentTarget.children[0].children[0].id;
    const radioButtonElement = document.getElementById(radioButtonId);

    if (radioButtonElement.click) {
      radioButtonElement.click();
    }
  };

  const onClickRadioButton = event => {
    dispatch(TakePhotoActions.setIncomingPhoto(event.currentTarget.value));
    setSelectedAccessory(event.currentTarget.value);
    event.stopPropagation();
  };

  const getIconButton = () => {
    if (enableErrorStyles) {
      return (
        <Button
          className="accessories-section-button-error-container"
          onClick={() => showAccessoriesModal()}
        />
      );
    }
    return (
      <IconButton
        id="getButtonId()"
        IconComponent={IconAdd}
        onClick={() => showAccessoriesModal()}
        className="float-right"
      />
    );
  };

  const renderDeletePhotoModal = () => (
    <Modal
      isOpen={deleteModalDisplayed}
      closeButtonHandler={hideDeleteModal}
      primaryButtonLabel={PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL}
      primaryButtonHandler={onDeleteAccessory}
      secondaryButtonLabel={PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL}
      secondaryButtonHandler={hideDeleteModal}
      buttonId={getButtonId()}
    >
      <div>
        <div className="accessories-section-delete-modal-image">
          <img src={IconTriangleAlert} alt={PHOTO_HUB_DELETE_IMAGE_ALT_TEXT} />
        </div>
        <div className="accessories-section-delete-modal-content-container">
          <div className="accessories-section-delete-modal-text-content-container">
            <div className="accessories-section-delete-modal-title">
              {PHOTO_HUB_DELETE_TITLE}
            </div>
            <div className="accessories-section-delete-modal-subtitle">
              <p>{PHOTO_HUB_DELETE_SUBTITLE}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  const renderDeletedPhotoConfirmationModal = () => (
    <Modal
      isOpen={deleteConfirmationModalDisplayed}
      closeButtonHandler={hideDeleteConfirmationModal}
      secondaryButtonLabel={
        PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL
      }
      secondaryButtonHandler={hideDeleteConfirmationModal}
    >
      <div>
        <div className="accessories-section-delete-confirmation-modal-image">
          <img
            src={IconTrashcan}
            alt={PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT}
          />
        </div>
        <div className="accessories-section-delete-modal-content-container">
          <div className="accessories-section-delete-modal-text-content-container">
            <div className="accessories-section-delete-modal-title">
              {PHOTO_HUB_DELETE_CONFIRMATION_TITLE}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  const showUpdatePhotoModal = photoId => {
    dispatch(TakePhotoActions.setIncomingPhoto(photoId));
    dispatch(ModalFlowActions.onDisplayRetakePhotoModal(true));
  };

  return (
    <div className="accessories-section">
      {renderDeletePhotoModal()}
      {renderDeletedPhotoConfirmationModal()}
      <CapturePhoto webcamRef={capturePhotoRef} photoId={selectedAccessory} />

      <ModalAccessories
        language={language}
        isOpen={accessoriesModalDisplayed}
        title={PHOTO_HUB_ACCESSORIES_SECTION_MODAL_TITLE}
        hideAccessoriesModal={hideAccessoriesModal}
        onAccessoryButtonClicked={onAccessoryButtonClicked}
        onClickRadioButton={onClickRadioButton}
        showContinueButton={!!selectedAccessory}
        startCapturePhoto={openCapturePhotoComponent}
        primaryButtonLabel={
          PHOTO_HUB_ACCESSORIES_SECTION_MODAL_PRIMARY_BUTTON_LABEL
        }
      />

      <DefaultPhotoPanel
        IconComponent={getIconButton()}
        title={PHOTO_HUB_ACCESSORIES_SECTION_PANEL_TITLE}
        body={PHOTO_HUB_ACCESSORIES_SECTION_PANEL_DESCRIPTION}
        errorStyles={enableErrorStyles}
        photoId={selectedAccessory}
      />

      <AccessoriesPhotoList
        photos={photosTaken}
        navigate={navigate}
        onUpdate={showUpdatePhotoModal}
        onDelete={showDeleteModal}
      />
    </div>
  );
};
AccessoriesSection.propTypes = {
  photosTaken: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default AccessoriesSection;
