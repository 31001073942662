import { useRef, useEffect, useState } from 'react';

export function useScroll() {
  const [scrollValue, setScrollValue] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');
  const prevScrollY = useRef(0);

  useEffect(() => {
    const onScroll = () => {
      const scrollY =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up';

      setScrollValue(scrollY);

      setScrollDirection(newScrollDirection);

      prevScrollY.current = scrollY;
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return { scrollValue, scrollDirection };
}
