import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, Heading, IconMulticar } from '@lmig/lmds-react';
import { CircleImage, Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';

export const ModalFinishConfirmationProcess = ({
  isOpen,
  language,
  confirmFinish,
  hideFinishConfirmationModal,
}) => {
  const {
    PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON,
    PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON,
    PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE,
    PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE,
  } = localizedStrings(language);
  return (
    <Modal
      isOpen={isOpen}
      primaryButtonLabel={PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON}
      primaryButtonHandler={confirmFinish}
      secondaryButtonHandler={hideFinishConfirmationModal}
      secondaryButtonLabel={PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON}
      centerContent
    >
      <div className="photohub-modal">
        <Heading type="h3-light" className="auto-left-right-margin">
          {PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE}
        </Heading>

        <BodyText>{PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE}</BodyText>

        <div className="photohub-modal-image auto-left-right-margin">
          <CircleImage>
            <IconMulticar sizing="auto" />
          </CircleImage>
        </div>
      </div>
    </Modal>
  );
};

ModalFinishConfirmationProcess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  confirmFinish: PropTypes.func.isRequired,
  hideFinishConfirmationModal: PropTypes.func.isRequired,
};
