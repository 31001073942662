import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ disabled, ...props }) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    style={{ pointerEvents: disabled && 'none' }}
    {...props}
  >
    <defs>
      <filter
        id="Rectángulo_8"
        x="0"
        y="0"
        width="54"
        height="54"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="2" result="blur" />
        <feFlood floodOpacity="0.251" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>

    <g id="Grupo_11300" data-name="Grupo 11300" transform="translate(4 4)">
      <g transform="matrix(1, 0, 0, 1, -4, -4)" filter="url(#Rectángulo_8)">
        <rect
          id="Rectángulo_8-2"
          data-name="Rectángulo 8"
          width="42"
          height="42"
          rx="2"
          transform="translate(4 4)"
          style={{ fill: disabled ? '#fff' : '#28a3af' }}
        />
      </g>
      <g id="Grupo_11302" data-name="Grupo 11302">
        <path
          id="Trazado_3421"
          data-name="Trazado 3421"
          d="M1.049,0,0,1.061,6.964,8.107.213,14.939,1.261,16,8.013,9.169l6.726,6.808,1.05-1.062L9.061,8.107,16,1.086,14.951.024,8.013,7.045Z"
          transform="translate(21 9.686) rotate(45)"
          fill={disabled ? '#000' : '#fff'}
        />
      </g>
    </g>
  </svg>
);

PlusIcon.defaultProps = {
  disabled: false,
};

PlusIcon.propTypes = {
  disabled: PropTypes.bool,
};

export default PlusIcon;
