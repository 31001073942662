import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WIZARD_PHOTO_STEP } from '../constants/wizardPhotos';

export const useValidationCompleteProcess = (
  currentStep,
  wizardPhotoHub,
  photosConfig,
) => {
  const { videoParameters, finishedVideo, photosVideoPreview } = useSelector(
    store => store.takeVideo,
  );

  const [isAllPhotoCompleted, setAllPhotoCompleted] = useState(false);

  const validateVideo = () => {
    if (!videoParameters?.requiredVideo) return true;
    return finishedVideo && photosVideoPreview.length > 0;
  };

  const getAllPhotoIdPhotosTaken = wizardPhotosHub => {
    const allPhotoIdPhotosTaken = [];

    wizardPhotosHub.forEach(step => {
      const { id, photos, conditionalPhotos } = step;

      if (id === WIZARD_PHOTO_STEP.THREE) {
        allPhotoIdPhotosTaken.push(
          ...conditionalPhotos.map(photo => photo.photoId),
        );
      }

      if (id === WIZARD_PHOTO_STEP.FOUR) {
        const { accessories, damages } = photos;
        allPhotoIdPhotosTaken.push(
          ...accessories.map(photo => photo.photoId),
          ...damages.map(photo => photo.photoId),
        );
      } else {
        allPhotoIdPhotosTaken.push(...step.photos.map(photo => photo.photoId));
      }
    });

    return allPhotoIdPhotosTaken;
  };

  useEffect(() => {
    if (currentStep === WIZARD_PHOTO_STEP.FOUR) {
      const allPhotoIdPhotosTaken = getAllPhotoIdPhotosTaken(wizardPhotoHub);

      const completed = photosConfig.every(config =>
        allPhotoIdPhotosTaken.includes(config.photoId),
      );

      setAllPhotoCompleted(completed);
    }
  }, [currentStep, wizardPhotoHub, photosConfig]);

  return isAllPhotoCompleted && validateVideo();
};
