import { Button } from '@lmig/lmds-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const IconButton = ({
  id,
  onClick,
  IconComponent,
  className,
  removeShadow,
  simple,
  color,
  sizing,
  background,
  disabled,
}) => (
  <Button
    icon
    id={id}
    type="button"
    onClick={onClick}
    className={classNames('icon-button-container', className, {
      'remove-shadow': removeShadow || simple,
      simple,
      'disabled-button': disabled,
    })}
  >
    <IconComponent color={color} sizing={sizing} background={background} />
  </Button>
);

IconButton.propTypes = {
  IconComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  removeShadow: PropTypes.bool,
  id: PropTypes.string,
  simple: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'inverse']),
  sizing: PropTypes.oneOf(['default', 'auto']),
  background: PropTypes.oneOf(['round', 'square']),
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  id: undefined,
  onClick: () => {
    // Empty function
  },
  className: [],
  removeShadow: false,
  simple: false,
  color: 'inverse',
  sizing: 'auto',
  background: 'square',
  disabled: false,
};

export default IconButton;
