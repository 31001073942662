import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGeolocated } from 'react-geolocated';
import { logcodes, logger } from '../utils/logger';
import { DeviceInfoActions } from '../actions';
import localizedStrings from '../utils/localizedStrings';
import { logDeviceError } from '../api';
import { useInterval } from './useInterval';

// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
const GeolocationPositionError = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
};

export const useGeolocation = interval => {
  const dispatch = useDispatch();

  const {
    GPS_PERMISSION_DENIED,
    GPS_PERMISSION_ENABLED,
    GPS_ERROR_NOT_SUPPORT,
    GPS_POSITION_ERROR_UNAVAILABLE,
    GPS_POSITION_ERROR_TIMEOUT,
  } = localizedStrings();

  const { GPSStatus } = useSelector(store => store.deviceInfo);

  const geocodeLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };
    return geocoder.geocode({ location: latlng });
  };

  const onUnknownError = err => {
    logger.log(logcodes.GPS40, err);
    dispatch(DeviceInfoActions.setGPSStatus('Unknown'));
    return 'Unknown';
  };

  const onPermissionDenied = err => {
    logger.log(logcodes.GPS30, err);
    dispatch(DeviceInfoActions.setGPSStatus(GPS_PERMISSION_DENIED, err?.code));
    return GPS_PERMISSION_DENIED;
  };

  const onUnavailablePosition = err => {
    logger.log(logcodes.GPS50, err);
    dispatch(DeviceInfoActions.setGPSStatus(GPS_POSITION_ERROR_UNAVAILABLE));
    return GPS_POSITION_ERROR_UNAVAILABLE;
  };
  const onGeolocationTimeout = err => {
    logger.log(logcodes.GPS50, err);
    dispatch(DeviceInfoActions.setGPSStatus(GPS_POSITION_ERROR_TIMEOUT));
    return GPS_POSITION_ERROR_TIMEOUT;
  };

  const onGeolocationPositionError = async err => {
    let message;
    dispatch(DeviceInfoActions.onChangeCurrentLocationGPS(null));
    if (GPSStatus?.code === err?.code) return;
    switch (err?.code) {
      case GeolocationPositionError.PERMISSION_DENIED:
        message = onPermissionDenied(err);
        break;
      case GeolocationPositionError.POSITION_UNAVAILABLE:
        message = onUnavailablePosition(err);
        break;
      case GeolocationPositionError.TIMEOUT:
        message = onGeolocationTimeout(err);
        break;
      default:
        message = onUnknownError(err);
    }
    try {
      await logDeviceError({ errorData: { type: 'gps', message } });
    } catch (error) {
      logger.log(logcodes.GPS70, error);
    }
  };

  const onGeolocationSuccess = async ({ coords: coordinates }) => {
    dispatch(DeviceInfoActions.setGPSStatus(GPS_PERMISSION_ENABLED));
    dispatch(DeviceInfoActions.onChangeCurrentLocationGPS(coordinates));
    const { latitude, longitude } = coordinates;
    logger.log(logcodes.GPS10, { latitude, longitude });

    let streetAddress;
    try {
      const { results, status } = await geocodeLatLng(latitude, longitude);
      if (!results) {
        logger.log(logcodes.GPS90, { status, results });
        return;
      }

      [streetAddress] = results;

      logger.log(logcodes.GPS130, {
        streetAddress: streetAddress.formatted_address,
      });
    } catch (error) {
      logger.log(logcodes.GPS80, error);
    }

    if (streetAddress) {
      dispatch(
        DeviceInfoActions.onChangeCurrentLocationGPS({
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          accuracy: coordinates.accuracy,
          streetAddress: streetAddress.formatted_address,
        }),
      );
    } else logger.log(logcodes.GPS100);
  };

  const { isGeolocationAvailable, coords, getPosition } = useGeolocated({
    onSuccess: onGeolocationSuccess,
    onError: onGeolocationPositionError,
    userDecisionTimeout: 5000,
    positionOptions: {
      enableHighAccuracy: true,
    },
  });

  useEffect(() => {
    if (!isGeolocationAvailable) {
      logger.log(logcodes.GPS20);
      dispatch(DeviceInfoActions.setGPSStatus(GPS_ERROR_NOT_SUPPORT));
    }
  }, [isGeolocationAvailable, GPS_ERROR_NOT_SUPPORT, dispatch]);

  /*
   * This segment requests the browser with an interval in miliseconds the
   * position of the user. If it is the first time that it is executed, it will
   * request the GPS permissions, later it will be capturing the user's position.
   */
  useInterval(() => {
    logger.log(logcodes.GPS120);
    getPosition();
  }, interval);

  return { getPosition, coords };
};
