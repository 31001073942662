import React from 'react';

export default function CircleCarIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97">
      <g id="Grupo_5094" data-name="Grupo 5094" transform="translate(4.5 -0.5)">
        <g id="Grupo_4567" data-name="Grupo 4567" transform="translate(0.5)">
          <circle
            id="Oval"
            cx="48"
            cy="48"
            r="48"
            transform="translate(-4.5 1)"
            fill="#f0fbfc"
            stroke="#99e5ea"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(19.587 22.758)">
          <g
            id="Combined_Shape"
            data-name="Combined Shape"
            transform="translate(-0.087 7.692)"
          >
            <path
              d="M38.736,37.1c-1.121,0-2.675-.72-2.675-2.071v-1.8h1.874v1.731a1.382,1.382,0,0,0,.8.267h5.57a.384.384,0,0,0,.136-.018c-.022,0,.006-.06.006-.179v-9.67H37.9a2.038,2.038,0,0,1-1.986-1.537l-.778-2.364c-.009-.027-.016-.054-.022-.082a2.3,2.3,0,0,1,.43-1.992,2.548,2.548,0,0,1,2.02-.931h6.882a2.065,2.065,0,0,0-.593-1.512l-2.689-2.484H7.326v-.048L4.343,17a2.2,2.2,0,0,0-.789,1.453h7.1a2.546,2.546,0,0,1,2.019.931,2.3,2.3,0,0,1,.433,1.992c-.007.028-.015.055-.024.082L12.3,23.819a2.042,2.042,0,0,1-1.988,1.538H3.551v9.67a.761.761,0,0,0,.547.2H9.67c.128,0,.393-.048.393-.2v-1.8h1.874v1.8A2.128,2.128,0,0,1,9.67,37.1H4.1a2.254,2.254,0,0,1-2.422-2.071v-16.5a3.992,3.992,0,0,1,1.432-2.945l.339-.294H2.192A2.1,2.1,0,0,1,0,13.191V10.5A2.106,2.106,0,0,1,2.192,8.386H7.78L9.41,3.266A4.639,4.639,0,0,1,13.622,0H34.44a4.586,4.586,0,0,1,4.139,3.129l1.866,5.258h5.567A1.943,1.943,0,0,1,48,10.5v2.692a1.938,1.938,0,0,1-1.988,2.1H44.834l.276.255a3.9,3.9,0,0,1,1.213,2.984v16.5A1.936,1.936,0,0,1,44.306,37.1ZM10.286,23.482a.186.186,0,0,0,.2-.133c.007-.028.014-.055.023-.082l.773-2.349a.439.439,0,0,0-.08-.371.7.7,0,0,0-.553-.223h-7.1v3.158Zm26.727-2.934a.437.437,0,0,0-.079.37l.773,2.349a.745.745,0,0,1,.022.082.175.175,0,0,0,.173.132h6.546V20.325H37.565A.7.7,0,0,0,37.013,20.548ZM1.874,10.5v2.692c0,.056,0,.227.317.227H5.549v.05l.847-.736.787-2.472H2.192C1.981,10.26,1.874,10.34,1.874,10.5Zm44.138,2.919a.355.355,0,0,0,.105-.011c-.016-.009.007-.072.008-.215V10.5a.618.618,0,0,0-.034-.235c-.011,0-.037,0-.08,0h-4.9l.84,2.367.856.791Zm-6.067-.838L36.814,3.758A2.726,2.726,0,0,0,34.44,1.874H13.622A2.763,2.763,0,0,0,11.2,3.838L8.412,12.58ZM6.388,30.388V28.514H41.612v1.874Z"
              transform="translate(0)"
              fill="none"
            />
            <path
              d="M38.736,37.1c-1.121,0-2.675-.72-2.675-2.071v-1.8h1.874v1.731a1.382,1.382,0,0,0,.8.267h5.57a.384.384,0,0,0,.136-.018c-.022,0,.006-.06.006-.179v-9.67H37.9a2.038,2.038,0,0,1-1.986-1.537l-.778-2.364c-.009-.027-.016-.054-.022-.082a2.3,2.3,0,0,1,.43-1.992,2.548,2.548,0,0,1,2.02-.931h6.882a2.065,2.065,0,0,0-.593-1.512l-2.689-2.484H7.326v-.048L4.343,17a2.2,2.2,0,0,0-.789,1.453h7.1a2.546,2.546,0,0,1,2.019.931,2.3,2.3,0,0,1,.433,1.992c-.007.028-.015.055-.024.082L12.3,23.819a2.042,2.042,0,0,1-1.988,1.538H3.551v9.67a.761.761,0,0,0,.547.2H9.67c.128,0,.393-.048.393-.2v-1.8h1.874v1.8A2.128,2.128,0,0,1,9.67,37.1H4.1a2.254,2.254,0,0,1-2.422-2.071v-16.5a3.992,3.992,0,0,1,1.432-2.945l.339-.294H2.192A2.1,2.1,0,0,1,0,13.191V10.5A2.106,2.106,0,0,1,2.192,8.386H7.78L9.41,3.266A4.639,4.639,0,0,1,13.622,0H34.44a4.586,4.586,0,0,1,4.139,3.129l1.866,5.258h5.567A1.943,1.943,0,0,1,48,10.5v2.692a1.938,1.938,0,0,1-1.988,2.1H44.834l.276.255a3.9,3.9,0,0,1,1.213,2.984v16.5A1.936,1.936,0,0,1,44.306,37.1ZM10.286,23.482a.186.186,0,0,0,.2-.133c.007-.028.014-.055.023-.082l.773-2.349a.439.439,0,0,0-.08-.371.7.7,0,0,0-.553-.223h-7.1v3.158Zm26.727-2.934a.437.437,0,0,0-.079.37l.773,2.349a.745.745,0,0,1,.022.082.175.175,0,0,0,.173.132h6.546V20.325H37.565A.7.7,0,0,0,37.013,20.548ZM1.874,10.5v2.692c0,.056,0,.227.317.227H5.549v.05l.847-.736.787-2.472H2.192C1.981,10.26,1.874,10.34,1.874,10.5Zm44.138,2.919a.355.355,0,0,0,.105-.011c-.016-.009.007-.072.008-.215V10.5a.618.618,0,0,0-.034-.235c-.011,0-.037,0-.08,0h-4.9l.84,2.367.856.791Zm-6.067-.838L36.814,3.758A2.726,2.726,0,0,0,34.44,1.874H13.622A2.763,2.763,0,0,0,11.2,3.838L8.412,12.58ZM6.388,30.388V28.514H41.612v1.874Z"
              transform="translate(0)"
              fill="none"
            />
            <path
              d="M38.736,37.1c-1.121,0-2.675-.72-2.675-2.071v-1.8h1.874v1.731a1.382,1.382,0,0,0,.8.267h5.57a.384.384,0,0,0,.136-.018c-.022,0,.006-.06.006-.179v-9.67H37.9a2.038,2.038,0,0,1-1.986-1.537l-.778-2.364c-.009-.027-.016-.054-.022-.082a2.3,2.3,0,0,1,.43-1.992,2.548,2.548,0,0,1,2.02-.931h6.882a2.065,2.065,0,0,0-.593-1.512l-2.689-2.484H7.326v-.048L4.343,17a2.2,2.2,0,0,0-.789,1.453h7.1a2.546,2.546,0,0,1,2.019.931,2.3,2.3,0,0,1,.433,1.992c-.007.028-.015.055-.024.082L12.3,23.819a2.042,2.042,0,0,1-1.988,1.538H3.551v9.67a.761.761,0,0,0,.547.2H9.67c.128,0,.393-.048.393-.2v-1.8h1.874v1.8A2.128,2.128,0,0,1,9.67,37.1H4.1a2.254,2.254,0,0,1-2.422-2.071v-16.5a3.992,3.992,0,0,1,1.432-2.945l.339-.294H2.192A2.1,2.1,0,0,1,0,13.191V10.5A2.106,2.106,0,0,1,2.192,8.386H7.78L9.41,3.266A4.639,4.639,0,0,1,13.622,0H34.44a4.586,4.586,0,0,1,4.139,3.129l1.866,5.258h5.567A1.943,1.943,0,0,1,48,10.5v2.692a1.938,1.938,0,0,1-1.988,2.1H44.834l.276.255a3.9,3.9,0,0,1,1.213,2.984v16.5A1.936,1.936,0,0,1,44.306,37.1ZM10.286,23.482a.186.186,0,0,0,.2-.133c.007-.028.014-.055.023-.082l.773-2.349a.439.439,0,0,0-.08-.371.7.7,0,0,0-.553-.223h-7.1v3.158Zm26.727-2.934a.437.437,0,0,0-.079.37l.773,2.349a.745.745,0,0,1,.022.082.175.175,0,0,0,.173.132h6.546V20.325H37.565A.7.7,0,0,0,37.013,20.548ZM1.874,10.5v2.692c0,.056,0,.227.317.227H5.549v.05l.847-.736.787-2.472H2.192C1.981,10.26,1.874,10.34,1.874,10.5Zm44.138,2.919a.355.355,0,0,0,.105-.011c-.016-.009.007-.072.008-.215V10.5a.618.618,0,0,0-.034-.235c-.011,0-.037,0-.08,0h-4.9l.84,2.367.856.791Zm-6.067-.838L36.814,3.758A2.726,2.726,0,0,0,34.44,1.874H13.622A2.763,2.763,0,0,0,11.2,3.838L8.412,12.58ZM6.388,30.388V28.514H41.612v1.874Z"
              transform="translate(0)"
              fill="none"
            />
            <path
              d="M38.736,37.1c-1.121,0-2.675-.72-2.675-2.071v-1.8h1.874v1.731a1.382,1.382,0,0,0,.8.267h5.57a.384.384,0,0,0,.136-.018c-.022,0,.006-.06.006-.179v-9.67H37.9a2.038,2.038,0,0,1-1.986-1.537l-.778-2.364c-.009-.027-.016-.054-.022-.082a2.3,2.3,0,0,1,.43-1.992,2.548,2.548,0,0,1,2.02-.931h6.882a2.065,2.065,0,0,0-.593-1.512l-2.689-2.484H7.326v-.048L4.343,17a2.2,2.2,0,0,0-.789,1.453h7.1a2.546,2.546,0,0,1,2.019.931,2.3,2.3,0,0,1,.433,1.992c-.007.028-.015.055-.024.082L12.3,23.819a2.042,2.042,0,0,1-1.988,1.538H3.551v9.67a.761.761,0,0,0,.547.2H9.67c.128,0,.393-.048.393-.2v-1.8h1.874v1.8A2.128,2.128,0,0,1,9.67,37.1H4.1a2.254,2.254,0,0,1-2.422-2.071v-16.5a3.992,3.992,0,0,1,1.432-2.945l.339-.294H2.192A2.1,2.1,0,0,1,0,13.191V10.5A2.106,2.106,0,0,1,2.192,8.386H7.78L9.41,3.266A4.639,4.639,0,0,1,13.622,0H34.44a4.586,4.586,0,0,1,4.139,3.129l1.866,5.258h5.567A1.943,1.943,0,0,1,48,10.5v2.692a1.938,1.938,0,0,1-1.988,2.1H44.834l.276.255a3.9,3.9,0,0,1,1.213,2.984v16.5A1.936,1.936,0,0,1,44.306,37.1ZM10.286,23.482a.186.186,0,0,0,.2-.133c.007-.028.014-.055.023-.082l.773-2.349a.439.439,0,0,0-.08-.371.7.7,0,0,0-.553-.223h-7.1v3.158Zm26.727-2.934a.437.437,0,0,0-.079.37l.773,2.349a.745.745,0,0,1,.022.082.175.175,0,0,0,.173.132h6.546V20.325H37.565A.7.7,0,0,0,37.013,20.548ZM1.874,10.5v2.692c0,.056,0,.227.317.227H5.549v.05l.847-.736.787-2.472H2.192C1.981,10.26,1.874,10.34,1.874,10.5Zm44.138,2.919a.355.355,0,0,0,.105-.011c-.016-.009.007-.072.008-.215V10.5a.618.618,0,0,0-.034-.235c-.011,0-.037,0-.08,0h-4.9l.84,2.367.856.791Zm-6.067-.838L36.814,3.758A2.726,2.726,0,0,0,34.44,1.874H13.622A2.763,2.763,0,0,0,11.2,3.838L8.412,12.58ZM6.388,30.388V28.514H41.612v1.874Z"
              transform="translate(0)"
              fill="none"
            />
            <path
              d="M38.736,37.1c-1.121,0-2.675-.72-2.675-2.071v-1.8h1.874v1.731a1.382,1.382,0,0,0,.8.267h5.57a.384.384,0,0,0,.136-.018c-.022,0,.006-.06.006-.179v-9.67H37.9a2.038,2.038,0,0,1-1.986-1.537l-.778-2.364c-.009-.027-.016-.054-.022-.082a2.3,2.3,0,0,1,.43-1.992,2.548,2.548,0,0,1,2.02-.931h6.882a2.065,2.065,0,0,0-.593-1.512l-2.689-2.484H7.326v-.048L4.343,17a2.2,2.2,0,0,0-.789,1.453h7.1a2.546,2.546,0,0,1,2.019.931,2.3,2.3,0,0,1,.433,1.992c-.007.028-.015.055-.024.082L12.3,23.819a2.042,2.042,0,0,1-1.988,1.538H3.551v9.67a.761.761,0,0,0,.547.2H9.67c.128,0,.393-.048.393-.2v-1.8h1.874v1.8A2.128,2.128,0,0,1,9.67,37.1H4.1a2.254,2.254,0,0,1-2.422-2.071v-16.5a3.992,3.992,0,0,1,1.432-2.945l.339-.294H2.192A2.1,2.1,0,0,1,0,13.191V10.5A2.106,2.106,0,0,1,2.192,8.386H7.78L9.41,3.266A4.639,4.639,0,0,1,13.622,0H34.44a4.586,4.586,0,0,1,4.139,3.129l1.866,5.258h5.567A1.943,1.943,0,0,1,48,10.5v2.692a1.938,1.938,0,0,1-1.988,2.1H44.834l.276.255a3.9,3.9,0,0,1,1.213,2.984v16.5A1.936,1.936,0,0,1,44.306,37.1ZM10.286,23.482a.186.186,0,0,0,.2-.133c.007-.028.014-.055.023-.082l.773-2.349a.439.439,0,0,0-.08-.371.7.7,0,0,0-.553-.223h-7.1v3.158Zm26.727-2.934a.437.437,0,0,0-.079.37l.773,2.349a.745.745,0,0,1,.022.082.175.175,0,0,0,.173.132h6.546V20.325H37.565A.7.7,0,0,0,37.013,20.548ZM1.874,10.5v2.692c0,.056,0,.227.317.227H5.549v.05l.847-.736.787-2.472H2.192C1.981,10.26,1.874,10.34,1.874,10.5Zm44.138,2.919a.355.355,0,0,0,.105-.011c-.016-.009.007-.072.008-.215V10.5a.618.618,0,0,0-.034-.235c-.011,0-.037,0-.08,0h-4.9l.84,2.367.856.791Zm-6.067-.838L36.814,3.758A2.726,2.726,0,0,0,34.44,1.874H13.622A2.763,2.763,0,0,0,11.2,3.838L8.412,12.58ZM6.388,30.388V28.514H41.612v1.874Z"
              transform="translate(0)"
              fill="none"
            />
            <path
              d="M38.736,37.1c-1.121,0-2.675-.72-2.675-2.071v-1.8h1.874v1.731a1.382,1.382,0,0,0,.8.267h5.57a.384.384,0,0,0,.136-.018c-.022,0,.006-.06.006-.179v-9.67H37.9a2.038,2.038,0,0,1-1.986-1.537l-.778-2.364c-.009-.027-.016-.054-.022-.082a2.3,2.3,0,0,1,.43-1.992,2.548,2.548,0,0,1,2.02-.931h6.882a2.065,2.065,0,0,0-.593-1.512l-2.689-2.484H7.326v-.048L4.343,17a2.2,2.2,0,0,0-.789,1.453h7.1a2.546,2.546,0,0,1,2.019.931,2.3,2.3,0,0,1,.433,1.992c-.007.028-.015.055-.024.082L12.3,23.819a2.042,2.042,0,0,1-1.988,1.538H3.551v9.67a.761.761,0,0,0,.547.2H9.67c.128,0,.393-.048.393-.2v-1.8h1.874v1.8A2.128,2.128,0,0,1,9.67,37.1H4.1a2.254,2.254,0,0,1-2.422-2.071v-16.5a3.992,3.992,0,0,1,1.432-2.945l.339-.294H2.192A2.1,2.1,0,0,1,0,13.191V10.5A2.106,2.106,0,0,1,2.192,8.386H7.78L9.41,3.266A4.639,4.639,0,0,1,13.622,0H34.44a4.586,4.586,0,0,1,4.139,3.129l1.866,5.258h5.567A1.943,1.943,0,0,1,48,10.5v2.692a1.938,1.938,0,0,1-1.988,2.1H44.834l.276.255a3.9,3.9,0,0,1,1.213,2.984v16.5A1.936,1.936,0,0,1,44.306,37.1ZM10.286,23.482a.186.186,0,0,0,.2-.133c.007-.028.014-.055.023-.082l.773-2.349a.439.439,0,0,0-.08-.371.7.7,0,0,0-.553-.223h-7.1v3.158Zm26.727-2.934a.437.437,0,0,0-.079.37l.773,2.349a.745.745,0,0,1,.022.082.175.175,0,0,0,.173.132h6.546V20.325H37.565A.7.7,0,0,0,37.013,20.548ZM1.874,10.5v2.692c0,.056,0,.227.317.227H5.549v.05l.847-.736.787-2.472H2.192C1.981,10.26,1.874,10.34,1.874,10.5Zm44.138,2.919a.355.355,0,0,0,.105-.011c-.016-.009.007-.072.008-.215V10.5a.618.618,0,0,0-.034-.235c-.011,0-.037,0-.08,0h-4.9l.84,2.367.856.791Zm-6.067-.838L36.814,3.758A2.726,2.726,0,0,0,34.44,1.874H13.622A2.763,2.763,0,0,0,11.2,3.838L8.412,12.58ZM6.388,30.388V28.514H41.612v1.874Z"
              transform="translate(0)"
              fill="#1a1446"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
