const pathRoot = '/app';

export const DEFAULT = `${pathRoot}`;
export const PHOTO_HUB = `${pathRoot}/photo_hub`;
export const TAKE_PHOTO = `${pathRoot}/take_photo`;
export const TERMS_CONDITIONS = `${pathRoot}/terms`;
export const PROCESS_COMPLETE = `${pathRoot}/complete?utm_source=adil&utm_medium=finalButton`;
export const PROCESS_COMPLETE_ORIGINAL = `${pathRoot}/complete`;
export const PROCESS_SUPPORT = `${pathRoot}/support`;
export const FORM_SUPPORT = `${pathRoot}/form_support`;
export const INVALID_STATUS = `${pathRoot}/invalid_status`;
export const ERROR_GENERIC = `${pathRoot}/error`;
export const ERROR_NON_MOBILE = `${pathRoot}/error_non_mobile`;
