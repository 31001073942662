import React from 'react';
import PropTypes from 'prop-types';
import Icon from './logo.svg';
import '../icon.scss';

function IconLiberty({ classNames = '' }) {
  return (
    <img className={`${classNames}`} style={{ zIndex: 9 }} src={Icon} alt="" />
  );
}

IconLiberty.defaultProps = {
  classNames: '',
};

IconLiberty.propTypes = {
  classNames: PropTypes.string,
};

export default IconLiberty;
