/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { GridCol, GridRow } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CAMERA_MODE } from '../../constants/camera';
import PanelPhotoFlow from '../WizardPhotos/PanelPhotoFlow/PanelPhotoFlow';
import NotifyProcess from '../NotifyProcess/NotifyProcess';
import './styles.scss';

const ProcessStepsList = ({
  navigate,
  photos,
  photoListConfig,
  startCapturePhoto,
  capturePhotoRef,
}) => {
  const {
    user: { vehicleClass },
  } = useSelector(store => store);

  return (
    <div className="process-steps-list-container">
      <NotifyProcess />

      {photoListConfig.map((requiredPhoto, index) => {
        const photo = photos.filter(
          element => element.photoId === requiredPhoto.photoId,
        )[0];
        const previewPhoto = photo ? photo.blobUrl : '';
        const notHavePreviewPhoto = !photos[index - 1];
        const disabled = index > 0 && notHavePreviewPhoto;
        const sectionId = requiredPhoto.photoId.toString();

        return (
          <div key={requiredPhoto.photoId} id={sectionId}>
            <GridRow>
              <GridCol>
                <PanelPhotoFlow
                  photoConfig={requiredPhoto}
                  vehicleClass={vehicleClass}
                  disabled={disabled}
                  navigate={navigate}
                  previewPhoto={previewPhoto}
                  cameraMode={CAMERA_MODE.PHOTO}
                  startCapturePhoto={startCapturePhoto}
                  capturePhotoRef={capturePhotoRef}
                  buttons={['update']}
                />
              </GridCol>
            </GridRow>
          </div>
        );
      })}
    </div>
  );
};

ProcessStepsList.propTypes = {
  navigate: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.any).isRequired,
  photoListConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
  capturePhotoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default ProcessStepsList;
