import {
  ACCESSORY_PHOTO_IDS,
  CONDITIONALLY_REQUIRED_PHOTO_IDS,
  REQUIRED_PHOTO_IDS,
  MOTORBIKE_REQUIRED_PHOTO_IDS,
  ACCESSORY_MOTORBIKE_PHOTO_IDS,
} from '@lmig-latam/adil-api-common-lib/constants';
import { countryCode } from '@lmig-latam/adil-api-common-lib/countries/cl';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import localizedStrings from '../utils/localizedStrings';
import { DAMAGE_PHOTO, PHOTO_TYPES } from '../utils/constants';
import { WizardStepData } from '../utils/WizardStepData';
import { getCountryCode } from '../utils/NavigationUtils';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

const {
  WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE,
  WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE,
  WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE,
  WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE,

  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE,
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE,
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE,
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE,
} = localizedStrings(countryCode);

export const WIZARD_PHOTO_STEP = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
};

export function getInitialWizardPhotoHub(vehicleClass) {
  let vehicleClassOption = vehicleClass;

  if (!vehicleClass)
    vehicleClassOption = sessionStorage.getItem('VEHICLE_CLASS');

  const wizardStepData = new WizardStepData({
    WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE,
    WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE,
    WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE,
    WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE,
    WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE,
    WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE,
    WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE,
    WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE,
  });
  wizardStepData.setVehicleClass(vehicleClassOption);
  return wizardStepData.getInitialState();
}
export const initialWizardPhotoHub = getInitialWizardPhotoHub();

const {
  INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
  INSPECTION_PHOTO_PROPERTY_CARD_BACK,
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE,
  INSPECTION_PHOTO_VEHICLE_FRONT,
  INSPECTION_PHOTO_VEHICLE_REAR,
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
  INSPECTION_PHOTO_VEHICLE_ENGINE,
  INSPECTION_PHOTO_VEHICLE_DASH,
  INSPECTION_PHOTO_VEHICLE_MILEAGE,
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER,
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE,
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER,
} = REQUIRED_PHOTO_IDS;

const { INSPECTION_PHOTO_SPARE_WHEEL } = CONDITIONALLY_REQUIRED_PHOTO_IDS;

const {
  INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE,
  INSPECTION_PHOTO_MOTORBIKE_FRONT,
  INSPECTION_PHOTO_MOTORBIKE_REAR,
  INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE,
  INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE,
  INSPECTION_PHOTO_MOTORBIKE_ENGINE,
  INSPECTION_PHOTO_MOTORBIKE_DASH,
  INSPECTION_PHOTO_MOTORBIKE_MILEAGE,
  INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER,
  INSPECTION_PHOTO_MOTORBIKE_WINDSHIELD_PLATE,
} = MOTORBIKE_REQUIRED_PHOTO_IDS;

export const getClassificationPhotos = vehicleClass => {
  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
    return {
      [WIZARD_PHOTO_STEP.ONE]: [
        MOTORBIKE_REQUIRED_PHOTO_IDS.INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
        MOTORBIKE_REQUIRED_PHOTO_IDS.INSPECTION_PHOTO_PROPERTY_CARD_BACK,
        INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE,
      ],
      [WIZARD_PHOTO_STEP.TWO]: [
        INSPECTION_PHOTO_MOTORBIKE_FRONT,
        INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE,
        INSPECTION_PHOTO_MOTORBIKE_REAR,
        INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE,
      ],
      [WIZARD_PHOTO_STEP.THREE]: [
        INSPECTION_PHOTO_MOTORBIKE_ENGINE,
        INSPECTION_PHOTO_MOTORBIKE_DASH,
        INSPECTION_PHOTO_MOTORBIKE_MILEAGE,
        INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER,
        INSPECTION_PHOTO_MOTORBIKE_WINDSHIELD_PLATE,
      ],
      [WIZARD_PHOTO_STEP.FOUR]: [],
    };
  }

  return {
    [WIZARD_PHOTO_STEP.ONE]: [
      INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
      INSPECTION_PHOTO_PROPERTY_CARD_BACK,
      INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE,
    ],
    [WIZARD_PHOTO_STEP.TWO]: [
      INSPECTION_PHOTO_VEHICLE_FRONT,
      INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
      INSPECTION_PHOTO_VEHICLE_REAR,
      INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
    ],
    [WIZARD_PHOTO_STEP.THREE]: [
      INSPECTION_PHOTO_VEHICLE_ENGINE,
      INSPECTION_PHOTO_VEHICLE_DASH,
      INSPECTION_PHOTO_VEHICLE_MILEAGE,
      INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER,
      INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE,
      INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER,
      ...(getCountryCode() === 'ec' ? [INSPECTION_PHOTO_SPARE_WHEEL] : []),
    ],
    [WIZARD_PHOTO_STEP.FOUR]: [],
  };
};

export const getPhotoType = photoId => {
  const {
    countryRequiredPhotoIds,
    countryMotorBikeRequiredPhotosIds,
    countryConditionallyRequiredPhotoIds,
    countryMotorBikeConditionallyRequiredPhotoIds,
  } = getCountryConfig(getCountryCode());
  const isRequiredPhoto = [
    ...countryRequiredPhotoIds,
    ...countryMotorBikeRequiredPhotosIds,
  ].includes(photoId);

  if (isRequiredPhoto) return PHOTO_TYPES.REQUIRED_PHOTO;

  const isConditionallyRequired = [
    ...countryConditionallyRequiredPhotoIds,
    ...countryMotorBikeConditionallyRequiredPhotoIds,
  ].includes(photoId);

  if (isConditionallyRequired) return PHOTO_TYPES.CONDITIONAL_REQUIRED_PHOTO;

  const isDamage = String(photoId).indexOf(DAMAGE_PHOTO) !== -1;
  if (isDamage) return PHOTO_TYPES.DAMAGE_PHOTO;

  const isAccessory = Object.values({
    ...ACCESSORY_PHOTO_IDS,
    ...ACCESSORY_MOTORBIKE_PHOTO_IDS,
  }).includes(photoId);
  if (isAccessory) return PHOTO_TYPES.ACCESSORY_PHOTO;

  return null;
};

export const getPhotoStep = (photoId, vehicleClass) => {
  const photoClasification = getClassificationPhotos(vehicleClass);
  const totalSteps = Object.keys(photoClasification).length;
  const photoType = getPhotoType(photoId);

  switch (photoType) {
    case PHOTO_TYPES.REQUIRED_PHOTO:
      for (let step = 1; step < totalSteps; step += 1) {
        if (photoClasification[step].includes(photoId)) {
          return { step, photoType };
        }
      }
      break;
    case PHOTO_TYPES.CONDITIONAL_REQUIRED_PHOTO:
      return { step: WIZARD_PHOTO_STEP.THREE, photoType };
    case PHOTO_TYPES.ACCESSORY_PHOTO:
    case PHOTO_TYPES.DAMAGE_PHOTO:
      return { step: WIZARD_PHOTO_STEP.FOUR, photoType };
    default:
  }

  return null;
};
