import React from 'react';

const RetakeIcon = ({ ...props }) => (
  <svg width="50" height="50" viewBox="0 0 44.198 44.198" {...props}>
    <defs>
      <filter
        id="Trazado_3460"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="2" result="blur" />
        <feFlood floodOpacity="0.4" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Grupo_11333" data-name="Grupo 11333" transform="translate(4 4)">
      <g
        transform="matrix(1, 0, 0, 1, -4, -4)"
        filter="url(#Trazado_3460)"
        style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
      >
        <path
          id="Trazado_3460-2"
          data-name="Trazado 3460"
          d="M16.1,0A16.1,16.1,0,1,0,32.2,16.1,16.1,16.1,0,0,0,16.1,0"
          transform="translate(4 4)"
          fill="#c0bfc0"
        />
      </g>
      <g
        id="Grupo_11277"
        data-name="Grupo 11277"
        transform="translate(-75 -446)"
      >
        <path
          id="Trazado_3461"
          data-name="Trazado 3461"
          d="M14.219,10.124,17.2,7.207,14.369,4.239l-.715.682,1.624,1.7a9.508,9.508,0,0,0-1.447,18.71V24.1A8.311,8.311,0,0,1,15.138,7.842l-1.61,1.576Z"
          transform="translate(75 446)"
          fill="#fff"
        />
        <path
          id="Trazado_3462"
          data-name="Trazado 3462"
          d="M25.617,16.1a9.525,9.525,0,0,0-7.251-9.233V8.1a8.311,8.311,0,0,1-1.343,16.259l1.617-1.582-.692-.706-2.981,2.917L17.8,27.959l.715-.682-1.623-1.7A9.524,9.524,0,0,0,25.617,16.1"
          transform="translate(75 446)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default RetakeIcon;
