/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { getAccessoryPhotoInfo } from '../../config/availableAccesories';
import { PhotoPreviewCard } from '../WizardPhotos/PhotoPreviewCard/PhotoPreviewCard';
import localizedStrings from '../../utils/localizedStrings';

const {
  PHOTO_HUB_ACCESSORIES_SECTION_PREVIEW_DESCRIPTION,
} = localizedStrings();

const renderPhotoPreviewPanel = (photo, previewPhoto, onDelete, onUpdate) => {
  const { title } = getAccessoryPhotoInfo(photo.photoId);
  const handlerOnDelete = () => {
    onDelete(photo.photoId.toString());
  };

  return (
    <div key={photo.photoId} id={photo && photo.photoId.toString()}>
      <PhotoPreviewCard
        title={title}
        description={PHOTO_HUB_ACCESSORIES_SECTION_PREVIEW_DESCRIPTION}
        photoId={photo.photoId}
        buttons={['delete', 'update']}
        previewPhoto={previewPhoto}
        onUpdate={onUpdate}
        onDelete={handlerOnDelete}
      />
    </div>
  );
};

const AccessoriesPhotoList = ({ photos, onDelete, onUpdate }) => (
  <div>
    {photos.map(accessoryPhoto => {
      const photo = photos.filter(
        element => element.photoId === accessoryPhoto.photoId,
      )[0];
      const previewPhoto = photo ? photo.blobUrl : '';

      return renderPhotoPreviewPanel(
        accessoryPhoto,
        previewPhoto,
        onDelete,
        onUpdate,
      );
    })}
  </div>
);

AccessoriesPhotoList.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AccessoriesPhotoList;
