import { STATUS_PROGRESS_VIDEO } from '@lmig-latam/adil-api-common-lib/constants';
import { TakePhotoActions, TakeVideoActions } from '../../../actions';
import { CAMERA_MODE } from '../../../constants/camera';
import { deleteVideo } from '../../../api';

export class VideoCameraStrategy {
  constructor(video, videoId) {
    this.videoId = videoId;
    this.video = video;
  }

  setDispatch(dispatch) {
    this.useDispatch = dispatch;
    return this;
  }

  async start() {
    this.useDispatch(TakePhotoActions.setIncomingPhoto(this.videoId));
    this.useDispatch(TakePhotoActions.onChangeCameraMode(CAMERA_MODE.RECORD));

    if (!this.video) return;

    const { enableSection, finished, status: statusVideoProgress } = this.video;

    if (this.video?.required)
      this.useDispatch(
        TakeVideoActions.onChangeRequireVideo(this.video.required),
      );

    if (statusVideoProgress === STATUS_PROGRESS_VIDEO.INCOMPLETE) {
      await deleteVideo();
      this.useDispatch(TakeVideoActions.onFinishVideo(false));
    }

    if (enableSection !== null && enableSection !== undefined) {
      this.useDispatch(TakeVideoActions.onDisplayVideoSection(enableSection));
      this.useDispatch(TakeVideoActions.onFinishVideo(finished));
    }
  }
}
