import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { ConditionallyRequiredPhotoConfig } from './ConditionallyRequiredPhotoConfig';
import { formatPhotoConfig, getSinglePhotoConfig } from '../utils';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

const getConditionallyRequiredPhotos = (language, vehicleClass) => {
  const conditionallyVehicleConfig = new ConditionallyRequiredPhotoConfig(
    language,
    vehicleClass,
  );
  return conditionallyVehicleConfig.getConfigByVehicleClass();
};

const unformattedConditionallyRequiredPhotos = (lang, vehicleClass) => {
  let conditionallyPhotosIds = [];
  const {
    countryConditionallyRequiredPhotoIds,
    countryMotorBikeConditionallyRequiredPhotoIds,
  } = getCountryConfig(lang);

  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
    conditionallyPhotosIds = countryMotorBikeConditionallyRequiredPhotoIds;
  } else {
    conditionallyPhotosIds = countryConditionallyRequiredPhotoIds;
  }
  return conditionallyPhotosIds.map(photoId =>
    getConditionallyRequiredPhotos(lang, vehicleClass).find(
      photoConfig => photoConfig.photoId === photoId,
    ),
  );
};

export const countryHasConditionallyRequiredPhotos = lang =>
  unformattedConditionallyRequiredPhotos(lang).length > 0;

const conditionallyRequiredPhotos = (lang, vehicleClass) =>
  formatPhotoConfig(unformattedConditionallyRequiredPhotos(lang, vehicleClass));

export const getConditionallyRequiredPhotosConfig = (lang, vehicleClass) =>
  conditionallyRequiredPhotos(lang, vehicleClass);

export const getConditionallyRequiredPhoto = (
  requestedId,
  lang,
  vehicleClass,
) =>
  getSinglePhotoConfig(
    requestedId,
    conditionallyRequiredPhotos(lang, vehicleClass),
  );

export const conditionallyRequiredPhotosPhotoIdList = lang =>
  conditionallyRequiredPhotos(lang).map(({ photoId }) => photoId);

export default getConditionallyRequiredPhotos;
