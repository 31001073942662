import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Heading } from '@lmig/lmds-react';
import images from '../../../assets/images/ExampleCarImages/index';
import localizedStrings from '../../../utils/localizedStrings';

export const CameraExample = ({ idImageExample }) => {
  const [dissapearExample, setDissapearExample] = useState(true);

  const {
    settings: {
      environment: { language },
    },
    takePhoto: { cameraMode },
  } = useSelector(store => store);

  const {
    PHOTO_HUB_EXAMPLE_TITLE,
    PHOTO_HUB_EXAMPLE_TITLE_VIDEO,
    PHOTO_HUB_EXAMPLE_BUTTON,
  } = localizedStrings(language);

  const isRecordMode = cameraMode === 'RECORD';
  const isHeightBelowThreshold = window.innerHeight < 750;

  const getClassName = () => {
    if (isRecordMode) {
      return 'ExampleOverlay__imageVideo';
    }
    if (isHeightBelowThreshold) {
      return 'ExampleOverlay__image--changeHeight';
    }
    return 'ExampleOverlay__image';
  };

  return (
    dissapearExample && (
      <div className="ExampleOverlay">
        <Heading
          type="h4"
          className={
            cameraMode !== 'RECORD'
              ? 'ExampleOverlay__title'
              : 'ExampleOverlay__titleVideo'
          }
        >
          {cameraMode !== 'RECORD'
            ? PHOTO_HUB_EXAMPLE_TITLE
            : PHOTO_HUB_EXAMPLE_TITLE_VIDEO}
        </Heading>
        <img
          src={images[idImageExample]}
          alt={idImageExample}
          className={getClassName()}
        />
        <Button
          type="button"
          className="preview__upload-photo-button ExampleOverlay__button"
          onClick={() => setDissapearExample(!dissapearExample)}
        >
          {PHOTO_HUB_EXAMPLE_BUTTON}
        </Button>
      </div>
    )
  );
};

CameraExample.propTypes = {
  idImageExample: PropTypes.string.isRequired,
};
