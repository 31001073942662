import { MOTORBIKE_REQUIRED_PHOTO_IDS } from '@lmig-latam/adil-api-common-lib/constants';
import { store } from '../../utils/configureStore';
import localizedStrings from '../../utils/localizedStrings';
import requiredMotorBikeImages from '../requiredPhotosImages/MotorBike';
import { getCountryCode } from '../../utils/NavigationUtils';

const {
  settings: {
    environment: { language },
  },
} = store.getState();

const country = getCountryCode();

const getRequiredPhotosMotorBikeConfig = () => {
  const {
    PHOTO_HUB_MOTORBIKE_STEP_01_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_01_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_01_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_02_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_02_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_02_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_03_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_03_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_03_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_04_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_04_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_04_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_05_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_05_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_05_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_06_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_06_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_06_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_07_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_07_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_07_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_08_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_08_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_08_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_09_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_09_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_09_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_10_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_10_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_10_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_11_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_11_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_11_HINT,
    PHOTO_HUB_MOTORBIKE_STEP_12_TITLE,
    PHOTO_HUB_MOTORBIKE_STEP_12_SUBTITLE,
    PHOTO_HUB_MOTORBIKE_STEP_12_HINT,
  } = localizedStrings(language);

  const {
    INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
    INSPECTION_PHOTO_PROPERTY_CARD_BACK,
    INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE,
    INSPECTION_PHOTO_MOTORBIKE_FRONT,
    INSPECTION_PHOTO_MOTORBIKE_REAR,
    INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE,
    INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE,
    INSPECTION_PHOTO_MOTORBIKE_ENGINE,
    INSPECTION_PHOTO_MOTORBIKE_DASH,
    INSPECTION_PHOTO_MOTORBIKE_MILEAGE,
    INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER,
    INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER,
  } = MOTORBIKE_REQUIRED_PHOTO_IDS;

  return [
    {
      photoId: INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
      title: PHOTO_HUB_MOTORBIKE_STEP_01_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_01_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_01_HINT,
        images: requiredMotorBikeImages[country].step01,
      },
    },
    {
      photoId: INSPECTION_PHOTO_PROPERTY_CARD_BACK,
      title: PHOTO_HUB_MOTORBIKE_STEP_02_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_02_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_02_HINT,
        images: requiredMotorBikeImages[country].step02,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE,
      title: PHOTO_HUB_MOTORBIKE_STEP_03_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_03_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_03_HINT,
        images: requiredMotorBikeImages[country].step03,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_FRONT,
      title: PHOTO_HUB_MOTORBIKE_STEP_04_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_04_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_04_HINT,
        images: requiredMotorBikeImages[country].step04,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_REAR,
      title: PHOTO_HUB_MOTORBIKE_STEP_05_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_05_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_05_HINT,
        images: requiredMotorBikeImages[country].step05,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE,
      title: PHOTO_HUB_MOTORBIKE_STEP_06_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_06_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_06_HINT,
        images: requiredMotorBikeImages[country].step06,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE,
      title: PHOTO_HUB_MOTORBIKE_STEP_07_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_07_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_07_HINT,
        images: requiredMotorBikeImages[country].step07,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_ENGINE,
      title: PHOTO_HUB_MOTORBIKE_STEP_08_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_08_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_08_HINT,
        images: requiredMotorBikeImages[country].step08,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_DASH,
      title: PHOTO_HUB_MOTORBIKE_STEP_09_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_09_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_09_HINT,
        images: requiredMotorBikeImages[country].step09,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_MILEAGE,
      title: PHOTO_HUB_MOTORBIKE_STEP_10_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_10_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_10_HINT,
        images: requiredMotorBikeImages[country].step10,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER,
      title: PHOTO_HUB_MOTORBIKE_STEP_11_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_11_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_11_HINT,
        images: requiredMotorBikeImages[country].step11,
      },
    },
    {
      photoId: INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER,
      title: PHOTO_HUB_MOTORBIKE_STEP_12_TITLE,
      body: PHOTO_HUB_MOTORBIKE_STEP_12_SUBTITLE,
      hints: {
        text: PHOTO_HUB_MOTORBIKE_STEP_12_HINT,
        images: requiredMotorBikeImages[country].step12,
      },
    },
  ];
};

export default getRequiredPhotosMotorBikeConfig;
