import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Disclaimer, Header as LibertyMutualHeader } from '@lmig/lmds-react';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import logoSpanish from '../../styles/images/logo-liberty.svg';
import logoHDILibertyCo from '../../styles/images/logo-hdiLiberty-co.svg';
import localizedStrings from '../../utils/localizedStrings';
import ScroollEffectsActions from '../../actions/ScroollEffectsActions';
import { useScroll } from '../../hooks';
import './styles.scss';
import { getCountryCode } from '../../utils/NavigationUtils';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

const getSubtitle = (lang, vehicleClass) => {
  const {
    APP_HEADER_SUBTITLE,
    APP_HEADER_SUBTITLE_MOTORBIKE,
  } = localizedStrings(lang);

  let subTitle = APP_HEADER_SUBTITLE;

  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
    subTitle = APP_HEADER_SUBTITLE_MOTORBIKE;
  }

  return subTitle.toUpperCase();
};

const libertyLogo = getCountryCode() === 'co' ? logoHDILibertyCo : logoSpanish;

const getClassName =
  getCountryCode() === 'co' ? 'app-header app-header-hdi-co' : 'app-header';

const getSubtitleClassName =
  getCountryCode() === 'co'
    ? 'subtitle-container subtitle-container-hdi-co'
    : 'subtitle-container ';

const AppHeader = ({ displaySubtitle }) => {
  const {
    user: { vehicleClass },
    scroll: { displayHeader },
    settings: {
      environment: { language },
    },
  } = useSelector(store => store);

  const { scrollDirection } = useScroll();

  const dispatch = useDispatch();

  const { LIBERTY_LOGO_IMAGE_ALT_TEXT } = localizedStrings(language);

  useEffect(() => {
    dispatch(ScroollEffectsActions.onDisplayHeader(scrollDirection === 'down'));
  }, [scrollDirection, dispatch]);

  return (
    <div className="header-container">
      {displayHeader && (
        <LibertyMutualHeader className={getClassName}>
          <img
            src={libertyLogo}
            alt={LIBERTY_LOGO_IMAGE_ALT_TEXT}
            className="app-header-image"
          />
        </LibertyMutualHeader>
      )}

      {displaySubtitle && (
        <div className={getSubtitleClassName}>
          <Disclaimer className="subtitle">
            {getSubtitle(language, vehicleClass)}
          </Disclaimer>
        </div>
      )}
    </div>
  );
};

AppHeader.propTypes = {
  displaySubtitle: PropTypes.bool,
};

AppHeader.defaultProps = {
  displaySubtitle: false,
};

export default AppHeader;
