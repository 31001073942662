import { WIZARD_PHOTO_STEP } from '../../../constants/wizardPhotos';
import { getConditionallyRequiredPhotosConfig } from '../../../config/conditionallyRequiredPhotos';

export class ConditionallyPhotoHelper {
  constructor(stepsWizard) {
    this.step = null;
    this.stepsWizard = stepsWizard;
  }

  setStep(step) {
    this.step = step;
    return this;
  }

  getResult() {
    return this.stepsWizard;
  }

  isCompletedConditionallyPhotos(
    spareWheelToggleEnabled,
    language,
    vehicleClass,
  ) {
    if (this.step !== WIZARD_PHOTO_STEP.THREE || !spareWheelToggleEnabled)
      return true;

    const [photoConfig] = getConditionallyRequiredPhotosConfig(
      language,
      vehicleClass,
    );

    const { conditionalPhotos } = this.stepsWizard[WIZARD_PHOTO_STEP.THREE - 1];
    return conditionalPhotos.some(
      photo => photo.photoId === photoConfig.photoId,
    );
  }

  storePhoto(incomingPhoto) {
    const { photoId } = incomingPhoto;
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep].conditionalPhotos;

    const isPhotoExists = photosInStep.find(item => item.photoId === photoId);

    if (isPhotoExists) {
      const photoStepListUpdated = photosInStep.map(item =>
        item.photoId === photoId ? incomingPhoto : item,
      );
      this.stepsWizard[positionStep].conditionalPhotos = [
        ...photoStepListUpdated,
      ];
    } else {
      photosInStep.push(incomingPhoto);
      this.stepsWizard[positionStep].conditionalPhotos = photosInStep;
    }

    return this;
  }

  updatePhoto() {
    return this;
  }

  removePhoto(photoId) {
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep].conditionalPhotos;
    const newConditionallyArray = photosInStep.filter(
      photo => photo.photoId !== photoId,
    );
    this.stepsWizard[positionStep].conditionalPhotos = newConditionallyArray;
    return this;
  }
}
