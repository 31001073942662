import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Panel';
import { PhotoHeaderCard } from '../../WizardPhotos';
import './styles.scss';

export const DefaultVideoPanel = ({
  disabled,
  title,
  body,
  IconComponent,
  errorStyles,
}) => (
  <Panel disabled={disabled} className="default-photo-panel">
    <PhotoHeaderCard
      title={title}
      description={body}
      IconComponent={IconComponent}
      isError={errorStyles}
    />
  </Panel>
);

DefaultVideoPanel.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.node.isRequired,
  errorStyles: PropTypes.bool.isRequired,
};

DefaultVideoPanel.defaultProps = {
  title: '',
  body: '',
  disabled: false,
};
