import React from 'react';

export default function CircleVehicleDocumentIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97">
      <g id="Grupo_5094" data-name="Grupo 5094" transform="translate(4.5 -0.5)">
        <g id="Grupo_4567" data-name="Grupo 4567" transform="translate(0.5)">
          <circle
            id="Oval"
            cx="48"
            cy="48"
            r="48"
            transform="translate(-4.5 1)"
            fill="#f0fbfc"
            stroke="#99e5ea"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(18 20.554)">
          <g
            id="Combined_Shape"
            data-name="Combined Shape"
            transform="translate(0 11.479)"
          >
            <path
              id="Combined_Shape-2"
              data-name="Combined Shape"
              d="M4.621,33.932A4.626,4.626,0,0,1,0,29.311V3.9A3.9,3.9,0,0,1,3.9,0H48.1A3.9,3.9,0,0,1,52,3.9V28.721H49.991V3.9A1.891,1.891,0,0,0,48.1,2.009H3.9A1.891,1.891,0,0,0,2.009,3.9V29.311a2.615,2.615,0,0,0,2.611,2.612l47.135,0a3.094,3.094,0,0,1-2.93,2.012ZM25.15,27.116a1.742,1.742,0,0,1-1.86-1.563V23.2H12.49v2.355a1.6,1.6,0,0,1-1.66,1.563H8.085a1.639,1.639,0,0,1-1.736-1.563V17.425a2.507,2.507,0,0,1,.889-1.857l1.509-1.31L10.185,9.74a2.8,2.8,0,0,1,2.592-1.988H23.034a2.808,2.808,0,0,1,2.549,1.9L27.2,14.209l1.458,1.348a2.431,2.431,0,0,1,.772,1.868v8.127a1.5,1.5,0,0,1-1.537,1.563ZM8.56,17.08a.553.553,0,0,0-.2.345v7.682H10.48V23.2h-.012V21.187H25.315V23.2H25.3v1.892l2.124.015V17.425a.541.541,0,0,0-.109-.377l-1.178-1.09H9.851ZM12.1,10.353l-1.145,3.6H24.977l-1.284-3.623a.828.828,0,0,0-.658-.567H12.776A.838.838,0,0,0,12.1,10.353Zm21.676,12.3V20.644H46.217v2.009Zm0-7.08V13.563H46.217v2.009Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="Combined_Shape-3"
              data-name="Combined Shape"
              d="M4.621,33.932A4.626,4.626,0,0,1,0,29.311V3.9A3.9,3.9,0,0,1,3.9,0H48.1A3.9,3.9,0,0,1,52,3.9V28.721H49.991V3.9A1.891,1.891,0,0,0,48.1,2.009H3.9A1.891,1.891,0,0,0,2.009,3.9V29.311a2.615,2.615,0,0,0,2.611,2.612l47.135,0a3.094,3.094,0,0,1-2.93,2.012ZM25.15,27.116a1.742,1.742,0,0,1-1.86-1.563V23.2H12.49v2.355a1.6,1.6,0,0,1-1.66,1.563H8.085a1.639,1.639,0,0,1-1.736-1.563V17.425a2.507,2.507,0,0,1,.889-1.857l1.509-1.31L10.185,9.74a2.8,2.8,0,0,1,2.592-1.988H23.034a2.808,2.808,0,0,1,2.549,1.9L27.2,14.209l1.458,1.348a2.431,2.431,0,0,1,.772,1.868v8.127a1.5,1.5,0,0,1-1.537,1.563ZM8.56,17.08a.553.553,0,0,0-.2.345v7.682H10.48V23.2h-.012V21.187H25.315V23.2H25.3v1.892l2.124.015V17.425a.541.541,0,0,0-.109-.377l-1.178-1.09H9.851ZM12.1,10.353l-1.145,3.6H24.977l-1.284-3.623a.828.828,0,0,0-.658-.567H12.776A.838.838,0,0,0,12.1,10.353Zm21.676,12.3V20.644H46.217v2.009Zm0-7.08V13.563H46.217v2.009Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="Combined_Shape-4"
              data-name="Combined Shape"
              d="M4.621,33.932A4.626,4.626,0,0,1,0,29.311V3.9A3.9,3.9,0,0,1,3.9,0H48.1A3.9,3.9,0,0,1,52,3.9V28.721H49.991V3.9A1.891,1.891,0,0,0,48.1,2.009H3.9A1.891,1.891,0,0,0,2.009,3.9V29.311a2.615,2.615,0,0,0,2.611,2.612l47.135,0a3.094,3.094,0,0,1-2.93,2.012ZM25.15,27.116a1.742,1.742,0,0,1-1.86-1.563V23.2H12.49v2.355a1.6,1.6,0,0,1-1.66,1.563H8.085a1.639,1.639,0,0,1-1.736-1.563V17.425a2.507,2.507,0,0,1,.889-1.857l1.509-1.31L10.185,9.74a2.8,2.8,0,0,1,2.592-1.988H23.034a2.808,2.808,0,0,1,2.549,1.9L27.2,14.209l1.458,1.348a2.431,2.431,0,0,1,.772,1.868v8.127a1.5,1.5,0,0,1-1.537,1.563ZM8.56,17.08a.553.553,0,0,0-.2.345v7.682H10.48V23.2h-.012V21.187H25.315V23.2H25.3v1.892l2.124.015V17.425a.541.541,0,0,0-.109-.377l-1.178-1.09H9.851ZM12.1,10.353l-1.145,3.6H24.977l-1.284-3.623a.828.828,0,0,0-.658-.567H12.776A.838.838,0,0,0,12.1,10.353Zm21.676,12.3V20.644H46.217v2.009Zm0-7.08V13.563H46.217v2.009Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="Combined_Shape-5"
              data-name="Combined Shape"
              d="M4.621,33.932A4.626,4.626,0,0,1,0,29.311V3.9A3.9,3.9,0,0,1,3.9,0H48.1A3.9,3.9,0,0,1,52,3.9V28.721H49.991V3.9A1.891,1.891,0,0,0,48.1,2.009H3.9A1.891,1.891,0,0,0,2.009,3.9V29.311a2.615,2.615,0,0,0,2.611,2.612l47.135,0a3.094,3.094,0,0,1-2.93,2.012ZM25.15,27.116a1.742,1.742,0,0,1-1.86-1.563V23.2H12.49v2.355a1.6,1.6,0,0,1-1.66,1.563H8.085a1.639,1.639,0,0,1-1.736-1.563V17.425a2.507,2.507,0,0,1,.889-1.857l1.509-1.31L10.185,9.74a2.8,2.8,0,0,1,2.592-1.988H23.034a2.808,2.808,0,0,1,2.549,1.9L27.2,14.209l1.458,1.348a2.431,2.431,0,0,1,.772,1.868v8.127a1.5,1.5,0,0,1-1.537,1.563ZM8.56,17.08a.553.553,0,0,0-.2.345v7.682H10.48V23.2h-.012V21.187H25.315V23.2H25.3v1.892l2.124.015V17.425a.541.541,0,0,0-.109-.377l-1.178-1.09H9.851ZM12.1,10.353l-1.145,3.6H24.977l-1.284-3.623a.828.828,0,0,0-.658-.567H12.776A.838.838,0,0,0,12.1,10.353Zm21.676,12.3V20.644H46.217v2.009Zm0-7.08V13.563H46.217v2.009Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="Combined_Shape-6"
              data-name="Combined Shape"
              d="M4.621,33.932A4.626,4.626,0,0,1,0,29.311V3.9A3.9,3.9,0,0,1,3.9,0H48.1A3.9,3.9,0,0,1,52,3.9V28.721H49.991V3.9A1.891,1.891,0,0,0,48.1,2.009H3.9A1.891,1.891,0,0,0,2.009,3.9V29.311a2.615,2.615,0,0,0,2.611,2.612l47.135,0a3.094,3.094,0,0,1-2.93,2.012ZM25.15,27.116a1.742,1.742,0,0,1-1.86-1.563V23.2H12.49v2.355a1.6,1.6,0,0,1-1.66,1.563H8.085a1.639,1.639,0,0,1-1.736-1.563V17.425a2.507,2.507,0,0,1,.889-1.857l1.509-1.31L10.185,9.74a2.8,2.8,0,0,1,2.592-1.988H23.034a2.808,2.808,0,0,1,2.549,1.9L27.2,14.209l1.458,1.348a2.431,2.431,0,0,1,.772,1.868v8.127a1.5,1.5,0,0,1-1.537,1.563ZM8.56,17.08a.553.553,0,0,0-.2.345v7.682H10.48V23.2h-.012V21.187H25.315V23.2H25.3v1.892l2.124.015V17.425a.541.541,0,0,0-.109-.377l-1.178-1.09H9.851ZM12.1,10.353l-1.145,3.6H24.977l-1.284-3.623a.828.828,0,0,0-.658-.567H12.776A.838.838,0,0,0,12.1,10.353Zm21.676,12.3V20.644H46.217v2.009Zm0-7.08V13.563H46.217v2.009Z"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="Combined_Shape-7"
              data-name="Combined Shape"
              d="M4.621,33.932A4.626,4.626,0,0,1,0,29.311V3.9A3.9,3.9,0,0,1,3.9,0H48.1A3.9,3.9,0,0,1,52,3.9V28.721H49.991V3.9A1.891,1.891,0,0,0,48.1,2.009H3.9A1.891,1.891,0,0,0,2.009,3.9V29.311a2.615,2.615,0,0,0,2.611,2.612l47.135,0a3.094,3.094,0,0,1-2.93,2.012ZM25.15,27.116a1.742,1.742,0,0,1-1.86-1.563V23.2H12.49v2.355a1.6,1.6,0,0,1-1.66,1.563H8.085a1.639,1.639,0,0,1-1.736-1.563V17.425a2.507,2.507,0,0,1,.889-1.857l1.509-1.31L10.185,9.74a2.8,2.8,0,0,1,2.592-1.988H23.034a2.808,2.808,0,0,1,2.549,1.9L27.2,14.209l1.458,1.348a2.431,2.431,0,0,1,.772,1.868v8.127a1.5,1.5,0,0,1-1.537,1.563ZM8.56,17.08a.553.553,0,0,0-.2.345v7.682H10.48V23.2h-.012V21.187H25.315V23.2H25.3v1.892l2.124.015V17.425a.541.541,0,0,0-.109-.377l-1.178-1.09H9.851ZM12.1,10.353l-1.145,3.6H24.977l-1.284-3.623a.828.828,0,0,0-.658-.567H12.776A.838.838,0,0,0,12.1,10.353Zm21.676,12.3V20.644H46.217v2.009Zm0-7.08V13.563H46.217v2.009Z"
              transform="translate(0 0)"
              fill="#1a1446"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
