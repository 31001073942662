import constants from '@lmig-latam/adil-api-common-lib/constants/constants';

const {
  LANGUAGES: { VN },
} = constants;

const code = VN;

const strings = {
  BROWSER_TAB_TITLE: 'Giám định kỹ thuật số - Bảo hiểm Liberty',
  APP_HEADER_SUBTITLE: 'Giám định kỹ thuật số Liberty',
  APP_HEADER_SUBTITLE_MOTORBIKE: 'Kiểm tra kỹ thuật số Liberty Motos',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Bảo hiểm Liberty',
  LIBERTY_SECONDARY_LOGO_IMAGE: '',
  LIBERTY_SECONDARY_LOGO_IMAGE_ALT_TEXT: '',
  VIGILADO_IMAGE_ALT_TEXT: '',

  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: 'một lỗi đã xảy ra',
  ADMIN_SUPPORT_ERROR_GENERAL: 'Vui lòng thử lại hành động',

  MAIN_LOADER_TITLE: '',
  MAIN_LOADER_SUBTITLE: '',

  LOADING_TEXT: 'Đang tải',
  INLINE_LOADING_TEXT: '...Đang tải',
  LOADING_TEXT_DETECTING_BROWSER: 'Đang phát hiện trình duyệt và hệ điều hành...',

  FLOAT_MENU_ITEM_1: 'Câu hỏi thường gặp',
  FLOAT_MENU_ITEM_2: 'Chuyển sang tiếng Anh',
  FLOAT_MENU_ITEM_3: 'Nhà',

  APP_FOOTER_NEED_HELP_LINE_1: 'Bạn có cần hỗ trợ giám định kỹ thuật số?',
  APP_FOOTER_NEED_HELP_LINE_2: '',
  APP_FOOTER_TERMS_CONDITIONS_LINK: 'Điều khoản và điều kiện',
  APP_FOOTER_PRIVACY_POLICY_LINK: 'Chính sách quyền riêng tư',
  APP_FOOTER_COPYRIGHT: 'Liberty Insurance {year}',

  ERROR_MESSAGE_TITLE: 'Xin lỗi, quy trình đang  chuẩn bị hoặc không hoạt động chính xác',
  NON_BROWSER_ERROR_MESSAGE_TITLE: 'Chúng tôi đã phát hiện ra rằng bạn đang sử dụng trình duyệt không tương thích với ứng dụng',
  ERROR_MESSAGE_SUBTITLE: 'Nếu cần trợ giúp, bạn có thể liên hệ với chúng tôi qua tổng đài dịch vụ',
  NON_BROWSER_ERROR_MESSAGE_SUBTITLE: 'Chúng tôi khuyên bạn nên sao chép liên kết này bằng một trong các trình duyệt sau:',

  NON_MOBILE_ERROR_HEADER_TITLE: 'Giám định Hình ảnh Xe ô tô',
  NON_MOBILE_ERROR_MESSAGE_TITLE: 'Chúng tôi cần bạn chụp ảnh bằng điện thoại',
  NON_MOBILE_ERROR_MESSAGE_SUBTITLE: 'Thử tiến hành quy trình và chụp ảnh bằng điện thoại của bạn',
  NON_MOBILE_ERROR_PRIMARY_MESSAGE: 'Để biết thêm thông tin, vui lòng gọi số này ',
  NON_MOBILE_ERROR_PRIMARY_PHONE_NUMBER: '028 381 25103',
  NON_MOBILE_ERROR_OTHER_COUNTRY_MESSAGE: '',
  NON_MOBILE_ERROR_OTHER_COUNTRY_PHONE_NUMBER: '',
  BUTTON_CLIPBOARD: 'Sao chép',
  TEXT_COPIED: 'Đã sao chép',

  PAGE_NOT_FOUND_ERROR_MESSAGE: 'Lỗi 404: Không tìm thấy trang!',

  GPS_PERMISSION_DENIED: '',
  GPS_PERMISSION_ENABLED: '',
  GPS_ERROR_NOT_SUPPORT: '',
  GPS_POSITION_ERROR_UNAVAILABLE: '',
  GPS_POSITION_ERROR_TIMEOUT: '',

  MODAL_PRIMARY_BUTTON_LABEL: 'Tiếp tục',
  MODAL_LAST_BUTTON_LABEL: 'Để đóng',

  PHONE_NUMBER_CONFIRMATION_TITLE: 'Để bắt đầu quy trình, chúng tôi cần một số thông tin xác thực từ bạn',
  PHONE_NUMBER_CONFIRMATION_SUBTITLE: 'Chúng tôi đã gửi mã xác thực đến số di động của bạn',
  PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT: 'Điện thoại di động và tin nhắn',
  PHONE_NUMBER_CONFIRMATION_INPUT_PLACEHOLDER: 'Nhập mã tại đây',
  PHONE_NUMBER_CONFIRMATION_INPUT_VALIDATION_MESSAGE: 'Vui lòng điền mã tin nhắn gồm 6 chữ số',
  PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE: 'Mã xác thực đã điền không tương thích, xin thử lại',
  PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED: 'Tôi vẫn chưa nhận được',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE: 'Gửi lại',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS: 'Đã gửi',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE: 'Xin lỗi bạn',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE: 'Đã xảy ra sự cố, mã của bạn có thể không gửi được, vui lòng thử lại',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE_TWO: '',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL: 'Đóng',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE: 'Bạn đã sử dụng hết số lượt thử tối đa được phép',
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE: 'Vui lòng thử lại vào 10 phút sau',
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL: 'Gửi lại',
  PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON: 'Tiếp tục',
  PHONE_NUMBER_CONFIRMATION_LOADING_TEXT: 'Vui lòng chờ…',

  TERMS_CONDITIONS_TITLE: 'Chào mừng',
  TERMS_CONDITIONS_SUBTITLE: 'Xin đừng quên kiểm tra các điều khoản sử dụng để tiếp tục',
  TERMS_CONDITIONS_SUBTITLE_2: 'We welcome you to the digital inspection process for your vehicle',
  TERMS_CONDITIONS_MOTORBIKE_SUBTITLE_2: 'Te damos la bienvenida al proceso de inspección digital para tu moto',
  TERMS_CONDITIONS_SHOW_MODAL_BUTTON: 'Điều khoản và điều kiện',
  TERMS_CONDITIONS_DISCLAIMER: 'Bằng cách nhấn chọn bắt đầu, chúng tôi hiểu rằng bạn đã đồng ý với các điều khoản sử dụng',
  TERMS_CONDITIONS_DISCLAIMER_2: 'Before starting you must read and accept the ',
  TERMS_CONDITIONS_CONTINUE_BUTTON: 'Bắt đầu',
  TERMS_CONDITIONS_LOADING_TEXT: 'Vui lòng chờ…',

  HELP_SUPPORT_BUTTON: 'Yêu cầu hỗ trợ',

  TERMS_CONDITIONS_MODAL_CONTENT: {
    VN: [
      {
        TERMS_CONDITIONS_MODAL_CONTENT_TITLE: 'Điều khoản và điều kiện',
        TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP:
          'Kính chào Quý khách,\n\n Công ty TNHH Bảo Hiểm Liberty (“Liberty”) hân hạnh chào đón Quý khách tham gia ứng dụng Thẩm Định Kỹ Thuật Số Xe Ô Tô Của Liberty (ADIL) cho việc thẩm định xe của Quý khách. Quý khách vui lòng đọc kỹ các điều kiện sử dụng và chính sách bảo mật dưới đây của ứng dụng này, vì khi đã tham gia sử dụng ứng dụng, Quý khách được xem là đã chấp thuận rõ ràng các điều khoản và điều kiện của ứng dụng được nêu dưới đây. Trường hợp Quý khách không muốn chấp thuận các điều khoản và điều kiện được nêu, Quý khách cần phải ngừng sử dụng nền tảng/ ứng dụng này ngay lập tức.\n\nADIL là ứng dụng cho phép công ty biết tình trạng hiện tại của xe thông qua các thông tin và hình ảnh Quý khách cung cấp. Mọi thông tin và hình ảnh do Quý khách cung cấp đều là cơ sở cho hợp đồng bảo hiểm giữa Quý khách và Liberty. Bất kỳ thông tin và/hoặc hình ảnh nào không chính xác hoặc không được cung cấp đầy đủ đều có thể khiến hợp đồng bảo hiểm trở nên vô hiệu và (các) yêu cầu bồi thường bảo hiểm của Quý khách sẽ không được Liberty thanh toán.\n\nThông tin cá nhân của Quý khách cung cấp thông qua ứng dụng sẽ được bảo mật. Quý khách vui lòng đọc kỹ chính sách bảo mật của chúng tôi tại\n <p style="overflow: auto;">http://www.libertyinsurance.com.vn/en/internet-privacy-policy.</p> \nBằng cách ấn chọn nút bên dưới, Quý khách đãhiểu rõ, và đồng ý với các điều kiện được nêu trên.',
      },
    ],
  },
  TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL: 'Tiếp tục',

  WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE: '',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE: '',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE: '',
  WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE: '',

  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE: '',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE: '',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE: '',
  WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE: '',
  WIZARD_PHOTO_HUB_FINISH_TITLE: '',

  PHOTO_HUB_TITLE: 'Hình ảnh',
  PHOTO_HUB_SUBTITLE: 'Tiến hành chụp ảnh và vui lòng lưu ý các chỉ dẫn cho từng bức ảnh',
  PHOTO_HUB_HINTS_TOGGLE_ENABLED_LABEL: 'Tắt chỉ dẫn',
  PHOTO_HUB_HINTS_TOGGLE_DISABLED_LABEL: 'Bật chỉ dẫn',
  PHOTO_HUB_FINISH_PROGRESS: '… đang kết thúc',
  PHOTO_HUB_LOAD_TEST_IMAGES_PROGRESS: 'Đang nén & tải ảnh mẫu…',
  PHOTO_HUB_FOOTER_PROGRESS: '{photosTaken} trong số {photosRequired} ảnh đã chụp',
  PHOTO_HUB_FOOTER_SUCCESS_VIDEO: 'Video đã hoàn thành',
  PHOTO_HUB_FOOTER_PENDING_VIDEO: 'Video không được thực hiện',
  PHOTO_HUB_CONTINUE_BUTTON: 'Hoàn tất quy trình',
  PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT: 'Xem trước',
  PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT: 'Chụp lại ảnh',
  PHOTO_HUB_SUBMIT_LOADER_TEXT: 'Đang gửi ảnh',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE: 'Xin lỗi bạn',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE: 'Đã xảy ra sự cố, mã của bạn có thể không gửi được, vui lòng thử lại',
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL: 'Thử lại',
  PHOTO_HUB_LOAD_TEST_IMAGES_BUTTON_LABEL: 'Tải Ảnh Mẫu',
  PHOTO_HUB_SHOW_CAMERA_BUTTON_LABE: 'Xem máy ảnh',

  PHOTO_HUB_STEP_01_TITLE: 'Mặt trước Giấy chứng nhận đăng ký xe',
  PHOTO_HUB_STEP_01_SUBTITLE: 'Giấy chứng nhận đăng ký xe bạn muốn giám định',
  PHOTO_HUB_STEP_01_HINT: 'Chụp ảnh mặt trước của giấy phép lái xe',

  PHOTO_HUB_STEP_02_TITLE: 'Mặt sau Giấy chứng nhận đăng ký xe',
  PHOTO_HUB_STEP_02_SUBTITLE: 'Giấy chứng nhận đăng ký xe bạn muốn giám định',
  PHOTO_HUB_STEP_02_HINT: 'Chụp ảnh mặt sau của giấy phép lái xe',

  PHOTO_HUB_STEP_03_TITLE: 'Biển số xe',
  PHOTO_HUB_STEP_03_SUBTITLE: 'Biển số của xe bạn muốn giám định',
  PHOTO_HUB_STEP_03_HINT: 'Hãy khuỵu thấp đầu gối của bạn và đặt điện thoại của bạn ngay phía trước tấm biển số xe.',

  PHOTO_HUB_STEP_04_TITLE: 'Hình chụp trước',
  PHOTO_HUB_STEP_04_SUBTITLE: 'Vị trí bao quát phía trước gần đầu xe',
  PHOTO_HUB_STEP_04_HINT: 'Hãy chọn một vị trí đứng bao quát phía trước xe để chụp ảnh.',

  PHOTO_HUB_STEP_05_TITLE: 'Hình chụp sau',
  PHOTO_HUB_STEP_05_SUBTITLE: 'Vị trí bao quát phía sau gần đuôi xe ',
  PHOTO_HUB_STEP_05_HINT: 'Hãy chọn một vị trí đứng bao quát phía sau xe để chụp ảnh.',

  PHOTO_HUB_STEP_06_TITLE: 'Hình chụp bên phải',
  PHOTO_HUB_STEP_06_SUBTITLE: 'Vị trí bao quát giữa xe bên phải',
  PHOTO_HUB_STEP_06_HINT: 'Hãy chọn một vị trí đứng bao quát phía bên phải xe để chụp ảnh (phía ghế phụ)',

  PHOTO_HUB_STEP_07_TITLE: 'Hình chụp bên trái',
  PHOTO_HUB_STEP_07_SUBTITLE: 'Vị trí bao quát giữa xe bên trái',
  PHOTO_HUB_STEP_07_HINT: 'Hãy chọn một vị trí đứng bao quát phía bên trái xe để chụp ảnh (phía tài xế)',

  PHOTO_HUB_STEP_08_TITLE: 'Khoang động cơ',
  PHOTO_HUB_STEP_08_SUBTITLE: 'Cũng chính là không gian nơi đặt động cơ xe.',
  PHOTO_HUB_STEP_08_HINT: 'Giữ điện thoại của bạn ngang đầu và nghiêng nhẹ điện thoại xuống.',

  PHOTO_HUB_STEP_09_TITLE: 'Đồng hồ đo số km hoặc bảng điều khiển',
  PHOTO_HUB_STEP_09_SUBTITLE: 'Đó là bảng điều khiển nơi bạn có thể kiểm soát quãng đường đã đi, mức nhiên liệu và nhiệt độ của xe.',
  PHOTO_HUB_STEP_09_HINT: 'Hãy đứng ở phí trước xe và chụp toàn cảnh kính chắn gió',

  PHOTO_HUB_STEP_10_TITLE: 'Odometer',
  PHOTO_HUB_STEP_10_SUBTITLE: 'Số km xe đã đi, được ghi lại từ bảng điều khiển.',
  PHOTO_HUB_STEP_10_HINT: 'Bật đèn bảng điều khiển để bạn có thể chụp rõ hơn quãng đường đi.',
  PHOTO_HUB_STEP_10_HINT_BEFORE_TAKE_PHOTO: 'Khởi động động cơ một cách thận trọng \nkiểm tra xem bạn không có thiết bị trong thiết bị',
  PHOTO_HUB_STEP_10_HINT_BEFORE_TAKE_PHOTO_BUTTON: 'Thông minh! Tôi đã khởi động động cơ',

  PHOTO_HUB_STEP_11_TITLE: 'Nhãn dán hoặc bảng mã nhận diện xe',
  PHOTO_HUB_STEP_11_SUBTITLE: 'Còn được gọi là VIN, là dãy số nhận dạng của xe.',
  PHOTO_HUB_STEP_11_HINT: 'Bạn có thể tìm thấy ở vị trí trung tâm bên trái hoặc bên phải, trên tấm che bụi kim loại bên trái hoặc trên tấm vách ngăn.',
  PHOTO_HUB_STEP_11_HINT_2: 'If you cant find the VIN, you can take a photo of the serial number near the drivers door..',

  PHOTO_HUB_STEP_12_TITLE: 'Hình chụp số khung xe',
  PHOTO_HUB_STEP_12_SUBTITLE: 'Số khung là dãy số nhằm nhận diện xe. Bạn tìm và chụp lại nó ở một trong các vị trí như sau: lườn cửa trước, dưới ghế trước phía ghế phụ, trên kính chắn gió phía tài xế, trong khoan động cơ, trên khung ở bánh xe trước hoặc sau… ',
  PHOTO_HUB_STEP_12_HINT: 'Số khung là dãy số nhằm nhận diện xe. Bạn tìm và chụp lại nó ở một trong các vị trí như sau: lườn cửa trước, dưới ghế trước phía ghế phụ, trên kính chắn gió phía tài xế, trong khoan động cơ, trên khung ở bánh xe trước hoặc sau… ',

  PHOTO_HUB_STEP_13_TITLE: 'Hình chụp kính chắn gió trước',
  PHOTO_HUB_STEP_13_SUBTITLE: 'Bạn chọn vị trí giữa và trước đầu xe, chụp gần kính chắn gió',
  PHOTO_HUB_STEP_13_HINT: 'Hãy chọn một vị trí đứng bao quát phía trước xe để chụp toàn bộ kính chắn gió',

  PHOTO_HUB_STEP_14_TITLE: 'Hình chụp đèn pha trái',
  PHOTO_HUB_STEP_14_SUBTITLE: 'Góc nghiêng gần đèn pha tài',
  PHOTO_HUB_STEP_14_HINT: 'Hãy chụp toàn bộ đèn pha bên trái',

  PHOTO_HUB_STEP_15_TITLE: 'Hình chụp đèn pha phải',
  PHOTO_HUB_STEP_15_SUBTITLE: 'Góc nghiêng gần đèn pha phụ',
  PHOTO_HUB_STEP_15_HINT: 'Hãy chụp toàn bộ đèn pha bên phải',

  PHOTO_HUB_STEP_16_TITLE: 'Hình chụp đăng kiểm xe',
  PHOTO_HUB_STEP_16_SUBTITLE: 'chụp rõ thông tin, vừa khung hình',
  PHOTO_HUB_STEP_16_HINT: 'Hãy chụp mặt sau giấy chứng nhận đăng kiểm xe ( Trang chứa thông tin chi tiết xe)',

  PHOTO_HUB_STEP_17_TITLE: 'Hình chụp đăng ký xe',
  PHOTO_HUB_STEP_17_SUBTITLE: 'chụp rõ thông tin, vừa khung hình',
  PHOTO_HUB_STEP_17_HINT: 'Hãy chụp mặt sau giấy chứng nhận đăng ký xe ( Trang chứa thông tin chi tiết xe)',

  PHOTO_HUB_STEP_18_TITLE: 'Hình chụp góc trước trái',
  PHOTO_HUB_STEP_18_SUBTITLE: 'góc nghiêng 45°',
  PHOTO_HUB_STEP_18_HINT: 'Hãy chọn một vị trí đứng ở góc nghiêng 45 độ phía trước để chụp ảnh ( phía tài xế)',

  PHOTO_HUB_STEP_19_TITLE: 'Hình chụp góc trước phải',
  PHOTO_HUB_STEP_19_SUBTITLE: 'góc nghiêng 45°',
  PHOTO_HUB_STEP_19_HINT: 'Hãy chọn một vị trí đứng ở góc nghiêng 45 độ phía trước để chụp ảnh ( phía ghế phụ)',

  PHOTO_HUB_STEP_20_TITLE: 'Hình chụp góc sau phải',
  PHOTO_HUB_STEP_20_SUBTITLE: 'góc nghiêng 45°',
  PHOTO_HUB_STEP_20_HINT: 'Hãy chọn một vị trí đứng ở góc nghiêng 45 độ phía sau để chụp ảnh ( phía ghế phụ)',

  PHOTO_HUB_STEP_21_TITLE: 'Hình chụp góc sau trái',
  PHOTO_HUB_STEP_21_SUBTITLE: 'góc nghiêng 45°',
  PHOTO_HUB_STEP_21_HINT: 'Hãy chọn một vị trí đứng ở góc nghiêng 45 độ phía sau để chụp ảnh ( phía tài xế)',

  PHOTO_HUB_STEP_22_TITLE: 'Hình chụp đồng hồ táp lô',
  PHOTO_HUB_STEP_22_SUBTITLE: 'Nổ máy xe 30 giây mới chụp lại, có số km tổng',
  PHOTO_HUB_STEP_22_HINT: 'Bạn nổ máy xe và đợi cho các đèn báo lỗi kịp tắt hết (tầm 30 giây) rồi chụp lại đồng hồ táp lô lúc xe đang nổ máy',

  PHOTO_HUB_STEP_23_TITLE: 'Chứng minh thư / Hộ chiếu',
  PHOTO_HUB_STEP_23_SUBTITLE: 'CMND / Hộ chiếu hợp lệ.',
  PHOTO_HUB_STEP_23_HINT: 'Vui lòng chỉ chụp ảnh mặt trước của CMND hoặc trang thông tin của hộ chiếu',

  PHOTO_HUB_STEP_24_TITLE: 'Giấy phép lái xe/IDP cho người lái xe được đặt tên đầu tiên',
  PHOTO_HUB_STEP_24_SUBTITLE: 'Giấy phép lái xe hợp lệ.',
  PHOTO_HUB_STEP_24_HINT: 'Vui lòng chỉ chụp ảnh mặt trước của Giấy phép lái xe.',

  PHOTO_HUB_STEP_25_TITLE: 'Giấy phép lái xe/ IDP cho người lái xe có tên thứ 2',
  PHOTO_HUB_STEP_25_SUBTITLE: 'Giấy phép lái xe hợp lệ.',
  PHOTO_HUB_STEP_25_HINT: 'Vui lòng chỉ chụp ảnh mặt trước của Giấy phép lái xe.',

  PHOTO_HUB_VIDEO_HINTS_TITLE: '',
  PHOTO_HUB_VIDEO_HINTS_DESCRIPTION: '',
  PHOTO_HUB_VIDEO_HINTS_WARNING: '',
  PHOTO_HUB_VIDEO_HINTS_PRIMARY_BUTTON: '',
  PHOTO_HUB_VIDEO_HINTS_MINUTES: '',
  PHOTO_HUB_VIDEO_HINTS_MINUTE: '',

  PHOTO_HUB_EXAMPLE_TITLE: 'VÍ DỤ',
  PHOTO_HUB_EXAMPLE_TITLE_VIDEO: 'VỊ TRÍ MÁY ẢNH NHƯ THẾ NÀY:',
  PHOTO_HUB_EXAMPLE_BUTTON: 'Chấp nhận',

  PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON: 'Có, tiếp tục',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON: 'Không, chụp thêm ảnh',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE: 'Bạn có muốn kết thúc quy trình?',
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE: 'Nếu tiếp tục sẽ kết thúc quy trình',

  PHOTO_HUB_PHOTOS_TAKEN_MODAL_PRIMARY_BUTTON: 'Có, gửi ảnh',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SECONDARY_BUTTON: 'Không, tôi muốn bổ sung phụ kiện',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_TITLE: 'Chúc mừng! Bạn đã tải xong các ảnh được yêu cầu',
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SUBTITLE: 'Bạn có muốn kết thúc quy trình giám định?',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_CONTINUE: 'Continue',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_BACK: 'Back',
  PHOTO_HUB_LOADING_PHOTO_LABEL: '',
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_FINISH: '',

  PERMISSION_CAMERA_MODAL_INFO_TITLE: 'quyền đối với máy ảnh',
  PERMISSION_CAMERA_MODAL_INFO_DESCRIPTION: 'Trước khi bắt đầu! Nếu bạn không thể chụp ảnh chính xác, hãy nhớ rằng bạn phải kích hoạt quyền máy ảnh trong trình duyệt.',
  PERMISSION_CAMERA_MODAL_INFO_PRIMARY_BUTTON: 'Nó được hiểu',

  PHOTO_HUB_VIDEO_SECTION_PANEL_TITLE: 'วิดีโอรถ 360°',
  PHOTO_HUB_VIDEO_SECTION_PANEL_SUBTITLE: 'ทำการบันทึกภาพรถแบบ 360°',
  PHOTO_HUB_VIDEO_SECTION_PANEL_HINT: 'ทำการบันทึกภาพรถแบบ 360° โดยเริ่มจากป้ายทะเบียนและเลี้ยวจากด้านซ้ายไปด้านขวา จนกระทั่งกลับมาที่ป้ายทะเบียนรถ',
  PHOTO_HUB_VIDEO_SECTION_PANEL_HINT_NOTE: 'Lưu ý: Sẽ không thể tạm dừng ghi',

  PHOTO_HUB_ACCESSORIES_SECTION_TITLE: 'Phụ kiện',
  PHOTO_HUB_ACCESSORIES_SECTION_PANEL_TITLE: 'Thêm phụ kiện',
  PHOTO_HUB_ACCESSORIES_SECTION_PANEL_DESCRIPTION: '',
  PHOTO_HUB_ACCESSORIES_SECTION_PREVIEW_DESCRIPTION: '',
  PHOTO_HUB_ACCESSORIES_SECTION_MODAL_TITLE: 'Chọn phụ kiện bạn muốn thêm ảnh, Kiểm tra kỹ các linh kiện của sản phẩm',
  PHOTO_HUB_ACCESSORIES_SECTION_MODAL_PRIMARY_BUTTON_LABEL: 'Tiếp tục',

  PHOTO_HUB_DAMAGE_SECTION_TITLE: 'thiệt hại từ trước',
  PHOTO_HUB_DAMAGE_SECTION_PANEL_TITLE: 'Khai báo những thiệt hại đã có từ trước',
  PHOTO_HUB_DAMAGE_SECTION_PANEL_DESCRIPTION: '',
  PHOTO_HUB_DAMAGE_SECTION_PREVIEW_DESCRIPTION: '',
  PHOTO_HUB_DAMAGE_SECTION_PREVIEW_TITLE: 'Hư hại',

  PHOTO_HUB_DELETE_TITLE: '',
  PHOTO_HUB_DELETE_SUBTITLE: '',
  PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL: 'Xóa',
  PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL: 'Hủy',
  PHOTO_HUB_DELETE_IMAGE_ALT_TEXT: 'Xóa ảnh',
  PHOTO_HUB_DELETE_CONFIRMATION_TITLE: '',
  PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT: '',
  PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL: '',

  PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE: 'Bánh xe dự phòng',
  PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_QUESTION: 'Xe có bánh xe dự phòng không?',
  PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE: 'Hãy chắc chắn chụp toàn cảnh bánh xe dự phòng',
  PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT: 'Bức ảnh nên được chụp với độ sáng đầy đủ để xác định trạng thái của bánh xe',
  PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_ON: '',
  PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_OFF: '',

  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_TITLE: '',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_QUESTION: '',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_SUBTITLE: '',
  PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_HINT: '',

  PHOTO_HUB_INFORMATION_IMAGE_ALT_TEXT: 'Information',

  TAKE_PHOTO_CONFIRM_PHOTO_BUTTON_LABEL: 'Xác nhận',
  TAKE_PHOTO_RETAKE_PHOTO_BUTTON_LABEL: 'Chụp lại ảnh',
  TAKE_PHOTO_MODAL_TITLE: 'Các gợi ý',
  TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT: 'Gợi ý',
  TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL: 'Tiếp tục',
  TAKE_PHOTO_RETAKE_MODAL_TITLE: 'Lưu ý',
  TAKE_PHOTO_RETAKE_MODAL_SUBTITLE: 'Một khi bạn chụp ảnh, ảnh hiện có sẽ tự động bị xóa',
  TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL: 'Chụp ảnh khác',
  TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL: 'Hủy',
  TAKE_PHOTO_PRIMARY_BUTTON: 'Tải ảnh lên',
  TAKE_PHOTO_PRIMARY_BUTTON_LOADING: 'Tiết kiệm',
  TAKE_PHOTO_SECONDARY_BUTTON: 'Lặp lại',
  PHOTO_HUB_UPLOAD_ERROR_MESSAGE: '',

  SATISFACTION_SURVEY: 'Nộp khảo sát mức độ hài lòng',

  INACTIVITY_TIMER_MODAL_PRIMARY_BUTTON: '',
  INACTIVITY_TIMER_MODAL_CLOSED_SESSION_PRIMARY_BUTTON: '',
  INACTIVITY_TIMER_MODAL_SECONDARY_BUTTON: '',
  INACTIVITY_TIMER_MODAL_TITLE: '',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_TITLE: '',
  INACTIVITY_TIMER_MODAL_HINT: '',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_ONE: '',
  INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_TWO: '',

  EXPIRED_SESSION_MODAL_PRIMARY_BUTTON: '',
  EXPIRED_SESSION_MODAL_SECONDARY_BUTTON: '',
  EXPIRED_SESSION_MODAL_TITLE: '',
  EXPIRED_SESSION_MODAL_HINT: '',

  TAKE_VIDEO_PRIMARY_BUTTON: 'Hoàn thành',
  TAKE_VIDEO_FINISH_TITLE: 'ghi xong',
  TAKE_VIDEO_FINISH_DESCRIPTION: 'Bạn đã quay xong video 360 °',
  TAKE_VIDEO_FINISH_WAITING_DESCRIPTION: 'Chúng tôi đang hoàn tất việc tải lên video về chiếc xe của bạn. Vui lòng chờ...',
  TAKE_VIDEO_FINISH_PRIMARY_BUTTON: 'tải lên',
  TAKE_VIDEO_FINISH_SECUNDARY_BUTTON: 'Lặp lại',

  PERMISSION_CAMERA_MODAL_TITLE: 'Bạn chưa bật quyền đối với máy ảnh',
  PERMISSION_CAMERA_MODAL_DESCRIPTION: 'Bạn cần bật quyền đối với máy ảnh để chụp ảnh',
  PERMISSION_CAMERA_MODAL_PRIMARY_BUTTON: 'tôi sẽ kích hoạt chúng',

  GUIDED_VISIT_INIT_TITLE: 'Bắt đầu chuyến tham quan có hướng dẫn',
  GUIDED_VISIT_INIT_DESCRIPTION: 'Chúng tôi sẽ cung cấp cho bạn một chuyến tham quan về các công cụ máy ảnh chính để bạn có thể khai thác tối đa nó.',
  GUIDED_VISIT_FIRST_TITLE: 'Tập trung vào những gì cần thiết',
  GUIDED_VISIT_FIRST_DESCRIPTION: 'Nhấn một lần vào nơi bạn muốn lấy nét và chụp rõ ràng.',
  GUIDED_VISIT_SECOND_TITLE: 'Bật / tắt đèn flash',
  GUIDED_VISIT_SECOND_DESCRIPTION: 'Kích hoạt đèn flash trong điều kiện ánh sáng yếu. Tắt nó nếu bạn đang ở trong ngày hoặc trong điều kiện ánh sáng tối ưu.',
  GUIDED_VISIT_THIRD_TITLE: 'Toàn màn hình',
  GUIDED_VISIT_THIRD_DESCRIPTION: 'Đặt máy ảnh ở chế độ toàn màn hình để có cái nhìn chi tiết hơn về hình ảnh được chụp.',
  GUIDED_VISIT_FOURTH_TITLE: 'Chụp một chạm',
  GUIDED_VISIT_FOURTH_DESCRIPTION: 'Nhấn nút chụp trong một lần chạm và chụp ảnh đẹp nhất để gửi.',
  GUIDED_VISIT_FIFTH_TITLE: 'bắt lại',
  GUIDED_VISIT_FIFTH_DESCRIPTION: 'Nếu bạn phát hiện ra lỗi trong quá trình chụp ảnh hoặc muốn cải thiện nó, bạn có thể quay lại và chụp ảnh lại.',
  GUIDED_VISIT_SIXTH_TITLE: 'Gửi trong một lần chạm',
  GUIDED_VISIT_SIXTH_DESCRIPTION: 'Khi bạn hài lòng với bức ảnh, hãy xác nhận và gửi bằng một lần chạm.',
  GUIDED_VISIT_SEVENTH_TITLE: 'đóng máy ảnh',
  GUIDED_VISIT_SEVENTH_DESCRIPTION: 'Bạn có thể quay lại nền tảng kiểm tra bất kỳ lúc nào.',
  GUIDED_VISIT_INIT_BUTTON_LABEL: 'Khởi đầu',
  GUIDED_VISIT_OMIT_BUTTON_LABEL: 'Nhảy',
  GUIDED_VISIT_NEXT_BUTTON_LABEL: 'Tiếp theo',
  GUIDED_VISIT_BACK_BUTTON_LABEL: 'Trước',
  GUIDED_VISIT_DONE_BUTTON_LABEL: 'Làm xong',

  GUIDED_VIDEO_VISIT_INIT_TITLE: 'Bắt đầu chuyến tham quan có hướng dẫn',
  GUIDED_VIDEO_VISIT_INIT_DESCRIPTION: 'Chúng tôi sẽ cung cấp cho bạn một chuyến tham quan về các công cụ máy ảnh chính để bạn có thể khai thác tối đa nó.',
  GUIDED_VIDEO_VISIT_FIRST_TITLE: 'Nắm bắt tất cả!',
  GUIDED_VIDEO_VISIT_FIRST_DESCRIPTION: 'Cố gắng sử dụng nhiều không gian nhất có thể trong khung máy ảnh.',
  GUIDED_VIDEO_VISIT_SECOND_TITLE: 'Nút ghi',
  GUIDED_VIDEO_VISIT_SECOND_DESCRIPTION: 'Nút này cho phép bạn bắt đầu ghi âm.',
  GUIDED_VIDEO_VISIT_THIRD_TITLE: 'Nút ghi',
  GUIDED_VIDEO_VISIT_THIRD_DESCRIPTION: 'Nút này cho phép bạn kết thúc ghi âm, hãy nhớ rằng sẽ không thể tạm dừng ghi âm.',
  GUIDED_VIDEO_VISIT_FOURTH_TITLE: 'Hẹn giờ',
  GUIDED_VIDEO_VISIT_FOURTH_DESCRIPTION: 'Bộ đếm thời gian này cho biết bạn có bao nhiêu thời gian để ghi âm, khi hết thời gian, quá trình ghi sẽ kết thúc. Nếu bạn cần thử lại, chỉ cần nhập lại phần này và bắt đầu ghi.',
  GUIDED_VIDEO_VISIT_FIFTH_TITLE: 'Toàn màn hình',
  GUIDED_VIDEO_VISIT_FIFTH_DESCRIPTION: 'Thích ứng với toàn màn hình để có được cái nhìn chi tiết hơn về video được quay.',
  GUIDED_VIDEO_VISIT_SIXTH_TITLE: 'Đóng máy ảnh',
  GUIDED_VIDEO_VISIT_SIXTH_DESCRIPTION: 'Bạn có thể quay lại nền tảng kiểm tra bất kỳ lúc nào.',
  GUIDED_VIDEO_VISIT_INIT_BUTTON_LABEL: 'Khởi đầu',
  GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL: 'Bỏ sót',
  GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL: 'Kế tiếp',
  GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL: 'Trước',
  GUIDED_VIDEO_VISIT_DONE_BUTTON_LABEL: 'Xong',

  PROCESS_COMPLETE_TITLE: 'Quy trình chụp ảnh đã hoàn tất thành công',
  PROCESS_COMPLETE_SUBTITLE_ONE: 'Chúng tôi sẽ thông báo sớm cho bạn về kết quả kiểm định',
  PROCESS_COMPLETE_SUBTITLE_TWO: '',
  PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS: 'Các chuyên viên của chúng tôi sẽ xem xét các ảnh chụp từ thứ Hai đến thứ Sáu trong giờ làm việc',
  PROCESS_COMPLETE_CONTINUE_BUTTON: 'Hãy đến Bảo hiểm Liberty',
  PROCESS_COMPLETE_ERROR_MESSAGE_TITLE: 'Hồ sơ của chúng tôi cho thấy bạn đã hoàn tất quy trình',
  PROCESS_COMPLETE_ERROR_MESSAGE_SUBTITLE: 'Một chuyên viên hiện đang xem xét ảnh chụp của bạn',

  PROCESS_SUPPORT_TITLE: 'Your support request was sent successfuly to our team.',
  PROCESS_SUPPORT_SUBTITLE_ONE: 'The opening hours are Monday to friday between 8:00 am and 5:30 pm.',
  PROCESS_SUPPORT_SUBTITLE_TWO: 'You can also consult the sections of ',
  PROCESS_SUPPORT_SUBTITLE_THREE: ' and ',
  PROCESS_SUPPORT_SUBTITLE_FOUR: ' to get util information about the process.',
  PROCESS_SUPPORT_SUBTITLE_FIVE: 'You will receive a response within a maximum of 2 business hours. ',
  PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT: 'FAQ',
  PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT: 'Instructions',
  PROCESS_SUPPORT_CONTINUE_BUTTON: 'Gửi yêu cầu',

  PROCESS_SUPPORT_IN_PROGRESS_MODAL_TITLE: 'You already have a support request in progress',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_SUBTITLE: 'Our team will be in contact with you as soon as possible.',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_ATTENTION_SCHEDULE: 'Attention schedule:',
  PROCESS_SUPPORT_IN_PROGRESS_MODAL_WORKING_HOURS: 'Monday to Thursday from 9:00 to 18:00 Fridays from 9:00 to 14:00',

  FORM_SUPPORT_TITLE: 'Yêu cầu hỗ trợ',
  FORM_SUPPORT_SUBTITLE: 'Hãy cho chúng tôi biết bạn có yêu cầu gì hoặc các vấn đề nảy sinh:',
  FORM_SUPPORT_TEXT_AREA: 'Vui lòng chia sẻ thêm chi tiết với chúng tôi hoặc nếu nó tương ứng với một yêu cầu khác',
  FORM_SUPPORT_SEND_SUPPORT: 'Gửi yêu cầu',
  FORM_SUPPORT_CANCEL_SUPPORT: 'Đừng gửi',
  FORM_SUPPORT_REQUIRED_INPUTS: 'Trường này là bắt buộc',
  FORM_SUPPORT_REQUIRED_INPUTS_ONLY_NUMBERS: 'Trường này chỉ chấp nhận số',
  FORM_SUPPORT_REQUIRED_INPUTS_TEXT_LENGTH: '{length} 1.000 ký tự',

  FAQ_TITLE: 'Câu Hỏi Thường Gặp',
  FAQ_SUBTITLE: 'Chúng tôi biết bạn sẽ có thắc mắc, vì vậy đây là câu trả lời cho các câu hỏi phổ biến nhất',

  FAQ_1_QUESTION: 'Quy trình kiểm định diễn ra như thế nào?',
  FAQ_1_ANSWER: 'Sau khi chúng tôi nhận được ảnh chụp xe của bạn, nhóm chuyên môn của chúng tôi sẽ thực hiện các bước thẩm định sau: \n\n\u2713 Phân tích trạng thái vật lý của chiếc xe.\n\n\u2713 Tham khảo cơ sở dữ liệu bên trong và bên ngoài.\n\n\u2713 Kết quả thẩm định.',

  FAQ_2_QUESTION: 'Nếu tôi chụp một bức ảnh không tương ứng với bộ phận được chỉ định của xe và gửi ảnh, tôi nên làm thế nào để chụp lại?',
  FAQ_2_ANSWER_PART_1: 'Bạn sẽ phải đợi email từ nhóm chuyên môn của chúng tôi (',
  FAQ_2_ANSWER_PART_2: ') thông báo bạn có cần chụp ảnh lại hay không.',

  FAQ_3_QUESTION: 'Nếu tôi chụp một bức ảnh không đạt chất lượng hoặc bị mờ và tôi đã gửi ảnh, tôi nên làm thế nào để chụp lại?',
  FAQ_3_ANSWER_PART_1: 'Bạn sẽ phải đợi email từ nhóm chuyên môn của chúng tôi (',
  FAQ_3_ANSWER_PART_2: ') thông báo bạn có cần chụp ảnh lại hay không.',

  FAQ_4_QUESTION: 'Nếu kết quả kiểm định đã được thông qua, bước tiếp theo là gì?',
  FAQ_4_ANSWER: 'Đúng vậy, bạn có thể tiến hành giám định từ bất kỳ nơi nào bạn thấy thuận tiện.',

  FAQ_5_QUESTION: 'Bằng cách thực hiện quy trình giám định thông qua ứng dụng này, tôi sẽ không cần phải đi đến trung tâm được ủy quyền hoặc CDA đúng không?',
  FAQ_5_ANSWER: 'Đúng vậy, bạn có thể tiến hành kiểm định từ bất kỳ nơi nào bạn thấy thuận tiện.',

  FAQ_6_QUESTION: 'Việc giám định này có tốn chi phí không?',
  FAQ_6_ANSWER: 'Quy trình này hoàn toàn miễn phí.',

  FAQ_7_QUESTION: 'Xe của tôi sẽ có giá trị bảo hiểm bao nhiêu?',
  FAQ_7_ANSWER_PART_1: 'Giá trị bảo hiểm phụ thuộc vào giá trị thương mại được đăng ký trong hướng dẫn từng giai đoạn theo đặc điểm của chiếc xe.',
  FAQ_7_ANSWER_PART_2: 'Cẩm nang hướng dẫn Fasecolda',
  FAQ_7_ANSWER_PART_3: ': Cơ sở dữ liệu công khai về giá trị thương mại của một chiếc xe do công ty Bảo hiểm Liberty xác định.',

  FAQ_8_QUESTION: 'Nếu phụ kiện của tôi không có trong danh sách mặc định, làm cách nào tôi có thể đưa chúng vào?',
  FAQ_8_ANSWER: 'Nếu phụ kiện không có trong danh sách, bạn không thể đưa vào.',

  FAQ_9_QUESTION: 'Tôi có thể dùng quy trình này giám định xe máy của tôi không?',
  FAQ_9_ANSWER: 'Rất tiếc, bạn chỉ có thể giám định xe ô tô cá nhân.',

  FAQ_10_QUESTION: 'Tôi có thể chụp ảnh xe khi xe đang ướt không?',
  FAQ_10_ANSWER: 'Tốt nhất chiếc xe nên ở trạng thái khô ráo.',

  FAQ_11_QUESTION: 'Tôi có thể tiến hành giám định mà không cần kết nối mạng Internet không?',
  FAQ_11_ANSWER: 'Rất tiếc là không thể, vì việc kết nối với các công cụ giám định cần phải dùng kết nối Internet.',

  FAQ_12_QUESTION: 'Tôi có thể tiến hành việc giám định ở bất kỳ nơi nào trong nước đúng không?',
  FAQ_12_ANSWER: 'Đúng vậy, không có vấn đề gì, miễn là bạn có thể truy cập vào ứng dụng.',

  FAQ_13_QUESTION: 'Có bất kỳ hạn chế nào đối với hệ điều hành di động của tôi để truy cập ứng dụng không?',
  FAQ_13_ANSWER: 'Không, cả thiết bị di động Android và iOS đều có thể truy cập mà không có bất kỳ hạn chế nào.',

  FAQ_14_QUESTION: 'Ứng dụng không cho phép tôi chụp ảnh. Tôi nên làm thế nào?',
  FAQ_14_ANSWER: 'Bạn phải đảm bảo rằng bạn không mở quá nhiều ứng dụng hoặc nhiều cửa sổ trong công cụ tìm kiếm và thử lại.',

  FAQ_15_QUESTION: '',
  FAQ_15_ANSWER: '',

  FAQ_CONTACT_EMAIL: '',

  REQUEST_CALL_TEXT_1: 'Cannot find the answer you need?',
  REQUEST_CALL_TEXT_2: 'Talk with a support agent',
  REQUEST_CALL_PHONE_NUMBER: '',

  REQUEST_CALL_SCHEDULES_1: 'Opening hours are',
  REQUEST_CALL_SCHEDULES_2: 'Monday to Thursday from 9:00 to 18:00, Fridays from 9:00 to 14:00',

  INSTRUCTIONS_LABEL: 'Instructions',
  FAQ_LABEL: 'FAQ',
  SUPPORT_REQUEST_LABEL: 'Support',

  AUTH_TOKEN_SESSION_TIMED_OUT: 'Xin lỗi, phiên làm việc của bạn đã hết thời gian, bạn cần xác nhận lại danh tính để tiếp tục',

  EMAIL_TOKEN_ERROR_MESSAGE_TITLE: 'Xin lỗi, đã xảy ra sự cố trong quá trình và bạn cần thử lại',
  EMAIL_TOKEN_ERROR_MESSAGE_SUBTITLE: 'Vui lòng nhấp vào liên kết trong email chào mừng của bạn để bắt đầu lại',

  INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE: 'Quy trình kiểm tra hình ảnh',
  INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE: 'Các chuyên viên của chúng tôi đang kiểm tra hình ảnh xe của bạn và chúng tôi sẽ liên lạc qua email trong thời gian sớm nhất.',
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE: 'Việc giám định xe của bạn đã thành công',
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE: 'Để biết thêm thông tin về các bước tiếp theo của quy trình cấp bảo hiểm, bạn có thể liên hệ với chúng tôi qua các kênh dịch vụ của chúng tôi hoặc liên hệ với người tư vấn của bạn',
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE: 'Các ảnh đã gửi bị từ chối',
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE: 'Nếu bạn cần trợ giúp, bạn có thể liên hệ với chúng tôi qua các kênh dịch vụ của chúng tôi hoặc liên hệ với người tư vấn của bạn',
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE: 'Quy trình giám định đã hết hạn',
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE: 'Thời hạn để hoàn thành việc giám định của bạn đã hết. Nếu bạn cần trợ giúp, hãy liên hệ với một nhân viên tư vấn ',
  INVALID_STATUS_CALL_AN_ADVISOR_BUTTON: 'Gọi cho Nhân viên Tư vấn',
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_TITLE: 'Việc kiểm tra đã bị hủy bỏ.',
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_SUBTITLE: 'Nếu cần trợ giúp, bạn có thể liên hệ với chúng tôi qua các kênh dịch vụ của chúng tôi hoặc liên hệ với cố vấn của bạn.',

  ERROR_CAMERA_SYSTEM_PERMISSION_DENIED: 'Ứng dụng cần quyền truy cập vào máy ảnh để tiếp tục.',
  ERROR_CAMERA_USER_PERMISSION_DENIED: 'Bạn đã từ chối quyền truy cập vào máy ảnh. Ứng dụng cần quyền truy cập vào máy ảnh để tiếp tục.',
  ERROR_CAMERA_COULD_NOT_START_VIDEO_SOURCE: 'Một ứng dụng khác đang sử dụng máy ảnh',
  ERROR_NKNOWN_PERMISSION_CAMERA: 'Giáo sư! Đã xảy ra sự cố',
  CAMERA_PERMISSIONS_TITLE_ICON_1: 'Câu Hỏi Thường Gặp',
  CAMERA_PERMISSIONS_TITLE_ICON_2: 'quyền của máy ảnh',
  CAMERA_PERMISSIONS_IPHONE_CHROME: 'điện thoại Chrome',
  CAMERA_PERMISSIONS_SELECT_SO: 'Chọn tổ hợp đúng giữa Hệ điều hành và trình duyệt để xem hướng dẫn tương ứng:',
  CAMERA_PERMISSIONS_IPHONE_SAFARI: 'IPhone Safari',
  CAMERA_PERMISSIONS_RECOMMENDATIONS: 'Để thực hiện kiểm tra tối ưu, hãy ghi nhớ các khuyến nghị sau',
  CAMERA_PERMISSIONS_ANDROID_CHROME: 'Android và Chrome',
  CAMERA_PERMISSIONS_OTHERS: 'Người khác',
  CAMERA_PERMISSIONS_INCORRECT_INFORMATION: 'thông tin không chính xác',
  CAMERA_PERMISSIONS_IPHONE_CHROME_ID: 'camera_permissions_IOS_CHROME',
  CAMERA_PERMISSIONS_IPHONE_SAFARI_ID: 'camera_permissions_IOS_SAFARI',
  CAMERA_PERMISSIONS_ANDROID_CHROME_ID: 'camera_permissions_ANDROID_CHROME',
  CAMERA_PERMISSIONS_OTHERS_ID: 'camera_permissions_others',
  CAMERA_PERMISSIONS_TITLE_BROWSER_1: 'Cho phép sử dụng máy ảnh trong trình duyệt của bạn',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE: 'Để cấp quyền, hãy làm như sau:',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE: 'Cài đặt Chrome > Cài đặt trang web > Máy ảnh > Bật máy ảnh',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE: 'Đồng thời bật quyền truy cập của Chrome vào máy ảnh trên Android',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE: 'Cài đặt Android > Ứng dụng > Chrome > Quyền > Máy ảnh > Cho phép',
  CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE: 'Cho phép trình duyệt Safari truy cập camera',
  CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE: 'Cài đặt iPhone > Safari > Máy ảnh > Cho phép',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE: 'Cho phép trình duyệt Chrome truy cập máy ảnh',
  CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE: 'Cài đặt iPhone > Chrome > Bật Camera',
  TUTORIAL_STEP_FINAL: 'Bắt đầu',
  TUTORIAL_STEP_FINAL_READY: 'Sẵn sàng',
  CAMERA_PERMISSIONS_TITLE_BROWSER_2: 'Đồng thời bật quyền truy cập của Chrome vào máy ảnh trên Android',
  CAMERA_PERMISSIONS_TITLE_BROWSER_3: 'Cho phép trình duyệt Safari truy cập camera',
  CAMERA_PERMISSIONS_TITLE_BROWSER_4: 'Cho phép trình duyệt Chrome truy cập máy ảnh',
  CAMERA_PERMISSIONS_WIFI_HEADER: 'Hãy chắc chắn rằng bạn có một tín hiệu tốt trên kết nối internet của bạn.',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_1: 'Để cấp quyền, hãy làm như sau: Cài đặt Chrome > Cài đặt trang web > Máy ảnh > Bật máy ảnh',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_2: 'Để bật nó, hãy làm như sau: Cài đặt Android > Ứng dụng > Quyền của Chrome > Máy ảnh > Cho phép',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_3: 'Để cấp quyền, hãy làm như sau: Cài đặt iPhone Safari Camera > Cho phép',
  CAMERA_PERMISSIONS_DESCRIPTION_BROWSER_4: 'Để bật nó, hãy làm như sau: Cài đặt iPhone > Chrome > Bật Camera',
  CAMERA_PERMISSIONS_WIFI_FOOTER: 'Bằng cách duy trì kết nối internet ổn định, bạn đảm bảo rằng hình ảnh được tải lên nền tảng một cách hiệu quả.',
  CAMERA_PERMISSIONS_DETECTED_PHONE_1: 'Chúng tôi phát hiện ra rằng điện thoại di động của bạn là',
  CAMERA_PERMISSIONS_DETECTED_PHONE_2: ' và trình duyệt mặc định của bạn là ',

  MODAL_REPEAT_VIDEO_MESSAGE: 'Lưu ý rằng nếu bạn quay video mới, video hiện tại sẽ bị xóa.',
  MODAL_REPEAT_VIDEO_PRIMARY_BUTTON: 'Lặp lại video',
  MODAL_REPEAT_VIDEO_SECONDARY_BUTTON: 'Hủy bỏ',

  LOADING_MESSAGE_CAMERA_PHOTO: 'tải máy ảnh',

  FORM_SUPPORT_ASIAN_INFORMATION: 'Vui lòng cung cấp thông tin đặt lịch Giám định xe',
  FORM_SUPPORT_ASIAN_NAME: 'Tên Người liên hệ',
  FORM_SUPPORT_ASIAN_NUMBER: 'Số điện thoại',
  FORM_SUPPORT_ASIAN_PR_INSPECTION_TIME: 'Thời gian dự kiến Giám định xe',
  FORM_SUPPORT_ASIAN_ADDRESS: 'Địa điểm Giám định xe',
  FORM_SUPPORT_ASIAN_WE_WILL_CONTACT_WITH_YOU: 'Chúng tôi sẽ liên hệ Quý khách trong thời gian sớm nhất để xác nhận lịch đặt Giám định xe',

  PREPOSITION_OF: 'of',
  PASSSED_STEP: 'Passed',

  STEP_INSTRUCCION_TEXT: 'Capture images taking into account the instructions',

  INSTRUCTIONS_MODAL_BUTTON_FINISH: 'DONE',
  INSTRUCTIONS_MODAL_BUTTON_SHOW_INSTRUCTIONS_AGAIN: 'See instructions again',
  INSTRUCTIONS_MODAL_BUTTON_FINISH_INITIAL_PROCESS: 'Start inspection',
  INSTRUCTIONS_MODAL_BUTTON_OMIT: 'Omit',
  INSTRUCTIONS_MODAL_TITLE: 'To carry out the inspection, consider the following:',
  INSTRUCTIONS_MODAL_SUBTITLE_1: 'Take photographs of the vehicle directly from your cell phone (smartphone). This application only works from this device',
  INSTRUCTIONS_MODAL_SUBTITLE_2: 'The vehicle must be clean, located in a spacious place and with daylight.',
  INSTRUCTIONS_MODAL_SUBTITLE_3: 'The vehicle must be clean, located in a spacious place and with daylight.',
  INSTRUCTIONS_MODAL_SUBTITLE_4: 'When taking exterior photos of the vehicle, try to capture the entire vehicle.',
  INSTRUCTIONS_MODAL_SUBTITLE_5: 'You must have the engine running to capture the mileage photo',
  INSTRUCTIONS_MODAL_SUBTITLE_6: 'You must have a good internet connection and close the rest of the applications you have open.',
  INSTRUCTIONS_MODAL_SUBTITLE_7: 'You have 2 hours to complete the inspection process. If you exceed the time, you will lose the photos you have captured and you will have to re-enter from the link.',
  INSTRUCTIONS_MODAL_TITLE_FINISH_PAGE: '¿Do you want to start the inspection?',
  INSTRUCTIONS_MODAL_SUBTITLE_FINISH_PAGE: 'Please note that you have 2 hours to complete the inspection process. If you exceed the time, you will lose the photos you have captured and you will have to re-enter from the link.',
};

export default { code, strings };
