import { deleteVideo, getPhotosByVideo } from '../api';
import { EMAIL_TOKEN_COOKIE_NAME } from '../utils/constants';
import {
  handleInvalidStatusError,
  handleTokenMissingError,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { logcodes, logger } from '../utils/logger';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { getCookie, navigate } from '../utils/NavigationUtils';
import {
  ADD_PHOTO_TO_VIDEO_STATUS_LIST,
  ON_COUNTER_RECORDS_CAMERA,
  ON_COUNTER_SECONS_RECORDING,
  ON_DISPLAY_MODAL_FINISH_VIDEO,
  ON_FINISH_VIDEO,
  ON_RECORDING_CAMERA,
  VIDEO_PARAMETERS,
  ON_CAPTURE_PHOTO_FROM_VIDEO,
  ON_CHANGE_REQUIRE_VIDEO,
  GET_PHOTOS_PREVIEW_VIDEO,
  ON_DISPLAY_PREVIEW_VIDEO,
  RESET_PHOTOS_PREVIEW_VIDEO,
  DISPLAY_VIDEO_SECTION,
} from './ActionTypes';

const onRecordingCamera = isRecording => ({
  type: ON_RECORDING_CAMERA,
  payload: { isRecording },
});

const onCounterSecondsRecording = seconds => ({
  type: ON_COUNTER_SECONS_RECORDING,
  payload: { seconds },
});

const onCounterRecordsCamera = () => ({
  type: ON_COUNTER_RECORDS_CAMERA,
});

const addPhotoToVideoPhotoStatusList = (id, status) => ({
  type: ADD_PHOTO_TO_VIDEO_STATUS_LIST,
  payload: { id, status },
});

const onDisplayModalFinishVideo = status => ({
  type: ON_DISPLAY_MODAL_FINISH_VIDEO,
  payload: { status },
});

const onFinishVideo = status => ({
  type: ON_FINISH_VIDEO,
  payload: { status, emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME) },
});

const onDeleteVideo = async secondsRecording => {
  try {
    localStorage.removeItem('VIDEO_PHOTOS');
    await deleteVideo(secondsRecording);
    logger.log(logcodes.CAMV120);
  } catch (error) {
    logger.log(logcodes.CAMV130);
    if (error instanceof TokenError) {
      handleTokenMissingError(error);
    } else if (error instanceof InvalidStatusError) {
      handleInvalidStatusError(error);
    } else {
      navigate(ERROR_GENERIC);
    }
  }
};

const setVideoParameters = parameters => ({
  type: VIDEO_PARAMETERS,
  payload: parameters,
});

const resetPhotosPreviewVideo = () => ({
  type: RESET_PHOTOS_PREVIEW_VIDEO,
});

const getPhotosPreviewVideo = () => async dispatch => {
  const { data } = await getPhotosByVideo();
  dispatch({
    type: GET_PHOTOS_PREVIEW_VIDEO,
    payload: data,
  });
};

const onDisplayPreviewVideo = status => ({
  type: ON_DISPLAY_PREVIEW_VIDEO,
  payload: { status },
});

const reviewVideoParameters = videoParams => dispatch => {
  if (videoParams) {
    localStorage.setItem('VIDEO_PARAMETERS', JSON.stringify(videoParams));
    dispatch(setVideoParameters(videoParams));
  } else {
    const videoParameters = JSON.parse(
      localStorage.getItem('VIDEO_PARAMETERS'),
    );

    if (videoParameters !== null || videoParameters !== undefined)
      dispatch(setVideoParameters(videoParameters));
  }
};

const onCapturePhotoFromVideo = status => ({
  type: ON_CAPTURE_PHOTO_FROM_VIDEO,
  payload: { status },
});

const onChangeRequireVideo = status => ({
  type: ON_CHANGE_REQUIRE_VIDEO,
  payload: { status },
});

const onDisplayVideoSection = status => async dispatch => {
  dispatch({
    type: DISPLAY_VIDEO_SECTION,
    payload: { status },
  });
};

export default {
  onCounterRecordsCamera,
  onDisplayModalFinishVideo,
  onCounterSecondsRecording,
  addPhotoToVideoPhotoStatusList,
  onFinishVideo,
  onRecordingCamera,
  onDeleteVideo,
  reviewVideoParameters,
  setVideoParameters,
  onCapturePhotoFromVideo,
  onChangeRequireVideo,
  getPhotosPreviewVideo,
  resetPhotosPreviewVideo,
  onDisplayPreviewVideo,
  onDisplayVideoSection,
};
