import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconCamera } from '@lmig/lmds-react';
import IconButton from '../IconButton';
import {
  BUTTON_ACTION,
  FingerprintButton,
} from '../../utils/FingerprintButton';
import './DefaultPhotoPanel/styles.scss';
import { IconVideoCamera, PlusIcon } from '../Icons';

export const ICON_TYPE_PANEL = {
  RETRY_CAMERA: 'retry-camera',
  DISABLED_CAMERA: 'disabled-camera',
  DEFAULT_CAMERA: 'default-camera',
  VIDEO_CAMERA: 'video-camera',
  DISABLED_VIDEO_CAMERA: 'disabled-video-camera',
  ADD_PLUS: 'add-plus',
};

export const PanelIconFactory = ({
  type,
  photoId,
  vehicleClass,
  onClick,
  isRetake,
  disabled,
}) => {
  const getButtonId = useMemo(
    () => () =>
      new FingerprintButton(photoId)
        .setVehicleClass(vehicleClass)
        .setAction(BUTTON_ACTION.ADD)
        .setIsRetake(isRetake)
        .getId(),
    [photoId, vehicleClass, isRetake],
  );

  switch (type) {
    case ICON_TYPE_PANEL.ADD_PLUS:
      return (
        <PlusIcon
          disabled={disabled}
          id={getButtonId()}
          onClick={() => onClick(photoId, BUTTON_ACTION.ADD)}
        />
      );
    case ICON_TYPE_PANEL.DEFAULT_CAMERA:
      return (
        <IconButton
          id={getButtonId()}
          IconComponent={IconCamera}
          onClick={() => onClick(photoId, BUTTON_ACTION.ADD)}
          className="float-right"
        />
      );
    case ICON_TYPE_PANEL.DISABLED_CAMERA:
      return (
        <IconButton
          disabled
          color="default"
          IconComponent={IconCamera}
          className="float-right"
        />
      );
    case ICON_TYPE_PANEL.VIDEO_CAMERA:
      return (
        <IconVideoCamera
          id={getButtonId(BUTTON_ACTION.ADD)}
          disabled={disabled}
          onClick={() => onClick(photoId, BUTTON_ACTION.ADD)}
        />
      );

    case ICON_TYPE_PANEL.DISABLED_VIDEO_CAMERA:
      return (
        <IconVideoCamera
          id={getButtonId(BUTTON_ACTION.ADD)}
          onClick={() => onClick(photoId, BUTTON_ACTION.ADD)}
        />
      );
    case ICON_TYPE_PANEL.RETRY_CAMERA:
      return (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <div
          className="icon-error"
          role="button"
          onClick={() => onClick(photoId)}
        />
      );
    default:
      return null;
  }
};

PanelIconFactory.propTypes = {
  isRetake: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  photoId: PropTypes.string.isRequired,
  vehicleClass: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
