import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@lmig/lmds-react';
import CircleImage from '../CircleImage';
import Modal from '../Modal/Modal';
import localizedStrings from '../../utils/localizedStrings';
import RetakePhotoImg from './retakephoto.svg';
import './styles.scss';

export const ModalRequiredPhotoTaken = ({
  isOpen,
  primaryButtonLabel,
  primaryButtonHandler,
  secondaryButtonHandler,
  secondaryButtonLabel,
  backButtonHandler,
  language,
  buttonId,
}) => {
  const {
    TAKE_PHOTO_RETAKE_MODAL_TITLE,
    TAKE_PHOTO_RETAKE_MODAL_SUBTITLE,
    PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT,
  } = localizedStrings(language);

  return (
    <Modal
      isOpen={isOpen}
      backButtonHandler={backButtonHandler}
      primaryButtonLabel={primaryButtonLabel}
      primaryButtonHandler={primaryButtonHandler}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonHandler={secondaryButtonHandler}
      buttonId={buttonId}
    >
      <CircleImage className="retake-photo-modal-image">
        <img src={RetakePhotoImg} alt={PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT} />
      </CircleImage>

      <div className="modal-retake-photos-taken__content">
        <Heading type="h3-light" className="modal-retake-photos-taken__title">
          {TAKE_PHOTO_RETAKE_MODAL_TITLE}
        </Heading>
        <Heading
          type="h4-light"
          className="modal-retake-photos-taken__subtitle"
        >
          {TAKE_PHOTO_RETAKE_MODAL_SUBTITLE}
        </Heading>
      </div>
    </Modal>
  );
};

ModalRequiredPhotoTaken.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  primaryButtonHandler: PropTypes.func.isRequired,
  secondaryButtonHandler: PropTypes.func.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
  backButtonHandler: PropTypes.func.isRequired,
  buttonId: PropTypes.string.isRequired,
};
