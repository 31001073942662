import React from 'react';
import PropTypes from 'prop-types';
import { useFormatTimer } from './useFormatTimer';
import './styles.scss';

const Timer = ({ startSeconds }) => {
  const [minutes, seconds] = useFormatTimer(startSeconds);

  return (
    <div className="inactivity-modal__counter-container">
      <div className="inactivity-modal__counter-number">
        <label>{minutes}</label>
      </div>
      <div className="inactivity-modal__divition">:</div>
      <div className="inactivity-modal__counter-number">
        <label>{seconds}</label>
      </div>
    </div>
  );
};

Timer.propTypes = {
  startSeconds: PropTypes.number.isRequired,
};

export default Timer;
