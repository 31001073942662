export class RequiredPhotoHelper {
  constructor(stepsWizard) {
    this.stepsWizard = stepsWizard;
  }

  setStep(step) {
    this.step = step;
    return this;
  }

  getResult() {
    return this.stepsWizard;
  }

  storePhoto(incomingPhoto) {
    const { photoId } = incomingPhoto;
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep];

    const isPhotoExists = photosInStep.photos.find(
      item => item.photoId === photoId,
    );

    if (isPhotoExists) {
      const photoStepListUpdated = photosInStep.photos.map(item =>
        item.photoId === photoId ? incomingPhoto : item,
      );
      this.stepsWizard[positionStep].photos = [...photoStepListUpdated];
    } else {
      const previusStepPhotos = this.stepsWizard[positionStep];
      previusStepPhotos.photos.push(incomingPhoto);
      this.stepsWizard[positionStep] = previusStepPhotos;
    }
    return this;
  }
}
