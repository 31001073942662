import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import { formatPhotoConfig, getSinglePhotoConfig } from '../../utils';
import { store } from '../../utils/configureStore';
import { getCountryCode } from '../../utils/NavigationUtils';

const unformattedCountriesAvailableAccessories = availableAccesories =>
  availableAccesories.filter(photo => photo.title);

const localizedStrings = require('@lmig-latam/adil-api-common-lib/utils/localizedStrings');

const { VEHICLE_CLASS_MOTORBIKE } = localizedStrings.getCurrentLanguage();

export const availableAccessories = language => {
  const {
    user: { vehicleClass },
    settings: { retake },
  } = store.getState();

  const countryConfig = getCountryConfig(language || getCountryCode());

  let accessoriesList = [];
  if (countryConfig) {
    if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
      accessoriesList = countryConfig.getAvaliableMotorbikeAccesories();
    } else {
      accessoriesList = countryConfig.getAvaliableVehicleAccesories();
    }
  }

  if (retake) {
    const { accessories } = retake;
    const idsAccessoriesRetake = accessoriesList.filter(item =>
      accessories.includes(item.photoId),
    );
    accessoriesList = idsAccessoriesRetake;
  }

  return formatPhotoConfig(
    unformattedCountriesAvailableAccessories(accessoriesList),
  );
};

export const getAccessoryPhotoInfo = requestedId =>
  getSinglePhotoConfig(requestedId, availableAccessories());

export default availableAccessories();
