import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lmig/lmds-react';
import IconNotice from '../../styles/images/icon-notice.svg';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

function ModalRepeatVideo({ isOpen, onClose, onRepeat, lang }) {
  const {
    MODAL_REPEAT_VIDEO_MESSAGE,
    MODAL_REPEAT_VIDEO_PRIMARY_BUTTON,
    MODAL_REPEAT_VIDEO_SECONDARY_BUTTON,
  } = localizedStrings(lang);
  return (
    <Modal styles isOpen={isOpen} centerContent closeButtonHandler={onClose}>
      <div className="modal-repeat-video__container">
        <div className="modal-repeat-video__icon">
          <img src={IconNotice} width="90" alt="" />
        </div>

        <label className="modal-repeat-video__title">
          {MODAL_REPEAT_VIDEO_MESSAGE}
        </label>

        <div>
          <Button
            onClick={() => onRepeat()}
            className="modal-repeat-video__repeat-button"
          >
            {MODAL_REPEAT_VIDEO_PRIMARY_BUTTON}
          </Button>
        </div>

        <div>
          <Button
            onClick={() => onClose()}
            className="modal-repeat-video__cancel-button"
          >
            {MODAL_REPEAT_VIDEO_SECONDARY_BUTTON}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ModalRepeatVideo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRepeat: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ModalRepeatVideo;
