import PropTypes from 'prop-types';
import { fileUtils } from '@lmig-latam/adlib-ui';
import {
  finishProcess,
  uploadPhoto,
  deletePhoto,
  uploadPhotoList360,
  uploadInspektlabs,
  getSignedURL,
  savePhotoDataInDB,
} from '../api';
import localizedStrings from '../utils/localizedStrings';
import { logcodes, logger } from '../utils/logger';
import { PROCESS_COMPLETE, ERROR_GENERIC } from '../utils/navigationConstants';
import { getCountryCode, navigate } from '../utils/NavigationUtils';
import {
  DELETE_PHOTO,
  GET_PRESIGNED_URL_ARRAY,
  HIDE_APP_LOADER,
  ON_CHANGE_CURRENT_STEP,
  ON_CHANGE_SPARE_WHEEL_TOGGLE,
  ON_CLICK_PHOTO_HUB_BUTTON_STEP,
  RESET_PRESIGNED_URL_ARRAY,
  RESET_VIDEO_SECTION,
  SET_WIZARD_STATE_BY_VEHICLE_CLASS,
  SHOW_APP_LOADER,
} from './ActionTypes';
import { store } from '../utils/configureStore';
import { TakePhotoActions } from '.';
import {
  TokenError,
  InvalidStatusError,
  handleTokenMissingError,
  handleInvalidStatusError,
} from '../utils/customErrors';
import TakeVideoActions from './TakeVideoActions';
import { UPLOADING_PHOTO_STATUS } from '../constants/camera';
import { DAMAGE_PHOTO } from '../utils/constants';

const { getBlobUrlFromBlobFile } = fileUtils;
const {
  settings: {
    environment: { language },
  },
} = store.getState();

const { PHOTO_HUB_FINISH_PROGRESS } = localizedStrings(language);

export default {
  PropertyTypes: PropTypes.shape({
    finishProcess: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    uploadPhoto: PropTypes.func.isRequired,
  }),

  onChangeCurrentStep(step, stepsToShow) {
    return {
      type: ON_CHANGE_CURRENT_STEP,
      payload: { step, stepsToShow },
    };
  },

  deletePhoto(photoId) {
    return async dispatch => {
      const {
        photos: { wizardPhotoHub },
      } = store.getState();
      dispatch({
        type: SHOW_APP_LOADER,
      });

      let allPhotos = [];
      wizardPhotoHub.forEach(step => {
        if (step.id === 4) {
          const {
            photos: { accessories, damages },
          } = step;
          allPhotos = [...accessories, ...damages];
        }
      });

      const fileType = allPhotos
        .filter(photo => photo.photoId === photoId)
        .map(item => item.fileType)[0];

      const photoName = `${photoId}.${fileType}`;
      const isDamage =
        String(photoName).indexOf(DAMAGE_PHOTO) !== -1 || undefined;
      // Delete server side version of photo
      try {
        await deletePhoto(photoName, isDamage);
      } catch (error) {
        dispatch({
          type: HIDE_APP_LOADER,
        });
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          navigate(ERROR_GENERIC);
        }
      } finally {
        // Delete local version of photo
        dispatch({
          type: DELETE_PHOTO,
          photoId,
          isDamage,
        });
        dispatch({
          type: HIDE_APP_LOADER,
        });
      }
    };
  },

  getPresignedURLArray(photoIdArray, isAccessory) {
    // eslint-disable-next-line consistent-return
    return async dispatch => {
      try {
        const response = await getSignedURL(photoIdArray);
        const presignedURLS = await response.json();
        return isAccessory
          ? presignedURLS
          : dispatch({
              type: GET_PRESIGNED_URL_ARRAY,
              payload: { presignedURLS },
            });
      } catch (error) {
        logger.log(logcodes.UPF10, error);
      }
    };
  },

  resetPresignedURLArray() {
    return async dispatch => {
      dispatch({
        type: RESET_PRESIGNED_URL_ARRAY,
      });
    };
  },

  uploadPhoto(
    photoToUpload,
    photoToDisplay,
    presignedURL,
    geoLocation,
    onUploadProgress,
  ) {
    return async dispatch => {
      try {
        logger.log(logcodes.CAUPL130, {
          name: photoToUpload.photoId,
        });

        const response = await uploadPhoto(
          photoToUpload,
          presignedURL,
          onUploadProgress,
        );

        if (response.status === 200 || response.status === 201) {
          await savePhotoDataInDB(
            photoToUpload,
            photoToDisplay.isDamage,
            geoLocation,
          );
          // eslint-disable-next-line no-param-reassign
          photoToDisplay.blobUrl = getBlobUrlFromBlobFile(photoToDisplay.file);
          await dispatch(TakePhotoActions.storePhoto(photoToDisplay));
        }
      } catch (error) {
        // Delete local version of photo
        dispatch({
          type: DELETE_PHOTO,
          photoId: photoToDisplay.photoId,
        });
        logger.log(logcodes.CAUPL120, {
          name: photoToUpload.photoId,
          error: error.message,
          stack: error.stack,
        });

        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          throw error;
        }
      }
    };
  },

  onResetVideoSection() {
    return async dispatch => {
      dispatch({
        type: RESET_VIDEO_SECTION,
      });
    };
  },

  finishProcess() {
    return async dispatch => {
      dispatch({
        type: SHOW_APP_LOADER,
        translucent: true,
        message: PHOTO_HUB_FINISH_PROGRESS,
      });
      try {
        await finishProcess();
      } catch (error) {
        dispatch({
          type: HIDE_APP_LOADER,
        });
        logger.log(logcodes.CAUPL140, { error });

        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          navigate(ERROR_GENERIC);
        }
        return;
      } finally {
        if (getCountryCode() === 'cl') {
          try {
            await uploadInspektlabs();
          } catch (error) {
            logger.log(logcodes.CAUPL160, { error });
          }
        }
      }
      logger.log(logcodes.CAUPL110);
      window.location.href = `${PROCESS_COMPLETE}`;
    };
  },

  onChangeSpareWheelToggle(isEnabled) {
    return {
      type: ON_CHANGE_SPARE_WHEEL_TOGGLE,
      payload: { isEnabled },
    };
  },

  setWizardStateByVehicleClass(vehicleClass) {
    return {
      type: SET_WIZARD_STATE_BY_VEHICLE_CLASS,
      payload: { vehicleClass },
    };
  },

  onChangeStatusPhotosStep(isAllow) {
    return {
      type: ON_CLICK_PHOTO_HUB_BUTTON_STEP,
      payload: { isAllow },
    };
  },
};

export const uploadPhotoRecord360 = file => async dispatch => {
  const { PENDING, REJECTED, COMPLETED } = UPLOADING_PHOTO_STATUS;
  try {
    dispatch(TakeVideoActions.addPhotoToVideoPhotoStatusList(file.id, PENDING));
    await uploadPhotoList360(file);
    dispatch(
      TakeVideoActions.addPhotoToVideoPhotoStatusList(file.id, COMPLETED),
    );
  } catch (err) {
    dispatch(
      TakeVideoActions.addPhotoToVideoPhotoStatusList(file.id, REJECTED),
    );
    logger.log(logcodes.CAUPL120);
  }
};
