import image0101 from './01/01_01.jpg';
import image0201 from './02/02_01.jpg';
import image0301 from './03/03_01.jpg';
import image0401 from './04/04_01.jpg';
import image0402 from './04/04_02.jpg';
import image0501 from './05/05_01.jpg';
import image0502 from './05/05_02.jpg';
import image0601 from './06/06_01.jpg';
import image0602 from './06/06_02.jpg';
import image0701 from './07/07_01.jpg';
import image0702 from './07/07_02.jpg';
import image0801 from './08/08_01.jpg';
import image0901 from './09/09_01.jpg';
import image0902 from './09/09_02.jpg';
import image1001 from './10/10_01.jpg';
import image1002 from './10/10_02.jpg';
import image1101 from './11/11_01.jpg';
import image1102 from './11/11_02.jpg';
import image1103 from './11/11_03.jpg';
import image1201 from './12/12_01.jpg';
import image1202 from './12/12_02.jpg';

const defaultPhotoImages = {
  step01: [image0101],
  step02: [image0201],
  step03: [image0301],
  step04: [image0401, image0402],
  step05: [image0501, image0502],
  step06: [image0601, image0602],
  step07: [image0701, image0702],
  step08: [image0801],
  step09: [image0901, image0902],
  step10: [image1001, image1002],
  step11: [image1101, image1102, image1103],
  step12: [image1201, image1202],
};

export default {
  en: {
    ...defaultPhotoImages,
  },
  co: {
    ...defaultPhotoImages,
  },
  cl: {
    ...defaultPhotoImages,
  },
  ec: {
    ...defaultPhotoImages,
  },
  vn: {
    ...defaultPhotoImages,
  },
  th: {
    ...defaultPhotoImages,
  },
};
