import React from 'react';
import PropTypes from 'prop-types';
import { useTimerCamera } from '../CameraHooks';
import './styles.scss';

const CameraTimer = ({
  timeLimit,
  onStopRecording,
  frequencyVideo,
  onCounter,
}) => {
  const [timerFormat] = useTimerCamera({
    timeLimit,
    onStopRecording,
    frequencyVideo,
    onCounter,
  });

  return (
    <div className="camera-timer">
      <div className="camera-timer__container">
        <div className="camera-timer__recording-point blink" />
        <p className="camera-timer__seconds">{timerFormat}</p>
      </div>
    </div>
  );
};

CameraTimer.defaultProps = {
  timeLimit: 180,
};

CameraTimer.propTypes = {
  timeLimit: PropTypes.number,
  onStopRecording: PropTypes.func.isRequired,
  frequencyVideo: PropTypes.number.isRequired,
  onCounter: PropTypes.func.isRequired,
};

export default CameraTimer;
