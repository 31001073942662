import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import VideoActionButtons from './VideoActionButtons';
import ButtonsPreviewPhoto from './ButtonsPreviewPhoto';
import PhotoActionButtons from './PhotoActionButtons';
import './styles.scss';

function PanelCameraDown({
  handlerFullScreen: { openFullScreen, exitFullScreen },
  recordingMode,
  recording,
  handlerRecording,
  capturePhoto,
  isPhotoInPreview,
  handlerOptionsPreview,
  isLoading,
  isFullscreen,
  disable,
  lang,
}) {
  const goFullscreen = useCallback(() => {
    if (!isFullscreen) openFullScreen();
    else exitFullScreen();
  }, [exitFullScreen, isFullscreen, openFullScreen]);

  return (
    <>
      <div className={!isPhotoInPreview ? 'photo-button-down' : 'hidden'}>
        {recordingMode ? (
          <VideoActionButtons
            disable={disable}
            recording={recording}
            handlerRecording={handlerRecording}
            handlerFullScreen={{ openFullScreen, exitFullScreen }}
            isFullscreen={isFullscreen}
            goFullscreen={goFullscreen}
          />
        ) : (
          <PhotoActionButtons
            disable={disable}
            capturePhoto={capturePhoto}
            isFullscreen={isFullscreen}
            goFullscreen={goFullscreen}
          />
        )}
      </div>

      <ButtonsPreviewPhoto
        lang={lang}
        isLoading={isLoading}
        isPhotoInPreview={isPhotoInPreview}
        handlerOptionsPreview={handlerOptionsPreview}
        recordingMode={recordingMode}
      />
    </>
  );
}

PanelCameraDown.defaultProps = {
  isFullscreen: null,
};

PanelCameraDown.propTypes = {
  lang: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
  recording: PropTypes.bool.isRequired,
  recordingMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPhotoInPreview: PropTypes.bool.isRequired,
  capturePhoto: PropTypes.func.isRequired,
  handlerOptionsPreview: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool,
  handlerRecording: PropTypes.shape({
    onStartRecording: PropTypes.func.isRequired,
    onStopRecording: PropTypes.func.isRequired,
  }).isRequired,
  handlerFullScreen: PropTypes.shape({
    openFullScreen: PropTypes.func.isRequired,
    exitFullScreen: PropTypes.func.isRequired,
  }).isRequired,
};

export default PanelCameraDown;
