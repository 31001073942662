import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import { ExpiredSessionView } from './ExpiredSessionView';
import CircleExpiredSessionIcon from './CircleExpiredSessionIcon';
import './styles.scss';

export const ModalExpiredSession = ({
  isOpen,
  language,
  onSuccessModal,
  onCloseModal,
}) => {
  const {
    EXPIRED_SESSION_MODAL_PRIMARY_BUTTON,
    EXPIRED_SESSION_MODAL_SECONDARY_BUTTON,
  } = localizedStrings(language);

  const labelButtonModal = {
    primaryButton: EXPIRED_SESSION_MODAL_PRIMARY_BUTTON,
    secundatyButtons: EXPIRED_SESSION_MODAL_SECONDARY_BUTTON,
  };

  const onSuccessModalHandler = () => {
    onSuccessModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="expired-session-modal--visivility"
      overlayClassName="expired-session-modal--visivility"
      primaryButtonHandler={onSuccessModalHandler}
      secondaryButtonHandler={onCloseModal}
      primaryButtonLabel={labelButtonModal.primaryButton}
      secondaryButtonLabel={labelButtonModal.secundatyButtons}
      centerContent
    >
      <CircleExpiredSessionIcon />
      <ExpiredSessionView language={language} />
    </Modal>
  );
};
export default ModalExpiredSession;

ModalExpiredSession.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  onSuccessModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
