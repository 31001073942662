import { CAMERA_MODE } from '../camera';
import { STEP_ITEMS_GUIDED_VISIT } from './guidedTourPhoto';
import { STEP_ITEMS_GUIDED_VISIT_VIDEO } from './guidedTourVideo';

export const onManagerDirectionButtons = (stepId, cameraMode) => {
  let manager = {};

  if (cameraMode !== CAMERA_MODE.RECORD) {
    const {
      INIT,
      FIRST,
      SECOND,
      THIRD,
      FOURTH,
      FIFTH,
      SIXTH,
      SEVENTH,
    } = STEP_ITEMS_GUIDED_VISIT;
    manager = {
      [INIT.ID]: { NEXT: FIRST.ID, BACK: null },
      [FIRST.ID]: { NEXT: SECOND.ID, BACK: INIT.ID },
      [SECOND.ID]: { NEXT: THIRD.ID, BACK: FIRST.ID },
      [THIRD.ID]: { NEXT: FOURTH.ID, BACK: SECOND.ID },
      [FOURTH.ID]: { NEXT: FIFTH.ID, BACK: THIRD.ID },
      [FIFTH.ID]: { NEXT: SIXTH.ID, BACK: FOURTH.ID },
      [SIXTH.ID]: { NEXT: SEVENTH.ID, BACK: FIFTH.ID },
      [SEVENTH.ID]: { NEXT: null, BACK: SIXTH.ID },
    };
  } else {
    const {
      INIT,
      FIRST,
      SECOND,
      THIRD,
      FOURTH,
      FIFTH,
      SIXTH,
    } = STEP_ITEMS_GUIDED_VISIT_VIDEO;
    manager = {
      [INIT.ID]: { NEXT: FIRST.ID, BACK: null },
      [FIRST.ID]: { NEXT: SECOND.ID, BACK: INIT.ID },
      [SECOND.ID]: { NEXT: THIRD.ID, BACK: FIRST.ID },
      [THIRD.ID]: { NEXT: FOURTH.ID, BACK: SECOND.ID },
      [FOURTH.ID]: { NEXT: FIFTH.ID, BACK: THIRD.ID },
      [FIFTH.ID]: { NEXT: SIXTH.ID, BACK: FOURTH.ID },
      [SIXTH.ID]: { NEXT: null, BACK: FIFTH.ID },
    };
  }
  return manager[stepId];
};
