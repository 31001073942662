/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button, Content } from '@lmig/lmds-react';
import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { STATUS_TYPES } from '@lmig-latam/adil-api-common-lib/constants';
import { formatString } from '@lmig-latam/adlib-ui/src/utils';
import SupportRequest from '../../actions/SupportRequest';
import { AppFooter, AppHeader, MenuFooter, Modal } from '../../components';
import { is2FADisabled } from '../../utils';
import {
  AUTH_TOKEN_COOKIE_NAME,
  EMAIL_TOKEN_COOKIE_NAME,
  ID_TROUBLE_REQUIRED,
} from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import {
  ERROR_GENERIC,
  PROCESS_SUPPORT,
  INVALID_STATUS,
  TERMS_CONDITIONS,
  DEFAULT,
} from '../../utils/navigationConstants';
import {
  getCookie,
  getCountryCode,
  navigate,
} from '../../utils/NavigationUtils';
import './styles.scss';
import {
  DISABLE_SUPPORT_REQUEST,
  GET_REGISTERS_SUPPORT_REQUEST,
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  UPDATE_USER_DETAILS,
} from '../../actions/ActionTypes';
import { validateEmailToken, validateSmsConfirmationCode } from '../../api';
import IconErrorAlert from './IconErrorAlert.svg';
import IconCircleAlert from '../../assets/icons/IconCircleAlert.svg';
import history from '../../utils/history';
import AuthToken from '../../utils/session/AuthToken';

const MySwal = withReactContent(Swal);
const {
  STATUS_CUSTOMER_NEW,
  STATUS_CUSTOMER_IN_PROGRESS,
  STATUS_CUSTOMER_RETAKE,
} = STATUS_TYPES;

const {
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
} = localizedStrings;

const createCheckboxList = (
  { id, description },
  activateButtonSend,
  register,
) => (
  <label key={id} className="adilForms__options__checkboxes">
    <input
      type="checkbox"
      value={id}
      {...register('supportOptions', {
        onChange: activateButtonSend,
      })}
    />
    {description}
  </label>
);

const FormSupport = () => {
  const {
    settings: {
      environment: { language },
      lastPath,
    },
    user: { userInspectionStatus: inspectionStatusUser },
  } = useSelector(store => store);

  const [requiredText, setRequiredText] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [textLength, setTextLenght] = useState(0);
  const [optionsArray, setOptionsArray] = useState([]);
  const [optionsArrayRequired, setOptionsArrayRequired] = useState([]);
  const [
    displaySupportRqInProgressModal,
    setDisplaySupportRqInProgressModal,
  ] = useState(false);
  const dispatch = useDispatch();

  const goToErrorPage = (path = ERROR_GENERIC) => {
    history.push({
      pathname: path,
      search: history.location.search,
    });
  };

  const showAppLoader = () => {
    dispatch({
      type: SHOW_APP_LOADER,
    });
  };

  const hideAppLoader = () => {
    dispatch({
      type: HIDE_APP_LOADER,
    });
  };

  const getUserInspectionStatus = async () => {
    let currentStatus = inspectionStatusUser;

    try {
      if (!currentStatus || currentStatus === '') {
        const { status, vehicleClass } = await validateEmailToken();
        const userInspectionStatus = status;
        currentStatus = status;
        const vehicleClassInspection = vehicleClass;
        dispatch({
          type: UPDATE_USER_DETAILS,
          value: {
            userInspectionStatus,
            vehicleClassInspection,
          },
        });
      }

      if (
        ![
          STATUS_CUSTOMER_NEW,
          STATUS_CUSTOMER_IN_PROGRESS,
          STATUS_CUSTOMER_RETAKE,
        ].includes(currentStatus)
      ) {
        throw new Error('Invalid Status');
      }
    } catch (error) {
      if (error.message === 'Invalid Status') {
        setDisplaySupportRqInProgressModal(true);
      } else {
        goToErrorPage();
      }
      hideAppLoader();
    }
  };

  const getSRData = useCallback(async () => {
    try {
      const responseSR = await SupportRequest.getSupportRequestDb(
        getCountryCode().toLocaleLowerCase(),
      );
      const { troubles, troublesRequired } = responseSR;
      setOptionsArrayRequired(troublesRequired.reverse());
      const activeTroubles = troubles.filter(
        trouble => trouble.activeOption === 'active',
      );
      activeTroubles.sort((a, b) => a.sort - b.sort);
      setOptionsArray(activeTroubles);

      dispatch({
        type: GET_REGISTERS_SUPPORT_REQUEST,
        payload: {
          troubles,
          troublesRequired,
        },
      });
      hideAppLoader();
    } catch (error) {
      goToErrorPage();
      hideAppLoader();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showAppLoader();
    getUserInspectionStatus();
    getSRData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    FORM_SUPPORT_TITLE,
    FORM_SUPPORT_SUBTITLE,
    FORM_SUPPORT_TEXT_AREA,
    FORM_SUPPORT_SEND_SUPPORT,
    FORM_SUPPORT_REQUIRED_INPUTS,
    FORM_SUPPORT_REQUIRED_INPUTS_TEXT_LENGTH,
    PROCESS_SUPPORT_SUBTITLE_TWO,
    PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT,
    PROCESS_SUPPORT_SUBTITLE_THREE,
    PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT,
    PROCESS_SUPPORT_SUBTITLE_FOUR,
    PROCESS_SUPPORT_IN_PROGRESS_MODAL_TITLE,
    PROCESS_SUPPORT_IN_PROGRESS_MODAL_SUBTITLE,
    PROCESS_SUPPORT_IN_PROGRESS_MODAL_ATTENTION_SCHEDULE,
    PROCESS_SUPPORT_IN_PROGRESS_MODAL_WORKING_HOURS,
  } = localizedStrings(language);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { isValid },
  } = useForm();

  const onSubmit = async data => {
    if (!isValid) return;
    const itemsSupport = optionsArray.filter(
      // eslint-disable-next-line no-shadow
      options => data.supportOptions.indexOf(options.id) > -1,
    );

    data.supportOptions.forEach(id => {
      if (ID_TROUBLE_REQUIRED.indexOf(id) !== -1) {
        let troubleRequired = {};
        if (ID_TROUBLE_REQUIRED[0] === id) {
          troubleRequired = optionsArrayRequired.find(
            element => ID_TROUBLE_REQUIRED[0] === element.id,
          );
        }
        if (ID_TROUBLE_REQUIRED[1] === id) {
          troubleRequired = optionsArrayRequired.find(
            element => ID_TROUBLE_REQUIRED[1] === element.id,
          );
        }
        itemsSupport.push(troubleRequired);
      }
    });

    const supportObject = {
      countryCode: getCountryCode().toLocaleLowerCase(),
      emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
      troubles: itemsSupport,
      textareaSupport: data.textareaSupport,
    };

    showAppLoader();

    const authToken = getCookie(AUTH_TOKEN_COOKIE_NAME);
    if (authToken.length === 0) {
      try {
        const { authorizationToken } = await validateSmsConfirmationCode(
          process.env.REACT_APP_SMS_CONFIRMATION_CODE,
          true,
        );

        // Set auth cookie expiry to 2 hours. Same as the JWT token expiry
        AuthToken.setAuthToken(authorizationToken);
      } catch (error) {
        hideAppLoader();
        goToErrorPage(error === 'Invalid Status' ? INVALID_STATUS : null);
      }
    }

    await SupportRequest.sendSupportRequestApp(supportObject)
      .then(async response => {
        const { newStatus, lastCustomerStatus } = response;
        dispatch({
          type: UPDATE_USER_DETAILS,
          value: {
            userInspectionStatus: newStatus,
            lastCustomerStatus,
          },
        });
        navigate(PROCESS_SUPPORT);
      })
      .catch(() => {
        MySwal.fire({
          title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
          text: ADMIN_SUPPORT_ERROR_GENERAL,
          allowOutsideClick: false,
          customClass: {
            htmlContainer: 'sweetAdil__container',
            title: 'sweetAdil__title',
            confirmButton: 'sweetAdil__acceptButton',
          },
        });
      })
      .finally(() => {
        hideAppLoader();
      });
  };

  const handleCancel = () => {
    const initialPage = is2FADisabled() ? TERMS_CONDITIONS : DEFAULT;
    reset();
    setIsDisabled(true);
    dispatch({
      type: DISABLE_SUPPORT_REQUEST,
    });

    navigate(lastPath || initialPage);
  };

  const activateButtonSend = () => {
    const valuesForm = getValues();
    const optionRequired = watch('supportOptions');
    const hasSupportOptions = valuesForm?.supportOptions.length > 0;
    const isOptionRequired = optionRequired.includes(ID_TROUBLE_REQUIRED[0]);
    const isTextEmpty = textLength === 0;

    setIsDisabled(
      !(hasSupportOptions && (isOptionRequired ? !isTextEmpty : true)),
    );
    setRequiredText(isOptionRequired && isTextEmpty);
  };

  const textLengthCalculator = text => {
    const { length } = text.target.value;
    setTextLenght(length);
    const optionRequired = watch('supportOptions');
    const isRequired = optionRequired.includes(ID_TROUBLE_REQUIRED[0]);
    const isTextEmpty = length === 0;

    setRequiredText(isRequired && isTextEmpty);
    setIsDisabled(isRequired && isTextEmpty);
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  const renderSupportRqInProgressModal = () => (
    <Modal
      isOpen={displaySupportRqInProgressModal}
      closeButtonHandler={handleCancel}
      primaryButtonLabel="CONTINUAR INSPECCIÓN"
      primaryButtonHandler={handleCancel}
    >
      <div>
        <div className="supportRq-InProgress-modal-image">
          <img
            src={IconCircleAlert}
            alt="PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT"
          />
        </div>
        <div className="supportRq-InProgress-modal-content-container">
          <div className="supportRq-InProgress-modal-text-content-container">
            <div className="supportRq-InProgress-modal-title">
              {PROCESS_SUPPORT_IN_PROGRESS_MODAL_TITLE}
            </div>
            <div className="supportRq-InProgress-modal-subtitle">
              <div>
                <strong>{PROCESS_SUPPORT_IN_PROGRESS_MODAL_SUBTITLE}</strong>
              </div>
              <br />
              <div>
                <strong>
                  {PROCESS_SUPPORT_IN_PROGRESS_MODAL_ATTENTION_SCHEDULE}
                </strong>
              </div>
              <br />
              <div>{PROCESS_SUPPORT_IN_PROGRESS_MODAL_WORKING_HOURS}</div>
              <div>
                <p>
                  <span>{PROCESS_SUPPORT_SUBTITLE_TWO}</span>
                  <span className="supportRq-InProgress-modal-subtitle-blue-text">
                    {PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT}
                  </span>
                  <span>{PROCESS_SUPPORT_SUBTITLE_THREE}</span>
                  <span className="supportRq-InProgress-modal-subtitle-blue-text">
                    {PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT}
                  </span>
                  <span>{PROCESS_SUPPORT_SUBTITLE_FOUR}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="adil-screen">
      {renderSupportRqInProgressModal()}
      <AppHeader displaySubtitle />
      <div className="adilForms-header-container">
        <div
          className="adilForms-header-icon"
          onClick={handleCancel}
          role="button"
        />
        <div className="adilForms-header-text">{FORM_SUPPORT_TITLE}</div>
      </div>
      <Content className="adil-content process-complete">
        <form className="formsAdil" onSubmit={handleSubmit(onSubmit)}>
          <div className="adilForms">
            <h4 className="adilForms__title">{FORM_SUPPORT_SUBTITLE}</h4>
            <div className="adilForms__options">
              {optionsArray.map(option =>
                createCheckboxList(option, activateButtonSend, register),
              )}
              {optionsArrayRequired?.map(option =>
                createCheckboxList(option, activateButtonSend, register),
              )}
            </div>
            {requiredText && (
              <div className="adilForms-textArea-container">
                <img
                  className="adilForms-textArea-icon-alert"
                  src={IconErrorAlert}
                  alt="IconErrorAlert"
                />
                <label className="adilForms-textArea-error">
                  {FORM_SUPPORT_REQUIRED_INPUTS}
                </label>
              </div>
            )}
            <textarea
              maxLength={1000}
              className={`adilForms__inputs ${
                requiredText ? 'adilForms__requiredBorder' : ''
              }`}
              placeholder={FORM_SUPPORT_TEXT_AREA}
              {...register('textareaSupport', {
                required: requiredText,
                onChange: textLengthCalculator,
              })}
            />
            <label className="adilForms-text-box-length">
              {formatString(FORM_SUPPORT_REQUIRED_INPUTS_TEXT_LENGTH, {
                length: textLength.toString(),
              })}
            </label>
          </div>
          <div className="adilForms__buttons">
            <Button
              type="submit"
              id="adilForms__submit"
              disabled={isDisabled}
              className={
                isDisabled
                  ? 'form-support-button__disabled'
                  : 'form-support-button__enabled'
              }
            >
              {FORM_SUPPORT_SEND_SUPPORT}
            </Button>
          </div>
        </form>
        {renderFooterSection()}
      </Content>
      <MenuFooter />
    </div>
  );
};

export default FormSupport;
