/* eslint-disable no-plusplus */
import {
  DELETE_PHOTO,
  DISPLAY_VIDEO_SECTION,
  GET_PRESIGNED_URL_ARRAY,
  RESET_PRESIGNED_URL_ARRAY,
  RESET_VIDEO_SECTION,
  STORE_PHOTO,
  SET_NAMED_DRIVERS,
  ON_CHANGE_CURRENT_STEP,
  ON_CHANGE_SPARE_WHEEL_TOGGLE,
  SET_WIZARD_STATE_BY_VEHICLE_CLASS,
  ON_CLICK_PHOTO_HUB_BUTTON_STEP,
} from '../actions/ActionTypes';
import {
  VIDEO_PHOTOS_TAKEN_LIST,
  STORAGE_IS_FINISHED_VIDEO,
} from '../constants/storage';
import {
  getClassificationPhotos,
  getInitialWizardPhotoHub,
  initialWizardPhotoHub,
} from '../constants/wizardPhotos';
import { getCookie } from '../utils/NavigationUtils';
import { PhotoHubBuilder } from '../utils/PhotoHubBuilder';
import { PHOTO_TYPES, RETAKE_DATA_COOKIE_NAME } from '../utils/constants';

const STORAGE_DISPLAY_VIDEO_SECTION_KEY = 'STORAGE_DISPLAY_VIDEO_SECTION_KEY';

const getEnableVideoSection = () => {
  const item = localStorage.getItem(STORAGE_DISPLAY_VIDEO_SECTION_KEY);
  return item === 'true';
};
const getVideoPhotosTaken = () => {
  const isVideoFinished = JSON.parse(
    localStorage.getItem(STORAGE_IS_FINISHED_VIDEO),
  );
  const photos = localStorage.getItem(VIDEO_PHOTOS_TAKEN_LIST);
  return !isVideoFinished?.status ? [] : JSON.parse(photos) || [];
};

const getPresignedURLFromStorage = () => {
  const urls = localStorage.getItem('PRESIGNED_URLS');
  if (urls) return JSON.parse(urls);
  return [];
};

const setInitialStepsToShow = () => {
  const arrayByDefault = Array.from(Array(initialWizardPhotoHub.length)).map(
    (e, i) => i + 1,
  );
  const validateSteps = requiredPhotos => {
    const { required, accessories, conditionallyRequired } = requiredPhotos;
    const stepsToShow = [];
    const MAXIMUM_NUMBER_OF_STEPS = initialWizardPhotoHub.length;

    const vehicleClass = sessionStorage.getItem('VEHICLE_CLASS');
    const photosClassification = getClassificationPhotos(vehicleClass);
    for (let step = 1; step <= MAXIMUM_NUMBER_OF_STEPS; step += 1) {
      if (
        required.some(element => photosClassification[step].includes(element))
      ) {
        stepsToShow.push(step);
      }
    }

    if (conditionallyRequired.length > 0 && !stepsToShow.includes(3)) {
      stepsToShow.push(3);
    }

    if (
      accessories &&
      accessories.length > 0 &&
      !stepsToShow.includes(MAXIMUM_NUMBER_OF_STEPS)
    )
      stepsToShow.push(MAXIMUM_NUMBER_OF_STEPS);

    return stepsToShow.length > 0 ? stepsToShow : arrayByDefault;
  };

  const stepRetake = getCookie(RETAKE_DATA_COOKIE_NAME);

  if (stepRetake.length > 0) return validateSteps(JSON.parse(stepRetake));

  return arrayByDefault;
};

export const initialState = {
  drivers: [],
  spareWheelToggleEnabled: true,
  stepsToShow: setInitialStepsToShow(),
  currentStep: setInitialStepsToShow()[0],
  wizardPhotoHub: initialWizardPhotoHub,
  presignedURLS: getPresignedURLFromStorage(),
  videoPhotosTaken: getVideoPhotosTaken(),
  isEnableVideoSection: getEnableVideoSection() || false,
  isAllowContinueStep: false,
  hintsModalDisplayed: false,
};

const storePhotoHandler = (state, incomingPhoto) => {
  const { step, photoType } = incomingPhoto;
  const result = new PhotoHubBuilder(state.wizardPhotoHub)
    .setStep(step)
    .setPhotoType(photoType)
    .storePhoto(incomingPhoto)
    .getPhotoArray();
  return { ...state, wizardPhotoHub: result };
};

const deletePhotoHandler = (state, incomingPhotoId, isDamage) => {
  const result = new PhotoHubBuilder(state.wizardPhotoHub)
    .setPhotoType(
      isDamage ? PHOTO_TYPES.DAMAGE_PHOTO : PHOTO_TYPES.ACCESSORY_PHOTO,
    )
    .removePhoto(incomingPhotoId)
    .getPhotoArray();

  return { ...state, wizardPhotoHub: result };
};

const storeURLPresigned = (state, { presignedURLS }) => {
  localStorage.setItem('PRESIGNED_URLS', JSON.stringify(presignedURLS));
  return {
    ...state,
    presignedURLS,
  };
};

const setNamedDrivers = (state, { drivers }) => ({
  ...state,
  drivers,
});

export default (
  state = initialState,
  { type, payload, photoId, photo, isDamage } = {},
) => {
  switch (type) {
    case STORE_PHOTO:
      return storePhotoHandler(state, photo);
    case DELETE_PHOTO:
      return deletePhotoHandler(state, photoId, isDamage);
    case DISPLAY_VIDEO_SECTION:
      return {
        ...state,
        isEnableVideoSection: payload.status,
      };
    case RESET_VIDEO_SECTION: {
      localStorage.removeItem(STORAGE_DISPLAY_VIDEO_SECTION_KEY);
      return { ...state, videoPhotosTaken: [] };
    }
    case RESET_PRESIGNED_URL_ARRAY: {
      localStorage.removeItem('PRESIGNED_URLS');
      return { ...state, presignedURLS: [] };
    }
    case GET_PRESIGNED_URL_ARRAY:
      return storeURLPresigned(state, payload);
    case SET_NAMED_DRIVERS:
      return setNamedDrivers(state, payload);
    case ON_CHANGE_SPARE_WHEEL_TOGGLE:
      return { ...state, spareWheelToggleEnabled: payload.isEnabled };
    case SET_WIZARD_STATE_BY_VEHICLE_CLASS:
      return {
        ...state,
        wizardPhotoHub: getInitialWizardPhotoHub(payload.vehicleClass),
      };
    case ON_CHANGE_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload.step,
        ...(payload.stepsToShow && {
          stepsToShow: payload.stepsToShow,
        }),
      };
    case ON_CLICK_PHOTO_HUB_BUTTON_STEP:
      return {
        ...state,
        isAllowContinueStep: payload.isAllow,
      };
    default:
      return state;
  }
};
