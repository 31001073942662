import {
  SET_CURRENT_LOCATION_GPS,
  SET_INFORMATION_MEMORY_HEAD,
  SET_GPS_STATUS,
  SET_USER_AGENT_DEVICE,
} from '../actions/ActionTypes';

export const initialState = {
  alreadyCheckInfo: false,
  headMemoryInfo: undefined,
  userAgentDevice: null,
  GPSStatus: null,
  geoLocation: null,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_USER_AGENT_DEVICE:
      return { ...state, userAgentDevice: payload.userAgent };
    case SET_INFORMATION_MEMORY_HEAD:
      return {
        ...state,
        alreadyCheckInfo: true,
        headMemoryInfo: payload.info,
      };
    case SET_CURRENT_LOCATION_GPS: {
      const { coordinates } = payload;
      return {
        ...state,
        geoLocation: coordinates,
      };
    }
    case SET_GPS_STATUS: {
      const { status, code } = payload;
      return { ...state, GPSStatus: { message: status, code } };
    }
    default:
      return state;
  }
};
