import {
  BodyText,
  Button,
  Caption,
  Content,
  GridRow,
  Heading,
  IconDocumentMultiple,
} from '@lmig/lmds-react';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ulid } from 'ulid';
import {
  TermsConditionsActions,
  SettingsActions,
  ModalFlowActions,
  SessionActions,
  AuthActions,
} from '../../actions';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  FloatButtonMenu,
  MenuFooter,
  Modal,
} from '../../components';
import { isAsianDomain } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import { getCookie, getCountryCode } from '../../utils/NavigationUtils';
import CloseSession from '../../utils/session/CloseSession';
import { LOGOUT_TYPE } from '../../constants/session';
import { InactivitySession } from '../../utils/session/InactivitySession';
import history from '../../utils/history';
import { PHOTO_HUB } from '../../utils/navigationConstants';
import { EMAIL_TOKEN_COOKIE_NAME } from '../../utils/constants';
import './styles.scss';

const localizedStringsCommon = require('@lmig-latam/adil-api-common-lib/utils/localizedStrings');

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

const TermsConditions = ({
  termsConditionsActions: {
    acceptTermsAndConditions,
    validateEmailTokenAndGenerateAuthToken,
  },
  settingsActions: { showAppLoader, hideAppLoader },
}) => {
  const [
    termsConditionModalDisplayed,
    setTermsConditionModalDisplayed,
  ] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const {
    settings: {
      environment: { language },
    },
    user: { vehicleClass, userInspectionStatus },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  const {
    TERMS_CONDITIONS_SUBTITLE_2,
    TERMS_CONDITIONS_MOTORBIKE_SUBTITLE_2,
    TERMS_CONDITIONS_SHOW_MODAL_BUTTON,
    TERMS_CONDITIONS_DISCLAIMER_2,
    TERMS_CONDITIONS_CONTINUE_BUTTON,
    TERMS_CONDITIONS_MODAL_CONTENT,
    TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL,
  } = localizedStrings(language);

  const getSubtitle = () => {
    let subTitle = TERMS_CONDITIONS_SUBTITLE_2;

    if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
      subTitle = TERMS_CONDITIONS_MOTORBIKE_SUBTITLE_2;
    }
    return subTitle;
  };

  const showTermsConditionsModal = () => {
    setTermsConditionModalDisplayed(true);
  };

  const hideTermsConditionsModal = () => {
    setTermsConditionModalDisplayed(false);
  };

  const handleTermsConditionsCheckbox = () => {
    setDisableSubmitButton(!disableSubmitButton);
  };

  const submit = async () => {
    showAppLoader();
    setDisableSubmitButton(true);

    await acceptTermsAndConditions(userInspectionStatus, vehicleClass);

    const isLogoutByInactivity =
      CloseSession.getLogoutType() === LOGOUT_TYPE.INACTIVITY_SESSION;

    if (isLogoutByInactivity) {
      const inactivitySession = new InactivitySession(getCountryCode());
      const nextTimestamp = inactivitySession.getNextTimerTimestamp();

      dispatch(SessionActions.onChangeMainInactivityTimer(nextTimestamp));
    } else {
      dispatch(ModalFlowActions.onDisplayInstructionsModal(true));
    }

    dispatch(AuthActions.setAuthenticationStatus(true));
    history.push(`${PHOTO_HUB}?token=${getCookie(EMAIL_TOKEN_COOKIE_NAME)}`);
    hideAppLoader();
  };

  const hideTermsConditionsModalContinue = async () => {
    setTermsConditionModalDisplayed(false);
    await submit();
  };

  useEffect(() => {
    showAppLoader();
    validateEmailTokenAndGenerateAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailTokenAndGenerateAuthToken]);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  const renderFooterContents = () => (
    <GridRow className="terms-conditions-footer">
      <Button
        className="button-submit"
        type="button"
        onClick={submit}
        disabled={disableSubmitButton}
      >
        {TERMS_CONDITIONS_CONTINUE_BUTTON}
      </Button>
    </GridRow>
  );

  return (
    <div className="adil-screen">
      <Modal
        isOpen={termsConditionModalDisplayed}
        primaryButtonLabel={TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL}
        primaryButtonHandler={hideTermsConditionsModalContinue}
        closeButtonHandler={hideTermsConditionsModal}
      >
        <div className="terms-conditions-modal-body">
          <CircleImage>
            <IconDocumentMultiple sizing="auto" />
          </CircleImage>
          <BodyText className="terms-conditions-modal-body-text">
            {TERMS_CONDITIONS_MODAL_CONTENT &&
              TERMS_CONDITIONS_MODAL_CONTENT[
                getCountryCode().toUpperCase()
              ].map((paragraph, index) => (
                <div className="terms-conditions-body-item" key={ulid()}>
                  <h3
                    className={
                      index === 0
                        ? 'terms-conditions-maintitle'
                        : 'terms-conditions-restitle'
                    }
                  >
                    {paragraph.TERMS_CONDITIONS_MODAL_CONTENT_TITLE}
                  </h3>
                  <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html:
                        paragraph.TERMS_CONDITIONS_MODAL_CONTENT_PARAGRAHP,
                    }}
                  />
                </div>
              ))}
          </BodyText>
        </div>
      </Modal>

      <AppHeader displaySubtitle />
      <Content className="adil-content">
        <div className="content-terms-conditions">
          <CircleImage>
            <IconDocumentMultiple sizing="auto" />
          </CircleImage>
          <Heading
            type="h4-light"
            className="terms-conditions-subtitle auto-left-right-margin"
          >
            {getSubtitle()}
          </Heading>
          <div className="terms-conditions-checkbox">
            <input type="checkbox" onChange={handleTermsConditionsCheckbox} />
            <Caption className="terms-conditions-disclaimer">
              {TERMS_CONDITIONS_DISCLAIMER_2}
              <Caption
                className="terms-conditions-disclaimer-show-modal"
                onClick={showTermsConditionsModal}
              >
                {TERMS_CONDITIONS_SHOW_MODAL_BUTTON}
              </Caption>
            </Caption>
          </div>
          {renderFooterContents()}
        </div>
        {renderFooterSection()}
      </Content>
      <div>
        <MenuFooter />
      </div>

      {isAsianDomain() && <FloatButtonMenu />}
    </div>
  );
};

TermsConditions.propTypes = {
  termsConditionsActions: TermsConditionsActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  termsConditionsActions: bindActionCreators(TermsConditionsActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(TermsConditions);
