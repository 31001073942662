import '@lmig/lmds-styles/lm-styles.css';
import React from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { AppLoader } from './components';
import * as serviceWorker from './serviceWorker';
import { App } from './screens';
import { setupAppConfigs } from './utils';
import { store } from './utils/configureStore';
import 'react-tridi/dist/index.css';
import './styles/main.scss';

// Set the anchor point for any modals displayed throughout the app
Modal.setAppElement('#root');

setupAppConfigs();

render(
  <Provider store={store}>
    <AppLoader />
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
