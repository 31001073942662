import {
  SET_INFORMATION_MEMORY_HEAD,
  SET_CURRENT_LOCATION_GPS,
  SET_GPS_STATUS,
  SET_USER_AGENT_DEVICE,
} from './ActionTypes';

export const getDeviceInfoMemoryHead = () => {
  // It's deprecated
  const heapSizeLimitInBytes = performance.memory.jsHeapSizeLimit; // will give you the JS heap size
  const usedHeapSize = performance.memory.usedJSHeapSize; // how much you're currently using
  return {
    type: SET_INFORMATION_MEMORY_HEAD,
    payload: { info: { heapSizeLimitInBytes, usedHeapSize } },
  };
};

export const onChangeCurrentLocationGPS = coordinates => ({
  type: SET_CURRENT_LOCATION_GPS,
  payload: { coordinates },
});

export const setGPSStatus = (status, code) => ({
  type: SET_GPS_STATUS,
  payload: { status, code },
});

export const setUserAgentDevice = userAgent => ({
  type: SET_USER_AGENT_DEVICE,
  payload: { userAgent },
});

export default {
  getDeviceInfoMemoryHead,
  onChangeCurrentLocationGPS,
  setGPSStatus,
  setUserAgentDevice,
};
