import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  GenericError,
  InvalidStatus,
  PageNotFound,
  PhoneNumberConfirmation,
  ProcessComplete,
  TermsConditions,
  ProcessSupport,
  FormSupport,
  WizardPhotoHub,
} from '.';
import history from '../utils/history';
import {
  DEFAULT,
  ERROR_GENERIC,
  INVALID_STATUS,
  PHOTO_HUB,
  PROCESS_COMPLETE_ORIGINAL,
  PROCESS_COMPLETE,
  TERMS_CONDITIONS,
  TAKE_PHOTO,
  PROCESS_SUPPORT,
  FORM_SUPPORT,
} from '../utils/navigationConstants';
import CapturePhoto from './CapturePhoto';

function Routes({ is2FADisabled }) {
  const redirectTermAndConditions = () => {
    const supportRq = new URLSearchParams(window.location.search).get(
      'supportRq',
    );
    return (
      <Route
        exact
        path={DEFAULT}
        render={() => (
          <Redirect
            to={`${TERMS_CONDITIONS}?token=${new URLSearchParams(
              window.location.search,
            ).get('token')}${supportRq !== null ? '&supportRq=true' : ''}`}
          />
        )}
      />
    );
  };

  return (
    <Router history={history}>
      <Switch>
        {is2FADisabled() ? (
          redirectTermAndConditions()
        ) : (
          <Route exact path={DEFAULT}>
            <PhoneNumberConfirmation />
          </Route>
        )}
        <Route exact path={TERMS_CONDITIONS}>
          <TermsConditions />
        </Route>
        <Route exact path={PHOTO_HUB}>
          <WizardPhotoHub />
        </Route>
        <Route path={PROCESS_COMPLETE_ORIGINAL}>
          <ProcessComplete />
        </Route>
        <Route path={PROCESS_COMPLETE}>
          <ProcessComplete />
        </Route>
        <Route path={PROCESS_SUPPORT}>
          <ProcessSupport />
        </Route>
        <Route exact path={FORM_SUPPORT}>
          <FormSupport />
        </Route>
        <Route exact path={TAKE_PHOTO}>
          <CapturePhoto />
        </Route>
        <Route exact path={ERROR_GENERIC}>
          <GenericError />
        </Route>
        <Route exact path={INVALID_STATUS}>
          <InvalidStatus />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
}

Routes.propTypes = {
  is2FADisabled: PropTypes.func.isRequired,
};

export default Routes;
