/* eslint-disable react/forbid-prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import { CAMERA_MODE, FACING_MODE } from '../../../constants/camera';
import { useScreenRatio, useScreenSize } from '../CameraHooks';
import { logcodes, logger } from '../../../utils/logger';
import { TakePhotoActions } from '../../../actions';
import CameraOverlay from '../CameraOverlay';
import { logDeviceError } from '../../../api';
import SplashLoading from '../SplashLoading/SplashLoading';
import localizedStrings from '../../../utils/localizedStrings';
import './styles.scss';
import { calculateRatio } from '../utils/screen';

const WebcamIos = () => {
  const [screenSize] = useScreenSize();
  const [ratio] = useScreenRatio(screenSize);
  const [videoConstraints, setVideoConstraints] = useState(null);
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const isLandscape = window.innerHeight <= window.innerWidth;

  const {
    takePhoto: { isPressButtonTakePhoto, cameraMode, overlay },
    settings: {
      environment: { language },
    },
  } = useSelector(store => store);

  const { LOADING_MESSAGE_CAMERA_PHOTO } = localizedStrings(language);

  const configCamera = useMemo(() => {
    const offset = window.innerHeight <= window.innerWidth ? 3.8 : 2.8;
    const facingMode = FACING_MODE.BACK_FACING;
    const newRatio = calculateRatio();

    return {
      ratio: newRatio,
      facingMode,
      width: window.innerWidth * offset,
      height: window.innerHeight,
    };
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      setVideoConstraints(configCamera);
    }, 1000);
    return () => clearTimeout(interval);
  }, [configCamera]);

  const activateOverlay = () => cameraMode === CAMERA_MODE.PHOTO && overlay;

  const onUserMedia = useCallback(
    media => {
      setLoading(false);
      logger.log(logcodes.CAMV110, { media, screenSize, ratio });
    },
    [ratio, screenSize],
  );

  const onUserMediaError = useCallback(async error => {
    logger.log(logcodes.CAMV10, { error });
    await logDeviceError({
      errorData: {
        type: error.name,
        message: error.message,
      },
    });
  }, []);

  const onCapturePhoto = useCallback(async () => {
    if (webcamRef.current) {
      let imageSrc;
      if (isLandscape) {
        imageSrc = webcamRef.current.getScreenshot({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      } else {
        imageSrc = webcamRef.current.getScreenshot();
      }
      dispatch(TakePhotoActions.setCurrentPhotoPreview(imageSrc));
    }
  }, [dispatch, isLandscape]);

  useEffect(() => {
    if (isPressButtonTakePhoto) {
      onCapturePhoto();
      setTimeout(
        () => dispatch(TakePhotoActions.onPressButtonTakePhotoStatus(false)),
        500,
      );
    }
  }, [dispatch, isPressButtonTakePhoto, onCapturePhoto]);

  useEffect(() => setLoading(true), []);

  return (
    <>
      {loading && (
        <SplashLoading translucid label={LOADING_MESSAGE_CAMERA_PHOTO} />
      )}
      <div className="containerCamera">
        {activateOverlay() && <CameraOverlay iosCamera />}
        {videoConstraints && (
          <Webcam
            imageSmoothing
            forceScreenshotSourceSize
            className={`webcam-camera ${
              activateOverlay() ? 'overlayActived' : ''
            }`}
            screenshotFormat="image/webp"
            height={window.innerHeight}
            width={window.innerWidth}
            videoConstraints={videoConstraints}
            onUserMediaError={onUserMediaError}
            onUserMedia={onUserMedia}
            screenshotQuality={1}
            ref={webcamRef}
            audio={false}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(WebcamIos);
