import axios from 'axios';
import { getEnvironmentConfig, isLocalhost } from '../config/environments';
import { store } from '../utils/configureStore';
import { EMAIL_TOKEN_COOKIE_NAME } from '../utils/constants';
import {
  checkForEmailToken,
  checkForTokens,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { logcodes, logger } from '../utils/logger';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { getCookie, getCountryCode, navigate } from '../utils/NavigationUtils';
import AuthToken from '../utils/session/AuthToken';

const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';

const buildBody = (method, body = {}) => {
  if ([GET, DELETE].includes(method)) {
    return null;
  }

  return JSON.stringify({
    ...body,
    emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
    countryCode: body.countryCode
      ? body.countryCode.toLowerCase()
      : getCountryCode(),
  });
};

const getRequestHeaders = (extraHeaders = {}) => {
  const authToken = AuthToken.getAuthToken();

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: authToken,
  };
  return { ...headers, ...extraHeaders };
};

const buildQueryStringFromObject = queryObject => {
  const queryStrings = [];
  let queryString = '';

  Object.keys(queryObject).forEach(key => {
    if (queryObject[key]) {
      queryStrings.push(`${key}=${queryObject[key]}`);
    }
  });

  if (queryStrings.length > 0) {
    queryString += `?${queryStrings.join('&')}`;
  }

  return queryString;
};

// Only log Client error if it is not already being logged by the API
const shouldLogClientError = response => !response.code;

const buildFullPath = (path, extraParams = {}) => {
  const {
    settings: {
      environment: { appUrl },
    },
  } = store.getState();

  const queryObject = {
    ...extraParams,
    countryCode: getCountryCode(),
  };

  const fullPath = isLocalhost() ? `${appUrl}/api/${path}` : `/api/${path}`;

  return `${fullPath}${buildQueryStringFromObject(queryObject)}`;
};

const serviceSuccessHandler = async (
  response,
  isLoggingPath,
  fullPath,
  disableErrorHandling,
) => {
  // Get the raw text of the response
  let parsedResponse = await response.text();

  // If the raw text has a length, then parse it as JSON, otherwise assign an empty object
  parsedResponse = parsedResponse.length ? JSON.parse(parsedResponse) : {};

  if (!response.ok && !isLoggingPath) {
    if (shouldLogClientError(parsedResponse)) {
      logger.log(logcodes.CAAPI110, {
        path: fullPath,
        status: response.status,
      });
    }

    if (response.status === 401) {
      return Promise.reject(new TokenError(logcodes.CANAV120.code));
    }

    if (parsedResponse.data && parsedResponse.data.invalidStatus) {
      return Promise.reject(
        new InvalidStatusError(parsedResponse.data.invalidStatus),
      );
    }

    return disableErrorHandling
      ? Promise.reject(parsedResponse)
      : navigate(ERROR_GENERIC);
  }

  return Promise.resolve(parsedResponse);
};

const serviceFailureHandler = (
  error,
  isLoggingPath,
  fullPath,
  disableErrorHandling,
) => {
  const { message, stack } = error;
  if (!isLoggingPath) {
    logger.log(logcodes.CAAPI120, {
      path: fullPath,
      message,
      stack,
    });
  }

  return disableErrorHandling ? Promise.reject(error) : null;
};

const callService = async (
  path,
  body,
  method,
  headers,
  params,
  disableErrorHandling = false,
) => {
  // Need to supress errors for the logging service to avoid endless loops
  const isLoggingPath = path === 'log';

  const fullPath = buildFullPath(path, params);

  try {
    const response = await fetch(fullPath, {
      method,
      credentials: 'same-origin',
      headers: getRequestHeaders(headers),
      body: buildBody(method, body),
    });

    return serviceSuccessHandler(
      response,
      isLoggingPath,
      fullPath,
      disableErrorHandling,
    );
  } catch (error) {
    return serviceFailureHandler(
      error,
      isLoggingPath,
      fullPath,
      disableErrorHandling,
    );
  }
};

const del = (path, disableErrorHandling, params) =>
  callService(path, null, DELETE, null, params, disableErrorHandling);

const post = (path, body, disableErrorHandling, headers) =>
  callService(path, body, POST, headers, null, disableErrorHandling);

const get = (path, disableErrorHandling) =>
  callService(path, null, GET, null, null, disableErrorHandling);

export const uploadPhotoList360 = async file => {
  const { id, image } = file;
  const data = {
    name: `RECORD-${id}`,
    emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
    file: image,
  };

  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  const fullPath = buildFullPath('video');

  await fetch(fullPath, {
    method: POST,
    credentials: 'same-origin',
    headers: {
      Authorization: AuthToken.getAuthToken(),
    },
    body: formData,
  });
};

export const getAuthorizationToken = () => {
  checkForTokens();
  return get('authorizer/token');
};

export const getSignedURL = async photoIdArray => {
  checkForTokens();
  const body = {
    getUrl: true,
    photosRequired: photoIdArray,
    emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
  };

  const fullPath = buildFullPath('images');

  try {
    return await fetch(fullPath, {
      method: POST,
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: {
        Authorization: AuthToken.getAuthToken(),
      },
    });
  } catch (error) {
    return undefined;
  }
};

export async function uploadPhoto({ file }, presignedURL, onUploadProgress) {
  checkForTokens();
  try {
    return await axios.put(presignedURL.url, file, {
      headers: {
        ContentType: 'image/jpeg',
        'X-Amz-Tagging': 'av-status=CLEAN',
      },
      onUploadProgress,
    });
  } catch (error) {
    return serviceFailureHandler(error, false, presignedURL, true);
  }
}

export const savePhotoDataInDB = async (
  photoToUpload,
  isDamage,
  geolocation,
) => {
  checkForTokens();
  const fullPath = buildFullPath('images');

  const body = {
    name: photoToUpload.photoId,
    emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
    geolocation,
    isDamage,
  };

  try {
    return await post('images', body, true);
  } catch (err) {
    return serviceFailureHandler(err, false, fullPath, true);
  }
};

export const deletePhoto = (photo, isDamage, expiredSession) => {
  checkForTokens();

  return del(`images/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/delete`, false, {
    name: photo,
    isDamage,
    expiredSession,
  });
};

export const deleteVideo = () => {
  checkForTokens();
  localStorage.removeItem('VIDEO_PHOTOS');
  return del(`video/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/delete`, false);
};

export const finishVideoRecord = async secondsVideo => {
  checkForTokens();
  const urlParams = new URLSearchParams({ isFinished: true });
  const fullPath = `${buildFullPath('video')}&${urlParams}`;
  const formData = new FormData();
  formData.append('secondsVideo', secondsVideo);
  formData.append('emailToken', getCookie(EMAIL_TOKEN_COOKIE_NAME));
  try {
    const response = await fetch(fullPath, {
      method: POST,
      credentials: 'same-origin',
      body: formData,
      headers: {
        Authorization: AuthToken.getAuthToken(),
      },
    });
    return serviceSuccessHandler(response, false, fullPath, true);
  } catch (error) {
    return serviceFailureHandler(error, false, fullPath, true);
  }
};

export const validateEmailToken = () => {
  checkForEmailToken();

  return post('emailtoken/validate', {}, true);
};

export const generateSmsConfirmationCode = (disableErrorHandling = false) => {
  checkForEmailToken();

  return post('code/sms', {}, disableErrorHandling);
};

export const getVendorSuport = dataUser => {
  checkForEmailToken();

  const config = getEnvironmentConfig();
  const url = `${
    config.vn.agentApiDomain
  }/api/inspection/externalVendor?token=${getCookie(EMAIL_TOKEN_COOKIE_NAME)}`;
  return axios.post(url, dataUser);
};

export const acceptTermsAndConditions = () => {
  checkForTokens();

  return post('terms/accept', {}, true);
};

export const validateSmsConfirmationCode = (
  smsConfirmationCode,
  isSupportRq = false,
) => {
  checkForEmailToken();
  const body = {
    smsCode: smsConfirmationCode,
    isSupportRq,
  };

  return post('code/sms/validate', body, true);
};

export const logMessage = body => {
  post('log', body, true);
};

export const logDeviceError = body => post('log-device/error', body, true);

export const finishProcess = () => {
  checkForTokens();

  return post('preinspection/finish', {}, true);
};

export const uploadInspektlabs = () => {
  checkForTokens();

  return post('preinspection/uploadInspektlabs', {}, true);
};

export const getRetakePhotos = () => {
  checkForTokens();

  return get(`images/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/retake`);
};

export const getPreviouslyTakenPhotos = () => {
  checkForTokens();
  const config = getEnvironmentConfig();
  const url = `${config.appUrl}/api/preinspection/${getCookie(
    EMAIL_TOKEN_COOKIE_NAME,
  )}/photos`;
  return axios.get(url, { params: { countryCode: getCountryCode() } });
};

export const getPhotosByVideo = () => {
  checkForTokens();
  const config = getEnvironmentConfig();
  const url = `${config.appUrl}/api/preinspection/${getCookie(
    EMAIL_TOKEN_COOKIE_NAME,
  )}/photos`;
  return axios.get(url, {
    params: { countryCode: getCountryCode(), gettingVideo: true },
  });
};

export const getResumePhotos = () => {
  checkForTokens();

  return get(`images/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/resume`);
};

export const getSupportRequest = () => get(`support`, true);

export const sendSupportRequest = arrayTroubles =>
  post(`support`, arrayTroubles, true);

// If you're adding functions here, be sure to mock them in config/jest/setup.js
export default {
  acceptTermsAndConditions,
  finishProcess,
  uploadInspektlabs,
  generateSmsConfirmationCode,
  getRetakePhotos,
  getResumePhotos,
  logMessage,
  uploadPhoto,
  deletePhoto,
  validateEmailToken,
  getSupportRequest,
  validateSmsConfirmationCode,
  getPreviouslyTakenPhotos,
  getSignedURL,
  getAuthorizationToken,
};
