import { LANGUAGES } from '@lmig-latam/adil-api-common-lib/constants';
import {
  getCurrentLanguage,
  getLanguageForCountryCode,
} from './localizedStrings';

const getLanguageCodeFromStorage = () => {
  if (localStorage.getItem('lang')) {
    return localStorage.getItem('lang');
  }
  return process.env.COUNTRY_CODE;
};

const getLanguageByCode = lang => {
  if ([LANGUAGES.CO, LANGUAGES.EC, LANGUAGES.CL].includes(lang))
    return getCurrentLanguage();
  return getLanguageForCountryCode(lang);
};

const setLanguageInLocalstorage = lang => {
  localStorage.setItem('lang', lang);
  return lang;
};

export {
  getLanguageCodeFromStorage,
  setLanguageInLocalstorage,
  getLanguageByCode,
};
