import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function PanelCameraTop({ onManagerTopButtons, buttonList }) {
  return (
    <div className="panel-camera-top">
      <div className="panel-camera-top__container">
        {buttonList.map(item => (
          <button
            key={item.id}
            type="button"
            className={`disable-decoration-button panel-button_${item.id}`}
            onClick={() => onManagerTopButtons(item.id)}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </div>
  );
}

PanelCameraTop.propTypes = {
  onManagerTopButtons: PropTypes.func.isRequired,
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      class: PropTypes.string.isRequired,
      icons: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  ).isRequired,
};

export default PanelCameraTop;
