/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconChevronLeft } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import { utils } from '@lmig-latam/adlib-ui';
import VideoHintsImage from '../../assets/gifs/video.gif';
import NotifyProcess from '../NotifyProcess/NotifyProcess';
import { navigate } from '../../utils/NavigationUtils';
import { TAKE_PHOTO } from '../../utils/navigationConstants';
import localizedStrings from '../../utils/localizedStrings';
import Modal from '../Modal';
import './styles.scss';

const { formatString } = utils;

const VideoHintsModal = ({ isOpen, language, closeButtonHandler }) => {
  const {
    user: { vehicleClass },
    takeVideo: { videoParameters },
  } = useSelector(store => store);

  const { durationVideo } = videoParameters;

  const {
    PHOTO_HUB_VIDEO_HINTS_TITLE,
    PHOTO_HUB_VIDEO_HINTS_DESCRIPTION,
    PHOTO_HUB_VIDEO_HINTS_WARNING,
    PHOTO_HUB_VIDEO_HINTS_PRIMARY_BUTTON,
    PHOTO_HUB_VIDEO_HINTS_MINUTE,
    PHOTO_HUB_VIDEO_HINTS_MINUTES,
  } = localizedStrings(language);

  const BUTTON_IDS = {
    BACK: `${vehicleClass.toUpperCase()}-VIDEO-HINTS-BACK_BUTTON`,
    GO_TO_TAKE_VIDEO: `${vehicleClass.toUpperCase()}-HINTS-CONTINUE_TO_TAKE_VIDEO_BUTTON`,
  };

  const onRedirectToVideoPage = () => {
    navigate(TAKE_PHOTO);
    closeButtonHandler();
  };

  const getMeasureTime = () =>
    durationVideo === 1
      ? PHOTO_HUB_VIDEO_HINTS_MINUTE
      : PHOTO_HUB_VIDEO_HINTS_MINUTES;

  return (
    <Modal
      styles
      centerContent
      displayBackButton
      isOpen={isOpen}
      closeButtonHandler={closeButtonHandler}
    >
      <div className="video-hints">
        <h1 className="video-hints-title">{PHOTO_HUB_VIDEO_HINTS_TITLE}</h1>

        <img className="video-hints-image" src={VideoHintsImage} alt="" />

        <p className="video-hints-description">
          {PHOTO_HUB_VIDEO_HINTS_DESCRIPTION}
        </p>

        <NotifyProcess
          level="warning"
          color="#B85D00"
          description={formatString(PHOTO_HUB_VIDEO_HINTS_WARNING, {
            time: durationVideo,
            measureTime: getMeasureTime(),
          })}
        />

        <div className="video-hints-buttons-container">
          <Button
            className="back-button"
            type="button"
            onClick={closeButtonHandler}
            icon
          >
            <IconChevronLeft />
          </Button>

          <Button
            type="button"
            className="button-rounded"
            onClick={() => onRedirectToVideoPage()}
            id={BUTTON_IDS.GO_TO_TAKE_VIDEO}
          >
            {PHOTO_HUB_VIDEO_HINTS_PRIMARY_BUTTON}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

VideoHintsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  language: PropTypes.bool.isRequired,
  closeButtonHandler: PropTypes.func.isRequired,
};

export default VideoHintsModal;
