import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import SupportScreenByCountry from '../../components/SupportScreenByCountry/SupportScreenByCountry';
import { SimpleLoader } from '../../components';
import { getCountryCode } from '../../utils/NavigationUtils';

const ProcessSupport = ({ processCompleteRedirectUrl }) => {
  const [showLoader, setShowLoader] = useState(false);

  const submit = async () => {
    window.location.href = processCompleteRedirectUrl;
  };

  const renderDependsCountry = () => (
    <SupportScreenByCountry setShowLoader={setShowLoader} submit={submit} />
  );

  return showLoader ? <SimpleLoader /> : renderDependsCountry();
};

ProcessSupport.propTypes = {
  processCompleteRedirectUrl: PropTypes.string.isRequired,
};

const mapStateToProps = ({ settings: { environment } }) => {
  const { processCompleteRedirectUrl } = environment[getCountryCode()];
  return {
    processCompleteRedirectUrl,
    language: environment.language,
  };
};

export default connect(mapStateToProps, null)(ProcessSupport);
