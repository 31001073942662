export const getDataNavigatorDevice = () => {
  const params = new URL(document.location).searchParams;
  const emailToken = params.get('token');
  return {
    userAgentData: navigator.userAgentData
      ? { brands: navigator.userAgentData.brands }
      : undefined,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    appVersion: navigator.appVersion,
    appName: navigator.appName,
    vendor: navigator.vendor,
    emailToken,
  };
};
