import { Button, Content, IconChevronLeft, IconExit } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { HISTORY_POP } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import { ModalFlowActions } from '../../actions';
import history from '../../utils/history';
import './styles.scss';

const renderFooterButtons = props => {
  const {
    displayFooter,
    displayPrimaryButton,
    primaryButtonHandler,
    primaryButtonDisabled,
    primaryButtonLabel,
    MODAL_PRIMARY_BUTTON_LABEL,
    displaySecondaryButton,
    secondaryButtonHandler,
    secondaryButtonDisabled,
    secondaryButtonLabel,
    buttonId,
    closeButtonHandler,
    displayChevronButton,
    buttonsDisabled,
  } = props;
  return (
    displayFooter && (
      <div className="pad-footer-buttons-container">
        {displayChevronButton && (
          <div className="modal-back-icon-chevron-left">
            <Button
              className="back-button"
              type="button"
              onClick={closeButtonHandler}
              icon
            >
              <IconChevronLeft />
            </Button>
          </div>
        )}

        <div className="modal-footer-buttons">
          {displayPrimaryButton && (
            <div className="align-center">
              <Button
                type="button"
                className="buttons"
                id={buttonId}
                onClick={primaryButtonHandler}
                disabled={primaryButtonDisabled || buttonsDisabled}
              >
                {String(
                  primaryButtonLabel || MODAL_PRIMARY_BUTTON_LABEL,
                ).toLocaleUpperCase()}
              </Button>
            </div>
          )}
          {displaySecondaryButton && (
            <div className="align-center">
              <Button
                type="button"
                level="secondary"
                className="buttons"
                onClick={secondaryButtonHandler}
                disabled={secondaryButtonDisabled || buttonsDisabled}
              >
                {String(secondaryButtonLabel).toUpperCase()}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const renderControlsHeader = props => {
  const {
    displayControlHeader,
    displayBackButton,
    backButtonHandler,
    displayCloseButton,
    closeButtonHandler,
  } = props;
  return (
    displayControlHeader && (
      <div className="modal-controls-header">
        {displayBackButton && (
          <Button
            className="modal-back-icon"
            type="button"
            onClick={backButtonHandler}
            icon
          >
            <IconChevronLeft />
          </Button>
        )}
        {displayCloseButton && (
          <Button
            className="modal-close-icon"
            type="button"
            onClick={closeButtonHandler}
            icon
          >
            <IconExit />
          </Button>
        )}
      </div>
    )
  );
};

const Modal = props => {
  const {
    isOpen,
    primaryButtonLabel,
    primaryButtonHandler,
    primaryButtonDisabled,
    secondaryButtonLabel,
    secondaryButtonHandler,
    secondaryButtonDisabled,
    closeButtonHandler,
    backButtonHandler,
    centerContent,
    backgroundColor,
    children,
    classFixedContentModal,
    className,
    overlayClassName,
    buttonId,
    enableChevronButton,
    buttonsDisabled,
  } = props;

  const {
    settings: {
      environment: { language },
    },
    modalFlow: {
      hiddenButtonSecondary,
      displayHintsModal,
      displayHintsVideoModal,
    },
  } = useSelector(store => store);

  const { MODAL_PRIMARY_BUTTON_LABEL } = localizedStrings(language);
  const displayPrimaryButton = !!primaryButtonHandler;
  const displaySecondaryButton =
    !!secondaryButtonHandler && !hiddenButtonSecondary;
  const displayCloseButton = !!closeButtonHandler;
  const displayBackButton = !!backButtonHandler;
  const displayControlHeader = displayBackButton || displayCloseButton;
  const displayFooter = displayPrimaryButton || displaySecondaryButton;
  const displayChevronButton = enableChevronButton && displayFooter;
  const centerContentClass = centerContent ? 'modal-center-content' : '';

  useEffect(() => {
    ModalFlowActions.hiddenSecondaryButtonModal(false);
    const body = document.querySelector('body');
    if (isOpen) {
      history.push({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    }

    body.style.overflow =
      displayHintsModal || displayHintsVideoModal ? 'hidden' : 'auto';
    return history.listen((location, action) => {
      if (action === HISTORY_POP) {
        if (closeButtonHandler) {
          closeButtonHandler();
        } else if (backButtonHandler) {
          backButtonHandler();
        }
      }
    });
  }, [
    backButtonHandler,
    closeButtonHandler,
    isOpen,
    displayHintsModal,
    displayHintsVideoModal,
  ]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={primaryButtonHandler}
      className={['modal', className].join(' ')}
      overlayClassName={['modal-overlay', overlayClassName].join(' ')}
    >
      {renderControlsHeader({
        displayControlHeader,
        displayBackButton,
        backButtonHandler,
        displayCloseButton,
        closeButtonHandler,
      })}

      <div className="modal-scrollable-content">
        <Content className={centerContentClass} style={{ backgroundColor }}>
          <div
            className={classFixedContentModal ? 'modal__contentFixed' : null}
          >
            {children}
          </div>
        </Content>
      </div>
      {renderFooterButtons({
        displayFooter,
        displayPrimaryButton,
        primaryButtonHandler,
        primaryButtonDisabled,
        primaryButtonLabel,
        MODAL_PRIMARY_BUTTON_LABEL,
        displaySecondaryButton,
        secondaryButtonHandler,
        secondaryButtonDisabled,
        secondaryButtonLabel,
        buttonId,
        closeButtonHandler,
        displayChevronButton,
        buttonsDisabled,
      })}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  primaryButtonLabel: PropTypes.string,
  primaryButtonHandler: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,

  secondaryButtonLabel: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonDisabled: PropTypes.bool,

  classFixedContentModal: PropTypes.bool,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,

  closeButtonHandler: PropTypes.func,
  backButtonHandler: PropTypes.func,
  backgroundColor: PropTypes.string,
  centerContent: PropTypes.bool,
  children: PropTypes.node.isRequired,
  buttonId: PropTypes.string,
  enableChevronButton: PropTypes.bool,
  buttonsDisabled: PropTypes.bool,
};

Modal.defaultProps = {
  primaryButtonLabel: '',
  primaryButtonHandler: null,
  primaryButtonDisabled: false,
  backgroundColor: null,
  secondaryButtonLabel: null,
  secondaryButtonHandler: null,
  secondaryButtonDisabled: false,
  className: undefined,
  overlayClassName: undefined,
  classFixedContentModal: false,

  closeButtonHandler: null,
  backButtonHandler: null,

  centerContent: false,
  buttonId: '',
  enableChevronButton: false,
  buttonsDisabled: false,
};

export default Modal;
