import {
  GET_REGISTERS_SUPPORT_REQUEST,
  DISABLE_SUPPORT_REQUEST,
  ENABLE_SUPPORT_REQUEST,
} from '../actions/ActionTypes';
import history from '../utils/history';
import { FORM_SUPPORT, PROCESS_SUPPORT } from '../utils/navigationConstants';

export const initialState = {
  supportTroubleAndSolutions: [],
  supportRqEnabled:
    [FORM_SUPPORT, PROCESS_SUPPORT].includes(history.location.pathname) ||
    false,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ENABLE_SUPPORT_REQUEST:
      return {
        ...state,
        supportRqEnabled: true,
      };
    case DISABLE_SUPPORT_REQUEST:
      return {
        ...state,
        supportRqEnabled: false,
      };
    case GET_REGISTERS_SUPPORT_REQUEST:
      return {
        ...state,
        supportTroubleAndSolutions: payload,
      };

    default:
      return state;
  }
};
