/* eslint-disable class-methods-use-this */
import { SESSION_STORAGE_KEYS } from '../../constants/session';
import AuthToken from './AuthToken';

const {
  INACTIVITY_TIMER_CLOSE_SESSION_MODAL,
  INACTIVITY_TIMER_SESSION_MAIN,
  TIMELIMIT_TO_EXPIRE_SESSION,
  TIMESTAMP_TO_EXPIRE_SESSION,
  TIMESTAMP_INACTIVITY_TIMER_SESSION_MAIN,
  LOGOUT_ISSUE,
} = SESSION_STORAGE_KEYS;

class CloseSession {
  constructor() {
    this.storageKeys = [
      INACTIVITY_TIMER_CLOSE_SESSION_MODAL,
      TIMESTAMP_INACTIVITY_TIMER_SESSION_MAIN,
      INACTIVITY_TIMER_SESSION_MAIN,
      TIMELIMIT_TO_EXPIRE_SESSION,
      TIMESTAMP_TO_EXPIRE_SESSION,
      'USER_ACCEPT_TERMS_OF_CONDITIONS',
      'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
      'VEHICLE_CLASS',
    ];
  }

  getLogoutType() {
    return sessionStorage.getItem(LOGOUT_ISSUE);
  }

  clearStorage() {
    this.storageKeys.forEach(key => sessionStorage.removeItem(key));
    AuthToken.removeAuthToken();
    localStorage.clear();
  }

  clearSessionByInactivity() {
    const keysToSkip = [
      TIMELIMIT_TO_EXPIRE_SESSION,
      TIMESTAMP_TO_EXPIRE_SESSION,
      'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
      'USER_ACCEPT_TERMS_OF_CONDITIONS',
    ];

    const keysToRemove = this.storageKeys.filter(
      key => !keysToSkip.includes(key),
    );

    keysToRemove.forEach(key => sessionStorage.removeItem(key));
    localStorage.clear();
  }

  clearSessionExpired() {
    this.clearStorage();
  }
}

export default new CloseSession();
