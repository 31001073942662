import {
  LANGUAGES,
  ASIAN_COUNTRIES,
} from '@lmig-latam/adil-api-common-lib/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions, ModalFlowActions } from '../../actions';
import ImgOpenMenu from '../../styles/images/points-menu.svg';
import ImgCloseMenu from '../../styles/images/close.svg';
import getFloatMenuItems from '../../constants/getFloatMenuItems';
import { isAsianDomain } from '../../utils';
import { FAQModal } from '..';
import './styles.scss';
import { getCountryCode } from '../../utils/NavigationUtils';

function FloatMenuButton() {
  const [openMenu, setOpenMenu] = useState(false);
  const {
    modalFlow: { faqAnswerDisplayed },
    settings: {
      environment: { language },
    },
  } = useSelector(store => store);

  const [floatMenu, setFloatMenu] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setFloatMenu(getFloatMenuItems(language));
  }, [language, setFloatMenu]);

  const handlerMenu = item => {
    if (item.id === 'languages') {
      const [questions, languages] = getFloatMenuItems(language);
      const countryCode = ASIAN_COUNTRIES.includes(language.toUpperCase())
        ? LANGUAGES.EN
        : LANGUAGES[getCountryCode().toUpperCase()];

      setFloatMenu([questions, { ...languages, countryCode }]);
      dispatch(SettingsActions.setLanguage(item.countryCode));
      setOpenMenu(false);
    }

    if (item.id === 'questions') {
      dispatch(ModalFlowActions.changeStateFaqAnswer(!faqAnswerDisplayed));
      setOpenMenu(false);
    }
  };

  const hideFAQModal = () => {
    dispatch(ModalFlowActions.changeStateFaqAnswer(false));
  };

  const stylesMenu = `${
    openMenu ? 'btn-float-menu btn-float-menu--active' : 'btn-float-menu '
  }`;

  const stylesItemMenu = openMenu
    ? 'menu-float'
    : 'menu-float menu-float--hidden';

  return (
    <>
      {openMenu && <div className="app-loader translucent" />}
      <div className="container">
        {isAsianDomain() && (
          <button
            type="button"
            onClick={() => setOpenMenu(!openMenu)}
            className={stylesMenu}
            aria-expanded={openMenu}
            aria-label="Menu"
          >
            <img
              src={!openMenu ? ImgOpenMenu : ImgCloseMenu}
              className={
                !openMenu ? 'btn-float-menu--open' : 'btn-float-menu--close'
              }
              alt=""
            />
          </button>
        )}

        <nav>
          <ul
            id="menu"
            role="menu"
            className={stylesItemMenu}
            aria-hidden={openMenu}
          >
            {floatMenu.map(item => (
              <li key={item.id} role="presentation">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="menuitem"
                  className="menu-float__item"
                  onClick={() => handlerMenu(item)}
                >
                  <div className="menu-float__icon-container">
                    <img className={item.class} src={item.img} alt={item.alt} />
                  </div>
                  <p className="menu-float__text">{item.label}</p>
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <FAQModal
          isOpen={faqAnswerDisplayed}
          backButtonHandler={hideFAQModal}
        />
      </div>
    </>
  );
}

export default FloatMenuButton;
