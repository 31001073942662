import PropTypes from 'prop-types';
import {
  STATUS_TYPES,
  STATUS_PROGRESS_VIDEO,
} from '@lmig-latam/adil-api-common-lib/constants';
import {
  generateSmsConfirmationCode,
  getRetakePhotos,
  validateEmailToken,
  validateSmsConfirmationCode,
  deleteVideo,
} from '../api';
import {
  deleteCookie,
  enableRetakeFlow,
  setCookie,
  stepsToShowValidator,
} from '../utils';
import { store } from '../utils/configureStore';
import { RETAKE_DATA_COOKIE_NAME, TOKEN_TIMEOUT } from '../utils/constants';
import {
  handleInvalidStatusError,
  handleTokenMissingError,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';
import {
  UPDATE_USER_DETAILS,
  UPDATE_RETAKE,
  HIDE_APP_LOADER,
  ON_CHANGE_CURRENT_STEP,
} from './ActionTypes';
import {
  AuthActions,
  ModalFlowActions,
  PhotoHubActions,
  SessionActions,
} from '.';
import TakeVideoActions from './TakeVideoActions';
import TakePhotoActions from './TakePhotoActions';
import AuthToken from '../utils/session/AuthToken';
import CloseSession from '../utils/session/CloseSession';
import { LOGOUT_TYPE } from '../constants/session';

export const clearLocalStorageAndSessionStorage = () => {
  localStorage.removeItem('VIDEO_PARAMETERS');
  localStorage.removeItem('CAMERA_TYPE');
  localStorage.removeItem('STORAGE_IS_FINISHED_VIDEO');
  sessionStorage.removeItem('VEHICLE_CLASS');
};

export const setInactivityTimers = (
  dispatch,
  timerCloseSessionTimeLimit,
  timerCloseSessionWarning,
  timelimitToExpireSession,
) => {
  dispatch(AuthActions.setAuthenticationStatus(false));
  dispatch(ModalFlowActions.onDisplayExpiredSessionModal(false));
  dispatch(
    SessionActions.setDefaultInactivityTimers(
      Number(timerCloseSessionTimeLimit),
      Number(timerCloseSessionWarning),
    ),
  );
  dispatch(
    SessionActions.setTimelimitToExpireSession(
      Number(timelimitToExpireSession),
    ),
  );
};

export const handleVideo = async (dispatch, video) => {
  const {
    enableSection,
    finished,
    status: statusVideoProgress,
    duration,
    frequency,
    compress,
    required,
  } = video;

  const videoParameters = {
    enableSection,
    finished,
    required,
    duration,
    frequency,
    compress,
  };

  dispatch(TakeVideoActions.reviewVideoParameters(videoParameters));

  if (statusVideoProgress === STATUS_PROGRESS_VIDEO.INCOMPLETE) {
    await deleteVideo();
  } else if (statusVideoProgress === STATUS_PROGRESS_VIDEO.FINISHED) {
    dispatch(TakeVideoActions.getPhotosPreviewVideo());
  }

  if (enableSection !== null && enableSection !== undefined) {
    dispatch(TakeVideoActions.onDisplayVideoSection(enableSection));
    dispatch(TakeVideoActions.onFinishVideo(finished));

    if (finished === false) {
      localStorage.removeItem('VIDEO_PHOTOS');
    }
  }
};

export const handleErrors = error => {
  if (error instanceof TokenError) {
    handleTokenMissingError(error);
  } else if (error instanceof InvalidStatusError) {
    handleInvalidStatusError(error);
  } else {
    navigate(ERROR_GENERIC);
  }
};

export default {
  PropertyTypes: PropTypes.shape({
    validateEmailTokenAndGenerateSmsConfirmationCode: PropTypes.func.isRequired,
    validateSmsConfirmationCode: PropTypes.func.isRequired,
    resendSmsConfirmationCode: PropTypes.func.isRequired,
  }),
  validateEmailTokenAndGenerateSmsConfirmationCode() {
    let userInspectionStatus;
    let vehicleClassInspection;
    let lastStatus;
    return async dispatch => {
      clearLocalStorageAndSessionStorage();
      try {
        const {
          status,
          vehicleClass,
          video,
          camera,
          lastCustomerStatus,
          sessionConfig,
        } = await validateEmailToken();

        const {
          timerCloseSessionTimeLimit,
          timerCloseSessionWarning,
          timelimitToExpireSession,
        } = sessionConfig;

        setInactivityTimers(
          dispatch,
          timerCloseSessionTimeLimit,
          timerCloseSessionWarning,
          timelimitToExpireSession,
        );

        userInspectionStatus = status;
        vehicleClassInspection = vehicleClass;
        lastStatus = lastCustomerStatus;

        sessionStorage.setItem('VEHICLE_CLASS', vehicleClass);

        const islogoutByInactivity =
          CloseSession.getLogoutType() === LOGOUT_TYPE.INACTIVITY_SESSION;

        if (!islogoutByInactivity) {
          const keysToRemove = [
            'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
            'USER_ACCEPT_TERMS_OF_CONDITIONS',
          ];
          keysToRemove.forEach(key => sessionStorage.removeItem(key));
        }

        dispatch(PhotoHubActions.setWizardStateByVehicleClass(vehicleClass));

        if (camera) {
          dispatch(TakePhotoActions.onChangeCameraType(camera));
          localStorage.setItem('CAMERA_TYPE', JSON.stringify(camera));
        }

        if (video) {
          await handleVideo(dispatch, video);
        }
      } catch (error) {
        handleErrors(error);
        return;
      }

      let cellPhoneNumber;

      try {
        const response = await generateSmsConfirmationCode(true);
        cellPhoneNumber = response.maskedCellPhoneNumber;
      } catch (error) {
        handleErrors(error);
      }

      dispatch({
        type: UPDATE_USER_DETAILS,
        value: {
          maskedCellPhoneNumber: cellPhoneNumber,
          userInspectionStatus,
          vehicleClassInspection,
          lastCustomerStatus: lastStatus,
        },
      });

      dispatch({
        type: HIDE_APP_LOADER,
      });
    };
  },

  validateSmsConfirmationCode(smsConfirmationCode) {
    const {
      STATUS_CUSTOMER_IN_PROGRESS,
      STATUS_SUPPORT_IN_PROGRESS,
    } = STATUS_TYPES;

    return async dispatch => {
      try {
        const islogoutByInactivity =
          CloseSession.getLogoutType() === LOGOUT_TYPE.INACTIVITY_SESSION;

        if (!islogoutByInactivity) {
          const validateSmsResponse = await validateSmsConfirmationCode(
            smsConfirmationCode,
          );

          const { authorizationToken } = validateSmsResponse;

          // Set auth cookie expiry to 2 hours. Same as the JWT token expiry
          AuthToken.setAuthToken(authorizationToken);

          sessionStorage.removeItem(
            'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
          );
        }
      } catch (error) {
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          throw error;
        }
      }

      const {
        user: { userInspectionStatus, vehicleClass, lastCustomerStatus },
      } = store.getState();

      const isRetakeEnabled = enableRetakeFlow(
        userInspectionStatus,
        lastCustomerStatus,
      );

      if (isRetakeEnabled) {
        const retakeConfig = await getRetakePhotos();

        const stringifiedRetakeConfig = JSON.stringify(retakeConfig);
        const stepsToShow = stepsToShowValidator(retakeConfig, vehicleClass);

        // Add/Update RETAKE Cookie and State
        setCookie(
          RETAKE_DATA_COOKIE_NAME,
          stringifiedRetakeConfig,
          TOKEN_TIMEOUT,
        );

        dispatch({
          type: UPDATE_RETAKE,
          value: retakeConfig,
        });

        dispatch({
          type: ON_CHANGE_CURRENT_STEP,
          payload: { step: stepsToShow[0], stepsToShow },
        });
      }

      if (
        ![STATUS_CUSTOMER_IN_PROGRESS, STATUS_SUPPORT_IN_PROGRESS].includes(
          userInspectionStatus,
        ) &&
        !isRetakeEnabled
      ) {
        deleteCookie(RETAKE_DATA_COOKIE_NAME);

        dispatch({
          type: UPDATE_RETAKE,
          value: undefined,
        });

        dispatch({
          type: HIDE_APP_LOADER,
        });
      }
    };
  },

  resendSmsConfirmationCode() {
    return () => generateSmsConfirmationCode(true);
  },
};
