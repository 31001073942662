/* eslint-disable react/forbid-prop-types */
import './styles.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '@lmig/lmds-react-toggle';
import { useDispatch, useSelector } from 'react-redux';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import {
  getConditionallyRequiredPhoto,
  getConditionallyRequiredPhotosConfig,
} from '../../config/conditionallyRequiredPhotos';
import localizedStrings from '../../utils/localizedStrings';
import { ConditionallyRequiredPhotoList } from '..';
import { PhotoHubActions } from '../../actions';
import { WIZARD_PHOTO_STEP } from '../../constants/wizardPhotos';
import { getCountryCode } from '../../utils/NavigationUtils';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

const ConditionallyRequiredPhotosSection = ({
  conditionalPhotosTaken,
  navigate,
  isToggleEnabled,
  capturePhotoRef,
  startCapturePhoto,
  vehicleClass,
}) => {
  const {
    settings: {
      retake,
      environment: { language },
    },
    photos: { wizardPhotoHub },
  } = useSelector(store => store);

  const {
    PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_QUESTION,
    PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_QUESTION,
    PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_ON,
    PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_OFF,
  } = localizedStrings(language);

  const { photoId } = useMemo(() => {
    const [photoConfig] = getConditionallyRequiredPhotosConfig(
      language,
      vehicleClass,
    );
    return photoConfig;
  }, [language, vehicleClass]);

  const dispatch = useDispatch();

  const getLabelRightToggle = () =>
    isToggleEnabled
      ? PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_ON
      : PHOTO_HUB_CONDITIONAL_PHOTO_QUESTION_OFF;

  const onChangeToggle = () => {
    dispatch(PhotoHubActions.onChangeSpareWheelToggle(!isToggleEnabled));
  };

  const getQuestionLabel = () => {
    if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
      return PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_QUESTION;
    }
    return PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_QUESTION;
  };

  const conditionalRequiredPhotoConfig = useMemo(
    () => getConditionallyRequiredPhoto(photoId, language, vehicleClass),
    [photoId, language, vehicleClass],
  );

  /**
   * Here we select the last required photo before the conditional photos section
   * and when this photo is taken the conditional photos section will be enabled in step 3.
   * Currently in EC we don't have conditional photos section
   */
  const islastPhotoInWizard = () => {
    let lastRequiredPhotoTakenStep3;

    if (retake) return true;

    if (vehicleClass === VEHICLE_CLASS_MOTORBIKE)
      lastRequiredPhotoTakenStep3 = 'INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER';
    else if (['cl'].includes(getCountryCode()))
      lastRequiredPhotoTakenStep3 = 'INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE';
    else
      lastRequiredPhotoTakenStep3 = 'INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER'; // CO

    const requiredPhotosStep = wizardPhotoHub[WIZARD_PHOTO_STEP.THREE - 1];

    return requiredPhotosStep.photos.some(
      photo => photo.photoId === lastRequiredPhotoTakenStep3,
    );
  };

  const getStylesSwitch = () => ({
    marginBottom: !isToggleEnabled ? '20%' : '4%',
    opacity: !islastPhotoInWizard() && '0.2',
    pointerEvents: !islastPhotoInWizard() && 'none',
  });

  const renderToggleComponents = () => (
    <>
      {!retake && (
        <div className="spare-wheel-toggle" style={getStylesSwitch()}>
          <label className="spare-wheel-toggle__question">
            {getQuestionLabel()}
          </label>

          <Toggle
            compact
            showOnOff={false}
            icons="false"
            checked={isToggleEnabled}
            onClick={() => onChangeToggle()}
          />

          <label className="spare-wheel-toggle__label-toggle">
            {getLabelRightToggle()}
          </label>
        </div>
      )}

      {isToggleEnabled && (
        <ConditionallyRequiredPhotoList
          navigate={navigate}
          photos={conditionalPhotosTaken}
          capturePhotoRef={capturePhotoRef}
          startCapturePhoto={startCapturePhoto}
          photoConfig={conditionalRequiredPhotoConfig}
          disabled={!islastPhotoInWizard()}
        />
      )}
    </>
  );

  return (
    <div className="conditionally-required-photo-section">
      {renderToggleComponents()}
    </div>
  );
};

ConditionallyRequiredPhotosSection.propTypes = {
  conditionalPhotosTaken: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
  isToggleEnabled: PropTypes.bool.isRequired,
  vehicleClass: PropTypes.string.isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
  capturePhotoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default ConditionallyRequiredPhotosSection;
