import {
  BodyText,
  Caption,
  Content,
  Heading,
  IconSmartphone,
} from '@lmig/lmds-react';
import React from 'react';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  MenuFooter,
} from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { store } from '../../utils/configureStore';
import './styles.scss';
import { getCountryCode } from '../../utils/NavigationUtils';

const {
  settings: {
    environment: { language },
  },
} = store.getState();

const {
  NON_MOBILE_ERROR_MESSAGE_TITLE,
  NON_MOBILE_ERROR_MESSAGE_SUBTITLE,
  NON_MOBILE_ERROR_PRIMARY_MESSAGE,
  NON_MOBILE_ERROR_PRIMARY_PHONE_NUMBER,
  NON_MOBILE_ERROR_OTHER_COUNTRY_MESSAGE,
  NON_MOBILE_ERROR_OTHER_COUNTRY_PHONE_NUMBER,
} = localizedStrings(language);

const renderFooterSection = () =>
  getCountryCode() === 'co' ? <AppFooter /> : null;

const NonMobileError = () => (
  <div className="adil-screen">
    <AppHeader displaySubtitle />
    <div className="adil-content non-mobile-error-main-content">
      <Content className="non-mobile-error-content">
        <Heading type="h3-light" className="auto-left-right-margin">
          {NON_MOBILE_ERROR_MESSAGE_TITLE}
        </Heading>
        <BodyText className="non-mobile-error-subtitle auto-left-right-margin">
          {NON_MOBILE_ERROR_MESSAGE_SUBTITLE}
        </BodyText>
        <CircleImage>
          <IconSmartphone sizing="auto" />
        </CircleImage>
      </Content>
      <Content className="non-mobile-error-contact-info">
        <Caption className="non-mobile-error-caption">
          <div>
            <span>{NON_MOBILE_ERROR_PRIMARY_MESSAGE}</span>
            <a
              href={`tel:${NON_MOBILE_ERROR_PRIMARY_PHONE_NUMBER}`}
              className="non-mobile-error-phone-link"
            >
              {NON_MOBILE_ERROR_PRIMARY_PHONE_NUMBER}
            </a>
          </div>
          <div>
            <span>{NON_MOBILE_ERROR_OTHER_COUNTRY_MESSAGE}</span>
            <a
              href={`tel:${NON_MOBILE_ERROR_OTHER_COUNTRY_PHONE_NUMBER}`}
              className="non-mobile-error-phone-link"
            >
              {NON_MOBILE_ERROR_OTHER_COUNTRY_PHONE_NUMBER}
            </a>
          </div>
        </Caption>
        {renderFooterSection()}
      </Content>
    </div>
    <MenuFooter />
  </div>
);

export default NonMobileError;
