import { Heading, Button } from '@lmig/lmds-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import {
  IOS,
  ANDROID,
  IPHONE,
  CHROME,
  SAFARI,
  FIREFOX,
  EDGE,
  OPERA,
  EXPLORER,
} from '../../utils/constants';
import { getBrowser, getMobileOperatingSystem } from '../../utils';
import imgAndroid from '../../assets/images/logos/android-vertical.svg';
import imgApple from '../../assets/images/logos/apple.svg';
import imgSafari from '../../assets/images/logos/safari.svg';
import imgChrome from '../../assets/images/logos/chrome.svg';
import imgFirefox from '../../assets/images/logos/firefox.svg';
import imgEdge from '../../assets/images/logos/edge.svg';
import imgExplorer from '../../assets/images/logos/explorer.svg';
import imgOpera from '../../assets/images/logos/opera.svg';
import DetectingScreen from './DetectingScreen';

let operatingSystem = '';
let browser = '';
const ModalOperatingSystem = ({
  setSelectAnotherBrowser,
  pageNumber,
  setPageNumber,
  selectedBrowser,
}) => {
  const [showBrowser, setShowBrowser] = useState(false);
  useEffect(() => {
    const browserDetected = getBrowser();
    const OSDetected = getMobileOperatingSystem();
    if (OSDetected === IOS) {
      browser = browserDetected;
      operatingSystem = IPHONE;
    } else if (OSDetected === ANDROID) {
      browser = browserDetected;
      operatingSystem = ANDROID;
    } else {
      operatingSystem = ANDROID;
      browser = browserDetected;
    }
    setInterval(() => {
      setShowBrowser(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (selectedBrowser) {
      operatingSystem = '';
      browser = '';
    }
  }, [selectedBrowser]);

  const {
    environment: { language },
  } = useSelector(store => store.settings);
  const {
    MODAL_PRIMARY_BUTTON_LABEL,
    CAMERA_PERMISSIONS_IPHONE_SAFARI_ID,
    CAMERA_PERMISSIONS_ANDROID_CHROME_ID,
    CAMERA_PERMISSIONS_IPHONE_CHROME_ID,
    CAMERA_PERMISSIONS_INCORRECT_INFORMATION,
    CAMERA_PERMISSIONS_DETECTED_PHONE_1,
    CAMERA_PERMISSIONS_DETECTED_PHONE_2,
  } = localizedStrings(language);

  return (
    <>
      {showBrowser && (
        <>
          <div className="icons-camera-permissions">
            {(operatingSystem === IPHONE ||
              selectedBrowser === CAMERA_PERMISSIONS_IPHONE_SAFARI_ID ||
              selectedBrowser === CAMERA_PERMISSIONS_IPHONE_CHROME_ID) && (
              <img src={imgApple} alt="imgApple" />
            )}
            {(operatingSystem === ANDROID ||
              selectedBrowser === CAMERA_PERMISSIONS_ANDROID_CHROME_ID) && (
              <img src={imgAndroid} alt="imgAndroid" />
            )}
            {(browser === SAFARI ||
              selectedBrowser === CAMERA_PERMISSIONS_IPHONE_SAFARI_ID) && (
              <img src={imgSafari} alt="imgSafari" />
            )}
            {(browser === CHROME ||
              selectedBrowser === CAMERA_PERMISSIONS_ANDROID_CHROME_ID ||
              selectedBrowser === CAMERA_PERMISSIONS_IPHONE_CHROME_ID) && (
              <img src={imgChrome} alt="imgChrome" />
            )}
            {browser === FIREFOX && <img src={imgFirefox} alt="imgFirefox" />}
            {browser === EDGE && <img src={imgEdge} alt="imgEdge" />}
            {browser === OPERA && <img src={imgOpera} alt="imgOpera" />}
            {browser === EXPLORER && (
              <img src={imgExplorer} alt="imgExplorer" />
            )}
          </div>
          <Heading
            style={{ fontSize: '1.3rem' }}
            type="h3"
            className="auto-left-right-margin"
          >
            {CAMERA_PERMISSIONS_DETECTED_PHONE_1}
            <b>{operatingSystem}</b>
            {CAMERA_PERMISSIONS_DETECTED_PHONE_2}
            <b>{browser}</b>
          </Heading>
          <Heading
            type="h4-light"
            style={{ fontSize: '1rem' }}
            className="faq-subtitle auto-left-right-margin gray-text underline-text"
            onClick={() => {
              setSelectAnotherBrowser(true);
            }}
          >
            {CAMERA_PERMISSIONS_INCORRECT_INFORMATION}
          </Heading>
          <div className="footer-modal-permissions radiusButton_adil">
            <Button
              type="button"
              onClick={() => {
                setPageNumber(pageNumber + 1);
              }}
            >
              {MODAL_PRIMARY_BUTTON_LABEL}
            </Button>
          </div>
        </>
      )}
      {!showBrowser && (
        <div className="parent-image-modal-permissions">
          <DetectingScreen />
        </div>
      )}
    </>
  );
};

ModalOperatingSystem.defaultProps = {
  selectedBrowser: CHROME,
};

ModalOperatingSystem.propTypes = {
  setPageNumber: PropTypes.func.isRequired,
  selectedBrowser: PropTypes.string,
  setSelectAnotherBrowser: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
};
export default ModalOperatingSystem;
