import {
  BodyText,
  Content,
  Heading,
  IconSearch,
  IconCheckmark,
  IconAlert,
  IconClock,
  Button,
} from '@lmig/lmds-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import constants from '@lmig-latam/adil-api-common-lib/constants/constants';
import { SettingsActions } from '../../actions';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  MenuFooter,
} from '../../components';
import './styles.scss';
import localizedStrings from '../../utils/localizedStrings';
import { store } from '../../utils/configureStore';
import { getCountryCode } from '../../utils/NavigationUtils';

const {
  settings: {
    environment: { language },
  },
} = store.getState();

const {
  INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_CANCELLED_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE,
  ERROR_MESSAGE_TITLE,
  ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_CALL_AN_ADVISOR_BUTTON,
} = localizedStrings(language);

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_SUPPORT_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_CANCELLED,
    STATUS_AGENT_REJECTED,
    STATUS_EXPIRED,
  },
} = constants;

const getConfig = status => {
  switch (status) {
    case STATUS_AGENT_IN_PROGRESS:
    case STATUS_AGENT_REVIEW:
    case STATUS_SUPPORT_AGENT_REVIEW:
      return {
        title: INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE,
        icon: <IconSearch sizing="auto" />,
        showCallButton: false,
      };
    case STATUS_AGENT_APPROVED:
      return {
        title: INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconCheckmark sizing="auto" />,
        showCallButton: false,
      };
    case STATUS_AGENT_REJECTED:
      return {
        title: INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconAlert sizing="auto" />,
        showCallButton: true,
      };
    case STATUS_EXPIRED:
      return {
        title: INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconClock sizing="auto" />,
        showCallButton: true,
      };
    case STATUS_CANCELLED:
      return {
        title: INVALID_STATUS_CANCELLED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_CANCELLED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconAlert sizing="auto" />,
        showCallButton: true,
      };
    default:
      return {
        title: ERROR_MESSAGE_TITLE,
        subtitle: ERROR_MESSAGE_SUBTITLE,
        icon: <IconAlert sizing="auto" />,
        showCallButton: false,
      };
  }
};

const InvalidStatus = ({
  invalidStatus,
  advisorPhoneNumber,
  settingsActions: { hideAppLoader },
}) => {
  // Hide App Loader
  // (In case it was displayed prior to navigating here)
  hideAppLoader();

  const { title, subtitle, icon, showCallButton } = getConfig(invalidStatus);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader displaySubtitle />
      <Content className="adil-content invalid-status-content">
        <CircleImage>{icon}</CircleImage>
        <Heading type="h4-light" className="auto-left-right-margin">
          {title}
        </Heading>
        <BodyText className="gray-text">{subtitle}</BodyText>

        {showCallButton && (
          <Button
            className="call-an-advisor-button"
            type="a"
            level="primary"
            href={`tel:${advisorPhoneNumber}`}
          >
            {INVALID_STATUS_CALL_AN_ADVISOR_BUTTON}
          </Button>
        )}
        {renderFooterSection()}
      </Content>
      <MenuFooter />
    </div>
  );
};

InvalidStatus.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  invalidStatus: PropTypes.string,
  advisorPhoneNumber: PropTypes.string.isRequired,
};

InvalidStatus.defaultProps = {
  invalidStatus: 'default',
};

const mapStateToProps = ({ settings: { invalidStatus, environment } }) => {
  const { advisorPhoneNumber } = environment[getCountryCode()];
  return {
    invalidStatus,
    advisorPhoneNumber,
  };
};

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidStatus);
