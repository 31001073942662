import React, { useCallback, useMemo, useRef } from 'react';
import { Content } from '@lmig/lmds-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  WizardContainer,
  WizardCounterStep,
} from '../../components/WizardPhotos';
import {
  AppHeader,
  CapturePhoto,
  HintsModal,
  PhotoHubSection,
  ModalFinishConfirmationProcess,
  GPSInfo,
  ModalPlayerVideo,
  VideoHintsModal,
} from '../../components';
import { getCountryCode, navigate } from '../../utils/NavigationUtils';
import { buildRequiredPhotos } from '../../config/requiredPhotosConf';
import { initialWizardPhotoHub } from '../../constants/wizardPhotos';
import { PhotoGroupConfig } from '../../config';
import {
  ModalFlowActions,
  PhotoHubActions,
  TakePhotoActions,
  TakeVideoActions,
} from '../../actions';
import { TAKE_PHOTO } from '../../utils/navigationConstants';
import history from '../../utils/history';
import { CAMERA_MODE } from '../../constants/camera';
import { validateEmailToken } from '../../api';
import { PhotoCaptureFlow } from '../../utils/strategies';
import { logcodes, logger } from '../../utils/logger';
import { getDataNavigatorDevice } from '../../utils/log-device';
import { ModalRequiredPhotoTaken } from '../../components/ModalRetakePhotoTaken';
import localizedStrings from '../../utils/localizedStrings';
import {
  BUTTON_ACTION,
  FingerprintButton,
} from '../../utils/FingerprintButton';
import { PhotoClassifier } from '../../utils/PhotoClassifier';
import { getRequiredPhotosVideo } from '../../config/videoConfImages/videoRequiredConf';
import ModalRepeatVideo from '../../components/ModalRepeatVideo/ModalRepeatVideo';
import MainTools from '../../components/MainTools/MainTools';
import { useScroll } from '../../hooks';
import './styles.scss';

const MAXIMUM_NUMBER_OF_STEPS = initialWizardPhotoHub.length;

export default function WizardPhotoHub() {
  const {
    auth: { isAuthenticated },
    user: { vehicleClass },
    photos: { wizardPhotoHub, currentStep },
    takePhoto: { cameraMode, incomingPhotoId },
    takeVideo: { isEnableVideoSection, photosVideoPreview },
    settings: { displayHints },
    modalFlow: {
      displayHintsModal,
      displayFinishConfirmationModal,
      displayRetakePhotoModal,
      displayRepeatVideoModal,
      displayHintsVideoModal,
      displayPlayVideoModal,
    },
    settings: {
      environment: { language },
      retake,
    },
  } = useSelector(store => store);

  const { scrollDirection } = useScroll();

  const {
    TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL,
    TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL,
  } = localizedStrings(language);

  const dispatch = useDispatch();
  const country = getCountryCode();

  const getButtonId = useMemo(
    () => () =>
      new FingerprintButton(incomingPhotoId)
        .setVehicleClass(vehicleClass)
        .setAction(BUTTON_ACTION.REPEAT)
        .setIsRetake(!!retake)
        .getId(),
    [incomingPhotoId, vehicleClass, retake],
  );

  const requiredPhotos = buildRequiredPhotos(country);

  const capturePhotoRef = useRef();

  const goTakePhotoPage = () => {
    history.push({
      pathname: TAKE_PHOTO,
      search: history.location.search,
    });
  };

  const isRecordMode = useMemo(() => cameraMode === CAMERA_MODE.RECORD, [
    cameraMode,
  ]);

  const getPhotoGroupConfig = useCallback(
    photoId => new PhotoGroupConfig(photoId, language, vehicleClass),
    [language, vehicleClass],
  );

  const openCapturePhotoComponent = useCallback(() => {
    dispatch(ModalFlowActions.onDisplayHintsModal(false));
    dispatch(ModalFlowActions.onDisplayRetakePhotoModal(false));
    if (isRecordMode) {
      goTakePhotoPage();
    } else {
      capturePhotoRef.current.click();
    }
  }, [dispatch, isRecordMode]);

  const hideFinishConfirmationModal = () => {
    dispatch(ModalFlowActions.onDisplayFinishConfirmationModal(false));
  };

  const confirmFinish = () => {
    hideFinishConfirmationModal();
    dispatch(PhotoHubActions.finishProcess());

    const dataDevice = getDataNavigatorDevice();
    logger.log(logcodes.PHUB040, dataDevice);
  };

  const startCapturePhotoFlow = useCallback(
    async (photoId, action) => {
      const classifier = new PhotoClassifier();
      const isVideo = classifier.setPhotoID(photoId).isVideo();

      let cameraConfig;

      const incomingCameraMode = isVideo
        ? CAMERA_MODE.RECORD
        : CAMERA_MODE.PHOTO;

      if (isVideo) cameraConfig = getRequiredPhotosVideo(photoId, language);
      else cameraConfig = getPhotoGroupConfig(photoId).getPhotoConfig();

      const hintsAvailable =
        !!cameraConfig && !!(cameraConfig.hints || cameraConfig.steps);

      let result = {};

      if (isVideo) result = await validateEmailToken();

      const { camera, overlay, video } = result;

      const captureFlow = new PhotoCaptureFlow(incomingCameraMode, photoId);

      await captureFlow
        .setDispatch(dispatch)
        .addCameraType(camera)
        .addOverlay(overlay)
        .addVideo(video)
        .setup();

      if (isVideo) {
        dispatch(ModalFlowActions.onDisplayVideoHintsModal(true));
        return;
      }

      if (displayHints && hintsAvailable && action === BUTTON_ACTION.ADD) {
        dispatch(ModalFlowActions.onDisplayHintsModal(true));
      } else if (action === BUTTON_ACTION.REPEAT) {
        dispatch(ModalFlowActions.onDisplayRetakePhotoModal(true));
      } else {
        openCapturePhotoComponent();
      }
    },
    [
      language,
      displayHints,
      dispatch,
      openCapturePhotoComponent,
      getPhotoGroupConfig,
    ],
  );

  const hideRetakePhotoModal = () => {
    dispatch(ModalFlowActions.onDisplayRetakePhotoModal(false));
  };

  const retakeCapturePhotoFlow = () => {
    const classifier = new PhotoClassifier();
    classifier.setPhotoID(incomingPhotoId);
    if (classifier.isDamagePhoto() || classifier.isAccessoryPhoto()) {
      openCapturePhotoComponent();
    } else {
      dispatch(ModalFlowActions.onDisplayHintsModal(true));
      dispatch(ModalFlowActions.onDisplayRetakePhotoModal(false));
    }
  };

  const getHintsConfig = useCallback(
    () => getPhotoGroupConfig(incomingPhotoId).getHintsConfig(),
    [incomingPhotoId, getPhotoGroupConfig],
  );

  const getHintStepsConfig = useCallback(
    () => getPhotoGroupConfig(incomingPhotoId).getHintStepsConfig(),
    [incomingPhotoId, getPhotoGroupConfig],
  );

  const onRepeatVideo = async () => {
    dispatch(TakePhotoActions.onChangeCameraMode(CAMERA_MODE.RECORD));
    dispatch(ModalFlowActions.onDisplayRepeatVideoModal(false));
    dispatch(ModalFlowActions.onDisplayVideoHintsModal(true));
    await TakeVideoActions.onDeleteVideo();
    dispatch(TakeVideoActions.onFinishVideo(false));
  };

  const onHiddenVideoHintsModal = () => {
    dispatch(ModalFlowActions.onDisplayVideoHintsModal(false));
    dispatch(TakePhotoActions.setIncomingPhoto(null));
  };

  return (
    <>
      {isAuthenticated && <GPSInfo />}

      <div className="adil-screen">
        <div className="wizard-photos-hub-header">
          <AppHeader displaySubtitle />
          <WizardCounterStep
            currentTitle={wizardPhotoHub[currentStep - 1]?.name}
            currentStep={currentStep}
            nextTitle={wizardPhotoHub[currentStep]?.name}
            to={MAXIMUM_NUMBER_OF_STEPS}
          />
        </div>
        <WizardContainer>
          <Content
            className={`photohub-process-steps-container scroll-${scrollDirection}`}
          >
            <PhotoHubSection
              vehicleClass={vehicleClass}
              navigate={navigate}
              currentStep={currentStep}
              photosConfig={requiredPhotos}
              startCapturePhoto={startCapturePhotoFlow}
              capturePhotoRef={capturePhotoRef}
              isEnableVideoSection={isEnableVideoSection}
            />
          </Content>
        </WizardContainer>

        {displayHintsModal && (
          <HintsModal
            photoId={incomingPhotoId}
            vehicleClass={vehicleClass}
            isOpen={displayHintsModal}
            hints={getHintsConfig()}
            steps={getHintStepsConfig()}
            onContinue={openCapturePhotoComponent}
            onClose={() => {
              dispatch(ModalFlowActions.onDisplayHintsModal(false));
            }}
          />
        )}

        {displayHintsVideoModal && (
          <VideoHintsModal
            language={language}
            isOpen={displayHintsVideoModal}
            closeButtonHandler={onHiddenVideoHintsModal}
          />
        )}

        <ModalRequiredPhotoTaken
          isOpen={displayRetakePhotoModal}
          language={language}
          backButtonHandler={hideRetakePhotoModal}
          primaryButtonLabel={TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL}
          secondaryButtonLabel={TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL}
          secondaryButtonHandler={hideRetakePhotoModal}
          primaryButtonHandler={retakeCapturePhotoFlow}
          buttonId={getButtonId()}
        />

        <ModalFinishConfirmationProcess
          isOpen={displayFinishConfirmationModal}
          language={language}
          confirmFinish={confirmFinish}
          hideFinishConfirmationModal={hideFinishConfirmationModal}
        />

        <ModalPlayerVideo
          lang={language}
          isOpen={displayPlayVideoModal}
          onClose={() => {
            dispatch(ModalFlowActions.onDisplayPlayVideoModal(false));
          }}
          video={photosVideoPreview}
        />

        {displayRepeatVideoModal && (
          <ModalRepeatVideo
            isOpen={displayRepeatVideoModal}
            lang={language}
            onRepeat={onRepeatVideo}
            onClose={() => {
              dispatch(ModalFlowActions.onDisplayRepeatVideoModal(false));
            }}
          />
        )}

        <CapturePhoto
          photoId={incomingPhotoId}
          webcamRef={capturePhotoRef}
          currentStep={currentStep}
        />
        <MainTools vehicleClass={vehicleClass} />
      </div>
    </>
  );
}
