import { LOG_LEVELS } from './logger';

const { ERROR, INFO, WARN } = LOG_LEVELS;

export default {
  CANAV010: {
    code: 'CANAV010',
    level: INFO,
    message: 'User has navigated',
  },
  CANAV100: {
    code: 'CANAV100',
    level: WARN,
    message:
      'Navigating because user loaded into middle of flow with no authToken cookie',
  },
  CANAV110: {
    code: 'CANAV110',
    level: WARN,
    message:
      'Navigating because user loaded into middle of flow with no emailToken cookie',
  },
  CANAV120: {
    code: 'CANAV120',
    level: WARN,
    message: 'Navigating because user is Unauthorized',
  },
  CANAV130: {
    code: 'CANAV130',
    level: WARN,
    message: 'Navigating because inspection is in an AGENT status',
  },
  CANAV140: {
    code: 'CANAV140',
    level: WARN,
    message: 'Navigating because inspection has already been APPROVED',
  },
  CANAV150: {
    code: 'CANAV150',
    level: WARN,
    message: 'Navigating because inspection has been REJECTED',
  },
  CANAV160: {
    code: 'CANAV160',
    level: WARN,
    message: 'Navigating because inspection has EXPIRED',
  },
  CANAV170: {
    code: 'CANAV170',
    level: ERROR,
    message: 'Navigating because inspection is in an UNKNOWN invalid status',
  },
  CANAV180: {
    code: 'CANAV180',
    level: INFO,
    message: 'Information about UserAgent',
  },
  CANAV190: {
    code: 'CANAV190',
    level: WARN,
    message: 'Navigating because inspection has been CANCELLED',
  },
  CAAPI110: {
    code: 'CAAPI110',
    level: ERROR,
    message: 'Error in service response',
  },
  CAAPI120: {
    code: 'CAAPI120',
    level: ERROR,
    message: 'Error calling service',
  },
  CAIMG010: {
    code: 'CAIMG010',
    level: INFO,
    message: 'Image compressed',
  },
  CAIMG020: {
    code: 'CAIMG020',
    level: INFO,
    message: 'Before compression',
  },
  CAIMG110: {
    code: 'CAIMG110',
    level: ERROR,
    message: 'Error compressing image',
  },
  CAUPL110: {
    code: 'CAUPL110',
    level: INFO,
    message: 'Finish inspection service resolved',
  },
  CAUPL120: {
    code: 'CAUPL120',
    level: ERROR,
    message: 'Error while uploading photo',
  },
  CAUPL130: {
    code: 'CAUPL130',
    level: INFO,
    message: 'Starting to upload photo',
  },
  CAUPL140: {
    code: 'CAUPL140',
    level: ERROR,
    message: 'Error when finishing inspection',
  },
  CAUPL150: {
    code: 'CAUPL150',
    level: ERROR,
    message: 'Error converting base64 image to blob',
  },
  CAUPL160: {
    code: 'CAUPL160',
    level: ERROR,
    message: 'Error uploading photos to Inspektlabs',
  },

  PHUB010: {
    code: 'PHUB010',
    level: INFO,
    message: 'Finish confirmation modal shown',
  },
  PHUB020: {
    code: 'PHUB020',
    level: INFO,
    message: 'Finalize button shown',
  },
  PHUB030: {
    code: 'PHUB030',
    level: INFO,
    message: 'Finalize button hidden',
  },
  PHUB040: {
    code: 'PHUB040',
    level: INFO,
    message: 'Get device data in fininish of inspection',
  },
  PHUB050: {
    code: 'PHUB050',
    level: ERROR,
    message: 'Error getting ram info device',
  },
  PHUB060: {
    code: 'PHUB060',
    level: INFO,
    message: 'Getting ram info device',
  },
  PCON110: {
    code: 'PCON110',
    level: ERROR,
    message: 'PhotoID missing',
  },
  CALAN110: {
    code: 'CALAN110',
    level: ERROR,
    message: 'Error selecting language',
  },
  CAMV10: {
    code: 'CAMV10',
    level: ERROR,
    message: 'Error camera user permissions denied',
  },
  CAMV20: {
    code: 'CAMV20',
    level: ERROR,
    message: 'Error camera system permissions denied',
  },
  CAMV30: {
    code: 'CAMV30',
    level: ERROR,
    message: 'Error another app is using the camera',
  },
  CAMV40: {
    code: 'CAMV40',
    level: ERROR,
    message: 'Unknown error with camera permissions',
  },
  CAMV50: {
    code: 'CAMV50',
    level: ERROR,
    message: 'Permissions camera info',
  },
  CAMV60: {
    code: 'CAMV60',
    level: INFO,
    message: 'Permissions camera granted successfully',
  },
  CAMV70: {
    code: 'CAMV70',
    level: INFO,
    message: 'Permits are pending approval for user',
  },
  CAMV110: {
    code: 'CAMV110',
    level: INFO,
    message: 'Camera component loaded successfully',
  },
  CAMV120: {
    code: 'CAMV120',
    level: INFO,
    message: 'Photos of the video 360 successfully deleted',
  },
  CAMV130: {
    code: 'CAMV130',
    level: ERROR,
    message: 'Error deleting photos of the video 360',
  },

  CAMV140: {
    code: 'CAMV140',
    level: INFO,
    message: 'Video camera component loaded successfully',
  },
  CAMV150: {
    code: 'CAMV150',
    level: INFO,
    message: 'Loading camera dimension settings',
  },
  CAMV160: {
    code: 'CAMV160',
    level: INFO,
    message: 'Change camera orientation',
  },
  LPIM10: {
    code: 'LPIM10',
    level: ERROR,
    message: 'Error getting previously uploaded photos',
  },
  SPR10: {
    code: 'SPR10',
    level: ERROR,
    message: 'Error, already exists support request status in inspection',
  },
  UPF10: {
    code: 'UPF10',
    level: ERROR,
    message: 'Error getting url array presigned',
  },
  UPF20: {
    code: 'UPF20',
    level: ERROR,
    message: 'Error, photoId not found in url presigned array',
  },
  GPS10: {
    code: 'GPS10',
    level: INFO,
    message: 'Geolocation is successfully enabled, getting user location',
  },
  GPS20: {
    code: 'GPS20',
    level: ERROR,
    message: 'Error, this browser does not support Geolocation',
  },
  GPS30: {
    code: 'GPS30',
    level: ERROR,
    message: 'User denied Geolocation',
  },
  GPS40: {
    code: 'GPS40',
    level: ERROR,
    message: 'Geolocation error unknown',
  },
  GPS50: {
    code: 'GPS50',
    level: ERROR,
    message: 'Position unavailable',
  },
  GPS60: {
    code: 'GPS60',
    level: ERROR,
    message: 'Geolocation timeout',
  },
  GPS70: {
    code: 'GPS70',
    level: ERROR,
    message: 'Error in request: device geolocation error',
  },
  GPS80: {
    code: 'GPS80',
    level: ERROR,
    message:
      'Geocoder internal error converting geographic coordinates to an address',
  },
  GPS90: {
    code: 'GPS90',
    level: ERROR,
    message:
      'Geocoder failed bad status converting geographic coordinates to an address',
  },
  GPS100: {
    code: 'GPS100',
    level: ERROR,
    message: 'Geocoder not found results',
  },
  GPS120: {
    code: 'GPS120',
    level: INFO,
    message: 'Requesting position to browser',
  },
  GPS130: {
    code: 'GPS130',
    level: INFO,
    message: 'Geocoder getting geographic street address',
  },
  SESS010: {
    code: 'SESS010',
    level: INFO,
    message: 'Closing user current session',
  },
  SESS020: {
    code: 'SESS020',
    level: INFO,
    message: 'Opening close session modal by inactivity time',
  },
  SESS030: {
    code: 'SESS030',
    level: INFO,
    message: 'Timer Initialized',
  },
  SESS040: {
    code: 'SESS040',
    level: INFO,
    message: 'Timer Finalized',
  },
  SESS050: {
    code: 'SESS050',
    level: INFO,
    message: 'Redirecting to initial page',
  },
};
