import INSPECTION_PHOTO_VEHICLE_LEFT_SIDE from './left_car_lateral_2.png';
import INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE from './rigth_car_lateral_2.png';
import INSPECTION_PHOTO_VEHICLE_FRONT_SIDE from './front_car_video.png';

const images = {
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
  INSPECTION_PHOTO_VEHICLE_FRONT_SIDE,
};

export default images;
