import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TakeVideoActions } from '../../../actions';

export function useTimerCamera({
  timeLimit,
  onStopRecording,
  frequencyVideo,
  onCounter,
}) {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(timeLimit);
  const [timerFormat, setTimerFormat] = useState('');

  useEffect(() => {
    let intervalTimer = null;
    onCounter(seconds);

    if (seconds > 0) {
      const formatTimer = new Date(seconds * 1000).toISOString().substr(14, 5);
      setTimerFormat(formatTimer);
      intervalTimer = setInterval(() => {
        setSeconds(sec => sec - 1);
        if (seconds % frequencyVideo === 0)
          dispatch(TakeVideoActions.onCapturePhotoFromVideo(true));
      }, 1000);
    } else onStopRecording();
    return () => clearInterval(intervalTimer);
  }, [
    onCounter,
    onStopRecording,
    seconds,
    timeLimit,
    frequencyVideo,
    dispatch,
  ]);

  return [timerFormat];
}
