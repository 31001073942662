import React from 'react';
import PropTypes from 'prop-types';
import ButtonTakePhoto from './ButtonTakePhoto';
import './styles.scss';

function PhotoActionButtons({ disable, capturePhoto }) {
  return (
    <div className="photo-button-child">
      <div />
      <div>
        <ButtonTakePhoto disable={disable} capturePhoto={capturePhoto} />
      </div>
      <div />
    </div>
  );
}

PhotoActionButtons.propTypes = {
  disable: PropTypes.bool.isRequired,
  capturePhoto: PropTypes.func.isRequired,
};

export default PhotoActionButtons;
