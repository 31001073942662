export const useFormatTimer = startSeconds => {
  const minutes = Math.floor(startSeconds / 60);
  const remainingSecond = startSeconds % 60;

  let minuteString = String(minutes);
  let secondString = String(remainingSecond);

  minuteString = minuteString.length === 1 ? `0${minuteString}` : minuteString;
  secondString = secondString.length === 1 ? `0${secondString}` : secondString;
  return [minuteString, secondString];
};
