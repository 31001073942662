import { getConditionallyRequiredPhoto } from './conditionallyRequiredPhotos';
import { getRequiredPhoto } from './requiredPhotosConf';
import { getRequiredPhotosVideo } from './videoConfImages/videoRequiredConf';

export class PhotoGroupConfig {
  constructor(photoId, language, vehicleClass) {
    this.photoId = photoId;
    this.language = language;
    this.vehicleClass = vehicleClass;
  }

  setPhotoId(photoId) {
    this.photoId = photoId;
    return this;
  }

  getHintsConfig() {
    return this.getPhotoConfig(this.language)?.hints || undefined;
  }

  getHintStepsConfig() {
    return this.getPhotoConfig(this.language)?.steps || undefined;
  }

  getPhotoConfig() {
    if (getRequiredPhoto(this.photoId, this.language)) {
      return getRequiredPhoto(this.photoId, this.language);
    }

    if (
      getConditionallyRequiredPhoto(
        this.photoId,
        this.language,
        this.vehicleClass,
      )
    ) {
      return getConditionallyRequiredPhoto(
        this.photoId,
        this.language,
        this.vehicleClass,
      );
    }

    if (getRequiredPhotosVideo(this.photoId, this.language)) {
      return getRequiredPhotosVideo(this.photoId, this.language);
    }
    return undefined;
  }
}
