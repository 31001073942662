import localizedStrings from '../../utils/localizedStrings';

const STEP_ITEMS_GUIDED_VISIT_VIDEO = {
  INIT: { ID: 0, LABEL: 'init' },
  FIRST: { ID: 1, LABEL: 'first' },
  SECOND: { ID: 2, LABEL: 'second' },
  THIRD: { ID: 3, LABEL: 'third' },
  FOURTH: { ID: 4, LABEL: 'fourth' },
  FIFTH: { ID: 5, LABEL: 'fifth' },
  SIXTH: { ID: 6, LABEL: 'sixth' },
};

const getVisitGuidedItemsVideo = lang => {
  const {
    GUIDED_VIDEO_VISIT_INIT_TITLE,
    GUIDED_VIDEO_VISIT_INIT_DESCRIPTION,
    GUIDED_VIDEO_VISIT_FIRST_TITLE,
    GUIDED_VIDEO_VISIT_FIRST_DESCRIPTION,
    GUIDED_VIDEO_VISIT_SECOND_TITLE,
    GUIDED_VIDEO_VISIT_SECOND_DESCRIPTION,
    GUIDED_VIDEO_VISIT_THIRD_TITLE,
    GUIDED_VIDEO_VISIT_THIRD_DESCRIPTION,
    GUIDED_VIDEO_VISIT_FOURTH_TITLE,
    GUIDED_VIDEO_VISIT_FOURTH_DESCRIPTION,
    GUIDED_VIDEO_VISIT_FIFTH_TITLE,
    GUIDED_VIDEO_VISIT_FIFTH_DESCRIPTION,
    GUIDED_VIDEO_VISIT_SIXTH_TITLE,
    GUIDED_VIDEO_VISIT_SIXTH_DESCRIPTION,
    GUIDED_VIDEO_VISIT_INIT_BUTTON_LABEL,
    GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
    GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
    GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL,
    GUIDED_VIDEO_VISIT_DONE_BUTTON_LABEL,
  } = localizedStrings(lang);

  return {
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.INIT.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.INIT.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.INIT.LABEL,
      title: GUIDED_VIDEO_VISIT_INIT_TITLE,
      description: GUIDED_VIDEO_VISIT_INIT_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_INIT_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'top-right',
        container: 'init',
      },
    },
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.FIRST.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.FIRST.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.FIRST.LABEL,
      title: GUIDED_VIDEO_VISIT_FIRST_TITLE,
      description: GUIDED_VIDEO_VISIT_FIRST_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'down-center',
        container: 'first',
      },
    },
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.SECOND.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.SECOND.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.SECOND.LABEL,
      title: GUIDED_VIDEO_VISIT_SECOND_TITLE,
      description: GUIDED_VIDEO_VISIT_SECOND_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
      secundaryButton: GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'down-center',
        container: 'second',
      },
    },
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.THIRD.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.THIRD.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.THIRD.LABEL,
      title: GUIDED_VIDEO_VISIT_THIRD_TITLE,
      description: GUIDED_VIDEO_VISIT_THIRD_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
      secundaryButton: GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'down-center',
        container: 'third',
      },
    },
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.FOURTH.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.FOURTH.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.FOURTH.LABEL,
      title: GUIDED_VIDEO_VISIT_FOURTH_TITLE,
      description: GUIDED_VIDEO_VISIT_FOURTH_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
      secundaryButton: GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'top-center',
        container: 'fourth',
      },
    },
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.FIFTH.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.FIFTH.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.FIFTH.LABEL,
      title: GUIDED_VIDEO_VISIT_FIFTH_TITLE,
      description: GUIDED_VIDEO_VISIT_FIFTH_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
      secundaryButton: GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'down-right',
        container: 'fifth',
      },
    },
    [STEP_ITEMS_GUIDED_VISIT_VIDEO.SIXTH.ID]: {
      id: STEP_ITEMS_GUIDED_VISIT_VIDEO.SIXTH.ID,
      name: STEP_ITEMS_GUIDED_VISIT_VIDEO.SIXTH.LABEL,
      title: GUIDED_VIDEO_VISIT_SIXTH_TITLE,
      description: GUIDED_VIDEO_VISIT_SIXTH_DESCRIPTION,
      primaryButton: GUIDED_VIDEO_VISIT_DONE_BUTTON_LABEL,
      secundaryButton: GUIDED_VIDEO_VISIT_BACK_BUTTON_LABEL,
      omitButton: GUIDED_VIDEO_VISIT_OMIT_BUTTON_LABEL,
      position: {
        arrow: 'top-left',
        container: 'sixth',
      },
    },
  };
};

export { STEP_ITEMS_GUIDED_VISIT_VIDEO, getVisitGuidedItemsVideo };
