import { getAuthorizationToken } from '../api';
import { SET_AUTHENTICATION_STATUS } from './ActionTypes';
import AuthToken from '../utils/session/AuthToken';

function setAuthenticationStatus(status) {
  return {
    type: SET_AUTHENTICATION_STATUS,
    payload: { status },
  };
}

const getAuthTokenToStartInspection = () => async dispatch => {
  const responseAuth = await getAuthorizationToken();

  const { authorizationToken } = responseAuth;

  // Set auth cookie expiry to 2 hours. Same as the JWT token expiry
  AuthToken.setAuthToken(authorizationToken);

  dispatch(setAuthenticationStatus(true));
};

export default {
  setAuthenticationStatus,
  getAuthTokenToStartInspection,
};
