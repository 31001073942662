import {
  DECREMENT_INACTIVITY_TIMER_AUTO_CLOSE_SESSION,
  ON_CHANGE_MAIN_INACTIVITY_TIMER,
  ON_RESET_ALL_SESSION_TIMER,
  RESET_TIMELIMIT_EXPIRE_SESSION,
  SET_PARAMETERS_INACTIVITY_TIMER,
  SET_LOGOUT_TYPE,
  SET_TIMELIMIT_TO_EXPIRE_SESSION,
  START_TIMER_TO_EXPIRE_SESSION,
} from '../actions/ActionTypes';
import { SESSION_STORAGE_KEYS } from '../constants/session';

const {
  TIMELIMIT_TO_EXPIRE_SESSION,
  TIMESTAMP_TO_EXPIRE_SESSION,
  INACTIVITY_TIMER_SESSION_MAIN,
  INACTIVITY_TIMER_CLOSE_SESSION_MODAL,
  TIMESTAMP_INACTIVITY_TIMER_SESSION_MAIN,
  LOGOUT_ISSUE,
} = SESSION_STORAGE_KEYS;

// Return Date object
const getTimestampExpiredSessionInStorage = () => {
  const timestamp = sessionStorage.getItem(TIMESTAMP_TO_EXPIRE_SESSION);
  return timestamp || undefined;
};

// Return number in (seconds)
const getParamTimelimitExpiredSessionInStorage = () => {
  const timestamp = sessionStorage.getItem(TIMELIMIT_TO_EXPIRE_SESSION);
  return Number(timestamp) || undefined;
};

function getParamTimerModalInStorage() {
  const result = sessionStorage.getItem(INACTIVITY_TIMER_CLOSE_SESSION_MODAL);
  return Number(result) || 120;
}

function getShowTimerInactivityModalInStorage() {
  const result = sessionStorage.getItem(INACTIVITY_TIMER_SESSION_MAIN);
  return Number(result) || 600;
}

export const initialState = {
  timelimitToExpireSession: getParamTimelimitExpiredSessionInStorage(),
  timestampToExpireSession: getTimestampExpiredSessionInStorage(),
  inactivityTimestampLimit: undefined,
  timerCloseSessionInModal: getParamTimerModalInStorage(),
  timerShowInactivityModal: getShowTimerInactivityModalInStorage(),
  logoutType: undefined,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_TIMELIMIT_TO_EXPIRE_SESSION: {
      const { seconds } = payload;
      sessionStorage.setItem(TIMELIMIT_TO_EXPIRE_SESSION, seconds);
      return {
        ...state,
        timelimitToExpireSession: seconds,
      };
    }

    case START_TIMER_TO_EXPIRE_SESSION: {
      const milliseconds = Date.now() + state.timelimitToExpireSession * 1000;
      const timestamp = new Date(milliseconds);
      sessionStorage.setItem(TIMESTAMP_TO_EXPIRE_SESSION, timestamp);
      return {
        ...state,
        timestampToExpireSession: timestamp,
      };
    }

    case RESET_TIMELIMIT_EXPIRE_SESSION: {
      sessionStorage.removeItem(TIMELIMIT_TO_EXPIRE_SESSION);
      sessionStorage.removeItem(TIMESTAMP_TO_EXPIRE_SESSION);
      return {
        ...state,
        timelimitToExpireSession: null,
        timestampToExpireSession: null,
      };
    }

    case DECREMENT_INACTIVITY_TIMER_AUTO_CLOSE_SESSION: {
      const nextCounter = state.timerCloseSessionInModal - 1;

      return {
        ...state,
        timerCloseSessionInModal: nextCounter > 0 ? nextCounter : null,
      };
    }

    case ON_CHANGE_MAIN_INACTIVITY_TIMER: {
      const { timestamp } = payload;

      sessionStorage.setItem(
        TIMESTAMP_INACTIVITY_TIMER_SESSION_MAIN,
        timestamp,
      );

      return { ...state, inactivityTimestampLimit: timestamp };
    }

    case SET_PARAMETERS_INACTIVITY_TIMER: {
      const { mainTimer, modalTimer } = payload;

      sessionStorage.setItem(INACTIVITY_TIMER_SESSION_MAIN, mainTimer);
      sessionStorage.setItem(INACTIVITY_TIMER_CLOSE_SESSION_MODAL, modalTimer);

      return {
        ...state,
        timerCloseSessionInModal: modalTimer,
        timerShowInactivityModal: mainTimer,
      };
    }

    case ON_RESET_ALL_SESSION_TIMER: {
      sessionStorage.removeItem(INACTIVITY_TIMER_SESSION_MAIN);
      sessionStorage.removeItem(INACTIVITY_TIMER_CLOSE_SESSION_MODAL);

      return {
        ...state,
        inactivityTimestampLimit: null,
        timerCloseSessionInModal: null,
      };
    }

    case SET_LOGOUT_TYPE: {
      sessionStorage.setItem(LOGOUT_ISSUE, payload.topic);
      return { ...state, logoutType: payload.topic };
    }
    default:
      return state;
  }
};
