/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { GridCol, GridRow } from '@lmig/lmds-react';
import PanelPhotoFlow from '../WizardPhotos/PanelPhotoFlow/PanelPhotoFlow';
import { CAMERA_MODE } from '../../constants/camera';

const ConditionallyRequiredPhotoList = ({
  navigate,
  photos,
  photoConfig,
  capturePhotoRef,
  startCapturePhoto,
  disabled,
}) => {
  const {
    user: { vehicleClass },
  } = useSelector(store => store);

  const photo = photos.find(element => element.photoId === photoConfig.photoId);
  const previewPhoto = photo ? photo.blobUrl : '';

  return (
    <div key={photoConfig.photoId} id={photoConfig.photoId.toString()}>
      <GridRow key={photoConfig.photoId}>
        <GridCol>
          <PanelPhotoFlow
            photoConfig={photoConfig}
            vehicleClass={vehicleClass}
            disabled={disabled}
            navigate={navigate}
            previewPhoto={previewPhoto}
            cameraMode={CAMERA_MODE.PHOTO}
            startCapturePhoto={startCapturePhoto}
            capturePhotoRef={capturePhotoRef}
            buttons={['update']}
          />
        </GridCol>
      </GridRow>
    </div>
  );
};

ConditionallyRequiredPhotoList.propTypes = {
  disabled: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  photoConfig: PropTypes.shape({
    photoId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
  capturePhotoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default ConditionallyRequiredPhotoList;
