import { Content, Heading } from '@lmig/lmds-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppFooter, AppHeader } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { getCountryCode } from '../../utils/NavigationUtils';

const PageNotFound = () => {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const { PAGE_NOT_FOUND_ERROR_MESSAGE } = localizedStrings(language);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader displaySubtitle />
      <Content>
        <Heading type="h3-light" className="auto-left-right-margin">
          {PAGE_NOT_FOUND_ERROR_MESSAGE}
        </Heading>
        {renderFooterSection()}
      </Content>
    </div>
  );
};

export default PageNotFound;
