import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DefaultPhotoPanel } from '../DefaultPhotoPanel/DefaultPhotoPanel';
import { PhotoPreviewCard } from '../PhotoPreviewCard/PhotoPreviewCard';
import { CAMERA_MODE } from '../../../constants/camera';
import { ICON_TYPE_PANEL, PanelIconFactory } from '../IconPanelFactory';
import { PHOTO_TYPES } from '../../../utils/constants';
import { BUTTON_ACTION } from '../../../utils/FingerprintButton';

function PanelPhotoFlow({
  vehicleClass,
  photoConfig,
  navigate,
  previewPhoto,
  disabled,
  startCapturePhoto,
  capturePhotoRef,
  buttons,
}) {
  const { photoId, title, body } = photoConfig;
  const {
    takePhoto: { cameraMode, photoError },
    settings: { retake },
  } = useSelector(store => store);

  const isRecordMode = useMemo(() => cameraMode === CAMERA_MODE.RECORD, [
    cameraMode,
  ]);

  const { REQUIRED_PHOTO } = PHOTO_TYPES;
  const enableErrorStyles = [REQUIRED_PHOTO].includes(photoError) && !disabled;

  const getPanelIcon = type => (
    <PanelIconFactory
      isRetake={!!retake}
      photoId={photoId}
      disabled={disabled}
      vehicleClass={vehicleClass}
      onClick={startCapturePhoto}
      type={type}
    />
  );

  const getIconComponent = () => {
    if (enableErrorStyles) return getPanelIcon(ICON_TYPE_PANEL.RETRY_CAMERA);

    if (disabled) return getPanelIcon(ICON_TYPE_PANEL.DISABLED_CAMERA);
    return getPanelIcon(ICON_TYPE_PANEL.DEFAULT_CAMERA);
  };

  const onUpdatePhoto = () => {
    startCapturePhoto(photoId, BUTTON_ACTION.REPEAT);
  };

  return (
    <>
      {previewPhoto ? (
        <PhotoPreviewCard
          title={title}
          description={body}
          photoId={photoId}
          buttons={buttons}
          previewPhoto={previewPhoto}
          onUpdate={onUpdatePhoto}
        />
      ) : (
        <DefaultPhotoPanel
          {...photoConfig}
          vehicleClass={vehicleClass}
          disabled={disabled}
          navigate={navigate}
          previewPhoto={previewPhoto}
          cameraMode={CAMERA_MODE.PHOTO}
          isRecordMode={isRecordMode}
          IconComponent={getIconComponent()}
          capturePhotoRef={capturePhotoRef}
          errorStyles={enableErrorStyles}
        />
      )}
    </>
  );
}

PanelPhotoFlow.propTypes = {
  vehicleClass: PropTypes.string.isRequired,
  photoConfig: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    photoId: PropTypes.string.isRequired,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  previewPhoto: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['update', 'delete'])).isRequired,
  capturePhotoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default PanelPhotoFlow;
