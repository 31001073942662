/* eslint-disable class-methods-use-this */
import { LANGUAGES } from '@lmig-latam/adil-api-common-lib/constants';
import { DEFAULT, TERMS_CONDITIONS } from '../navigationConstants';
import history from '../history';
import { SESSION_STORAGE_KEYS } from '../../constants/session';
import { logcodes, logger } from '../logger';
import { getCookie } from '../NavigationUtils';
import { EMAIL_TOKEN_COOKIE_NAME } from '../constants';

export const INACTIVITY_SESSION_TIMER = {
  WARNING_MODAL: 120,
  MAIN: 600,
};

export class InactivitySession {
  constructor(country) {
    this.countryCode = country;
  }

  setInactivityTimeWarning(seconds) {
    this.inactivityTimeWarning = seconds;
    return this;
  }

  getTimestampInactivityTimeWarning() {
    const milliseconds = Date.now() + this.inactivityTimeWarning * 1000;
    return new Date(milliseconds);
  }

  getNextTimerTimestamp(seconds) {
    if (seconds) {
      this.setInactivityTimeWarning(seconds);
      return this.getTimestampInactivityTimeWarning();
    }

    const { INACTIVITY_TIMER_SESSION_MAIN } = SESSION_STORAGE_KEYS;

    const mainTimerStoraged = sessionStorage.getItem(
      INACTIVITY_TIMER_SESSION_MAIN,
    );

    const timerLimitInSeconds = Number(mainTimerStoraged) || 600;
    this.setInactivityTimeWarning(timerLimitInSeconds);
    return this.getTimestampInactivityTimeWarning();
  }

  getTimersFromStorage() {
    const {
      INACTIVITY_TIMER_SESSION_MAIN,
      INACTIVITY_TIMER_CLOSE_SESSION_MODAL,
    } = SESSION_STORAGE_KEYS;

    const { WARNING_MODAL, MAIN } = INACTIVITY_SESSION_TIMER;

    const mainTimer =
      Number(sessionStorage.getItem(INACTIVITY_TIMER_SESSION_MAIN)) || MAIN;
    const modalTimer =
      Number(sessionStorage.getItem(INACTIVITY_TIMER_CLOSE_SESSION_MODAL)) ||
      WARNING_MODAL;

    return { mainTimer, modalTimer };
  }

  redirectByInactivityTo() {
    logger.log(logcodes.SESS050);
    let route = '';
    switch (this.countryCode) {
      case LANGUAGES.EC:
      case LANGUAGES.CO:
        route = DEFAULT;
        break;
      case LANGUAGES.CL:
        route = TERMS_CONDITIONS;
        break;
      default:
        history.push(TERMS_CONDITIONS);
        break;
    }
    history.push(`${route}?token=${getCookie(EMAIL_TOKEN_COOKIE_NAME)}`);
    return this;
  }
}
