import { VIDEO_SECTION } from '@lmig-latam/adil-api-common-lib/constants';
import { formatPhotoConfig, getSinglePhotoConfig } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import { videoRequiredImages } from '..';
import { CAMERA_MODE } from '../../constants/camera';

const { INSPECTION_VIDEO_PHOTOS_VEHICLE } = VIDEO_SECTION;

const getRequiredVideoPhotos = language => {
  const {
    PHOTO_HUB_VIDEO_SECTION_PANEL_TITLE,
    PHOTO_HUB_VIDEO_SECTION_PANEL_SUBTITLE,
    PHOTO_HUB_VIDEO_SECTION_PANEL_HINT,
  } = localizedStrings(language);

  return [
    {
      photoId: INSPECTION_VIDEO_PHOTOS_VEHICLE,
      title: PHOTO_HUB_VIDEO_SECTION_PANEL_TITLE,
      body: PHOTO_HUB_VIDEO_SECTION_PANEL_SUBTITLE,
      cameraMode: CAMERA_MODE.RECORD,
      hints: {
        text: PHOTO_HUB_VIDEO_SECTION_PANEL_HINT,
        images: videoRequiredImages.videoHint,
      },
    },
  ];
};

const unformattedRequiredPhotos = lang =>
  [INSPECTION_VIDEO_PHOTOS_VEHICLE].map(
    photoId =>
      getRequiredVideoPhotos(lang).filter(
        photoConfig => photoConfig.photoId === photoId,
      )[0],
  );

export const countryHasConditionallyRequiredPhotos = lang =>
  unformattedRequiredPhotos(lang).length > 0;

const requiredPhotos = lang =>
  formatPhotoConfig(unformattedRequiredPhotos(lang));

export const getRequiredPhotosConfig = lang => requiredPhotos(lang);

export const getRequiredPhotosVideo = (requestedId, lang) =>
  getSinglePhotoConfig(requestedId, requiredPhotos(lang));

export const requiredPhotosPhotoIdList = lang =>
  requiredPhotos(lang).map(({ photoId }) => photoId);

export default getRequiredVideoPhotos;
