import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { Caption } from '@lmig/lmds-react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { store } from '../../../utils/configureStore';
import localizedStrings from '../../../utils/localizedStrings';

const {
  settings: {
    environment: { language },
  },
} = store.getState();

const {
  PREPOSITION_OF,
  GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL,
  PASSSED_STEP,
  WIZARD_PHOTO_HUB_FINISH_TITLE,
} = localizedStrings(language);

export function WizardCounterStep({
  currentTitle,
  currentStep,
  nextTitle,
  to,
}) {
  const renderNextTitleIfExist = () => (
    <Caption className="wizard-count-subtitle">
      {GUIDED_VIDEO_VISIT_NEXT_BUTTON_LABEL}
      :&nbsp;
      {nextTitle || WIZARD_PHOTO_HUB_FINISH_TITLE}
    </Caption>
  );

  return (
    <section className="wizard-counter-container">
      <div className="wizard-count">
        <span>
          <div className="wizard-progress-bar">
            <CircularProgressbarWithChildren
              value={(currentStep / to) * 100}
              styles={buildStyles({
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',

                // Text size
                textSize: '1.5rem',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: `#28a3af`,
                textColor: 'black',
                trailColor: '#C9F3F4',
                backgroundColor: '#3e98c7',
              })}
            >
              <div className="progress-bar-text">
                <p className="title-progress-bar">{PASSSED_STEP}</p>
                <p className="subtitle-progress-bar">{`${currentStep} ${PREPOSITION_OF} ${to}`}</p>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </span>
      </div>
      <div className="wizard-count-content">
        <Caption className="wizard-count-title">{currentTitle}</Caption>
        {renderNextTitleIfExist()}
      </div>
    </section>
  );
}

WizardCounterStep.propTypes = {
  currentTitle: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  nextTitle: PropTypes.string,
  to: PropTypes.number.isRequired,
};

WizardCounterStep.defaultProps = {
  nextTitle: '',
};

export default WizardCounterStep;
