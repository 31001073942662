import { SET_AUTHENTICATION_STATUS } from '../actions/ActionTypes';
import { SESSION_STORAGE_KEYS } from '../constants/session';
import AuthToken from '../utils/session/AuthToken';

const getAuthStatusFromStorage = () => {
  const { IS_AUTHENTICATED } = SESSION_STORAGE_KEYS;
  const result = sessionStorage.getItem(IS_AUTHENTICATED);
  if (result) return JSON.parse(result).status;
  return false;
};

export const initialState = {
  isAuthenticated: getAuthStatusFromStorage(),
  expTimestampToken: AuthToken.getTimestampExp() || undefined,
};

export default (state = initialState, { type, payload } = {}) => {
  if (type === SET_AUTHENTICATION_STATUS) {
    const { IS_AUTHENTICATED } = SESSION_STORAGE_KEYS;
    sessionStorage.setItem(
      IS_AUTHENTICATED,
      JSON.stringify({ status: payload.status }),
    );
    return { ...state, isAuthenticated: payload.status };
  }
  return state;
};
