import React from 'react';
import localizedStrings from '../utils/localizedStrings';
import RepeatIcon from '../styles/images/repeat.svg';
import UploadIcon from '../styles/images/upload.svg';
import {
  IconBackArrow,
  IconClose,
  IconHelp,
  IconRepeat,
} from '../components/Icons';

const IS_ALREADY_SAW_GUIDED_TOUR_STORAGE = 'isAlreadySawGuidedTour';

const UPLOADING_PHOTO_STATUS = {
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

const OPTIONS_PREVIEW_CAMERA = {
  UPLOAD: 'UPLOAD',
  REPEAT: 'REPEAT',
};

const FACING_MODE = {
  FRONT_FACING: 'user',
  BACK_FACING: 'environment',
};

const BUTTONS_TOP = {
  BACK: 'BACK',
  HELP: 'HELP',
  EXIT: 'EXIT',
  REPEAT: 'REPEAT',
};

const ORIENTATION_SCREEN = {
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
};

const PERMISSION_CAMERA_STATUS = {
  DENIED: 'denied',
  GRANTED: 'granted',
  PROMPT: 'prompt',
};

const CAMERA_TYPE = {
  NATIVE: 'NATIVE',
  FRAME: 'FRAME',
};

const CAMERA_MODE = {
  RECORD: 'RECORD',
  PHOTO: 'PHOTO',
};

const TIME_LIMIT_RECORD = 180;

const getArrayDownButtons = (lang, isRecording) => {
  const {
    TAKE_PHOTO_PRIMARY_BUTTON,
    TAKE_VIDEO_PRIMARY_BUTTON,
    TAKE_PHOTO_SECONDARY_BUTTON,
  } = localizedStrings(lang);

  return [
    {
      id: OPTIONS_PREVIEW_CAMERA.REPEAT,
      icon: RepeatIcon,
      level: 'secondary',
      value: TAKE_PHOTO_SECONDARY_BUTTON,
      class: {
        button: 'preview__repeat-button',
        icon: 'preview__repeat-icon',
        label: 'preview__repeat-label-button',
      },
    },
    {
      id: OPTIONS_PREVIEW_CAMERA.UPLOAD,
      icon: UploadIcon,
      level: 'primary',
      value: isRecording
        ? TAKE_VIDEO_PRIMARY_BUTTON
        : TAKE_PHOTO_PRIMARY_BUTTON,
      class: {
        button: 'preview__upload-photo-button',
        icon: 'preview__upload-icon',
        label: 'preview__upload-label-button',
        loading: 'preview__loading-icon',
      },
    },
  ];
};

const ARRAY_TOP_BUTTONS = {
  [BUTTONS_TOP.EXIT]: {
    id: BUTTONS_TOP.EXIT,
    class: 'panel-camera-top__back-icon',
    icon: (
      <IconClose
        onClick={() => {
          // Empty function
        }}
      />
    ),
  },
  [BUTTONS_TOP.REPEAT]: {
    id: BUTTONS_TOP.REPEAT,
    class: 'panel-camera-top__back-icon',
    icon: <IconRepeat />,
  },
  [BUTTONS_TOP.BACK]: {
    id: BUTTONS_TOP.BACK,
    class: 'panel-camera-top__back-icon',
    icon: <IconBackArrow />,
  },
  [BUTTONS_TOP.HELP]: {
    id: BUTTONS_TOP.HELP,
    class: 'panel-camera-top__help-icon',
    icon: <IconHelp />,
  },
};

export {
  OPTIONS_PREVIEW_CAMERA,
  FACING_MODE,
  BUTTONS_TOP,
  ARRAY_TOP_BUTTONS,
  ORIENTATION_SCREEN,
  PERMISSION_CAMERA_STATUS,
  CAMERA_TYPE,
  CAMERA_MODE,
  IS_ALREADY_SAW_GUIDED_TOUR_STORAGE,
  TIME_LIMIT_RECORD,
  UPLOADING_PHOTO_STATUS,
  getArrayDownButtons,
};
