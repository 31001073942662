/* eslint-disable react/forbid-prop-types */
import { Button } from '@lmig/lmds-react';
import { ulid } from 'ulid';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CapturePhoto, Modal } from '..';
import { CAMERA_MODE, CAMERA_TYPE } from '../../constants/camera';
import history from '../../utils/history';
import localizedStrings from '../../utils/localizedStrings';
import { TAKE_PHOTO } from '../../utils/navigationConstants';
import DamagePhotoList from './DamagePhotoList';
import { PHOTO_TYPES } from '../../utils/constants';
import {
  ModalFlowActions,
  PhotoHubActions,
  TakePhotoActions,
} from '../../actions';
import { DefaultPhotoPanel } from '../WizardPhotos';
import IconTriangleAlert from '../../assets/icons/IconTriangleAlert.svg';
import IconTrashcan from '../../assets/icons/IconTrashcan.svg';
import { BUTTON_ACTION } from '../../utils/FingerprintButton';
import { WIZARD_PHOTO_STEP } from '../../constants/wizardPhotos';
import {
  ICON_TYPE_PANEL,
  PanelIconFactory,
} from '../WizardPhotos/IconPanelFactory';
import './styles.scss';

const DamageSection = ({ photosTaken, navigate }) => {
  const {
    user: { vehicleClass },
    photos: { wizardPhotoHub },
    takePhoto: { defaultCamera, photoError, incomingPhotoId },
    settings: {
      retake,
      environment: { language },
    },
  } = useSelector(store => store);

  const {
    PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL,
    PHOTO_HUB_DELETE_TITLE,
    PHOTO_HUB_DELETE_SUBTITLE,
    PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL,
    PHOTO_HUB_DELETE_IMAGE_ALT_TEXT,
    PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT,
    PHOTO_HUB_DELETE_CONFIRMATION_TITLE,
    PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL,
  } = localizedStrings(language);

  const { DAMAGE_PHOTO } = PHOTO_TYPES;
  const enableErrorStyles = [DAMAGE_PHOTO].includes(photoError);

  const getDamagePhotoId = () => `${DAMAGE_PHOTO}-${ulid()}`;
  const [deleteModalDisplayed, setDeleteModalDisplayed] = useState(false);
  const [
    deleteConfirmationModalDisplayed,
    setDeleteConfirmationModalDisplayed,
  ] = useState(false);
  const [damagePhotoToDelete, setDamagePhotoToDelete] = useState(undefined);

  const dispatch = useDispatch();

  const {
    PHOTO_HUB_DAMAGE_SECTION_PANEL_TITLE,
    PHOTO_HUB_DAMAGE_SECTION_PANEL_DESCRIPTION,
  } = localizedStrings(language);

  // This will be used to get a handle on the <input> element inside CapturePhoto
  const capturePhotoRef = useRef();

  const openCapturePhotoComponent = (photoId, action) => {
    dispatch(TakePhotoActions.onChangeCameraMode(CAMERA_MODE.PHOTO));

    if (action === BUTTON_ACTION.ADD) {
      dispatch(TakePhotoActions.setIncomingPhoto(getDamagePhotoId()));
    } else if (action === BUTTON_ACTION.REPEAT) {
      dispatch(TakePhotoActions.setIncomingPhoto(photoId));
    }

    switch (defaultCamera) {
      case CAMERA_TYPE.FRAME:
        history.push({
          pathname: TAKE_PHOTO,
          search: history.location.search,
        });
        break;
      case CAMERA_TYPE.NATIVE:
        capturePhotoRef.current.click();
        break;
      default:
    }
  };

  const hideDeleteModal = () => {
    setDeleteModalDisplayed(false);
    setDamagePhotoToDelete(undefined);
  };

  const showDeleteModal = event => {
    setDamagePhotoToDelete(event);
    setDeleteModalDisplayed(true);
  };

  const hideDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDisplayed(false);
  };

  const onDeleteDamagePhoto = async () => {
    dispatch(PhotoHubActions.deletePhoto(damagePhotoToDelete));
    setDeleteConfirmationModalDisplayed(true);
    setDeleteModalDisplayed(false);
    setDamagePhotoToDelete(undefined);
  };

  const onUpdateDamagePhoto = photoId => {
    dispatch(ModalFlowActions.onDisplayRetakePhotoModal(true));
    dispatch(TakePhotoActions.setIncomingPhoto(photoId));
  };

  // last required photo has already been taken in current step
  const isTakenlastPhotoInWizard = () => {
    const lastRequiredPhotoTakenStep2 = 'INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE';

    const requiredPhotosStep = wizardPhotoHub[WIZARD_PHOTO_STEP.TWO - 1];

    return requiredPhotosStep.photos.some(
      photo => photo.photoId === lastRequiredPhotoTakenStep2,
    );
  };

  const getPanelIcon = type => (
    <PanelIconFactory
      isRetake={!!retake}
      photoId="ADD_ACCESSORY_PHOTO_BUTTON"
      disabled={!isTakenlastPhotoInWizard()}
      vehicleClass={vehicleClass}
      onClick={openCapturePhotoComponent}
      type={type}
    />
  );

  const getIconButton = () => {
    if (enableErrorStyles) {
      return (
        <Button
          className="accessories-section-button-error-container"
          onClick={() => openCapturePhotoComponent(null, BUTTON_ACTION.REPEAT)}
        />
      );
    }

    return getPanelIcon(ICON_TYPE_PANEL.ADD_PLUS);
  };

  const renderDeletePhotoModal = () => (
    <Modal
      isOpen={deleteModalDisplayed}
      closeButtonHandler={hideDeleteModal}
      primaryButtonLabel={PHOTO_HUB_DELETE_PRIMARY_BUTTON_LABEL}
      primaryButtonHandler={onDeleteDamagePhoto}
      secondaryButtonLabel={PHOTO_HUB_DELETE_SECONDARY_BUTTON_LABEL}
      secondaryButtonHandler={hideDeleteModal}
    >
      <div>
        <div className="damage-photos-section-delete-modal-image">
          <img src={IconTriangleAlert} alt={PHOTO_HUB_DELETE_IMAGE_ALT_TEXT} />
        </div>
        <div className="damage-photos-section-delete-modal-content-container">
          <div className="damage-photos-section-delete-modal-text-content-container">
            <div className="damage-photos-section-delete-modal-title">
              {PHOTO_HUB_DELETE_TITLE}
            </div>
            <div className="damage-photos-section-delete-modal-subtitle">
              <p>{PHOTO_HUB_DELETE_SUBTITLE}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  const renderDeletedPhotoConfirmationModal = () => (
    <Modal
      isOpen={deleteConfirmationModalDisplayed}
      closeButtonHandler={hideDeleteConfirmationModal}
      secondaryButtonLabel={
        PHOTO_HUB_DELETE_CONFIRMATION_SECONDARY_BUTTON_LABEL
      }
      secondaryButtonHandler={hideDeleteConfirmationModal}
    >
      <div>
        <div className="damage-photos-section-delete-confirmation-modal-image">
          <img
            src={IconTrashcan}
            alt={PHOTO_HUB_DELETE_CONFIRMATION_IMAGE_ALT_TEXT}
          />
        </div>
        <div className="damage-photos-section-delete-modal-content-container">
          <div className="damage-photos-section-delete-modal-text-content-container">
            <div className="damage-photos-section-delete-modal-title">
              {PHOTO_HUB_DELETE_CONFIRMATION_TITLE}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="damage-photos-section">
      {renderDeletePhotoModal()}
      {renderDeletedPhotoConfirmationModal()}

      <CapturePhoto webcamRef={capturePhotoRef} photoId={incomingPhotoId} />

      <DefaultPhotoPanel
        IconComponent={getIconButton()}
        title={PHOTO_HUB_DAMAGE_SECTION_PANEL_TITLE}
        body={PHOTO_HUB_DAMAGE_SECTION_PANEL_DESCRIPTION}
        errorStyles={enableErrorStyles}
        disabled={!isTakenlastPhotoInWizard()}
        photoId={incomingPhotoId?.includes('DAMAGE') ? incomingPhotoId : null}
      />

      <DamagePhotoList
        photos={photosTaken}
        navigate={navigate}
        onDelete={showDeleteModal}
        onUpdate={onUpdateDamagePhoto}
      />
    </div>
  );
};
DamageSection.propTypes = {
  photosTaken: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default DamageSection;
