import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Content, GridCol, GridRow } from '@lmig/lmds-react';
import localizedStrings from '../../utils/localizedStrings';
import AppHeader from '../AppHeader';
import IconCheck from './IconCheck.svg';
import './styles.scss';
import { is2FADisabled } from '../../utils';
import MenuFooter from '../MenuFooter/MenuFooter';
import { DEFAULT, TERMS_CONDITIONS } from '../../utils/navigationConstants';
import { DISABLE_SUPPORT_REQUEST } from '../../actions/ActionTypes';
import { getCountryCode, navigate } from '../../utils/NavigationUtils';
import AppFooter from '../AppFooter';

const SupportScreenByCountry = ({
  dataUserPreLoaded,
  setShowLoader,
  vietnamSupport,
  submit,
}) => {
  const {
    settings: {
      environment: { language },
      lastPath,
    },
  } = useSelector(state => state);
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (isValid) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [isValid]);

  useEffect(() => {
    if (dataUserPreLoaded) {
      const { nameCustomer, phoneNumberCustomer } = dataUserPreLoaded;
      setValue('name', nameCustomer);
      setValue('phoneNumber', phoneNumberCustomer);
    }
  }, [dataUserPreLoaded, setValue]);

  const handleCancel = () => {
    const initialPage = is2FADisabled() ? TERMS_CONDITIONS : DEFAULT;
    reset();
    dispatch({
      type: DISABLE_SUPPORT_REQUEST,
    });
    // eslint-disable-next-line no-unused-expressions
    navigate(lastPath || initialPage);
  };

  const onSubmit = data => {
    const { name, phoneNumber, inspectionAddress, inspectionTime } = data;
    if (!isValid) {
      setDisableButton(false);
      return;
    }

    const supportObject = {
      name,
      phoneNumber,
      inspectionAddress,
      inspectionTime,
      countryCode: getCountryCode(),
    };

    setShowLoader(true);
    submit(supportObject);
    reset();
  };

  const {
    PROCESS_SUPPORT_TITLE,
    PROCESS_SUPPORT_SUBTITLE_ONE,
    PROCESS_SUPPORT_SUBTITLE_TWO,
    PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT,
    PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT,
    PROCESS_SUPPORT_CONTINUE_BUTTON,
    PROCESS_SUPPORT_SUBTITLE_THREE,
    PROCESS_SUPPORT_SUBTITLE_FOUR,
    PROCESS_SUPPORT_SUBTITLE_FIVE,
  } = localizedStrings(language);

  const conditionalButton = () => {
    if (vietnamSupport) {
      handleSubmit(onSubmit)();
    } else {
      submit();
    }
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  const renderFooterContents = () => (
    <GridRow className="process-complete-footer">
      <GridCol className="align-center">
        <Button
          type="button"
          onClick={conditionalButton}
          disabled={disableButton}
          className="process-complete-button"
        >
          {PROCESS_SUPPORT_CONTINUE_BUTTON}
        </Button>
      </GridCol>
    </GridRow>
  );

  return (
    <div className="adil-screen">
      <AppHeader displaySubtitle />
      <Content className="adil-content process-complete">
        <div
          className="adilForms-header-icon"
          onClick={handleCancel}
          role="button"
          alt="cancel-icon"
        />
        <div className="process-complete-icon-check">
          <img src={IconCheck} alt="iconCheck-process-complete" />
        </div>
        <div className="process-complete-text-container">
          <p className="process-complete-title-text">{PROCESS_SUPPORT_TITLE}</p>
          <p className="process-complete-normal-text">
            <span className="process-complete-normal-text-bold">
              {PROCESS_SUPPORT_SUBTITLE_FIVE}
            </span>
            {PROCESS_SUPPORT_SUBTITLE_ONE}
          </p>
          <p>
            <span className="process-complete-normal-text">
              {PROCESS_SUPPORT_SUBTITLE_TWO}
            </span>
            <span className="process-complete-blue-text">
              {PROCESS_SUPPORT_SUBTITLE_ONE_IMPORTANT}
            </span>
            <span className="process-complete-normal-text">
              {PROCESS_SUPPORT_SUBTITLE_THREE}
            </span>
            <span className="process-complete-blue-text">
              {PROCESS_SUPPORT_SUBTITLE_TWO_IMPORTANT}
            </span>
            <span className="process-complete-normal-text">
              {PROCESS_SUPPORT_SUBTITLE_FOUR}
            </span>
          </p>
        </div>

        {renderFooterSection()}
        {renderFooterContents()}
      </Content>
      <MenuFooter />
    </div>
  );
};

SupportScreenByCountry.propTypes = {
  submit: PropTypes.func.isRequired,
  setShowLoader: PropTypes.func.isRequired,
  vietnamSupport: PropTypes.bool,
  dataUserPreLoaded: PropTypes.objectOf(PropTypes.string),
};

SupportScreenByCountry.defaultProps = {
  vietnamSupport: false,
  dataUserPreLoaded: {},
};

export default SupportScreenByCountry;
