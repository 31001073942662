import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { IconCloseCircle } from '@lmig/lmds-react/icons';
import { isDevelopment, isLocalhost } from '../../config/environments';
import { useGeolocation } from '../../hooks';
import './styles.scss';

const GPSInfo = ({ debug, delayGetPosition }) => {
  const { coords } = useGeolocation(delayGetPosition);
  const [displayDebugMode, setDisplayDebugMode] = useState(debug);

  const displayContentStyles = {
    display:
      coords && displayDebugMode && (isDevelopment() || isLocalhost())
        ? 'inline'
        : 'none',
  };

  return (
    <div className="gps-provider-info" style={displayContentStyles}>
      <div className="exit">
        <IconCloseCircle size="16" onClick={() => setDisplayDebugMode(false)} />
      </div>
      <div>
        <label>{`Latitude: ${coords?.latitude}`}</label>
        <br />
        <label>{`Longitude: ${coords?.longitude}`}</label>
      </div>
    </div>
  );
};

GPSInfo.defaultProps = {
  debug: false,
  delayGetPosition: 15000,
};

GPSInfo.propTypes = {
  debug: Proptypes.bool,
  delayGetPosition: Proptypes.number,
};

export default GPSInfo;
