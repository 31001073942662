import {
  BodyText,
  Button,
  Content,
  Heading,
  IconAlert,
  IconClock,
} from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { bindActionCreators } from 'redux';
import { PhoneNumberConfirmationActions, SettingsActions } from '../../actions';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  MenuFooter,
  Modal,
} from '../../components';
import { SMS_LOCKOUT } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import { TERMS_CONDITIONS } from '../../utils/navigationConstants';
import { getCountryCode, navigate } from '../../utils/NavigationUtils';
import cellphoneandmessage from '../../assets/icons/cellphoneandmessage.svg';
import history from '../../utils/history';
import { store } from '../../utils/configureStore';
import './styles.scss';
import IconSmsSent from '../../assets/icons/IconSmsSent.svg';
import IconTriangleAlert from '../../assets/icons/IconTriangleAlert.svg';
import IconCircleError from '../../assets/icons/IconCircleError.svg';

const {
  settings: {
    environment: { language },
  },
} = store.getState();

const {
  PHONE_NUMBER_CONFIRMATION_TITLE,
  PHONE_NUMBER_CONFIRMATION_SUBTITLE,
  PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT,
  PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE,
  PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED,
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE,
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE,
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE_TWO,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL,
  PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON,
  AUTH_TOKEN_SESSION_TIMED_OUT,
} = localizedStrings(language);

const getVerificationInpuntClassName = badCode => ({
  container: 'phone-number-confirmation-verification-input-container',
  character: `phone-number-confirmation-verification-input-character${
    badCode
      ? ' phone-number-confirmation-verification-input-character-error'
      : ''
  }`,
  characterSelected:
    'phone-number-confirmation-verification-input-character-selected',
});

const getConfirmationCodeClassName = resendSmsConfirmationCodeSucceeded =>
  `phone-number-confirmation-resend-code${
    resendSmsConfirmationCodeSucceeded
      ? ' phone-number-confirmation-resend-code-disabled'
      : ''
  }`;

const startTimer = (e, setResendSmsConfirmationCodeSucceeded, setTimer) => {
  const total = Date.parse(e) - Date.parse(new Date());

  if (total < 0) {
    setResendSmsConfirmationCodeSucceeded(false);
    return;
  }

  const minutes = Math.floor((total / 1000 / 60) % 60);
  const seconds = Math.floor((total / 1000) % 60);

  const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;
  const formattedSeconds = seconds > 9 ? seconds : `0${seconds}`;

  setTimer(`${formattedMinutes}:${formattedSeconds}`);
};

const clearTimer = (
  e,
  setTimer,
  Ref,
  setResendSmsConfirmationCodeSucceeded,
) => {
  setTimer('01:00');
  if (Ref.current) clearInterval(Ref.current);
  const id = setInterval(
    () => startTimer(e, setResendSmsConfirmationCodeSucceeded, setTimer),
    1000,
  );
  // eslint-disable-next-line no-param-reassign
  Ref.current = id;
  setResendSmsConfirmationCodeSucceeded(true);
};

const validaSmsLength = (
  value,
  setSmsCode,
  setBadCode,
  setDisableSubmitButton,
) => {
  setSmsCode(value);
  setBadCode(false);
  if (value?.length === 6) {
    setDisableSubmitButton(false);
  } else {
    setDisableSubmitButton(true);
  }
};

const renderBadCode = badCode =>
  badCode && (
    <div className="phone-number-confirmation-validation-message-container">
      <div className="phone-number-confirmation-incorrect-code-container">
        <img src={IconCircleError} alt="validation-message" />
        <span className="phone-number-confirmation-incorrect-code-text">
          {PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE}
        </span>
      </div>
    </div>
  );

const renderConfirmationCode = resendSmsConfirmationCodeSucceeded =>
  resendSmsConfirmationCodeSucceeded && (
    <div className="phone-number-confirmation-sms-sent-success-container">
      <div className="phone-number-confirmation-resend-sms-confirmation-code-success-container">
        <img
          src={IconSmsSent}
          alt="IconSmsSent"
          className="phone-number-confirmation-sms-sent-icon"
        />

        <span className="phone-number-confirmation-sms-sent-text">
          {PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS}
        </span>
      </div>
    </div>
  );

const renderExpiredTokenMsg = location =>
  location.state?.sessionTimeout && (
    <div className="phone-number-confirmation-session-timeout-container">
      <div className="phone-number-confirmation-session-timeout-icon-container">
        <IconAlert color="inverse" className="icon" size="24" />
      </div>
      <div className="phone-number-confirmation-session-timeout-text-container">
        <BodyText className="phone-number-confirmation-session-timeout">
          {AUTH_TOKEN_SESSION_TIMED_OUT}
        </BodyText>
      </div>
    </div>
  );

const PhoneNumberConfirmation = ({
  phoneNumberConfirmationActions: {
    resendSmsConfirmationCode,
    validateEmailTokenAndGenerateSmsConfirmationCode,
    validateSmsConfirmationCode,
  },
  settingsActions: { showAppLoader, hideAppLoader },
  user: { maskedCellPhoneNumber },
}) => {
  const Ref = useRef(null);

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [smsCode, setSmsCode] = useState('');
  const [
    resendSmsConfirmationCodeSucceeded,
    setResendSmsConfirmationCodeSucceeded,
  ] = useState(false);
  const [
    resendSmsConfirmationCodeFailureModalDisplayed,
    setResendSmsConfirmationCodeFailureModalDisplayed,
  ] = useState(false);
  const [
    resendSmsConfirmationCodeSendLimitModalDisplayed,
    setResendSmsConfirmationCodeSendLimitModalDisplayed,
  ] = useState(false);
  const showResendSmsConfirmationCodeFailureModal = () => {
    setResendSmsConfirmationCodeFailureModalDisplayed(true);
  };
  const hideResendSmsConfirmationCodeFailureModal = () => {
    setResendSmsConfirmationCodeFailureModalDisplayed(false);
  };
  const showResendSmsConfirmationCodeSendLimitModal = () => {
    setResendSmsConfirmationCodeSendLimitModalDisplayed(true);
  };
  const hideResendSmsConfirmationCodeSendLimitModal = () => {
    setResendSmsConfirmationCodeSendLimitModalDisplayed(false);
  };
  const [timer, setTimer] = useState('01:00');
  const [badCode, setBadCode] = useState(false);
  const resendCodeMessage = resendSmsConfirmationCodeSucceeded
    ? ` en ${timer}`
    : '';

  useEffect(() => {
    showAppLoader();
    validateEmailTokenAndGenerateSmsConfirmationCode();
  }, [showAppLoader, validateEmailTokenAndGenerateSmsConfirmationCode]);

  const navigateToTermsConditions = () => {
    navigate(TERMS_CONDITIONS);
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const resendCode = async () => {
    showAppLoader();
    setResendSmsConfirmationCodeSucceeded(false);
    try {
      await resendSmsConfirmationCode();
      setResendSmsConfirmationCodeSucceeded(true);
      clearTimer(
        getDeadTime(),
        setTimer,
        Ref,
        setResendSmsConfirmationCodeSucceeded,
      );
    } catch (error) {
      showResendSmsConfirmationCodeFailureModal();
    } finally {
      hideAppLoader();
    }
  };

  const retryResendSmsConfirmationCode = () => {
    hideResendSmsConfirmationCodeFailureModal();
    resendCode();
  };

  const submitForm = async () => {
    showAppLoader();
    try {
      if (smsCode) {
        await validateSmsConfirmationCode(smsCode);
      }

      navigateToTermsConditions();
    } catch ({ code }) {
      if (code === SMS_LOCKOUT) {
        showResendSmsConfirmationCodeSendLimitModal();
      }
      setBadCode(true);
      setDisableSubmitButton(true);
      hideAppLoader();
    }
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  const renderResendSmsFailureModal = () => (
    <Modal
      isOpen={resendSmsConfirmationCodeFailureModalDisplayed}
      primaryButtonLabel={
        PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL
      }
      primaryButtonHandler={retryResendSmsConfirmationCode}
      centerContent
      closeButtonHandler={hideResendSmsConfirmationCodeFailureModal}
    >
      <div>
        <div className="phone-number-confirmation-modal-image auto-left-right-margin">
          <img src={IconTriangleAlert} alt="IconTriangleAlert" />
        </div>
        <div className="phone-number-confirmation-failure-modal-content-container">
          <div className="phone-number-confirmation-failure-modal-text-content-container">
            <div className="phone-number-confirmation-failure-modal-title">
              {PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE}
            </div>
            <div className="phone-number-confirmation-failure-modal-subtitle">
              <p>
                {PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE}
              </p>
              <p className="phone-number-confirmation-failure-modal-subtitle-two">
                {
                  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE_TWO
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  const renderResendSmsSendLimitModal = () => (
    <Modal
      isOpen={resendSmsConfirmationCodeSendLimitModalDisplayed}
      primaryButtonLabel={
        PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL
      }
      primaryButtonHandler={hideResendSmsConfirmationCodeSendLimitModal}
      closeButtonHandler={hideResendSmsConfirmationCodeSendLimitModal}
      centerContent
    >
      <div className="sms-code-send-limit-modal">
        <div className="image">
          <CircleImage>
            <IconClock sizing="auto" />
          </CircleImage>
        </div>
        <Heading type="h4-light" className="wrap">
          {PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE}
        </Heading>
        <BodyText className="wrap">
          {PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE}
        </BodyText>
      </div>
    </Modal>
  );

  return (
    <div className="adil-screen">
      <AppHeader displaySubtitle />
      {renderResendSmsFailureModal()}
      {renderResendSmsSendLimitModal()}
      {renderExpiredTokenMsg(history.location)}
      <Content className="adil-content">
        <CircleImage>
          <img
            src={cellphoneandmessage}
            className="phone-number-confirmation-icon"
            alt={PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT}
          />
        </CircleImage>
        <div className="phone-number-confirmation-text-container">
          <div className="phone-number-confirmation-title">
            {PHONE_NUMBER_CONFIRMATION_TITLE}
          </div>
          <div className="phone-number-confirmation-subtitle">
            {PHONE_NUMBER_CONFIRMATION_SUBTITLE}
          </div>
        </div>

        <div className="phone-number-confirmation-hidden-number">
          {maskedCellPhoneNumber}
        </div>

        {renderBadCode(badCode)}

        <div className="phone-number-confirmation-input-container">
          <VerificationInput
            placeholder=""
            validChars="0-9"
            length={6}
            classNames={getVerificationInpuntClassName(badCode)}
            onChange={value => {
              validaSmsLength(
                value,
                setSmsCode,
                setBadCode,
                setDisableSubmitButton,
              );
            }}
          />
        </div>

        {renderConfirmationCode(resendSmsConfirmationCodeSucceeded)}
        <div className="phone-number-confirmation-sms-sent-container">
          <span className="phone-number-confirmation-code-not-received">
            {PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED}
          </span>
          <span
            onClick={resendCode}
            role="link"
            className={getConfirmationCodeClassName(
              resendSmsConfirmationCodeSucceeded,
            )}
          >
            {`${PHONE_NUMBER_CONFIRMATION_RESEND_CODE}${resendCodeMessage}`}
          </span>
        </div>

        <div className="phone-number-confirmation-button-container">
          <Button
            disabled={disableSubmitButton}
            type="submit"
            className="phone-number-confirmation-button-submit"
            onClick={submitForm}
          >
            {PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON}
          </Button>
        </div>
        {renderFooterSection()}
      </Content>
      <MenuFooter />
    </div>
  );
};
PhoneNumberConfirmation.propTypes = {
  phoneNumberConfirmationActions:
    PhoneNumberConfirmationActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  user: PropTypes.shape({
    maskedCellPhoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  phoneNumberConfirmationActions: bindActionCreators(
    PhoneNumberConfirmationActions,
    dispatch,
  ),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneNumberConfirmation);
