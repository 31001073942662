import { Heading } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import OneStepFlow from '../StepsFlow/OneStepFlow';
import StepFlowMultiple from '../StepsFlow/StepFlowMultiple';
import { getVehicleClassName } from '../../utils';
import './styles.scss';

function nextArrow({ id }) {
  return <div id={id} />;
}

function prevArrow({ id }) {
  return <div id={id} />;
}

const HintsModal = ({
  isOpen,
  onClose,
  hints,
  steps,
  onContinue,
  photoId,
  vehicleClass,
}) => {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const [indexSlide, setIndexSlide] = useState(0);
  const [loadingImage, setLoadingImage] = useState(true);

  const {
    TAKE_PHOTO_MODAL_TITLE,
    TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL,
  } = localizedStrings(language);

  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    className: 'hints-modal-slider',
    nextArrow: nextArrow({
      id: `${getVehicleClassName(vehicleClass)}-${photoId}-HINT_NEXT_ARROW`,
    }),
    prevArrow: prevArrow({
      id: `${getVehicleClassName(vehicleClass)}-${photoId}-HINT_BACK_ARROW`,
    }),
    afterChange: current => {
      setIndexSlide(current);
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      photoId={photoId}
      closeButtonHandler={onClose}
      primaryButtonLabel={TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL}
      primaryButtonHandler={() => onContinue()}
      enableChevronButton
      buttonsDisabled={loadingImage}
    >
      <Heading type="h3-light" className="auto-left-right-margin align-center">
        {TAKE_PHOTO_MODAL_TITLE}
      </Heading>

      {!steps ? (
        <OneStepFlow
          settings={settings}
          hints={hints}
          photoId={photoId}
          indexSlide={indexSlide}
          isImageLoaded={() => setLoadingImage(false)}
        />
      ) : (
        <StepFlowMultiple
          settings={settings}
          steps={steps}
          isImageLoaded={() => setLoadingImage(false)}
        />
      )}
    </Modal>
  );
};

HintsModal.defaultProps = {
  hints: undefined,
  steps: undefined,
};

HintsModal.propTypes = {
  photoId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  steps: PropTypes.shape({
    hints: PropTypes.instanceOf(Array).isRequired,
  }),
  hints: PropTypes.shape({
    text: PropTypes.string.isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  vehicleClass: PropTypes.string.isRequired,
};

export default HintsModal;
