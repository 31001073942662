import React from 'react';
import PropTypes from 'prop-types';
import { VIDEO_SECTION } from '@lmig-latam/adil-api-common-lib/constants';
import { GridCol, GridRow } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import { getRequiredPhotosVideo } from '../../config/videoConfImages/videoRequiredConf';
import { CAMERA_MODE } from '../../constants/camera';
import PanelVideoFlow from './PanelVideoFlow/PanelVideoFlow';
import { WIZARD_PHOTO_STEP } from '../../constants/wizardPhotos';
import './styles.scss';

const VideoSection = ({ navigate, startCapturePhoto }) => {
  const {
    settings: {
      environment: { language },
    },
    photos: { wizardPhotoHub },
    user: { vehicleClass },
    takeVideo: { photosVideoPreview, finishedVideo },
  } = useSelector(store => store);

  const { INSPECTION_VIDEO_PHOTOS_VEHICLE: photoId } = VIDEO_SECTION;
  const videoConfig = getRequiredPhotosVideo(photoId, language);
  let photoToDisplay;

  if (photosVideoPreview.length > 0 && finishedVideo) {
    const [photo] = photosVideoPreview;
    photoToDisplay = photo.url;
  }

  const previewPhoto = photoToDisplay || null;

  // last required photo has already been taken
  const islastPhotoInWizard = () => {
    const lastRequiredPhotoTakenStep2 = 'INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE';

    const requiredPhotosStep = wizardPhotoHub[WIZARD_PHOTO_STEP.TWO - 1];

    return requiredPhotosStep.photos.some(
      photo => photo.photoId === lastRequiredPhotoTakenStep2,
    );
  };

  return (
    <GridRow key={videoConfig.photoId} className="panel-video-section">
      <GridCol>
        <PanelVideoFlow
          photoConfig={videoConfig}
          vehicleClass={vehicleClass}
          navigate={navigate}
          previewPhoto={previewPhoto}
          cameraMode={CAMERA_MODE.RECORD}
          startCapturePhoto={startCapturePhoto}
          disabled={!islastPhotoInWizard()}
        />
      </GridCol>
    </GridRow>
  );
};

VideoSection.propTypes = {
  navigate: PropTypes.func.isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
};

export default React.memo(VideoSection);
