/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

function IconClose2({ height, width, color, onClick }) {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 94.368 94.368"
        onClick={() => onClick()}
      >
        <defs>
          <filter
            id="Trazado_8"
            x="0"
            y="0"
            width={width}
            height={height}
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.302" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Grupo_86"
          data-name="Grupo 86"
          transform="translate(240.224 -615.547) rotate(45)"
        >
          <path
            id="Unión_3"
            data-name="Unión 3"
            d="M8.665,20.376V11.71H0V8.665H8.665V0H11.71V8.665h8.665V11.71H11.71v8.666Z"
            transform="translate(319.812 592.812)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
}

IconClose2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

IconClose2.defaultProps = {
  height: 90,
  width: 90,
  color: '#bebebe',
};

export default IconClose2;
