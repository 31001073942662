import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IconWarning, IconInfo } from '@lmig/lmds-react/icons';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

export default function NotifyProcess({ description, color, level }) {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const { STEP_INSTRUCCION_TEXT } = localizedStrings(language);

  const IconLevel = () => {
    switch (level) {
      case 'warning':
        return <IconWarning color="caution" size="16" />;
      case 'info':
        return <IconInfo color="informative" size="16" />;
      default:
        return <IconInfo color="informative" size="16" />;
    }
  };

  return (
    <div
      className="process-steps-list-instruccion-container"
      style={{ borderLeft: `0.4rem solid ${color}` }}
    >
      <div
        className="process-steps-instruccion-line"
        style={{ backgroundColor: color || undefined }}
      />
      <div className="process-steps-instruccion-image">
        <IconLevel />
      </div>
      <div>
        <p
          className="process-steps-instruccion-text"
          style={{ color: color || undefined }}
        >
          {description || STEP_INSTRUCCION_TEXT}
        </p>
      </div>
    </div>
  );
}

NotifyProcess.defaultProps = {
  description: '',
  color: '#0061F5',
  level: 'info',
};

NotifyProcess.propTypes = {
  description: PropTypes.string,
  color: PropTypes.string,
  level: PropTypes.string,
};
