import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Panel from '../../Panel';
import { PhotoHeaderCard } from '../PhotoHeaderCard/PhotoHeaderCard';
import ProgressBar from '../../ProgressBar/ProgressBar';
import './styles.scss';

export const DefaultPhotoPanel = ({
  disabled,
  title,
  body,
  photoId,
  IconComponent,
  errorStyles,
}) => {
  const {
    takePhoto: { loadPhotoPercentage, incomingPhotoId },
  } = useSelector(store => store);

  const isCurrentIncomingPhoto = () => incomingPhotoId === photoId;

  const showProgressBar = () =>
    (loadPhotoPercentage && isCurrentIncomingPhoto()) || errorStyles;

  return (
    <Panel disabled={disabled} className="default-photo-panel">
      <PhotoHeaderCard
        title={title}
        description={body}
        IconComponent={IconComponent}
        isError={errorStyles}
      />
      {showProgressBar() && (
        <ProgressBar
          completed={errorStyles ? 100 : loadPhotoPercentage}
          isError={errorStyles}
          bgcolor={errorStyles ? '#E72444' : undefined}
        />
      )}
    </Panel>
  );
};

DefaultPhotoPanel.propTypes = {
  photoId: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.node.isRequired,
  errorStyles: PropTypes.bool.isRequired,
};

DefaultPhotoPanel.defaultProps = {
  title: '',
  body: '',
  disabled: false,
  photoId: null,
};
