import {
  ACCESSORY_PHOTO_IDS,
  CONDITIONALLY_REQUIRED_PHOTO_IDS,
  REQUIRED_PHOTO_IDS,
} from '@lmig-latam/adil-api-common-lib/constants';
import { getClassificationPhotos } from '../constants/wizardPhotos';
import { PHOTO_TYPES } from './constants';

export class PhotoClassifier {
  constructor(countryCode) {
    this.countryCode = countryCode;
  }

  setPhotoID(photoId) {
    this.photoId = photoId;
    return this;
  }

  isAccessoryPhoto() {
    return Object.values(ACCESSORY_PHOTO_IDS).includes(this.photoId);
  }

  isRequiredPhoto() {
    return Object.values(REQUIRED_PHOTO_IDS).includes(this.photoId);
  }

  isConditionallyRequiredPhoto() {
    return Object.values(CONDITIONALLY_REQUIRED_PHOTO_IDS).includes(
      this.photoId,
    );
  }

  isDamagePhoto() {
    return String(this.photoId).indexOf(PHOTO_TYPES.DAMAGE_PHOTO) !== -1;
  }

  isVideo() {
    return String(this.photoId).indexOf(PHOTO_TYPES.VIDEO) !== -1;
  }

  getPhotoType() {
    if (this.isConditionallyRequiredPhoto())
      return PHOTO_TYPES.CONDITIONAL_REQUIRED_PHOTO;

    if (this.isDamagePhoto()) return PHOTO_TYPES.DAMAGE_PHOTO;
    if (this.isAccessoryPhoto()) return PHOTO_TYPES.ACCESSORY_PHOTO;
    if (this.isRequiredPhoto()) return PHOTO_TYPES.REQUIRED_PHOTO;
    return null;
  }

  getPhotoStep() {
    const photoClasification = getClassificationPhotos();
    const totalSteps = Object.keys(photoClasification).length;

    for (let step = 1; step < totalSteps; step += 1) {
      if (photoClasification[step].includes(this.photoId)) {
        const photoType = this.getPhotoType(this.photoId);

        return { step, photoType };
      }
    }
    return null;
  }
}
