import React from 'react';
import { useSelector } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import chromeIcon from '../../styles/images/chrome_icon.svg';
import firefoxIcon from '../../styles/images/firefox_icon.svg';
import safariIcon from '../../styles/images/safari_icon.svg';
import edgeIcon from '../../styles/images/edge_icon.svg';
import './styles.scss';

const url = `${window.location.origin}/app${window.location.search}`;

const BrowserIcons = () => {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const { BUTTON_CLIPBOARD, TEXT_COPIED } = localizedStrings(language);

  return (
    <div>
      <div className="share_link_wrap">
        <input type="text" id="embedLink" defaultValue={url} />
        <button
          type="button"
          className="btn"
          id="btnEmbed"
          onClick={() => {
            navigator.clipboard.writeText(url);
          }}
        >
          {BUTTON_CLIPBOARD}
          <span className="tooltiptext" id="myTooltip">
            {TEXT_COPIED}
          </span>
        </button>
      </div>
      <div />
      <div>
        <img alt="chrome" src={chromeIcon} className="browserIcons_class" />

        <img alt="firefox" src={firefoxIcon} className="browserIcons_class" />

        <img alt="safari" src={safariIcon} className="browserIcons_class" />

        <img alt="edge" src={edgeIcon} className="browserIcons_class" />
      </div>
    </div>
  );
};
export default BrowserIcons;
