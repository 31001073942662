import PropTypes from 'prop-types';
import {
  STATUS_TYPES,
  APPROVAL_TYPES,
} from '@lmig-latam/adil-api-common-lib/constants';
import {
  acceptTermsAndConditions,
  validateEmailToken,
  validateSmsConfirmationCode,
  getRetakePhotos,
  getPreviouslyTakenPhotos,
} from '../api';
import {
  handleInvalidStatusError,
  handleTokenMissingError,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';
import {
  is2FADisabled,
  setCookie,
  deleteCookie,
  stepsToShowValidator,
  enableRetakeFlow,
} from '../utils';
import {
  HIDE_APP_LOADER,
  UPDATE_USER_DETAILS,
  UPDATE_RETAKE,
  RESET_PRESIGNED_URL_ARRAY,
  ON_CHANGE_CURRENT_STEP,
} from './ActionTypes';
import { RETAKE_DATA_COOKIE_NAME, TOKEN_TIMEOUT } from '../utils/constants';
import PhotoHubActions from './PhotoHubActions';
import TakePhotoActions from './TakePhotoActions';
import { getPhotoStep } from '../constants/wizardPhotos';
import {
  handleErrors,
  handleVideo,
  setInactivityTimers,
} from './PhoneNumberConfirmationActions';
import AuthActions from './AuthActions';
import AuthToken from '../utils/session/AuthToken';
import CloseSession from '../utils/session/CloseSession';
import { LOGOUT_TYPE } from '../constants/session';
import { USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION } from '../constants/instructions';

const getAuthTokenWithSMSBypass = async () => {
  const islogoutByInactivity =
    CloseSession.getLogoutType() === LOGOUT_TYPE.INACTIVITY_SESSION;

  if (!islogoutByInactivity) {
    const responseAuth = await validateSmsConfirmationCode(
      process.env.REACT_APP_SMS_CONFIRMATION_CODE,
      true,
    );

    const { authorizationToken } = responseAuth;

    // Set auth cookie expiry to 2 hours. Same as the JWT token expiry
    AuthToken.setAuthToken(authorizationToken);

    sessionStorage.removeItem(
      USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION,
    );
  }
};

export default {
  PropertyTypes: PropTypes.shape({
    acceptTermsAndConditions: PropTypes.func.isRequired,
    validateEmailTokenAndGenerateAuthToken: PropTypes.func.isRequired,
  }),

  validateEmailTokenAndGenerateAuthToken() {
    let auth;
    const {
      STATUS_CUSTOMER_IN_PROGRESS,
      STATUS_SUPPORT_IN_PROGRESS,
    } = STATUS_TYPES;
    return async dispatch => {
      try {
        auth = is2FADisabled();
        if (auth) {
          const {
            status,
            vehicleClass,
            video,
            camera,
            lastCustomerStatus,
            sessionConfig,
          } = await validateEmailToken();

          const {
            timerCloseSessionTimeLimit,
            timerCloseSessionWarning,
            timelimitToExpireSession,
          } = sessionConfig;

          dispatch(AuthActions.setAuthenticationStatus(false));

          setInactivityTimers(
            dispatch,
            timerCloseSessionTimeLimit,
            timerCloseSessionWarning,
            timelimitToExpireSession,
          );

          sessionStorage.removeItem('VEHICLE_CLASS');

          // Get Authorization token and validate logout type (Expired Session or Inactivity)
          await getAuthTokenWithSMSBypass();

          sessionStorage.setItem('VEHICLE_CLASS', vehicleClass);
          dispatch(PhotoHubActions.setWizardStateByVehicleClass(vehicleClass));

          if (camera) {
            dispatch(TakePhotoActions.onChangeCameraType(camera));
            localStorage.setItem('CAMERA_TYPE', JSON.stringify(camera));
          }

          if (video) {
            await handleVideo(dispatch, video);
          }

          const userInspectionStatus = status;
          const vehicleClassInspection = vehicleClass;
          const isRetakeEnabled = enableRetakeFlow(
            userInspectionStatus,
            lastCustomerStatus,
          );

          dispatch({
            type: UPDATE_USER_DETAILS,
            value: {
              userInspectionStatus,
              vehicleClassInspection,
              lastCustomerStatus,
            },
          });

          if (isRetakeEnabled) {
            const retakeConfig = await getRetakePhotos();
            const stepsToShow = stepsToShowValidator(
              retakeConfig,
              vehicleClass,
            );
            const stringifiedRetakeConfig = JSON.stringify(retakeConfig);

            // Add/Update RETAKE Cookie and State
            setCookie(
              RETAKE_DATA_COOKIE_NAME,
              stringifiedRetakeConfig,
              TOKEN_TIMEOUT,
            );

            dispatch({
              type: UPDATE_RETAKE,
              value: retakeConfig,
            });

            dispatch({
              type: ON_CHANGE_CURRENT_STEP,
              payload: { step: stepsToShow[0], stepsToShow },
            });
          }

          if (
            ![STATUS_CUSTOMER_IN_PROGRESS, STATUS_SUPPORT_IN_PROGRESS].includes(
              userInspectionStatus,
            ) &&
            !isRetakeEnabled
          ) {
            deleteCookie(RETAKE_DATA_COOKIE_NAME);

            dispatch({
              type: UPDATE_RETAKE,
              value: undefined,
            });
          }
        }
        sessionStorage.removeItem('USER_ACCEPT_TERMS_OF_CONDITIONS');
        const cameraTypeFrame = localStorage.getItem('CAMERA_TYPE');
        if (
          cameraTypeFrame !== null &&
          JSON.parse(cameraTypeFrame) !== 'FRAME'
        ) {
          localStorage.removeItem('VIDEO_PARAMETERS');
          localStorage.removeItem('STORAGE_IS_FINISHED_VIDEO');
        }
      } catch (error) {
        dispatch({
          type: HIDE_APP_LOADER,
        });
        handleErrors(error);
        return;
      }

      dispatch({
        type: HIDE_APP_LOADER,
      });
    };
  },

  acceptTermsAndConditions(
    inspectionsStatus,
    vehicleClass,
    lastCustomerStatus,
  ) {
    const {
      STATUS_CUSTOMER_IN_PROGRESS,
      STATUS_SUPPORT_IN_PROGRESS,
    } = STATUS_TYPES;
    return async dispatch => {
      try {
        await acceptTermsAndConditions();

        sessionStorage.setItem('USER_ACCEPT_TERMS_OF_CONDITIONS', true);

        const isRetakeEnabled = enableRetakeFlow(
          inspectionsStatus,
          lastCustomerStatus,
        );
        if (
          [STATUS_CUSTOMER_IN_PROGRESS, STATUS_SUPPORT_IN_PROGRESS].includes(
            inspectionsStatus,
          ) ||
          isRetakeEnabled
        ) {
          const {
            data: { photos, damages },
          } = await getPreviouslyTakenPhotos();
          if (photos.length > 0 || damages.length > 0) {
            const allPhotos = [...photos, ...damages].filter(
              photo => photo.status === APPROVAL_TYPES.PENDING,
            );

            allPhotos.forEach(photo => {
              const { step, photoType } = getPhotoStep(
                photo.name,
                vehicleClass,
              );
              const photoToDisplay = {
                blobUrl: photo.url,
                fileType: 'jpeg',
                photoId: photo.name,
                photoType,
                step,
              };

              dispatch(TakePhotoActions.loadPreviusPhotos(photoToDisplay));
            });
          }
        }
        dispatch({ type: RESET_PRESIGNED_URL_ARRAY });
        dispatch({
          type: HIDE_APP_LOADER,
        });
      } catch (error) {
        dispatch({
          type: HIDE_APP_LOADER,
        });
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          navigate(ERROR_GENERIC);
        }
      }
    };
  },
};
