import { REQUIRED_PHOTO_IDS } from '@lmig-latam/adil-api-common-lib/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import images from '../../../assets/images/ExampleCarImages/index';
import './styles.scss';

const CameraOverlay = ({ iosCamera }) => {
  const {
    takePhoto: { incomingPhotoId },
    settings: { isFullscreen },
  } = useSelector(store => store);
  const {
    INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
    INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
  } = REQUIRED_PHOTO_IDS;

  const heightClassName = window.innerHeight < 750 ? '--changeHeight' : '';
  const getClassName = photoId => {
    const className = photoId
      ? `overlay_${photoId.split('_').slice(-2, -1)}${heightClassName}`
      : 'overlay_front';
    return `${className}`;
  };

  return (
    <div
      className={`overlay ${isFullscreen ? 'fullScreen' : ''} ${
        iosCamera ? 'cameraIos__overlay' : ''
      }`}
    >
      {incomingPhotoId === INSPECTION_PHOTO_VEHICLE_LEFT_SIDE && (
        <img
          src={images[INSPECTION_PHOTO_VEHICLE_LEFT_SIDE]}
          alt={INSPECTION_PHOTO_VEHICLE_LEFT_SIDE}
          className={getClassName(INSPECTION_PHOTO_VEHICLE_LEFT_SIDE)}
        />
      )}
      {incomingPhotoId === INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE && (
        <img
          src={images[INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE]}
          alt={INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE}
          className={getClassName(INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE)}
        />
      )}
    </div>
  );
};

CameraOverlay.propTypes = {
  iosCamera: PropTypes.bool,
};

CameraOverlay.defaultProps = {
  iosCamera: false,
};

export default CameraOverlay;
