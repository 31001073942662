/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconChevronLeft, Button } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import { getBrowser, getMobileOperatingSystem } from '../../utils';
import imagesByBrowser from './ImagesByBrowser';

const checkSelectedBrowser = (selectedBrowserChoosed, keySwitchSOBROWSER) => {
  let result = keySwitchSOBROWSER;
  if (selectedBrowserChoosed) {
    // eslint-disable-next-line prefer-destructuring
    result = selectedBrowserChoosed.split('camera_permissions_')[1];
  }

  return result;
};

const getSliderInformation = (keySwitchSOBROWSER, language) => {
  const {
    CAMERA_PERMISSIONS_TITLE_BROWSER_1,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE,
    CAMERA_PERMISSIONS_WIFI_HEADER,
    CAMERA_PERMISSIONS_WIFI_FOOTER,
  } = localizedStrings(language);

  switch (keySwitchSOBROWSER.toUpperCase()) {
    case 'IOS_CHROME':
      return [
        {
          image: 'IOS',
          title_general: CAMERA_PERMISSIONS_TITLE_BROWSER_1,
          title_1: CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE,
          subtitle_1: CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE,
        },
        {
          image: 'wifiInspections',
          title_1: CAMERA_PERMISSIONS_WIFI_HEADER,
          subtitle_1: CAMERA_PERMISSIONS_WIFI_FOOTER,
        },
      ];

    case 'IOS_SAFARI':
      return [
        {
          image: 'IOSAFARI',
          title_general: CAMERA_PERMISSIONS_TITLE_BROWSER_1,
          title_1: CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE,
          subtitle_1: CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE,
        },
        {
          image: 'wifiInspections',
          title_1: CAMERA_PERMISSIONS_WIFI_HEADER,
          subtitle_1: CAMERA_PERMISSIONS_WIFI_FOOTER,
        },
      ];

    default:
      return [
        {
          image: 'ANDROID_CHROME1',
          title_general: CAMERA_PERMISSIONS_TITLE_BROWSER_1,
          title_1: CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE,
          subtitle_1: CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE,
        },
        {
          image: 'ANDROID_CHROME2',
          title_general: CAMERA_PERMISSIONS_TITLE_BROWSER_1,
          title_1: CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE,
          subtitle_1: CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE,
        },
        {
          image: 'wifiInspections',
          title_1: CAMERA_PERMISSIONS_WIFI_HEADER,
          subtitle_1: CAMERA_PERMISSIONS_WIFI_FOOTER,
        },
      ];
  }
};

const ModalBrowserInstructions = props => {
  const {
    pageNumber,
    setPageNumber,
    selectedBrowserChoosed,
    closeButtonHandler,
  } = props;
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const sliderRef = useRef();
  const [slideItems, setSlideItems] = useState({
    activeSlide: 0,
  });
  const [lastSlide, setLastSlide] = useState(false);
  const [numberItems, setNumberItems] = useState(0);

  const {
    MODAL_PRIMARY_BUTTON_LABEL,
    CAMERA_PERMISSIONS_TITLE_BROWSER_1,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE,
    CAMERA_PERMISSIONS_WIFI_HEADER,
    CAMERA_PERMISSIONS_WIFI_FOOTER,
    TUTORIAL_STEP_FINAL,
    TUTORIAL_STEP_FINAL_READY,
  } = localizedStrings(language);

  const settings = {
    dots: true,
    className: 'sliderRef',
    arrows: false,
    infinite: false,
    beforeChange: (current, next) =>
      setSlideItems(prevState => ({
        ...prevState,
        activeSlide: next,
      })),
  };

  const [informationPhone, setInformationPhone] = useState([]);

  useEffect(() => {
    const browserDetected = getBrowser();
    const OSDetected = getMobileOperatingSystem();

    let keySwitchSOBROWSER = `${OSDetected}_${browserDetected}`;

    keySwitchSOBROWSER = checkSelectedBrowser(
      selectedBrowserChoosed,
      keySwitchSOBROWSER,
    );

    let sliderInstructions = {};
    sliderInstructions = getSliderInformation(keySwitchSOBROWSER, language);

    setInformationPhone(sliderInstructions);
    setNumberItems(sliderInstructions.length - 1);
  }, [
    selectedBrowserChoosed,
    CAMERA_PERMISSIONS_TITLE_BROWSER_1,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_1_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_ANDROID_2_SUBTITLE,
    CAMERA_PERMISSIONS_WIFI_HEADER,
    CAMERA_PERMISSIONS_WIFI_FOOTER,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_CHROME_IOS_SUBTITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_TITLE,
    CAMERA_PERMISSIONS_TITLE_BROWSER_SAFARI_IOS_SUBTITLE,
    language,
  ]);

  useEffect(() => {
    if (
      slideItems.activeSlide !== 0 &&
      numberItems.length !== 0 &&
      slideItems.activeSlide === numberItems
    ) {
      setLastSlide(true);
    } else {
      setLastSlide(false);
    }
  }, [slideItems, informationPhone, numberItems]);

  const slickNextOrClose = () => {
    if (!lastSlide) {
      sliderRef.current.slickNext();
    } else {
      closeButtonHandler();
    }
  };

  return (
    informationPhone && (
      <Modal {...props} centerContent>
        <>
          <Slider ref={sliderRef} {...settings}>
            {informationPhone.map(item =>
              item.title_1 !== CAMERA_PERMISSIONS_WIFI_HEADER ? (
                <div className="tutorialUse" key={item.title_1}>
                  <img
                    src={imagesByBrowser[item.image]}
                    alt={item.image}
                    className="tutorialUse__image"
                  />
                  <h3 className="tutorialUse__generalTitle">
                    {item.title_general}
                  </h3>
                  <div className="tutorialUse__box">
                    <h4 className="tutorialUse__box__title">{item.title_1}</h4>
                    <p className="tutorialUse__box__subtitle">
                      {item.subtitle_1}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="tutorialUse" key={item.title_1}>
                  <img
                    src={imagesByBrowser[item.image]}
                    alt={item.image}
                    className="tutorialUse__image"
                  />
                  <h4 className="tutorialUse__box__title--lastSlide">
                    {item.title_1}
                  </h4>
                  <p className="tutorialUse__box__subtitle--lastSlide">
                    {item.subtitle_1}
                  </p>
                </div>
              ),
            )}
          </Slider>
          <br />
          <div className="footer-modal-permissions radiusButton_adil">
            <span role="button" onClick={() => setPageNumber(pageNumber - 1)}>
              <IconChevronLeft />
            </span>
            <Button type="button" onClick={slickNextOrClose}>
              {!lastSlide
                ? MODAL_PRIMARY_BUTTON_LABEL
                : TUTORIAL_STEP_FINAL_READY}
            </Button>
            {lastSlide && (
              <span
                className="exitText"
                role="button"
                onClick={() => closeButtonHandler()}
              >
                {TUTORIAL_STEP_FINAL}
              </span>
            )}
          </div>
        </>
      </Modal>
    )
  );
};

ModalBrowserInstructions.propTypes = {
  setPageNumber: PropTypes.func.isRequired,
  closeButtonHandler: PropTypes.func.isRequired,
  selectedBrowserChoosed: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  imgBrowser: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
ModalBrowserInstructions.defaultProps = {
  selectedBrowserChoosed: '',
};
export default ModalBrowserInstructions;
