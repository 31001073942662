import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, Heading } from '@lmig/lmds-react';
import localizedStrings from '../../utils/localizedStrings';

export const ExpiredSessionView = ({ language }) => {
  const {
    EXPIRED_SESSION_MODAL_TITLE,
    EXPIRED_SESSION_MODAL_HINT,
  } = localizedStrings(language);

  return (
    <>
      <Heading type="h3-light" className="inactivity-modal__title">
        {EXPIRED_SESSION_MODAL_TITLE}
      </Heading>

      <BodyText className="inactivity-modal__hints">
        {EXPIRED_SESSION_MODAL_HINT}
      </BodyText>
    </>
  );
};

ExpiredSessionView.propTypes = {
  language: PropTypes.string.isRequired,
};
