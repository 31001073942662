import React, { useCallback, useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { Route, Router, Switch } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { APPROVAL_TYPES } from '@lmig-latam/adil-api-common-lib/constants';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { bindActionCreators } from 'redux';
import { NonMobileError } from '..';
import { is2FADisabled, isMobileOrTablet } from '../../utils';
import {
  TokenError,
  checkForTokens,
  handleTokenMissingError,
} from '../../utils/customErrors';
import history from '../../utils/history';
import Routes from '../routes';
import {
  DeviceInfoActions,
  ModalFlowActions,
  SettingsActions,
  TakePhotoActions,
  TakeVideoActions,
} from '../../actions';
import { logcodes, logger } from '../../utils/logger';
import { getPreviouslyTakenPhotos, validateEmailToken } from '../../api';
import {
  HIDE_APP_LOADER,
  UPDATE_USER_DETAILS,
} from '../../actions/ActionTypes';
import { getPhotoStep } from '../../constants/wizardPhotos';
import { ActivityProvider } from '../../components/ActivityProvider';
import { FAQModal, InstructionsModal } from '../../components';
import {
  FORM_SUPPORT,
  PHOTO_HUB,
  PROCESS_SUPPORT,
} from '../../utils/navigationConstants';
import { buildRequiredPhotos } from '../../config/requiredPhotosConf';
import VideoHintsImage from '../../assets/gifs/video.gif';
import { getConditionallyRequiredPhotosConfig } from '../../config/conditionallyRequiredPhotos';
import { getCountryCode } from '../../utils/NavigationUtils';

const App = ({ settingsActions: { showAppLoader } }) => {
  const dispatch = useDispatch();

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const {
    VEHICLE_CLASS_MOTORBIKE,
  } = localizedStringsCommon.getCurrentLanguage();

  const {
    deviceInfo: { userAgentDevice },
    user: { userInspectionStatus: inspectionStatusUser, vehicleClass },
    takePhoto: { cameraMode },
    modalFlow: { instructionsModal, faqModalDisplayed },
    settings,
  } = useSelector(store => store);

  const [isMobileDevice, setIsMobileDevice] = useState(null);

  const getUserInspectionStatus = async () => {
    const {
      status,
      vehicleClass: newVehicleClass,
      camera,
      video,
      lastCustomerStatus,
    } = await validateEmailToken();
    if (video) {
      const {
        enableSection,
        finished,
        duration,
        frequency,
        compress,
        required,
      } = video;

      const videoParameters = {
        enableSection,
        finished,
        required,
        duration,
        frequency,
        compress,
      };

      dispatch(TakeVideoActions.reviewVideoParameters(videoParameters));
      if (enableSection !== null && enableSection !== undefined) {
        dispatch(TakeVideoActions.onDisplayVideoSection(enableSection));
      }
    }

    const userInspectionStatus = status;
    const vehicleClassInspection = newVehicleClass;
    if (camera) dispatch(TakePhotoActions.onChangeCameraType(camera));
    dispatch({
      type: UPDATE_USER_DETAILS,
      value: {
        userInspectionStatus,
        vehicleClassInspection,
        lastCustomerStatus,
      },
    });
  };

  useEffect(() => {
    if (
      !inspectionStatusUser &&
      window.location.pathname.split('/').pop() === 'photo_hub'
    ) {
      getUserInspectionStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionStatusUser, window.location.pathname]);

  useEffect(() => {
    const cameraTypeFrame = localStorage.getItem('CAMERA_TYPE');
    if (cameraTypeFrame !== null && JSON.parse(cameraTypeFrame) === 'FRAME') {
      dispatch(TakeVideoActions.onDisplayVideoSection(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requiredPhotos = buildRequiredPhotos();
    const conditionallyPhotos = getConditionallyRequiredPhotosConfig(
      getCountryCode(),
      vehicleClass,
    );

    const images = [];
    [...requiredPhotos, ...conditionallyPhotos].forEach(photoConfig =>
      photoConfig.hints?.images.forEach(imageSrc => images.push(imageSrc)),
    );
    if (vehicleClass !== VEHICLE_CLASS_MOTORBIKE) images.push(VideoHintsImage);
    images.forEach(imageSrc => {
      const img = new Image();
      img.src = imageSrc;
      window[imageSrc] = img;
    });
  }, [vehicleClass, VEHICLE_CLASS_MOTORBIKE]);

  const loadPreviusPhotosDone = useCallback(async () => {
    if (inspectionStatusUser !== '') {
      try {
        showAppLoader();
        const {
          data: { photos, damages },
        } = await getPreviouslyTakenPhotos();

        if (!photos) return;
        dispatch({ type: HIDE_APP_LOADER });
        const allPhotos = [...photos, ...damages].filter(
          photo => photo.status === APPROVAL_TYPES.PENDING,
        );

        allPhotos.forEach(photo => {
          const { step, photoType } = getPhotoStep(photo.name, vehicleClass);
          const photoToDisplay = {
            blobUrl: photo.url,
            fileType: 'jpeg',
            photoId: photo.name,
            photoType,
            step,
          };

          dispatch(TakePhotoActions.loadPreviusPhotos(photoToDisplay));
        });
      } catch (error) {
        if (error instanceof TokenError)
          dispatch(ModalFlowActions.onDisplayExpiredSessionModal(true));
        logger.log(logcodes.LPIM10, { error });
        dispatch({ type: HIDE_APP_LOADER });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inspectionStatusUser, settings.resume]);

  useEffect(() => {
    if (
      settings.lastPath !== '/app/take_photo' &&
      window.location.pathname.split('/').pop() === 'photo_hub'
    ) {
      loadPreviusPhotosDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadPreviusPhotosDone, window.location.pathname]);

  useEffect(() => {
    if (
      settings.lastPath === '/app/take_photo' &&
      cameraMode === 'RECORD' &&
      window.location.pathname.split('/').pop() === 'photo_hub'
    ) {
      loadPreviusPhotosDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadPreviusPhotosDone, window.location.pathname]);

  useEffect(() => {
    try {
      if (!is2FADisabled()) {
        checkForTokens();
      }
    } catch (error) {
      handleTokenMissingError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { userAgent } = navigator;
    if (userAgent !== userAgentDevice) {
      const isMobile = isMobileOrTablet();
      dispatch(DeviceInfoActions.setUserAgentDevice(userAgent));
      setIsMobileDevice(isMobile);
    }
  }, [dispatch, userAgentDevice]);

  useEffect(() => {
    const acceptTerms = sessionStorage.getItem(
      'USER_ACCEPT_TERMS_OF_CONDITIONS',
    );

    const userHasSeeInstructions = sessionStorage.getItem(
      'USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION',
    );

    const isAllowScreen = [PHOTO_HUB, PROCESS_SUPPORT, FORM_SUPPORT].includes(
      window.location.pathname,
    );

    if (isAllowScreen && acceptTerms && !userHasSeeInstructions)
      dispatch(ModalFlowActions.onDisplayInstructionsModal(true));
  }, [dispatch]);

  if (!isMobileDevice) {
    return (
      <Router history={history}>
        <Switch>
          <Route>
            <NonMobileError />
          </Route>
        </Switch>
      </Router>
    );
  }

  const onCloseModalInstructions = () => {
    dispatch(ModalFlowActions.onDisplayInstructionsModal(false));
  };

  return (
    <ActivityProvider>
      <Routes is2FADisabled={is2FADisabled} />
      {instructionsModal.displayInstructionsModal && (
        <InstructionsModal
          isBeginningProcess={!instructionsModal.location}
          isOpen={instructionsModal.displayInstructionsModal}
          onCloseButtonHandler={onCloseModalInstructions}
        />
      )}
      {faqModalDisplayed && (
        <FAQModal
          isOpen={faqModalDisplayed}
          closeButtonHandler={() => {
            dispatch(ModalFlowActions.changeStateFaqModal(false));
          }}
        />
      )}
    </ActivityProvider>
  );
};

App.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(App);
