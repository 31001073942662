/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@lmig/lmds-react';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import {
  AppFooter,
  ConditionallyRequiredPhotosSection,
  ProcessStepsList,
  VideoSection,
} from '..';
import { WIZARD_PHOTO_STEP } from '../../constants/wizardPhotos';
import { OptionalStepList } from '../OptionalStepList/OptionalStepList';
import { usePhotoConfigByStep, useTakenPhoto } from '../../hooks';
import { enableRetakeFlow } from '../../utils';
import DamageSection from '../DamageSection/DamageSection';
import { AllowPhotoCountry } from '../../config/AllowPhotoCountry';
import { getConditionallyRequiredPhotosConfig } from '../../config/conditionallyRequiredPhotos';
import { getCountryCode } from '../../utils/NavigationUtils';
import './styles.scss';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

const conditionalPhotosSectionValidator = props => {
  const {
    countryConditionallyRequiredPhotoIds,
    vehicleClass,
    countryMotorBikeConditionallyRequiredPhotoIds,
    userInspectionStatus,
    lastCustomerStatus,
    language,
    photosConfig,
    currentStep,
  } = props;
  let requiredPhotos = countryConditionallyRequiredPhotoIds;
  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE)
    requiredPhotos = countryMotorBikeConditionallyRequiredPhotoIds;
  let result = requiredPhotos.length > 0;
  const isRetakeEnabled = enableRetakeFlow(
    userInspectionStatus,
    lastCustomerStatus,
  );
  if (isRetakeEnabled) {
    const conditionalPhotos = getConditionallyRequiredPhotosConfig(
      language,
      vehicleClass,
    );
    result = conditionalPhotos.some(conditionalPhoto =>
      photosConfig.some(
        requiredPhoto => requiredPhoto.photoId === conditionalPhoto.photoId,
      ),
    );
  }

  return currentStep === WIZARD_PHOTO_STEP.THREE && result;
};

const showDamageSectionByCountry = (retake, vehicleClass, currentStep) => {
  const allow = new AllowPhotoCountry(getCountryCode());
  return (
    !retake &&
    allow
      .setVehicleClass(vehicleClass)
      .isAllowOptionalSectionByStep(currentStep)
  );
};

const renderConditionalPhotosSection = (
  showConditionallyPhotoSection,
  navigate,
  vehicleClass,
  conditionalPhotosTaken,
  spareWheelToggleEnabled,
  startCapturePhoto,
  capturePhotoRef,
) =>
  showConditionallyPhotoSection ? (
    <ConditionallyRequiredPhotosSection
      navigate={navigate}
      vehicleClass={vehicleClass}
      conditionalPhotosTaken={conditionalPhotosTaken}
      isToggleEnabled={spareWheelToggleEnabled}
      startCapturePhoto={startCapturePhoto}
      capturePhotoRef={capturePhotoRef}
    />
  ) : null;

const renderStepList = props => {
  const {
    isOptionalStep,
    photosConfig,
    accessoryPhotosTaken,
    capturePhotoRef,
    navigate,
    requiredPhotosTaken,
    photoListConfig,
    startCapturePhoto,
  } = props;

  return isOptionalStep ? (
    <OptionalStepList
      photosConfig={photosConfig}
      accessoryPhotosTaken={accessoryPhotosTaken}
      navigate={navigate}
    />
  ) : (
    <ProcessStepsList
      navigate={navigate}
      photos={requiredPhotosTaken}
      photoListConfig={photoListConfig}
      startCapturePhoto={startCapturePhoto}
      capturePhotoRef={capturePhotoRef}
    />
  );
};

const renderDamagesPhotosSection = (
  navigate,
  damagePhotosTaken,
  retake,
  vehicleClass,
  currentStep,
) =>
  showDamageSectionByCountry(retake, vehicleClass, currentStep) && (
    <Content>
      <DamageSection navigate={navigate} photosTaken={damagePhotosTaken} />
    </Content>
  );

const PhotoHubSection = ({
  vehicleClass,
  navigate,
  currentStep,
  photosConfig,
  startCapturePhoto,
  capturePhotoRef,
  isEnableVideoSection,
}) => {
  const {
    photos: { spareWheelToggleEnabled },
    settings: {
      retake,
      environment: { language },
    },
    user: { userInspectionStatus, lastCustomerStatus },
  } = useSelector(store => store);

  const {
    countryMotorBikeConditionallyRequiredPhotoIds,
    countryConditionallyRequiredPhotoIds,
  } = getCountryConfig(getCountryCode());

  const {
    requiredPhotosTaken = [],
    accessoryPhotosTaken,
    damagePhotosTaken,
    conditionalPhotosTaken,
  } = useTakenPhoto();

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? (
      <div className="photo-hub-section-app-footer-container">
        <AppFooter />
      </div>
    ) : null;

  const isOptionalStep = useMemo(() => currentStep === WIZARD_PHOTO_STEP.FOUR, [
    currentStep,
  ]);

  const showVideoSection = useCallback(() => {
    const allow = new AllowPhotoCountry(getCountryCode());
    return (
      !retake && allow.isAllowVideoSection(currentStep) && isEnableVideoSection
    );
  }, [retake, currentStep]);

  const showConditionallyPhotoSection = conditionalPhotosSectionValidator({
    countryConditionallyRequiredPhotoIds,
    vehicleClass,
    countryMotorBikeConditionallyRequiredPhotoIds,
    userInspectionStatus,
    lastCustomerStatus,
    language,
    photosConfig,
    currentStep,
  });

  const photoListConfig = usePhotoConfigByStep(photosConfig, vehicleClass);

  return (
    <Content>
      {renderStepList({
        isOptionalStep,
        photosConfig,
        accessoryPhotosTaken,
        capturePhotoRef,
        navigate,
        requiredPhotosTaken,
        photoListConfig,
        startCapturePhoto,
      })}

      {showVideoSection() && (
        <VideoSection
          navigate={navigate}
          startCapturePhoto={startCapturePhoto}
        />
      )}

      {renderDamagesPhotosSection(
        navigate,
        damagePhotosTaken,
        retake,
        vehicleClass,
        currentStep,
      )}

      {renderConditionalPhotosSection(
        showConditionallyPhotoSection,
        navigate,
        vehicleClass,
        conditionalPhotosTaken,
        spareWheelToggleEnabled,
        startCapturePhoto,
        capturePhotoRef,
      )}
      {renderFooterSection()}
    </Content>
  );
};

PhotoHubSection.propTypes = {
  vehicleClass: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  photosConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
  startCapturePhoto: PropTypes.func.isRequired,
  isEnableVideoSection: PropTypes.bool.isRequired,
  capturePhotoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default PhotoHubSection;
