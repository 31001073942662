/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REQUIRED_PHOTO_IDS } from '@lmig-latam/adil-api-common-lib/constants';
import WebcamCapturePhoto from '../WebcamCapture/WebcamCapturePhoto';
import WebcamCaptureVideo from '../WebcamCapture/WebcamCaptureVideo';

import { PanelCameraDown, PanelCameraTop, PreviewCamera } from '..';
import {
  GuidedVisitActions,
  PhotoHubActions,
  TakePhotoActions,
  TakeVideoActions,
} from '../../../actions';
import { getAccessoryPhotoInfo } from '../../../config/availableAccesories';
import { getConditionallyRequiredPhoto } from '../../../config/conditionallyRequiredPhotos';
import {
  ARRAY_TOP_BUTTONS,
  BUTTONS_TOP,
  CAMERA_MODE,
  OPTIONS_PREVIEW_CAMERA,
} from '../../../constants/camera';
import { STEP_ITEMS_GUIDED_VISIT } from '../../../constants/guidedTour/guidedTourPhoto';
import { STEP_ITEMS_GUIDED_VISIT_VIDEO } from '../../../constants/guidedTour/guidedTourVideo';
import { convertdataURItoBlob, generatePhotoObjects } from '../../../utils';
import { PHOTO_HUB } from '../../../utils/navigationConstants';
import history from '../../../utils/history';
import CameraTimer from '../CameraTimer';
import imagesInstruction from '../../../assets/images/index';
import { CameraExample } from '../CameraExample/CameraExample';
import { logcodes, logger } from '../../../utils/logger';
import { SAVE_LASTPATH } from '../../../actions/ActionTypes';
import { finishVideoRecord } from '../../../api';
import SplashLoading from '../SplashLoading/SplashLoading';
import { DAMAGE_PHOTO } from '../../../utils/constants';
import './styles.scss';

const {
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
} = REQUIRED_PHOTO_IDS;

const exitFS = (isFullscreen, dispatch) => {
  // (B2) THEN EXIT FULL SCREEN
  if (isFullscreen) {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    dispatch(TakePhotoActions.onChangeStateFullscreen(false));
  }
};

const showButtonsGuidedVisitPhoto = (isActiveGuidedVisit, currentStep) => {
  const { SIXTH, FIFTH } = STEP_ITEMS_GUIDED_VISIT;
  if (isActiveGuidedVisit && currentStep)
    return currentStep.id === SIXTH.ID || currentStep.id === FIFTH.ID;
  return false;
};

const showHintsGuidedVisitVideo = (isActiveGuidedVisit, currentStep) => {
  const { SECOND, THIRD, FOURTH } = STEP_ITEMS_GUIDED_VISIT_VIDEO;
  if (isActiveGuidedVisit && currentStep)
    return (
      currentStep.id === SECOND.ID ||
      currentStep.id === THIRD.ID ||
      currentStep.id === FOURTH.ID
    );
  return false;
};

const showInstructionsPhotosById = currentStep => {
  const { SECOND, THIRD } = STEP_ITEMS_GUIDED_VISIT_VIDEO;
  let urlName = '';
  let nameClass = '';
  if (currentStep.id === SECOND.ID) {
    urlName = imagesInstruction.recordButton;
    nameClass = 'recordButton_pos';
  } else if (currentStep.id === THIRD.ID) {
    urlName = imagesInstruction.recordFinish;
    nameClass = 'recordFinish_pos';
  } else {
    urlName = imagesInstruction.timerImage;
    nameClass = 'timerImage_pos';
  }
  return (
    <img src={urlName} alt={nameClass.split('_')[0]} className={nameClass} />
  );
};

const getArrayTopButtons = (photoInPreview, displayPreviewVideo) => {
  // eslint-disable-next-line no-unused-vars
  const { EXIT, REPEAT } = ARRAY_TOP_BUTTONS;
  if (photoInPreview) return [EXIT];
  if (displayPreviewVideo) return [REPEAT];
  return [EXIT];
};

const CameraContainer = ({
  photoHubActions,
  retake,
  language,
  showPhotoUploadFailureModal,
  handlerFullScreen: { openFullScreen, exitFullScreen },
}) => {
  const [isLoading, setLoading] = useState(false);
  const [rotateMode, setRotateMode] = useState(
    window.innerWidth > window.innerHeight,
  );
  const dispatch = useDispatch();

  const {
    photos: { presignedURLS },
    deviceInfo: { geoLocation, GPSStatus },
    guidedVisit: { isActiveGuidedVisit, currentStep },
    takePhoto: {
      incomingPhotoId,
      photoInPreview,
      cameraMode,
      overlay,
      isFullscreen,
    },
    takeVideo: {
      isRecording,
      photosVideoPreview,
      displayPreviewVideo,
      secondsVideo,
      photosPendingToFinish,
      uploadingVideoPhotoStatus,
      photosCompletedToFinish,
      videoParameters: { frequencyVideo, durationVideo },
    },
  } = useSelector(store => store);

  const getProgressUploadingPhoto = useCallback(() => {
    const { length } = uploadingVideoPhotoStatus;
    const result = Math.round((photosCompletedToFinish / length) * 100);
    return Number(result) || undefined;
  }, [uploadingVideoPhotoStatus, photosCompletedToFinish]);

  const getPhotosPreviewVideo = useCallback(async () => {
    dispatch(TakeVideoActions.resetPhotosPreviewVideo());
    dispatch(TakeVideoActions.getPhotosPreviewVideo());
  }, [dispatch]);

  useEffect(() => {
    if (photosPendingToFinish > 0 && secondsVideo === 0) {
      // The countdown has ended, but there are still photos pending to finish uploading
      getPhotosPreviewVideo();
      dispatch(TakeVideoActions.onDisplayPreviewVideo(true));
      setLoading(true);
    }

    if (
      (photosPendingToFinish === 0 && secondsVideo === 0) ||
      getProgressUploadingPhoto() === 100
    ) {
      // This is when it really ends, there are no photos left and the countdown is over.
      setLoading(false);
    }
  }, [
    isRecording,
    photosPendingToFinish,
    secondsVideo,
    getPhotosPreviewVideo,
    getProgressUploadingPhoto,
    dispatch,
  ]);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth > window.innerHeight) {
        setRotateMode(true);
      } else {
        setRotateMode(false);
      }
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [rotateMode, setRotateMode]);

  const goToPhotoHub = () => {
    dispatch({
      type: SAVE_LASTPATH,
      value: history.location.pathname,
    });
    history.push({
      pathname: PHOTO_HUB,
      search: history.location.search,
    });
    exitFS(isFullscreen, dispatch);
  };

  const onCapturePhoto = useCallback(async () => {
    dispatch(TakePhotoActions.onPressButtonTakePhotoStatus(true));
  }, [dispatch]);

  const onStartRecording = useCallback(async () => {
    dispatch(TakeVideoActions.onRecordingCamera(true));
  }, [dispatch]);

  const onStopRecording = useCallback(async () => {
    dispatch(TakeVideoActions.onRecordingCamera(false));
    setLoading(true);
    // Add await for get photos preview
    getPhotosPreviewVideo();
    dispatch(TakeVideoActions.onDisplayPreviewVideo(true));

    /** SHOULD SEND REQUEST FOR GET PHOTO VIDEOS */

    dispatch(TakeVideoActions.onDisplayModalFinishVideo(true));
  }, [dispatch, getPhotosPreviewVideo]);

  const handlerClose = () => {
    if (!photoInPreview) goToPhotoHub();
    dispatch(TakePhotoActions.setIncomingPhoto(null));
    dispatch(TakePhotoActions.setCurrentPhotoPreview(null));
    setLoading(false);
  };

  const uploadPhotoHandler = async (photoToUpload, photoToDisplay) => {
    try {
      let presignedPhoto = presignedURLS.find(
        i => i.name === photoToUpload.photoId,
      );

      if (!presignedPhoto) {
        // Steps for accesories
        const photoIdArray = [photoToUpload.photoId];
        const [presigned] = await photoHubActions.getPresignedURLArray(
          photoIdArray,
          true,
        );
        presignedPhoto = presigned;
      }

      if (!presignedPhoto) {
        logger.log(logcodes.UPF20);
        return;
      }

      const location = {
        ...geoLocation,
        status: GPSStatus?.message,
      };

      await photoHubActions.uploadPhoto(
        photoToUpload,
        photoToDisplay,
        presignedPhoto,
        location,
        currentStep,
      );
    } catch (error) {
      showPhotoUploadFailureModal();
    }
  };

  const handlerOptionsVideoPreview = async type => {
    if (type === OPTIONS_PREVIEW_CAMERA.UPLOAD) {
      await finishVideoRecord(secondsVideo);
      dispatch(TakeVideoActions.onDisplayPreviewVideo(false));
      dispatch(TakeVideoActions.onFinishVideo(true));
      handlerClose();
    }
  };

  const handlerOptionsPhotoPreview = async type => {
    switch (type) {
      case OPTIONS_PREVIEW_CAMERA.REPEAT:
        dispatch(TakePhotoActions.setCurrentPhotoPreview(null));
        dispatch(TakePhotoActions.onFlashInBackgroundPhoto(false));
        setLoading(false);
        break;
      case OPTIONS_PREVIEW_CAMERA.UPLOAD: {
        let isOptional = !!getAccessoryPhotoInfo(incomingPhotoId);
        let isConditionallyRequired = !!getConditionallyRequiredPhoto(
          incomingPhotoId,
          language,
        );

        if (Object.keys(retake).length !== 0) {
          isOptional = false;
          isConditionallyRequired = false;
        }
        const isDamage =
          String(incomingPhotoId).indexOf(DAMAGE_PHOTO) !== -1 || undefined;

        if (isDamage) {
          isOptional = false;
          isConditionallyRequired = false;
        }

        const blobIncomingFile = convertdataURItoBlob(photoInPreview);
        const { photoToUpload, photoToDisplay } = await generatePhotoObjects({
          incomingFile: blobIncomingFile,
          photoId: incomingPhotoId,
          isOptional,
          isConditionallyRequired,
          compress: 1,
          isDamage,
        });
        setLoading(true);
        await uploadPhotoHandler(photoToUpload, photoToDisplay);
        localStorage.setItem('photoId-ref', incomingPhotoId);
        dispatch(TakePhotoActions.setCurrentPhotoPreview(null));
        setLoading(false);
        goToPhotoHub();
        break;
      }
      default:
        break;
    }
  };

  const handlerHelp = () =>
    dispatch(GuidedVisitActions.onChangeStateGuidedVisit(true));

  const onManagerTopButtons = async name => {
    const { BACK, HELP, EXIT, REPEAT } = BUTTONS_TOP;
    switch (name) {
      case BACK:
        dispatch(TakePhotoActions.setCurrentPhotoPreview(null));
        dispatch(TakePhotoActions.onFlashInBackgroundPhoto(false));
        exitFS(isFullscreen, dispatch);
        break;
      case HELP:
        handlerHelp();
        break;
      case EXIT:
        dispatch(GuidedVisitActions.destroyStepsGuides());
        dispatch(TakePhotoActions.onChangeStateFullscreen(false));
        handlerClose();
        break;
      case REPEAT:
        dispatch(TakeVideoActions.onDisplayPreviewVideo(false));
        await TakeVideoActions.onDeleteVideo();
        dispatch(TakeVideoActions.onFinishVideo(false));
        break;
      default:
        break;
    }
  };

  const onManagerOptionsPreview = type => {
    const { PHOTO, RECORD } = CAMERA_MODE;
    switch (cameraMode) {
      case PHOTO:
        handlerOptionsPhotoPreview(type);
        break;
      case RECORD:
        handlerOptionsVideoPreview(type);
        break;
      default:
    }
  };

  function getButtonsInGuidedVisit() {
    return (
      <PanelCameraDown
        disable
        isPhotoInPreview
        lang={language}
        recordingMode={cameraMode === CAMERA_MODE.RECORD}
        recording={isRecording}
        isLoading={false}
        isFullscreen={false}
        capturePhoto={() => {
          // Empty function
        }}
        handlerOptionsPreview={() => {
          // Empty function
        }}
        handlerFullScreen={{
          openFullScreen: () => {
            // Empty function
          },
          exitFullScreen: () => {
            // Empty function
          },
        }}
        handlerRecording={{
          onStartRecording: () => {
            // Empty function
          },
          onStopRecording: () => {
            // Empty function
          },
        }}
      />
    );
  }

  const showDownButtonsPhoto = () =>
    showButtonsGuidedVisitPhoto(isActiveGuidedVisit, currentStep) ? (
      getButtonsInGuidedVisit()
    ) : (
      <PanelCameraDown
        lang={language}
        disable={false}
        recordingMode={cameraMode === CAMERA_MODE.RECORD}
        isLoading={isLoading}
        recording={isRecording}
        isFullscreen={isFullscreen}
        capturePhoto={onCapturePhoto}
        isPhotoInPreview={!!photoInPreview}
        handlerOptionsPreview={onManagerOptionsPreview}
        handlerRecording={{ onStartRecording, onStopRecording }}
        handlerFullScreen={{ openFullScreen, exitFullScreen }}
      />
    );

  const showDownButtonsVideo = () =>
    showHintsGuidedVisitVideo() ? (
      showInstructionsPhotosById(currentStep)
    ) : (
      <>
        {!displayPreviewVideo && (
          <PanelCameraDown
            lang={language}
            disable={false}
            recordingMode={cameraMode === CAMERA_MODE.RECORD}
            isLoading={isLoading}
            recording={isRecording}
            isFullscreen={isFullscreen}
            capturePhoto={onCapturePhoto}
            isPhotoInPreview={!!photoInPreview}
            handlerOptionsPreview={onManagerOptionsPreview}
            handlerRecording={{ onStartRecording, onStopRecording }}
            handlerFullScreen={{ openFullScreen, exitFullScreen }}
          />
        )}
      </>
    );

  const onSecondsRecording = useCallback(
    seconds => {
      dispatch(TakeVideoActions.onCounterSecondsRecording(seconds));
    },
    [dispatch],
  );

  return (
    <div className={`camera-container${rotateMode ? ' rotateMode' : ''}`}>
      <PanelCameraTop
        buttonList={getArrayTopButtons(photoInPreview, displayPreviewVideo)}
        onManagerTopButtons={onManagerTopButtons}
      />

      {isRecording && (
        <CameraTimer
          timeLimit={durationVideo * 60}
          frequencyVideo={frequencyVideo || 1}
          onStopRecording={onStopRecording}
          onCounter={onSecondsRecording}
        />
      )}

      {incomingPhotoId === INSPECTION_PHOTO_VEHICLE_LEFT_SIDE && !overlay && (
        <CameraExample idImageExample={INSPECTION_PHOTO_VEHICLE_LEFT_SIDE} />
      )}
      {incomingPhotoId === INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE && !overlay && (
        <CameraExample idImageExample={INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE} />
      )}

      {cameraMode === CAMERA_MODE.RECORD && (
        <CameraExample idImageExample="INSPECTION_PHOTO_VEHICLE_FRONT_SIDE" />
      )}

      {cameraMode === CAMERA_MODE.PHOTO && (
        <div style={{ display: !photoInPreview ? undefined : 'none' }}>
          <WebcamCapturePhoto />
        </div>
      )}

      {cameraMode === CAMERA_MODE.RECORD && !displayPreviewVideo && (
        <WebcamCaptureVideo />
      )}

      {cameraMode === CAMERA_MODE.RECORD
        ? showDownButtonsVideo()
        : showDownButtonsPhoto()}

      <PreviewCamera
        lang={language}
        picture={photoInPreview}
        video={photosVideoPreview}
        cameraMode={cameraMode}
        isFullscreen={isFullscreen}
        onUpload={onManagerOptionsPreview}
        photosPendingToFinish={photosPendingToFinish}
        displayVideo={
          displayPreviewVideo &&
          cameraMode === CAMERA_MODE.RECORD &&
          photosVideoPreview?.length > 0
        }
      />

      {isLoading && cameraMode === CAMERA_MODE.RECORD && (
        <SplashLoading translucid progress={getProgressUploadingPhoto()} />
      )}
    </div>
  );
};

CameraContainer.defaultProps = {
  retake: {},
};

CameraContainer.propTypes = {
  photoHubActions: PhotoHubActions.PropertyTypes.isRequired,
  retake: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  showPhotoUploadFailureModal: PropTypes.func.isRequired,
  handlerFullScreen: PropTypes.shape({
    openFullScreen: PropTypes.func.isRequired,
    exitFullScreen: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  photoHubActions: bindActionCreators(PhotoHubActions, dispatch),
});

export default connect(null, mapDispatchToProps)(CameraContainer);
