import constants from '@lmig-latam/adil-api-common-lib/constants/constants';
import localizedStringsCommonlib from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import 'url-search-params-polyfill';
import { logcodes, logger } from './logger';
import { UPDATE_RETAKE, UPDATE_RESUME } from '../actions/ActionTypes';
import {
  doesArrayContainCurrentUrl,
  isProduction,
} from '../config/environments';
import { store } from './configureStore';
import { compressImage } from './imageCompression';
import {
  EMAIL_TOKEN_COOKIE_NAME,
  RETAKE_DATA_COOKIE_NAME,
  RESUME_DATA_COOKIE_NAME,
  TOKEN_TIMEOUT,
  ANDROID,
  WINDOWS_PHONE,
  IOS,
  OPERATION_SYSTEM_NOT_FOUNT,
  SAFARI,
  CHROME,
  FIREFOX,
  EDGE,
  OPERA,
  EXPLORER,
} from './constants';
import localizedStrings from './localizedStrings';
import CautionSVG from '../styles/images/caution.svg';
import { getCookie, getCountryCode } from './NavigationUtils';
import {
  getClassificationPhotos,
  initialWizardPhotoHub,
} from '../constants/wizardPhotos';

export const CautionImage = CautionSVG;

export const is2FADisabled = () => {
  const isSupportRq = new URLSearchParams(window.location.search).get(
    'supportRq',
  );

  return (
    constants.NOT_DOUBLE_AUTH.includes(
      getCountryCode()
        .toString()
        .toUpperCase(),
    ) ||
    doesArrayContainCurrentUrl(['/support']) ||
    isSupportRq !== null
  );
};

// Taken from this answer: https://stackoverflow.com/a/11381730
// Which leads here: http://detectmobilebrowsers.com/
export const isMobileOrTablet = () => {
  const { userAgent, platform } = navigator;
  logger.log(logcodes.CANAV180, { userAgent, platform });
  const isPc =
    ['win', 'mac'].some(pc => platform.toLowerCase().includes(pc)) &&
    isProduction();

  return (
    !isPc &&
    (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      userAgent,
    ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        userAgent.substr(0, 4),
      ))
  );
};

export const isSupportedBrowsers = () => {
  const isChrome =
    /Chrome/.test(navigator.userAgent) && !/EdgW/.test(navigator.userAgent);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isSafari = /constructor/i.test(window.HTMLElement);
  const isMac = /Mac/.test(navigator.userAgent);

  return isChrome || isFirefox || isSafari || isMac;
};

export const getBrowser = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1:
      return EDGE;
    case agent.indexOf('edg/') > -1:
      return EDGE;
    case agent.indexOf('opr') > -1 && !!window.opr:
      return OPERA;
    case agent.indexOf('chrome') > -1 && !!window.chrome:
      return CHROME;
    case agent.indexOf('trident') > -1:
      return EXPLORER;
    case agent.indexOf('firefox') > -1:
      return FIREFOX;
    case agent.indexOf('safari') > -1:
      return SAFARI;
    default:
      return CHROME;
  }
};

const getClosingTime = () => {
  const {
    settings: { environment },
  } = store.getState();

  const { businessHoursEnd } = environment[getCountryCode()].businessHours;

  const closingTime = new Date();

  closingTime.setHours(businessHoursEnd);
  closingTime.setMinutes(0);
  closingTime.setSeconds(0);
  closingTime.setMilliseconds(0);

  return closingTime;
};

const getOpeningTime = () => {
  const {
    settings: { environment },
  } = store.getState();

  const { businessHoursStart } = environment[getCountryCode()].businessHours;

  const openingTime = new Date();

  openingTime.setHours(businessHoursStart);
  openingTime.setMinutes(0);
  openingTime.setSeconds(0);
  openingTime.setMilliseconds(0);

  return openingTime;
};

const getCurrentTime = () => new Date();

export const isOutsideBusinessHours = () => {
  const {
    settings: { environment },
  } = store.getState();

  const {
    businessHoursEnabled,
    weekendsAreOutsideOfBusinessHours,
  } = environment[getCountryCode()].businessHours;

  const openingTime = getOpeningTime();
  const closingTime = getClosingTime();
  const currentTime = getCurrentTime();

  // If businessHoursEnabled is false, then we are ALWAYS inside business hours
  if (!businessHoursEnabled) {
    return false;
  }

  const todaysDayOfWeek = new Date().getDay();

  // If weekends defined as outside of business hours, and today is a Saturday or Sunday,
  // then we are outside of business hours
  if (
    weekendsAreOutsideOfBusinessHours &&
    (todaysDayOfWeek === 0 || todaysDayOfWeek === 6)
  ) {
    return true;
  }

  return !(currentTime >= openingTime && currentTime <= closingTime);
};

/**
 * Will map an object from this:
 *
 * {
 *    photoId: 'example photo id',
 *    title: 'Example Photo Title',
 *    ...
 *    ...
 * }
 *
 * to this:
 *
 * {
 *    photoId: 'EXAMPLE_PHOTO_ID',
 *    title: 'Example Photo Title',
 *    ...
 *    ...
 * }
 *
 * i.e. uppercases the title and replaces spaces with underscores
 */
export const formatPhotoConfig = config =>
  config
    .map(photo => {
      const formattedPhotoId = photo?.photoId.toUpperCase().replace(/ /gi, '_');

      // There are a few instances where a photo gets uploaded with a photoId of ''. Log if this happens then filter it out
      if (!formattedPhotoId) {
        logger.log(logcodes.PCON110, { photo, formattedPhotoId });
      }

      return {
        ...photo,
        photoId: formattedPhotoId,
      };
    })
    .filter(photo => photo.photoId);

export const getSinglePhotoConfig = (requestedId, config) =>
  config.filter(({ photoId }) => photoId === requestedId)[0];

// Taken from https://www.w3schools.com/js/js_cookies.asp
// Note: If no expiry is provided, the cookie will expire with the session
export const setCookie = (cookieName, cookieValue, expiryInHours = null) => {
  let expires = '';
  if (expiryInHours) {
    const now = new Date();
    now.setTime(now.getTime() + expiryInHours * 60 * 60 * 1000);
    expires = `expires=${now.toUTCString()}`;
  }
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

// Taken from https://stackoverflow.com/a/2144404
export const deleteCookie = cookieName => {
  if (getCookie(cookieName)) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:0 UTC; path=/;`;
  }
};

export const createEmailTokenCookie = () => {
  const emailToken = new URLSearchParams(window.location.search).get('token');
  if (emailToken) {
    // Set email cookie expiry to 2 hours. Same as the auth token expiry
    setCookie(EMAIL_TOKEN_COOKIE_NAME, emailToken, TOKEN_TIMEOUT);
  }
};

export const createRetakeConfigCookie = () => {
  const unparsedRetakeConfig = getCookie(RETAKE_DATA_COOKIE_NAME);

  if (unparsedRetakeConfig && unparsedRetakeConfig !== 'undefined') {
    const parsedRetakeConfig = JSON.parse(unparsedRetakeConfig);

    store.dispatch({
      type: UPDATE_RETAKE,
      value: parsedRetakeConfig,
    });
  }
};

export const createResumeConfigCookie = () => {
  const unparsedResumeConfig = getCookie(RESUME_DATA_COOKIE_NAME);

  if (unparsedResumeConfig && unparsedResumeConfig !== 'undefined') {
    const parsedResumeConfig = JSON.parse(unparsedResumeConfig);

    store.dispatch({
      type: UPDATE_RESUME,
      value: parsedResumeConfig,
    });
  }
};

export const setAppTitle = () => {
  const {
    settings: {
      environment: { language },
    },
  } = store.getState();

  const { BROWSER_TAB_TITLE } = localizedStrings(language);
  document.title = BROWSER_TAB_TITLE;
};

export const setupAppConfigs = () => {
  setAppTitle();
  createRetakeConfigCookie();
  createResumeConfigCookie();
  createEmailTokenCookie();
};

export const generatePhotoObjects = async ({
  incomingFile,
  photoId,
  photoType,
  step,
  compress,
}) => {
  // Image to be shown to customer

  const photoToDisplay = {
    file: await compressImage(incomingFile, photoId, 720, 0.8),
    fileType: 'jpeg',
    photoId,
    photoType,
    step,
  };

  // Image to be uploaded
  const photoToUpload = {
    file: await compressImage(incomingFile, photoId, null, compress),
    photoId,
  };

  return { photoToDisplay, photoToUpload };
};

/**
 * @param {*} dataURI image or file in base64
 * @returns Blob object
 */
export const convertdataURItoBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

/**
 * @param {*} blob  image or file
 * @returns base64 file
 */
export function convertBlobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const isAsianDomain = () =>
  constants.ASIAN_COUNTRIES.includes(getCountryCode().toUpperCase());

export const checkPermissionCamera = async () => {
  try {
    if (navigator.permissions?.query)
      return await navigator.permissions.query({ name: 'camera' });
    return { state: 'granted' };
  } catch (error) {
    return { state: 'denied' };
  }
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }

  return OPERATION_SYSTEM_NOT_FOUNT;
};

export const getVehicleClassName = vehicleClass => {
  const {
    INSPECTION_DROPDOWN_DEFAULT_VALUES,
  } = localizedStringsCommonlib.getCurrentLanguage(getCountryCode());
  const { class: defaultVehicleClass } = INSPECTION_DROPDOWN_DEFAULT_VALUES;

  const [CAR, MOTORBIKE] = defaultVehicleClass;
  const result = {
    [MOTORBIKE]: 'MOTORBIKE',
    [CAR]: 'VEHICLE',
  };
  return result[vehicleClass] || 'UNKNOWN';
};

export const stepsToShowValidator = (requiredPhotos, vehicleClass) => {
  const { required, accessories, conditionallyRequired } = requiredPhotos;
  const stepsToShow = [];
  const MAXIMUM_NUMBER_OF_STEPS = initialWizardPhotoHub.length;
  const photosClassification = getClassificationPhotos(vehicleClass);
  for (let step = 1; step <= MAXIMUM_NUMBER_OF_STEPS; step += 1) {
    if (
      required.some(element => photosClassification[step].includes(element))
    ) {
      stepsToShow.push(step);
    }
  }

  if (conditionallyRequired.length > 0 && !stepsToShow.includes(3)) {
    stepsToShow.push(3);
  }

  if (
    accessories &&
    accessories.length > 0 &&
    !stepsToShow.includes(MAXIMUM_NUMBER_OF_STEPS)
  )
    stepsToShow.push(MAXIMUM_NUMBER_OF_STEPS);

  return stepsToShow.length > 0
    ? stepsToShow
    : Array.from(Array(MAXIMUM_NUMBER_OF_STEPS)).map((e, i) => i + 1);
};

export const enableRetakeFlow = (userInspectionStatus, lastCustomerStatus) =>
  [
    constants.STATUS_TYPES.STATUS_CUSTOMER_RETAKE,
    constants.STATUS_TYPES.STATUS_SUPPORT_RETAKE,
  ].includes(userInspectionStatus) ||
  (userInspectionStatus === constants.STATUS_TYPES.STATUS_SUPPORT_INSPECTOR &&
    lastCustomerStatus === constants.STATUS_TYPES.STATUS_CUSTOMER_RETAKE);
