export const SESSION_STORAGE_KEYS = {
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  TIMELIMIT_TO_EXPIRE_SESSION: 'TIMELIMIT_TO_EXPIRE_SESSION',
  TIMESTAMP_TO_EXPIRE_SESSION: 'TIMESTAMP_TO_EXPIRE_SESSION',
  INACTIVITY_TIMER_SESSION_MAIN: 'INACTIVITY_TIMER_SESSION_MAIN',
  INACTIVITY_TIMER_CLOSE_SESSION_MODAL: 'INACTIVITY_TIMER_CLOSE_SESSION_MODAL',
  TIMESTAMP_INACTIVITY_TIMER_SESSION_MAIN:
    'TIMESTAMP_INACTIVITY_TIMER_SESSION_MAIN',
  LOGOUT_ISSUE: 'LOGOUT_ISSUE',
};

export const LOGOUT_TYPE = {
  EXPIRED_SESSION: 'EXPIRED_SESSION',
  INACTIVITY_SESSION: 'INACTIVITY_SESSION',
};
