import { useEffect, useMemo, useState } from 'react';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: null,
    height: null,
  });

  const calculateDimension = useMemo(() => {
    const offset = 3.5;
    const isLandscape = window.innerHeight <= window.innerWidth;
    return isLandscape ? window.innerWidth : window.innerWidth * offset;
  }, []);

  useEffect(() => {
    function handleResize() {
      setScreenSize({
        width: calculateDimension,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [calculateDimension]);

  return [screenSize];
};
