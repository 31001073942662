/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import localizedStrings from '../../utils/localizedStrings';
import { PhotoPreviewCard } from '../WizardPhotos';

const renderPhotoPreviewPanel = (
  index,
  photo,
  previewPhoto,
  onDelete,
  onUpdate,
) => {
  const {
    PHOTO_HUB_DAMAGE_SECTION_PREVIEW_TITLE: titleText,
    PHOTO_HUB_DAMAGE_SECTION_PREVIEW_DESCRIPTION: description,
  } = localizedStrings();
  const title = `${titleText} ${index + 1}`;
  const handlerOnDelete = () => {
    onDelete(photo.photoId.toString());
  };

  return (
    <div key={photo.photoId} id={photo && photo.photoId.toString()}>
      <PhotoPreviewCard
        buttons={['delete', 'update']}
        title={title}
        photoId={photo.photoId}
        description={description}
        previewPhoto={previewPhoto}
        onUpdate={onUpdate}
        onDelete={handlerOnDelete}
      />
    </div>
  );
};

const DamagePhotoList = ({ photos, onDelete, onUpdate }) => (
  <div>
    {photos?.map((damagePhoto, index) => {
      const photo = photos.filter(
        element => element.photoId === damagePhoto.photoId,
      )[0];
      const previewPhoto = photo ? photo.blobUrl : '';

      return renderPhotoPreviewPanel(
        index,
        damagePhoto,
        previewPhoto,
        onDelete,
        onUpdate,
      );
    })}
  </div>
);

DamagePhotoList.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default DamagePhotoList;
