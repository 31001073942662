import React from 'react';

export default function CircleEngineIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97">
      <g id="Grupo_5094" data-name="Grupo 5094" transform="translate(4.5 -0.5)">
        <g id="Grupo_4567" data-name="Grupo 4567" transform="translate(0.5)">
          <circle
            id="Oval"
            cx="48"
            cy="48"
            r="48"
            transform="translate(-4.5 1)"
            fill="#f0fbfc"
            stroke="#99e5ea"
            strokeWidth="1"
          />
        </g>
        <g
          id="Icon_Auto_Engine_Trouble_64px"
          data-name="Icon/Auto/Engine Trouble/64px"
          transform="translate(19.5 32.516)"
        >
          <g id="Combined_Shape" data-name="Combined Shape">
            <path
              id="Combined_Shape-2"
              data-name="Combined Shape"
              d="M43.8,32.965a1.708,1.708,0,0,1-1.59-1.1l-1.6-5-3.505.016L35.362,31.83a1.7,1.7,0,0,1-1.6,1.135H15.907a1.694,1.694,0,0,1-1.3-.61l-4.569-5.472H6.811a1.7,1.7,0,0,1-1.7-1.7l-.09-6.949H1.875v7.712H0V9.065H1.875v7.3H5L4.913,9.642V9.63a1.7,1.7,0,0,1,1.681-1.7L9.526,7.9V6.635a1.7,1.7,0,0,1,1.7-1.7h9.17V1.875H11.941V0H30.317V1.875H22.269v3.06h9.449a1.7,1.7,0,0,1,1.7,1.7V7.742h1.731a1.708,1.708,0,0,1,1.659,1.336l.868,3.972a.234.234,0,0,0,.225.16h2.394L41.78,9.136a1.712,1.712,0,0,1,1.592-1.108l2.917-.038A1.706,1.706,0,0,1,48,9.687V26.242a1.779,1.779,0,0,1-.022.281l-1.015,5.054a1.674,1.674,0,0,1-1.67,1.388ZM42.329,26.09l1.6,5h1.221l.985-4.907L46.125,9.866,43.5,9.9l-1.486,4.076a1.714,1.714,0,0,1-1.593,1.109H37.9a2.108,2.108,0,0,1-2.053-1.613l-.842-3.856H33.241a1.7,1.7,0,0,1-1.7-1.7V6.811H11.4V8.077a1.706,1.706,0,0,1-1.681,1.7l-2.93.03.2,15.2h3.131a1.7,1.7,0,0,1,1.3.61L15.99,31.09H33.634l1.741-4.952A1.7,1.7,0,0,1,36.977,25l3.757-.016A1.71,1.71,0,0,1,42.329,26.09Zm-21.639.254V24.026h2.6v2.318Zm.007-3.8V20.161a2.411,2.411,0,0,1,.949-1.931c.124-.1.277-.205.449-.327.726-.514,1.939-1.374,1.939-2.2,0-1.092,0-1.5-1.868-1.5-1.532,0-1.764.473-1.764,2.1H17.8c0-3.119,1.468-4.7,4.365-4.7,3.048,0,4.469,1.3,4.469,4.1,0,2.167-1.974,3.566-3.036,4.317l-.37.267.069,2.246Z"
              fill="none"
            />
            <path
              id="Combined_Shape-3"
              data-name="Combined Shape"
              d="M43.8,32.965a1.708,1.708,0,0,1-1.59-1.1l-1.6-5-3.505.016L35.362,31.83a1.7,1.7,0,0,1-1.6,1.135H15.907a1.694,1.694,0,0,1-1.3-.61l-4.569-5.472H6.811a1.7,1.7,0,0,1-1.7-1.7l-.09-6.949H1.875v7.712H0V9.065H1.875v7.3H5L4.913,9.642V9.63a1.7,1.7,0,0,1,1.681-1.7L9.526,7.9V6.635a1.7,1.7,0,0,1,1.7-1.7h9.17V1.875H11.941V0H30.317V1.875H22.269v3.06h9.449a1.7,1.7,0,0,1,1.7,1.7V7.742h1.731a1.708,1.708,0,0,1,1.659,1.336l.868,3.972a.234.234,0,0,0,.225.16h2.394L41.78,9.136a1.712,1.712,0,0,1,1.592-1.108l2.917-.038A1.706,1.706,0,0,1,48,9.687V26.242a1.779,1.779,0,0,1-.022.281l-1.015,5.054a1.674,1.674,0,0,1-1.67,1.388ZM42.329,26.09l1.6,5h1.221l.985-4.907L46.125,9.866,43.5,9.9l-1.486,4.076a1.714,1.714,0,0,1-1.593,1.109H37.9a2.108,2.108,0,0,1-2.053-1.613l-.842-3.856H33.241a1.7,1.7,0,0,1-1.7-1.7V6.811H11.4V8.077a1.706,1.706,0,0,1-1.681,1.7l-2.93.03.2,15.2h3.131a1.7,1.7,0,0,1,1.3.61L15.99,31.09H33.634l1.741-4.952A1.7,1.7,0,0,1,36.977,25l3.757-.016A1.71,1.71,0,0,1,42.329,26.09Zm-21.639.254V24.026h2.6v2.318Zm.007-3.8V20.161a2.411,2.411,0,0,1,.949-1.931c.124-.1.277-.205.449-.327.726-.514,1.939-1.374,1.939-2.2,0-1.092,0-1.5-1.868-1.5-1.532,0-1.764.473-1.764,2.1H17.8c0-3.119,1.468-4.7,4.365-4.7,3.048,0,4.469,1.3,4.469,4.1,0,2.167-1.974,3.566-3.036,4.317l-.37.267.069,2.246Z"
              fill="none"
            />
            <path
              id="Combined_Shape-4"
              data-name="Combined Shape"
              d="M43.8,32.965a1.708,1.708,0,0,1-1.59-1.1l-1.6-5-3.505.016L35.362,31.83a1.7,1.7,0,0,1-1.6,1.135H15.907a1.694,1.694,0,0,1-1.3-.61l-4.569-5.472H6.811a1.7,1.7,0,0,1-1.7-1.7l-.09-6.949H1.875v7.712H0V9.065H1.875v7.3H5L4.913,9.642V9.63a1.7,1.7,0,0,1,1.681-1.7L9.526,7.9V6.635a1.7,1.7,0,0,1,1.7-1.7h9.17V1.875H11.941V0H30.317V1.875H22.269v3.06h9.449a1.7,1.7,0,0,1,1.7,1.7V7.742h1.731a1.708,1.708,0,0,1,1.659,1.336l.868,3.972a.234.234,0,0,0,.225.16h2.394L41.78,9.136a1.712,1.712,0,0,1,1.592-1.108l2.917-.038A1.706,1.706,0,0,1,48,9.687V26.242a1.779,1.779,0,0,1-.022.281l-1.015,5.054a1.674,1.674,0,0,1-1.67,1.388ZM42.329,26.09l1.6,5h1.221l.985-4.907L46.125,9.866,43.5,9.9l-1.486,4.076a1.714,1.714,0,0,1-1.593,1.109H37.9a2.108,2.108,0,0,1-2.053-1.613l-.842-3.856H33.241a1.7,1.7,0,0,1-1.7-1.7V6.811H11.4V8.077a1.706,1.706,0,0,1-1.681,1.7l-2.93.03.2,15.2h3.131a1.7,1.7,0,0,1,1.3.61L15.99,31.09H33.634l1.741-4.952A1.7,1.7,0,0,1,36.977,25l3.757-.016A1.71,1.71,0,0,1,42.329,26.09Zm-21.639.254V24.026h2.6v2.318Zm.007-3.8V20.161a2.411,2.411,0,0,1,.949-1.931c.124-.1.277-.205.449-.327.726-.514,1.939-1.374,1.939-2.2,0-1.092,0-1.5-1.868-1.5-1.532,0-1.764.473-1.764,2.1H17.8c0-3.119,1.468-4.7,4.365-4.7,3.048,0,4.469,1.3,4.469,4.1,0,2.167-1.974,3.566-3.036,4.317l-.37.267.069,2.246Z"
              fill="none"
            />
            <path
              id="Combined_Shape-5"
              data-name="Combined Shape"
              d="M43.8,32.965a1.708,1.708,0,0,1-1.59-1.1l-1.6-5-3.505.016L35.362,31.83a1.7,1.7,0,0,1-1.6,1.135H15.907a1.694,1.694,0,0,1-1.3-.61l-4.569-5.472H6.811a1.7,1.7,0,0,1-1.7-1.7l-.09-6.949H1.875v7.712H0V9.065H1.875v7.3H5L4.913,9.642V9.63a1.7,1.7,0,0,1,1.681-1.7L9.526,7.9V6.635a1.7,1.7,0,0,1,1.7-1.7h9.17V1.875H11.941V0H30.317V1.875H22.269v3.06h9.449a1.7,1.7,0,0,1,1.7,1.7V7.742h1.731a1.708,1.708,0,0,1,1.659,1.336l.868,3.972a.234.234,0,0,0,.225.16h2.394L41.78,9.136a1.712,1.712,0,0,1,1.592-1.108l2.917-.038A1.706,1.706,0,0,1,48,9.687V26.242a1.779,1.779,0,0,1-.022.281l-1.015,5.054a1.674,1.674,0,0,1-1.67,1.388ZM42.329,26.09l1.6,5h1.221l.985-4.907L46.125,9.866,43.5,9.9l-1.486,4.076a1.714,1.714,0,0,1-1.593,1.109H37.9a2.108,2.108,0,0,1-2.053-1.613l-.842-3.856H33.241a1.7,1.7,0,0,1-1.7-1.7V6.811H11.4V8.077a1.706,1.706,0,0,1-1.681,1.7l-2.93.03.2,15.2h3.131a1.7,1.7,0,0,1,1.3.61L15.99,31.09H33.634l1.741-4.952A1.7,1.7,0,0,1,36.977,25l3.757-.016A1.71,1.71,0,0,1,42.329,26.09Zm-21.639.254V24.026h2.6v2.318Zm.007-3.8V20.161a2.411,2.411,0,0,1,.949-1.931c.124-.1.277-.205.449-.327.726-.514,1.939-1.374,1.939-2.2,0-1.092,0-1.5-1.868-1.5-1.532,0-1.764.473-1.764,2.1H17.8c0-3.119,1.468-4.7,4.365-4.7,3.048,0,4.469,1.3,4.469,4.1,0,2.167-1.974,3.566-3.036,4.317l-.37.267.069,2.246Z"
              fill="none"
            />
            <path
              id="Combined_Shape-6"
              data-name="Combined Shape"
              d="M43.8,32.965a1.708,1.708,0,0,1-1.59-1.1l-1.6-5-3.505.016L35.362,31.83a1.7,1.7,0,0,1-1.6,1.135H15.907a1.694,1.694,0,0,1-1.3-.61l-4.569-5.472H6.811a1.7,1.7,0,0,1-1.7-1.7l-.09-6.949H1.875v7.712H0V9.065H1.875v7.3H5L4.913,9.642V9.63a1.7,1.7,0,0,1,1.681-1.7L9.526,7.9V6.635a1.7,1.7,0,0,1,1.7-1.7h9.17V1.875H11.941V0H30.317V1.875H22.269v3.06h9.449a1.7,1.7,0,0,1,1.7,1.7V7.742h1.731a1.708,1.708,0,0,1,1.659,1.336l.868,3.972a.234.234,0,0,0,.225.16h2.394L41.78,9.136a1.712,1.712,0,0,1,1.592-1.108l2.917-.038A1.706,1.706,0,0,1,48,9.687V26.242a1.779,1.779,0,0,1-.022.281l-1.015,5.054a1.674,1.674,0,0,1-1.67,1.388ZM42.329,26.09l1.6,5h1.221l.985-4.907L46.125,9.866,43.5,9.9l-1.486,4.076a1.714,1.714,0,0,1-1.593,1.109H37.9a2.108,2.108,0,0,1-2.053-1.613l-.842-3.856H33.241a1.7,1.7,0,0,1-1.7-1.7V6.811H11.4V8.077a1.706,1.706,0,0,1-1.681,1.7l-2.93.03.2,15.2h3.131a1.7,1.7,0,0,1,1.3.61L15.99,31.09H33.634l1.741-4.952A1.7,1.7,0,0,1,36.977,25l3.757-.016A1.71,1.71,0,0,1,42.329,26.09Zm-21.639.254V24.026h2.6v2.318Zm.007-3.8V20.161a2.411,2.411,0,0,1,.949-1.931c.124-.1.277-.205.449-.327.726-.514,1.939-1.374,1.939-2.2,0-1.092,0-1.5-1.868-1.5-1.532,0-1.764.473-1.764,2.1H17.8c0-3.119,1.468-4.7,4.365-4.7,3.048,0,4.469,1.3,4.469,4.1,0,2.167-1.974,3.566-3.036,4.317l-.37.267.069,2.246Z"
              fill="none"
            />
            <path
              id="Combined_Shape-7"
              data-name="Combined Shape"
              d="M43.8,32.965a1.708,1.708,0,0,1-1.59-1.1l-1.6-5-3.505.016L35.362,31.83a1.7,1.7,0,0,1-1.6,1.135H15.907a1.694,1.694,0,0,1-1.3-.61l-4.569-5.472H6.811a1.7,1.7,0,0,1-1.7-1.7l-.09-6.949H1.875v7.712H0V9.065H1.875v7.3H5L4.913,9.642V9.63a1.7,1.7,0,0,1,1.681-1.7L9.526,7.9V6.635a1.7,1.7,0,0,1,1.7-1.7h9.17V1.875H11.941V0H30.317V1.875H22.269v3.06h9.449a1.7,1.7,0,0,1,1.7,1.7V7.742h1.731a1.708,1.708,0,0,1,1.659,1.336l.868,3.972a.234.234,0,0,0,.225.16h2.394L41.78,9.136a1.712,1.712,0,0,1,1.592-1.108l2.917-.038A1.706,1.706,0,0,1,48,9.687V26.242a1.779,1.779,0,0,1-.022.281l-1.015,5.054a1.674,1.674,0,0,1-1.67,1.388ZM42.329,26.09l1.6,5h1.221l.985-4.907L46.125,9.866,43.5,9.9l-1.486,4.076a1.714,1.714,0,0,1-1.593,1.109H37.9a2.108,2.108,0,0,1-2.053-1.613l-.842-3.856H33.241a1.7,1.7,0,0,1-1.7-1.7V6.811H11.4V8.077a1.706,1.706,0,0,1-1.681,1.7l-2.93.03.2,15.2h3.131a1.7,1.7,0,0,1,1.3.61L15.99,31.09H33.634l1.741-4.952A1.7,1.7,0,0,1,36.977,25l3.757-.016A1.71,1.71,0,0,1,42.329,26.09Z"
              fill="#1a1446"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
