import {
  ON_RECORDING_CAMERA,
  ON_DISPLAY_MODAL_FINISH_VIDEO,
  ON_FINISH_VIDEO,
  ON_COUNTER_SECONS_RECORDING,
  VIDEO_PARAMETERS,
  ADD_PHOTO_TO_VIDEO_STATUS_LIST,
  ON_CAPTURE_PHOTO_FROM_VIDEO,
  ON_CHANGE_REQUIRE_VIDEO,
  GET_PHOTOS_PREVIEW_VIDEO,
  ON_DISPLAY_PREVIEW_VIDEO,
  RESET_PHOTOS_PREVIEW_VIDEO,
  DISPLAY_VIDEO_SECTION,
} from '../actions/ActionTypes';
import {
  STORAGE_CAMERA_MODE,
  STORAGE_DISPLAY_VIDEO_SECTION_KEY,
  STORAGE_IS_FINISHED_VIDEO,
} from '../constants/storage';
import { UPLOADING_PHOTO_STATUS } from '../constants/camera';

const getIsFinishVideo = () => {
  const itemStr = localStorage.getItem(STORAGE_IS_FINISHED_VIDEO);
  if (!itemStr) return false;
  const finished = JSON.parse(itemStr);
  return finished.status === true;
};

const getVideoParameterStorage = () => {
  const key = 'VIDEO_PARAMETERS';
  if (key in localStorage) {
    const parameters = JSON.parse(localStorage.getItem(key));
    return {
      frequencyVideo: parameters?.frequency || parameters?.frequencyVideo,
      durationVideo: parameters?.duration || parameters?.durationVideo,
      compressPhotosVideo:
        parameters?.compress || parameters?.compressPhotosVideo,
      requiredVideo: parameters?.required || parameters?.requiredVideo,
    };
  }
  return undefined;
};

const getVideoPhotosPreview = () => {
  const key = 'VIDEO_PHOTOS';
  const photos = JSON.parse(localStorage.getItem(key));
  if (photos) return photos;
  return [];
};

const getEnableVideoSection = () => {
  const item = localStorage.getItem(STORAGE_DISPLAY_VIDEO_SECTION_KEY);
  return item === 'true';
};

export const initialState = {
  isRecording: false,
  displayModalFinishVideo: false,
  frameRecordCounterUploaded: 0,
  frameVideoPreview: undefined,
  finishedVideo: getIsFinishVideo(),
  photosPendingToFinish: 0,
  photosCompletedToFinish: undefined,
  uploadingVideoPhotoStatus: [],
  isCapturingPhotoFromVideo: false,
  displayPreviewVideo: false,
  secondsVideo: undefined,
  isEnableVideoSection: getEnableVideoSection() || false,
  photosVideoPreview: getVideoPhotosPreview(),
  videoParameters: getVideoParameterStorage() || {
    frequencyVideo: 0,
    durationVideo: 0,
    compressPhotosVideo: false,
    requiredVideo: null,
  },
};

const setFinishedVideoHandler = ({ status, emailToken }) => {
  localStorage.removeItem(STORAGE_CAMERA_MODE);
  localStorage.setItem(
    STORAGE_IS_FINISHED_VIDEO,
    JSON.stringify({ status, emailToken }),
  );
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ON_COUNTER_SECONS_RECORDING:
      return {
        ...state,
        secondsVideo: payload.seconds,
      };
    case ADD_PHOTO_TO_VIDEO_STATUS_LIST: {
      const { PENDING, COMPLETED } = UPLOADING_PHOTO_STATUS;
      const { id, status } = payload;

      let list = [];
      const isExistInList = state.uploadingVideoPhotoStatus.some(
        item => item.id === id,
      );

      if (isExistInList)
        list = state.uploadingVideoPhotoStatus.map(item =>
          item.id === id ? { ...item, status } : item,
        );
      else list = [...state.uploadingVideoPhotoStatus, { id, status }];

      let photosPendingToFinish = 0;
      let photosCompletedToFinish = 0;

      list.forEach(i => {
        if (i.status === PENDING) photosPendingToFinish += 1;
        else if (i.status === COMPLETED) photosCompletedToFinish += 1;
      });
      return {
        ...state,
        uploadingVideoPhotoStatus: list,
        photosPendingToFinish,
        photosCompletedToFinish,
      };
    }
    case ON_RECORDING_CAMERA:
      return {
        ...state,
        isRecording: payload.isRecording,
      };
    case VIDEO_PARAMETERS:
      return {
        ...state,
        videoParameters: {
          frequencyVideo: payload.frequency,
          durationVideo: payload.duration,
          compressPhotosVideo: payload.compress,
          requiredVideo: payload.required,
        },
      };
    case DISPLAY_VIDEO_SECTION:
      return {
        ...state,
        isEnableVideoSection: payload.status,
      };
    case ON_DISPLAY_MODAL_FINISH_VIDEO:
      return { ...state, displayModalFinishVideo: payload.status };
    case ON_FINISH_VIDEO: {
      const { emailToken, status } = payload;
      setFinishedVideoHandler({ emailToken, status });
      return {
        ...state,
        finishedVideo: status,
        secondsVideo: undefined,
        photosPendingToFinish: 0,
        photosCompletedToFinish: undefined,
        uploadingVideoPhotoStatus: [],
      };
    }
    case GET_PHOTOS_PREVIEW_VIDEO: {
      const key = 'VIDEO_PHOTOS';
      localStorage.setItem(key, JSON.stringify(payload.photos));
      return {
        ...state,
        photosVideoPreview: payload.photos,
      };
    }
    case RESET_PHOTOS_PREVIEW_VIDEO: {
      const key = 'VIDEO_PHOTOS';
      localStorage.removeItem(key);
      return { ...state, photosVideoPreview: [] };
    }
    case ON_DISPLAY_PREVIEW_VIDEO: {
      return {
        ...state,
        displayPreviewVideo: payload.status,
      };
    }
    case ON_CAPTURE_PHOTO_FROM_VIDEO:
      return { ...state, isCapturingPhotoFromVideo: payload.status };
    case ON_CHANGE_REQUIRE_VIDEO: {
      const key = 'VIDEO_PARAMETERS';
      const videoParameters = {
        ...state.videoParameters,
        requiredVideo: payload.status,
      };

      localStorage.setItem(key, JSON.stringify(videoParameters));
      return {
        ...state,
        videoParameters,
      };
    }
    default:
      return state;
  }
};
