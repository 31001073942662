import React from 'react';

function IconRepeat() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M7.289,8.258.969,14.578,0,13.61,6.321,7.289,0,.968.969,0l6.32,6.32L13.61,0l.969.969-6.32,6.32,6.32,6.32-.969.969Z"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_11191"
        data-name="Grupo 11191"
        transform="translate(3987 21888)"
      >
        <g
          id="Grupo_11191-2"
          data-name="Grupo 11191"
          transform="translate(-3987 -21888)"
        >
          <circle
            id="Elipse_418"
            data-name="Elipse 418"
            cx="17"
            cy="17"
            r="17"
            transform="translate(0 -0.001)"
            fill="#343741"
            opacity="0.3"
            // style="mix-blend-mode: multiply;isolation: isolate"
          />
          <g
            id="Color_Liberty_Blue"
            data-name="Color/Liberty Blue"
            transform="translate(10.02 9.988)"
          >
            <path
              id="Mask-2"
              data-name="Mask"
              d="M7.289,8.258.969,14.578,0,13.61,6.321,7.289,0,.968.969,0l6.32,6.32L13.61,0l.969.969-6.32,6.32,6.32,6.32-.969.969Z"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Combined_Shape"
          data-name="Combined Shape"
          transform="translate(-3984.393 -21885.393)"
        >
          <path
            id="Combined_Shape-2"
            data-name="Combined Shape"
            d="M3.592,23.9,4.608,23a13,13,0,1,0-1.471-2.032l-1.168.685A14.433,14.433,0,1,1,3.592,23.9Zm6.077-4.277,9.307-5.2c-1.247-.721-4.607-2.66-6.766-3.9l-1.5-.863V17.08H9.361V9.173c0-.464-.008-.9.407-1.144s.789-.03,1.256.239l1.863,1.076c2.479,1.429,6.542,3.773,7.1,4.1.539.315.907.527.9,1.007s-.371.67-.724.864l-.147.08L10.328,20.8Z"
            fill="none"
          />
          <path
            id="Combined_Shape-3"
            data-name="Combined Shape"
            d="M3.592,23.9,4.608,23a13,13,0,1,0-1.471-2.032l-1.168.685A14.433,14.433,0,1,1,3.592,23.9Zm6.077-4.277,9.307-5.2c-1.247-.721-4.607-2.66-6.766-3.9l-1.5-.863V17.08H9.361V9.173c0-.464-.008-.9.407-1.144s.789-.03,1.256.239l1.863,1.076c2.479,1.429,6.542,3.773,7.1,4.1.539.315.907.527.9,1.007s-.371.67-.724.864l-.147.08L10.328,20.8Z"
            fill="none"
          />
          <path
            id="Combined_Shape-4"
            data-name="Combined Shape"
            d="M3.592,23.9,4.608,23a13,13,0,1,0-1.471-2.032l-1.168.685A14.433,14.433,0,1,1,3.592,23.9Zm6.077-4.277,9.307-5.2c-1.247-.721-4.607-2.66-6.766-3.9l-1.5-.863V17.08H9.361V9.173c0-.464-.008-.9.407-1.144s.789-.03,1.256.239l1.863,1.076c2.479,1.429,6.542,3.773,7.1,4.1.539.315.907.527.9,1.007s-.371.67-.724.864l-.147.08L10.328,20.8Z"
            fill="none"
          />
          <path
            id="Combined_Shape-5"
            data-name="Combined Shape"
            d="M3.592,23.9,4.608,23a13,13,0,1,0-1.471-2.032l-1.168.685A14.433,14.433,0,1,1,3.592,23.9Zm6.077-4.277,9.307-5.2c-1.247-.721-4.607-2.66-6.766-3.9l-1.5-.863V17.08H9.361V9.173c0-.464-.008-.9.407-1.144s.789-.03,1.256.239l1.863,1.076c2.479,1.429,6.542,3.773,7.1,4.1.539.315.907.527.9,1.007s-.371.67-.724.864l-.147.08L10.328,20.8Z"
            fill="none"
          />
          <path
            id="Combined_Shape-6"
            data-name="Combined Shape"
            d="M3.592,23.9,4.608,23a13,13,0,1,0-1.471-2.032l-1.168.685A14.433,14.433,0,1,1,3.592,23.9Zm6.077-4.277,9.307-5.2c-1.247-.721-4.607-2.66-6.766-3.9l-1.5-.863V17.08H9.361V9.173c0-.464-.008-.9.407-1.144s.789-.03,1.256.239l1.863,1.076c2.479,1.429,6.542,3.773,7.1,4.1.539.315.907.527.9,1.007s-.371.67-.724.864l-.147.08L10.328,20.8Z"
            fill="none"
          />
          <path
            id="Combined_Shape-7"
            data-name="Combined Shape"
            d="M4.7,25.022l-.728.7-.935-3.5L6.5,23.278l-.829.8a13.018,13.018,0,1,0-2.535-3.108l-1.168.685A14.427,14.427,0,1,1,4.7,25.022Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconRepeat;
