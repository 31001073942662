import React from 'react';
import PropTypes from 'prop-types';
import IconPlay from './play.png';
import IconPause from './pause.png';
import './styles.scss';

const PlayButton = ({ onClick, isPlayRunning }) => (
  <button type="button" className="play-button" onClick={onClick}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {!isPlayRunning ? (
        <img src={IconPlay} alt="" />
      ) : (
        <img src={IconPause} alt="" />
      )}
    </div>
  </button>
);

PlayButton.propTypes = {
  isPlayRunning: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PlayButton;
