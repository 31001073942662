import {
  CONDITIONALLY_REQUIRED_PHOTO_IDS,
  LANGUAGES,
  MOTORBIKE_REQUIRED_PHOTO_IDS,
  REQUIRED_PHOTO_IDS,
} from '@lmig-latam/adil-api-common-lib/constants';
import { WIZARD_PHOTO_STEP } from '../constants/wizardPhotos';
import { VEHICLE_FLOW_CLASS } from '../utils/constants';
import { VehicleClass } from '../utils/VehicleClass';

const { CL, EC, CO } = LANGUAGES;

const {
  INSPECTION_PHOTO_PROPERTY_CARD_FRONT,
  INSPECTION_PHOTO_PROPERTY_CARD_BACK,
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE,
  INSPECTION_PHOTO_VEHICLE_FRONT,
  INSPECTION_PHOTO_VEHICLE_REAR,
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE,
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE,
  INSPECTION_PHOTO_VEHICLE_ENGINE,
  INSPECTION_PHOTO_VEHICLE_DASH,
  INSPECTION_PHOTO_VEHICLE_MILEAGE,
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER,
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE,
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER,
} = REQUIRED_PHOTO_IDS;

const {
  INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE,
  INSPECTION_PHOTO_MOTORBIKE_FRONT,
  INSPECTION_PHOTO_MOTORBIKE_REAR,
  INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE,
  INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE,
  INSPECTION_PHOTO_MOTORBIKE_ENGINE,
  INSPECTION_PHOTO_MOTORBIKE_DASH,
  INSPECTION_PHOTO_MOTORBIKE_MILEAGE,
  INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER,
  INSPECTION_PHOTO_MOTORBIKE_WINDSHIELD_PLATE,
  INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER,
} = MOTORBIKE_REQUIRED_PHOTO_IDS;

const { INSPECTION_PHOTO_SPARE_WHEEL } = CONDITIONALLY_REQUIRED_PHOTO_IDS;

export const PHOTO_RULES_BY_COUNTRY = {
  // One Step
  [INSPECTION_PHOTO_PROPERTY_CARD_FRONT]: [CL, EC, CO],
  [INSPECTION_PHOTO_PROPERTY_CARD_BACK]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE]: [CL, EC, CO],
  [INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE]: [CO],

  // Two Step
  [INSPECTION_PHOTO_VEHICLE_FRONT]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_LEFT_SIDE]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_REAR]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE]: [CL, EC, CO],
  [INSPECTION_PHOTO_MOTORBIKE_FRONT]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_REAR]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE]: [CO],

  // Three Step
  [INSPECTION_PHOTO_VEHICLE_ENGINE]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_DASH]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_MILEAGE]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER]: [EC, CO],
  [INSPECTION_PHOTO_SPARE_WHEEL]: [CL, EC, CO],
  [INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE]: [CL, EC],
  [INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER]: [CL, EC, CO],
  [INSPECTION_PHOTO_MOTORBIKE_ENGINE]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_DASH]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_MILEAGE]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_WINDSHIELD_PLATE]: [CO],
  [INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER]: [CO],
};

export class AllowPhotoCountry {
  constructor(countryCode) {
    this.countryCode = countryCode;
  }

  setVehicleClass(vehicleClass) {
    this.vehicleClass = vehicleClass;
    return this;
  }

  isAllowVideoSection(currentStep) {
    return (
      [CO, EC, CL].includes(this.countryCode) &&
      currentStep === WIZARD_PHOTO_STEP.TWO
    );
  }

  isAllowConditionallySection(currentStep) {
    const vehicle = new VehicleClass(this.countryCode);
    const vehicleClassTidy = vehicle.getVehicle(this.vehicleClass);
    if (
      this.countryCode === LANGUAGES.CO &&
      vehicleClassTidy === VEHICLE_FLOW_CLASS.MOTORBIKE &&
      currentStep === WIZARD_PHOTO_STEP.THREE
    )
      return true;
    if (
      this.countryCode !== LANGUAGES.CO &&
      currentStep === WIZARD_PHOTO_STEP.THREE
    )
      return true;
    return false;
  }

  isAllowOptionalSectionByStep(step) {
    const vehicle = new VehicleClass(this.countryCode);
    const vehicleClassTidy = vehicle.getVehicle(this.vehicleClass);
    if (vehicleClassTidy === VEHICLE_FLOW_CLASS.MOTORBIKE) return false;

    const allowInCL =
      this.countryCode === LANGUAGES.CL && step === WIZARD_PHOTO_STEP.TWO;

    const allowInECAndCO =
      (this.countryCode === LANGUAGES.EC ||
        this.countryCode === LANGUAGES.CO) &&
      step === WIZARD_PHOTO_STEP.FOUR;

    if (allowInCL) return true;
    if (allowInECAndCO) return true;
    return false;
  }

  isAllow(photoId) {
    return PHOTO_RULES_BY_COUNTRY[photoId]?.includes(this.countryCode) || null;
  }
}
