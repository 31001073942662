import { TakePhotoActions } from '../../../actions';
import { CAMERA_MODE } from '../../../constants/camera';

export class PhotoCameraStrategy {
  constructor(camera, overlay, photoId) {
    this.camera = camera;
    this.overlay = overlay;
    this.photoId = photoId;
  }

  setDispatch(useDispatch) {
    this.useDispatch = useDispatch;
    return this;
  }

  start() {
    this.useDispatch(TakePhotoActions.setIncomingPhoto(this.photoId));
    this.useDispatch(TakePhotoActions.onChangeCameraMode(CAMERA_MODE.PHOTO));
  }
}
