import constants from '@lmig-latam/adil-api-common-lib/constants/constants';
import history from './history';
import { logcodes, logger } from './logger';
import { checkForTokens } from './customErrors';
import { isLocalhost } from '../config/environments';

export const navigate = async (newPath, state) => {
  const {
    location: { pathname },
    push,
  } = history;

  checkForTokens();

  logger.log(logcodes.CANAV010, { from: pathname, to: newPath });

  window.scrollTo(0, 0);

  // https://github.com/ReactTraining/history/blob/v4/docs/Navigation.md
  push({
    pathname: newPath,
    state,
    search: history.location.search,
    from: pathname,
  });
};

export const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  // eslint-disable-next-line no-restricted-syntax
  for (const cookie of cookieArray) {
    let value = cookie;
    while (value.charAt(0) === ' ') {
      value = value.substring(1);
    }
    if (value.indexOf(name) === 0) {
      return value.substring(name.length, value.length);
    }
  }
  return '';
};

export const getCountryCode = () =>
  isLocalhost()
    ? constants.LANGUAGES.CL
    : window.location.host.split('.').pop();

export default {
  navigate,
  getCookie,
  getCountryCode,
};
