/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import './stylesButtons.scss';
import { Button } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { store } from '../../utils/configureStore';
import localizedStrings from '../../utils/localizedStrings';

const {
  settings: {
    environment: { language },
  },
  user: { vehicleClass },
} = store.getState();

const {
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_CONTINUE,
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_BACK,
  PHOTO_HUB_PHOTOS_TAKEN_BUTTON_FINISH,
} = localizedStrings(language);

const ProcessStepsButtons = ({
  isAllowBack,
  isAllowContinue,
  isLastStep,
  showBackButton,
  onBack,
  onContinue,
  currentStep,
}) => {
  const getButtonId = useMemo(
    () => `${vehicleClass.toUpperCase()}-RETURN-TO-STEP-${currentStep - 1}`,
    [currentStep],
  );

  return (
    <div className="process-steps-buttons-container">
      <Button
        style={{
          visibility: showBackButton ? 'hidden' : 'visible',
        }}
        className="process-step-button"
        disabled={!isAllowBack}
        onClick={onBack}
        level="secondary"
        id={getButtonId}
      >
        {PHOTO_HUB_PHOTOS_TAKEN_BUTTON_BACK.toUpperCase()}
      </Button>
      <Button
        className="process-step-button"
        disabled={!isAllowContinue}
        onClick={onContinue}
      >
        {isLastStep
          ? PHOTO_HUB_PHOTOS_TAKEN_BUTTON_FINISH.toUpperCase()
          : PHOTO_HUB_PHOTOS_TAKEN_BUTTON_CONTINUE.toUpperCase()}
      </Button>
    </div>
  );
};

ProcessStepsButtons.propTypes = {
  isAllowBack: PropTypes.bool.isRequired,
  isAllowContinue: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default ProcessStepsButtons;
