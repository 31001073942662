/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Heading, BodyText, IconChevronLeft } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';

const ModalOtherBrowser = ({
  pageNumber,
  setPageNumber,
  setSelectedBrowser,
}) => {
  const {
    environment: { language },
  } = useSelector(store => store.settings);
  const {
    CAMERA_PERMISSIONS_IPHONE_CHROME,
    CAMERA_PERMISSIONS_IPHONE_SAFARI,
    CAMERA_PERMISSIONS_ANDROID_CHROME,
    MODAL_PRIMARY_BUTTON_LABEL,
    CAMERA_PERMISSIONS_IPHONE_CHROME_ID,
    CAMERA_PERMISSIONS_IPHONE_SAFARI_ID,
    CAMERA_PERMISSIONS_ANDROID_CHROME_ID,
    CAMERA_PERMISSIONS_SELECT_SO,
  } = localizedStrings(language);

  const [, setSelectedAccessory] = useState('');

  const onClickRadioButton = event => {
    setSelectedBrowser(event.currentTarget.value);
    setSelectedAccessory(event.currentTarget.value);
    event.stopPropagation();
  };

  const unformattedCountriesAvailableAccessories = availableAccesories =>
    availableAccesories.filter(photo => photo.title);

  const availableAccessories = () =>
    unformattedCountriesAvailableAccessories([
      {
        photoId: CAMERA_PERMISSIONS_IPHONE_CHROME_ID,
        title: CAMERA_PERMISSIONS_IPHONE_CHROME,
      },
      {
        photoId: CAMERA_PERMISSIONS_IPHONE_SAFARI_ID,
        title: CAMERA_PERMISSIONS_IPHONE_SAFARI,
      },
      {
        photoId: CAMERA_PERMISSIONS_ANDROID_CHROME_ID,
        title: CAMERA_PERMISSIONS_ANDROID_CHROME,
      },
    ]);
  return (
    <div>
      <Heading
        style={{ fontSize: '1.3rem' }}
        type="h3"
        className="auto-left-right-margin"
      >
        <p>{CAMERA_PERMISSIONS_SELECT_SO}</p>
      </Heading>
      <BodyText className="bodySelectSystem">
        {availableAccessories().map(({ photoId, title }) => {
          const radioButtonId = `radio_button_${photoId}`;

          return (
            <div
              role="button"
              className="full-width-button left-align-contents disable-button-focus radioButtonModal"
              key={photoId}
              // eslint-disable-next-line react/no-unknown-property
              level="secondary"
            >
              <input
                type="radio"
                className="radioButtonModal__option"
                id={radioButtonId}
                value={photoId}
                name="accessory"
                onClick={onClickRadioButton}
              />
              <label htmlFor={radioButtonId}>{title}</label>
            </div>
          );
        })}
      </BodyText>
      <div className="footer-modal-permissions radiusButton_adil">
        <span role="button" onClick={() => setPageNumber(pageNumber - 1)}>
          <IconChevronLeft />
        </span>
        <Button
          type="button"
          onClick={() => {
            setPageNumber(pageNumber + 1);
          }}
        >
          {MODAL_PRIMARY_BUTTON_LABEL}
        </Button>
      </div>
    </div>
  );
};
ModalOtherBrowser.propTypes = {
  setPageNumber: PropTypes.func.isRequired,
  setSelectedBrowser: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
};
export default ModalOtherBrowser;
