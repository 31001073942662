/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconChevronLeft, Button, IconChevronRight } from '@lmig/lmds-react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import CircleCameraIcon from './CircleCameraIcon';
import CircleSunIcon from './CircleSunIcon';
import CircleVehicleDocumentIcon from './CircleVehicleDocumentIcon';
import CircleCarIcon from './CircleCarIcon';
import CircleEngineIcon from './CircleEngineIcon';
import CircleNetworkIcon from './CircleNetworkIcon';
import CircleRetryIcon from './CircleRetryIcon';
import { ModalFlowActions } from '../../actions';
import InstructionsFinishView from './InstructionsFinishView';
import './styles.scss';

const renderCustomDots = currentSlide => {
  const dots = [];
  currentSlide.forEach(element => {
    const isActive = element.props?.className === 'slick-active';
    const dotStyle = {
      backgroundColor: isActive ? 'black' : 'white',
      border: '1px solid #919191',
      borderRadius: '50%',
      width: '.7rem',
      height: '.7rem',
      margin: '0 .4rem',
      cursor: 'pointer',
      display: 'inline-block',
    };
    dots.push(<div key={Number(element.key) + 1} style={dotStyle} />);
  });

  return <div style={{ textAlign: 'center', margin: '1rem 0' }}>{dots}</div>;
};

const InstructionsModal = ({
  onCloseButtonHandler,
  isBeginningProcess,
  ...props
}) => {
  const {
    settings: {
      environment: { language },
    },
    user: { vehicleClass },
  } = useSelector(store => store);

  const sliderRef = useRef();
  const [slideItems, setSlideItems] = useState({
    activeSlide: 0,
  });
  const [lastSlide, setLastSlide] = useState(false);

  const dispatch = useDispatch();

  const {
    INSTRUCTIONS_MODAL_BUTTON_FINISH,
    INSTRUCTIONS_MODAL_BUTTON_OMIT,
    INSTRUCTIONS_MODAL_TITLE,
    INSTRUCTIONS_MODAL_SUBTITLE_1,
    INSTRUCTIONS_MODAL_SUBTITLE_2,
    INSTRUCTIONS_MODAL_SUBTITLE_3,
    INSTRUCTIONS_MODAL_SUBTITLE_4,
    INSTRUCTIONS_MODAL_SUBTITLE_5,
    INSTRUCTIONS_MODAL_SUBTITLE_6,
    INSTRUCTIONS_MODAL_SUBTITLE_7,
  } = localizedStrings(language);

  const instructionsArray = useMemo(
    () => [
      INSTRUCTIONS_MODAL_SUBTITLE_1,
      INSTRUCTIONS_MODAL_SUBTITLE_2,
      INSTRUCTIONS_MODAL_SUBTITLE_3,
      INSTRUCTIONS_MODAL_SUBTITLE_4,
      INSTRUCTIONS_MODAL_SUBTITLE_5,
      INSTRUCTIONS_MODAL_SUBTITLE_6,
      ...(!isBeginningProcess ? [INSTRUCTIONS_MODAL_SUBTITLE_7] : []),
    ],
    [
      INSTRUCTIONS_MODAL_SUBTITLE_1,
      INSTRUCTIONS_MODAL_SUBTITLE_2,
      INSTRUCTIONS_MODAL_SUBTITLE_3,
      INSTRUCTIONS_MODAL_SUBTITLE_4,
      INSTRUCTIONS_MODAL_SUBTITLE_5,
      INSTRUCTIONS_MODAL_SUBTITLE_6,
      INSTRUCTIONS_MODAL_SUBTITLE_7,
      isBeginningProcess,
    ],
  );

  const showLAstPageInitProcess = isBeginningProcess && lastSlide;

  const settings = {
    dots: true,
    className: 'sliderRef',
    arrows: false,
    infinite: false,
    appendDots: dots => renderCustomDots(dots),
    beforeChange: (current, next) => {
      const lastItem = isBeginningProcess
        ? current
        : instructionsArray.length - 1;

      setSlideItems(prevState => ({
        ...prevState,
        activeSlide: next,
      }));
      setLastSlide(lastItem === next);
    },
  };

  const slickNextOrClose = () => {
    if (!lastSlide) {
      sliderRef.current.slickNext();
    } else {
      dispatch(ModalFlowActions.onDisplayInstructionsModal(false));
    }
  };

  const slickPrevious = () => {
    sliderRef.current.slickPrev();
  };

  const InstructionIcon = () => {
    const icons = {
      0: <CircleCameraIcon />,
      1: <CircleSunIcon />,
      2: <CircleVehicleDocumentIcon />,
      3: <CircleCarIcon />,
      4: <CircleEngineIcon />,
      5: <CircleNetworkIcon />,
      6: <CircleRetryIcon />,
    };
    return icons[lastSlide ? 6 : slideItems.activeSlide];
  };

  const goToInitInstructions = () => {
    setSlideItems(prev => ({ ...prev, activeSlide: 0 }));
    sliderRef.current.slickGoTo(0);
  };

  return (
    <Modal
      {...props}
      overlayClassName="instructionsModal--visivility"
      className="instructionsModal--visivility"
      centerContent
    >
      <div style={{ marginTop: showLAstPageInitProcess ? '30%' : '-20%' }}>
        <InstructionIcon />

        {showLAstPageInitProcess && (
          <InstructionsFinishView
            language={language}
            vehicleClass={vehicleClass}
            goToInitInstructions={goToInitInstructions}
            onCloseButtonHandler={onCloseButtonHandler}
          />
        )}

        <div
          style={{
            transform: showLAstPageInitProcess ? 'scale(0)' : undefined,
          }}
        >
          <Slider {...settings} ref={sliderRef}>
            {instructionsArray.map((item, index) => (
              <div className="instructionsModal" key={item}>
                <h4 className="instructionsModal__box__title--lastSlide">
                  {INSTRUCTIONS_MODAL_TITLE}
                </h4>
                <p className="instructionsModal__box__subtitle--lastSlide">
                  <strong>{`${index + 1}. `}</strong>
                  {item}
                </p>
              </div>
            ))}
          </Slider>

          <div className="buttons-container radiusButton_adil">
            {slideItems.activeSlide > 0 && (
              <span role="button" onClick={() => slickPrevious()}>
                <IconChevronLeft className="instructions-iconChevronLeft" />
              </span>
            )}
            {!lastSlide && (
              <Button
                type="button"
                onClick={slickNextOrClose}
                id={`${vehicleClass.toUpperCase()}-LAST-INSTRUCTION-READ-${slideItems.activeSlide +
                  1}`}
              >
                <IconChevronRight className="instructions-iconChevronRight" />
              </Button>
            )}

            {lastSlide && (
              <Button
                type="button"
                onClick={slickNextOrClose}
                id={`${vehicleClass.toUpperCase()}-LAST-INSTRUCTION-READ-${
                  instructionsArray.length
                }`}
              >
                {INSTRUCTIONS_MODAL_BUTTON_FINISH}
              </Button>
            )}

            {!lastSlide && !isBeginningProcess && (
              <span
                className="exitText"
                role="button"
                id={`${vehicleClass.toUpperCase()}-LAST-INSTRUCTION-READ-${slideItems.activeSlide +
                  1}`}
                onClick={() => onCloseButtonHandler()}
              >
                {INSTRUCTIONS_MODAL_BUTTON_OMIT}
              </span>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

InstructionsModal.defaultProps = {
  isBeginningProcess: false,
};

InstructionsModal.propTypes = {
  onCloseButtonHandler: PropTypes.func.isRequired,
  isBeginningProcess: PropTypes.bool,
};

export default InstructionsModal;
