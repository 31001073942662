/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CAMERA_MODE, OPTIONS_PREVIEW_CAMERA } from '../../../constants/camera';
import { PreviewVideo } from './PreviewVideo';
import { PreviewPhoto } from './PreviewPhoto';
import './styles.scss';
import localizedStrings from '../../../utils/localizedStrings';

function PreviewCamera({
  readOnly,
  picture,
  video,
  lang,
  isFullscreen,
  cameraMode,
  displayVideo,
  onUpload,
  photosPendingToFinish,
}) {
  const { TAKE_VIDEO_FINISH_PRIMARY_BUTTON } = localizedStrings(lang);
  const canDisplayVideo =
    cameraMode === CAMERA_MODE.RECORD && displayVideo && video?.length > 0;
  const displayFinishButton = !readOnly && photosPendingToFinish === 0;
  const onFinishVideo = () => {
    onUpload(OPTIONS_PREVIEW_CAMERA.UPLOAD);
  };

  const classNameContainer = () => {
    if (picture) {
      return isFullscreen
        ? 'preview__container-photo fullscreen'
        : 'preview__container-photo';
    }
    return 'hidden';
  };

  return (
    <>
      <div className={classNameContainer()}>
        {cameraMode === CAMERA_MODE.PHOTO && picture && (
          <PreviewPhoto picture={picture} />
        )}
      </div>

      {canDisplayVideo && (
        <div className={displayVideo ? 'preview__container-video' : 'hidden'}>
          <PreviewVideo
            lang={lang}
            video={video}
            photosPendingToFinish={photosPendingToFinish}
          />

          {displayFinishButton && (
            <button
              type="button"
              className="preview__button"
              onClick={() => onFinishVideo()}
            >
              {TAKE_VIDEO_FINISH_PRIMARY_BUTTON}
            </button>
          )}
        </div>
      )}
    </>
  );
}

PreviewCamera.defaultProps = {
  picture: '',
  video: null,
  photosPendingToFinish: null,
  readOnly: false,
};

PreviewCamera.propTypes = {
  picture: PropTypes.string,
  cameraMode: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  displayVideo: PropTypes.bool.isRequired,
  photosPendingToFinish: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  video: PropTypes.any,
};

export default PreviewCamera;
