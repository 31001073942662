import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { BodyText } from '@lmig/lmds-react';
import localizedStrings from '../../utils/localizedStrings';
import { LoadingAnimation } from '..';
import Spinner from '../CircleLoadingPhoto';
import './styles.scss';

const InlineLoader = ({ isLoading, showAnimation, message }) => {
  const {
    environment: { language },
  } = useSelector(store => store.settings);

  const { INLINE_LOADING_TEXT } = localizedStrings(language);

  return (
    isLoading && (
      <div className="inline-loader">
        {showAnimation ? (
          <LoadingAnimation />
        ) : (
          <div className="inline-loader-text-container">
            <BodyText>{`${message || INLINE_LOADING_TEXT} `}</BodyText>
            <Spinner />
          </div>
        )}
      </div>
    )
  );
};

InlineLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showAnimation: PropTypes.bool,
  message: PropTypes.string,
};

InlineLoader.defaultProps = {
  message: '',
  showAnimation: true,
};

export default InlineLoader;
