import React from 'react';
import { useSelector } from 'react-redux';
import { FAQModalSection } from '..';
import { Modal } from '../..';
import { faqs as getFacQuestionConfig } from '../../../config';
import localizedStrings from '../../../utils/localizedStrings';
import IconCall from './IconCall.svg';
import { getCountryCode } from '../../../utils/NavigationUtils';
import './styles.scss';

const FAQModal = props => {
  const {
    settings: {
      environment: { language },
    },
    user: { vehicleClass },
  } = useSelector(store => store);

  const {
    FAQ_TITLE,
    FAQ_SUBTITLE,
    REQUEST_CALL_TEXT_1,
    REQUEST_CALL_TEXT_2,
    REQUEST_CALL_PHONE_NUMBER,
    REQUEST_CALL_SCHEDULES_1,
    REQUEST_CALL_SCHEDULES_2,
  } = localizedStrings(language);

  return (
    <Modal {...props}>
      <div className="faq-title-container">
        <div className="faq-title-text">{FAQ_TITLE}</div>
        <div className="faq-subtitle">{FAQ_SUBTITLE}</div>
      </div>

      <div className="faq-container">
        {getFacQuestionConfig(language).map(({ questionText, answerText }) => (
          <FAQModalSection
            key={questionText}
            questionText={questionText}
            answerText={answerText}
          />
        ))}

        {getCountryCode() !== 'ec' && (
          <div className="request-call-container">
            <div>
              <img src={IconCall} alt="IconCall" />
            </div>
            <div>
              <div className="request-call-text">{REQUEST_CALL_TEXT_1}</div>
              <div className="request-call-text2">
                <a
                  href={`tel:${REQUEST_CALL_PHONE_NUMBER}`}
                  id={`${vehicleClass.toUpperCase()}-CALL-TO-ACTION`}
                >
                  {REQUEST_CALL_TEXT_2}
                </a>
              </div>
            </div>
          </div>
        )}
        {getCountryCode() === 'cl' && (
          <div className="request-call-schedules-container">
            <div>
              <p>
                {REQUEST_CALL_SCHEDULES_1}
                <strong>{REQUEST_CALL_SCHEDULES_2}</strong>
              </p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FAQModal;
