import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, Heading } from '@lmig/lmds-react';
import localizedStrings from '../../utils/localizedStrings';

const SessionClosedView = ({ language }) => {
  const {
    INACTIVITY_TIMER_MODAL_SESSION_CLOSED_TITLE,
    INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_ONE,
    INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_TWO,
  } = localizedStrings(language);

  return (
    <div>
      <Heading type="h3-light" className="inactivity-modal__title">
        {INACTIVITY_TIMER_MODAL_SESSION_CLOSED_TITLE}
      </Heading>

      <BodyText className="inactivity-modal__hints">
        {INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_ONE}
      </BodyText>

      <BodyText className="inactivity-modal__hints">
        {INACTIVITY_TIMER_MODAL_SESSION_CLOSED_HINT_TWO}
      </BodyText>
    </div>
  );
};

SessionClosedView.propTypes = {
  language: PropTypes.string.isRequired,
};

export default SessionClosedView;
