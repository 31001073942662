import {
  ON_CHANGE_FAQ_ANSWER,
  ON_DISPLAY_HINTS_MODAL,
  HIDDEN_SECONDARY_BUTTON_MODAL,
  ON_DISPLAY_FINISH_CONFIRMATION_MODAL,
  ON_DISPLAY_RETAKE_PHOTO_MODAL,
  ON_DISPLAY_INSTRUCTIONS_MODAL,
  ON_DISPLAY_EXPIRED_SESSION_MODAL,
  ON_DISPLAY_FAQ_MODAL,
  ON_DISPLAY_INACTIVITY_TIMER_MODAL,
  ON_DISPLAY_REPEAT_VIDEO_MODAL,
  ON_DISPLAY_HINTS_VIDEO_MODAL,
  ON_DISPLAY_PLAY_VIDEO_MODAL,
} from '../actions/ActionTypes';

export const initialState = {
  faqModalDisplayed: false,
  faqAnswerDisplayed: false,
  displayHintsModal: false,
  hiddenButtonSecondary: false,
  displayFinishConfirmationModal: false,
  displayRetakePhotoModal: false,
  displayExpiredSessionModal: false,
  displayInactivityTimerModal: false,
  displayRepeatVideoModal: false,
  displayPlayVideoModal: false,
  displayHintsVideoModal: false,
  instructionsModal: {
    displayInstructionsModal: false,
    location: undefined,
  },
};

export default (state = initialState, { type, value } = {}) => {
  switch (type) {
    case ON_DISPLAY_HINTS_MODAL:
      return {
        ...state,
        displayHintsModal: value.isOpen,
      };
    case ON_CHANGE_FAQ_ANSWER:
      return {
        ...state,
        faqAnswerDisplayed: value.isOpen,
      };
    case ON_DISPLAY_FAQ_MODAL:
      return {
        ...state,
        faqModalDisplayed: value.isOpen,
      };
    case HIDDEN_SECONDARY_BUTTON_MODAL:
      return {
        ...state,
        hiddenButtonSecondary: value.hiddenButtonSecondary,
      };
    case ON_DISPLAY_FINISH_CONFIRMATION_MODAL:
      return {
        ...state,
        displayFinishConfirmationModal: value.isOpen,
      };
    case ON_DISPLAY_RETAKE_PHOTO_MODAL:
      return {
        ...state,
        displayRetakePhotoModal: value.isOpen,
      };
    case ON_DISPLAY_INSTRUCTIONS_MODAL:
      return {
        ...state,
        instructionsModal: {
          displayInstructionsModal: value.isOpen,
          location: value.location,
        },
      };
    case ON_DISPLAY_EXPIRED_SESSION_MODAL:
      return {
        ...state,
        displayExpiredSessionModal: value.isOpen,
      };
    case ON_DISPLAY_INACTIVITY_TIMER_MODAL:
      return {
        ...state,
        displayInactivityTimerModal: value.isOpen,
      };
    case ON_DISPLAY_REPEAT_VIDEO_MODAL:
      return {
        ...state,
        displayRepeatVideoModal: value.isOpen,
      };
    case ON_DISPLAY_PLAY_VIDEO_MODAL:
      return {
        ...state,
        displayPlayVideoModal: value.isOpen,
      };
    case ON_DISPLAY_HINTS_VIDEO_MODAL:
      return {
        ...state,
        displayHintsVideoModal: value.isOpen,
      };
    default:
      return state;
  }
};
