import {
  LANGUAGES,
  ASIAN_COUNTRIES,
} from '@lmig-latam/adil-api-common-lib/constants';
import QuestionIcon from '../styles/images/question.png';
import IconVN from '../styles/images/vn.svg';
import IconEN from '../styles/images/en.svg';
import IconTH from '../styles/images/th.png';
import localizedStrings from '../utils/localizedStrings';
import { getCountryCode } from '../utils/NavigationUtils';

const availableLang = {
  EN: {
    icon: IconEN,
    lang: 'English',
  },
  VN: {
    icon: IconVN,
    lang: 'Vietnamese',
  },
  TH: {
    icon: IconTH,
    lang: 'Thai',
  },
  CL: {
    icon: IconTH,
    lang: 'Thai',
  },
};

const getIconLanguage = lang =>
  ASIAN_COUNTRIES.includes(lang)
    ? IconEN
    : availableLang[getCountryCode().toUpperCase()].icon;

const buildLabel = (FLOAT_MENU_ITEM_2, lang) => {
  if (lang === LANGUAGES.EN) {
    return `${FLOAT_MENU_ITEM_2} ${
      availableLang[getCountryCode().toUpperCase()].lang
    }`;
  }
  return FLOAT_MENU_ITEM_2;
};

const getFloatMenuItems = lang => {
  const { FLOAT_MENU_ITEM_1, FLOAT_MENU_ITEM_2 } = localizedStrings(lang);

  return [
    {
      id: 'languages',
      label: buildLabel(FLOAT_MENU_ITEM_2, lang),
      img: getIconLanguage(lang.toUpperCase()),
      alt: '',
      class: 'icon-language',
      countryCode: ASIAN_COUNTRIES.includes(lang.toUpperCase())
        ? LANGUAGES.EN
        : LANGUAGES[getCountryCode().toUpperCase()],
      languages: [
        {
          name: 'Ingles',
          img: IconEN,
          alt: '',
          countryCode: LANGUAGES.EN,
        },
        {
          name: 'Vietnamese',
          img: IconVN,
          alt: '',
          countryCode: LANGUAGES.VN,
        },
        {
          name: 'Thai',
          img: IconTH,
          alt: '',
          countryCode: LANGUAGES.TH,
        },
      ],
    },
    {
      id: 'questions',
      label: FLOAT_MENU_ITEM_1,
      route: '/',
      img: QuestionIcon,
      alt: '',
      class: 'icon-menu',
    },
  ];
};

export default getFloatMenuItems;
