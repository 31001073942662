import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { WIZARD_PHOTO_STEP } from '../constants/wizardPhotos';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();

export class WizardStepData {
  constructor(labelsByCountry) {
    this.labelsByCountry = labelsByCountry;
  }

  setVehicleClass(vehicleClass) {
    this.vehicleClass = vehicleClass;
  }

  getLabelByVehicleClass(step) {
    const {
      WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE,
      WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE,
      WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE,
      WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE,

      WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE,
      WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE,
      WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE,
      WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE,
    } = this.labelsByCountry;

    let vehicleLabels;
    if (VEHICLE_CLASS_MOTORBIKE === this.vehicleClass)
      vehicleLabels = {
        1: WIZARD_PHOTO_HUB_MOTORBIKE_STEP_ONE_TITLE,
        2: WIZARD_PHOTO_HUB_MOTORBIKE_STEP_TWO_TITLE,
        3: WIZARD_PHOTO_HUB_MOTORBIKE_STEP_THREE_TITLE,
        4: WIZARD_PHOTO_HUB_MOTORBIKE_STEP_FOUR_TITLE,
      };
    else
      vehicleLabels = {
        1: WIZARD_PHOTO_HUB_VEHICLE_STEP_ONE_TITLE,
        2: WIZARD_PHOTO_HUB_VEHICLE_STEP_TWO_TITLE,
        3: WIZARD_PHOTO_HUB_VEHICLE_STEP_THREE_TITLE,
        4: WIZARD_PHOTO_HUB_VEHICLE_STEP_FOUR_TITLE,
      };
    return vehicleLabels[step];
  }

  getInitialState() {
    const { ONE, TWO, THREE, FOUR } = WIZARD_PHOTO_STEP;
    return [
      {
        id: ONE,
        name: this.getLabelByVehicleClass(ONE),
        photos: [],
      },
      {
        id: TWO,
        name: this.getLabelByVehicleClass(TWO),
        photos: [],
      },
      {
        id: THREE,
        name: this.getLabelByVehicleClass(THREE),
        conditionalPhotos: [],
        photos: [],
      },
      {
        id: WIZARD_PHOTO_STEP.FOUR,
        name: this.getLabelByVehicleClass(FOUR),
        photos: {
          accessories: [],
          damages: [],
        },
      },
    ];
  }
}
