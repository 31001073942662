import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { useSelector } from 'react-redux';
import IconCircleError from '../../../assets/icons/IconCircleError.svg';
import localizedStrings from '../../../utils/localizedStrings';

export const PhotoHeaderCard = ({
  title,
  description,
  IconComponent,
  isError,
}) => {
  const {
    settings: {
      environment: { language },
    },
  } = useSelector(store => store);

  const { PHOTO_HUB_UPLOAD_ERROR_MESSAGE } = localizedStrings(language);

  const getContainerClass = () =>
    `photo-panel__info-container ${
      isError ? 'photo-panel__error-process' : ''
    }`;

  return (
    <div className={getContainerClass()}>
      <h1 className="photo-panel__title">{title}</h1>

      <div className="photo-panel__description-container">
        <div className="photo-panel__description">
          <p>{description}</p>
        </div>
        <div className="photo-panel__success-icon">{IconComponent}</div>
      </div>

      {isError && (
        <div className="photo-panel__error-message-container">
          <img src={IconCircleError} alt="icon-circle-error" />
          <div className="photo-panel__error-message-text">
            {PHOTO_HUB_UPLOAD_ERROR_MESSAGE}
          </div>
        </div>
      )}
    </div>
  );
};

PhotoHeaderCard.propTypes = {
  title: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  description: PropTypes.string.isRequired,
  IconComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

PhotoHeaderCard.defaultProps = {
  isError: false,
};

export default PhotoHeaderCard;
