const ANDROID_CHROME1 = require('../../assets/images/ANDROID_CHROME1.gif');
const ANDROID_CHROME2 = require('../../assets/images/ANDROID_CHROME2.gif');
const IOS = require('../../assets/images/IOS.gif');
const IOSAFARI = require('../../assets/images/IOS_SAFARI.gif');
const wifiInspections = require('../../assets/images/wifi_inpections.png');

const imagesByBrowser = {
  ANDROID_CHROME1,
  ANDROID_CHROME2,
  IOS,
  IOSAFARI,
  wifiInspections,
};

export default imagesByBrowser;
