import { CAMERA_MODE } from '../../../constants/camera';
import { StrategyManager } from '../StrategyManager';
import { PhotoCameraStrategy } from './PhotoCameraStrategy';
import { VideoCameraStrategy } from './VideoCameraStrategy';

export class PhotoCaptureFlow {
  constructor(cameraMode, photoId) {
    this.video = null;
    this.overlay = null;
    this.cameraType = null;
    this.instance = null;
    this.useDispatch = null;
    this.photoId = photoId;
    this.cameraMode = cameraMode;
    this.strategyManager = new StrategyManager();
  }

  addVideo(video) {
    this.video = video;
    return this;
  }

  addCameraType(cameraType) {
    this.cameraType = cameraType;
    return this;
  }

  addOverlay(overlay) {
    this.overlay = overlay;
    return this;
  }

  addCameraMode(cameraMode) {
    this.cameraMode = cameraMode;
    return this;
  }

  setDispatch(useDispatch) {
    this.useDispatch = useDispatch;
    return this;
  }

  setPhotoCameraStrategy() {
    const photoCameraStrategy = new PhotoCameraStrategy(
      this.cameraType,
      this.overlay,
      this.photoId,
    );
    photoCameraStrategy.setDispatch(this.useDispatch);
    this.strategyManager.setStrategy(photoCameraStrategy);
  }

  setVideoCameraStrategy() {
    const recordCameraStrategy = new VideoCameraStrategy(
      this.video,
      this.photoId,
    );
    recordCameraStrategy.setDispatch(this.useDispatch);
    this.strategyManager.setStrategy(recordCameraStrategy);
  }

  async setup() {
    const isRecordMode = this.cameraMode === CAMERA_MODE.RECORD;

    if (isRecordMode) {
      this.setVideoCameraStrategy();
    } else {
      this.setPhotoCameraStrategy();
    }

    await this.strategyManager.start();
  }
}

export default PhotoCaptureFlow;
