import React from 'react';
import { formatString } from '@lmig-latam/adlib-ui/src/utils';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../utils/NavigationUtils';

const { APP_FOOTER_TITLE } = localizedStrings(getCountryCode());

const AppFooter = () => (
  <div className="app-footer-container">
    <div className="app-footer-element">
      <span className="app-footer-title">
        {formatString(APP_FOOTER_TITLE, {
          year: new Date().getFullYear(),
        })}
      </span>
    </div>
  </div>
);

export default AppFooter;
