import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import CloseSession from '../../utils/session/CloseSession';
import { TimerIcon } from '../Icons';
import { MainTimerView } from './MainTimerView';
import SessionClosedView from './SessionClosedView';
import { AuthActions, SessionActions } from '../../actions';
import { useInterval } from '../../hooks';
import './styles.scss';
import { LOGOUT_TYPE } from '../../constants/session';

export const ModalInactivityTimer = ({
  isOpen,
  language,
  onSuccessModal,
  onCloseModal,
  onClosingUserSession,
}) => {
  const {
    INACTIVITY_TIMER_MODAL_PRIMARY_BUTTON,
    INACTIVITY_TIMER_MODAL_CLOSED_SESSION_PRIMARY_BUTTON,
    INACTIVITY_TIMER_MODAL_SECONDARY_BUTTON,
  } = localizedStrings(language);

  const dispatch = useDispatch();

  const {
    auth: { isAuthenticated },
    session: { timerCloseSessionInModal },
    modalFlow: { displayExpiredSessionModal },
  } = useSelector(store => store);

  const labelButtonModal = {
    primaryButton: isAuthenticated
      ? INACTIVITY_TIMER_MODAL_PRIMARY_BUTTON
      : INACTIVITY_TIMER_MODAL_CLOSED_SESSION_PRIMARY_BUTTON,
    secundatyButtons: isAuthenticated
      ? INACTIVITY_TIMER_MODAL_SECONDARY_BUTTON
      : undefined,
  };

  const onSuccessModalHandler = () => {
    onSuccessModal();
  };

  const onDecrementCloseSessionTimerModal = useCallback(() => {
    dispatch(SessionActions.onDecrementInactivityTimerAutoCloseSession());
  }, [dispatch]);

  // Interval close sesion from modal
  const { stopInterval } = useInterval(
    'AutoCloseSession',
    () => {
      if (!isAuthenticated || displayExpiredSessionModal) {
        stopInterval();
        return;
      }

      if (timerCloseSessionInModal > 0) onDecrementCloseSessionTimerModal();
      else {
        stopInterval();
        CloseSession.clearSessionByInactivity();
        dispatch(AuthActions.setAuthenticationStatus(false));
        dispatch(SessionActions.setLogoutType(LOGOUT_TYPE.INACTIVITY_SESSION));
      }
    },
    1000,
  );

  useEffect(() => {
    if (timerCloseSessionInModal === 0) {
      onClosingUserSession();
      stopInterval();
    }
    return () => stopInterval();
  }, [timerCloseSessionInModal, onClosingUserSession, stopInterval]);

  return (
    <Modal
      isOpen={isOpen}
      className="inactivity-modal--visivility"
      overlayClassName="inactivity-modal--visivility"
      hiddenButtonSecondary={isAuthenticated}
      primaryButtonHandler={onSuccessModalHandler}
      secondaryButtonHandler={isAuthenticated ? onCloseModal : undefined}
      primaryButtonLabel={labelButtonModal.primaryButton}
      secondaryButtonLabel={labelButtonModal.secundatyButtons}
      centerContent
    >
      <TimerIcon />

      {!isAuthenticated ? (
        <SessionClosedView language={language} />
      ) : (
        <MainTimerView
          language={language}
          startSeconds={timerCloseSessionInModal}
        />
      )}
    </Modal>
  );
};
export default ModalInactivityTimer;

ModalInactivityTimer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  onSuccessModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onClosingUserSession: PropTypes.func.isRequired,
};
