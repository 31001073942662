/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { getMobileOperatingSystem } from '../../../utils';
import { IOS } from '../../../utils/constants';
import WebcamIos from './WebcamIos';
import WebcamAndroid from './WebcamAndroid';

const WebcamCapturePhoto = () => (
  <>{getMobileOperatingSystem() === IOS ? <WebcamIos /> : <WebcamAndroid />}</>
);

export default React.memo(WebcamCapturePhoto);
