import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lmig/lmds-react';
import { getArrayDownButtons } from '../../../constants/camera';
import localizedStrings from '../../../utils/localizedStrings';
import CircleLoading from '../../CircleLoading/CircleLoading';

function ButtonsPreviewPhoto({
  lang,
  isLoading,
  isPhotoInPreview,
  handlerOptionsPreview,
  recordingMode,
}) {
  const { TAKE_PHOTO_PRIMARY_BUTTON_LOADING } = localizedStrings(lang);

  const getContentButton = item => {
    if (isLoading && item.class.loading) {
      return (
        <>
          <div className={item.class.loading}>
            <CircleLoading />
          </div>
          <b className={item.class.label}>
            {TAKE_PHOTO_PRIMARY_BUTTON_LOADING}
          </b>
        </>
      );
    }

    return (
      <>
        <div className={item.class.icon}>
          <img src={item.icon} alt="" />
        </div>
        <b className={item.class.label}>{item.value}</b>
      </>
    );
  };
  return (
    <div className={isPhotoInPreview ? 'preview__container-buttons' : 'hidden'}>
      {!recordingMode &&
        getArrayDownButtons(lang, recordingMode).map(item => (
          <Button
            key={item.id}
            type="button"
            level={item.level}
            className={item.class.button}
            onClick={() => handlerOptionsPreview(item.id)}
          >
            {getContentButton(item)}
          </Button>
        ))}
    </div>
  );
}

ButtonsPreviewPhoto.propTypes = {
  lang: PropTypes.string.isRequired,
  recordingMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPhotoInPreview: PropTypes.bool.isRequired,
  handlerOptionsPreview: PropTypes.func.isRequired,
};

export default ButtonsPreviewPhoto;
