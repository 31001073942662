export class AccessoryPhotoHelper {
  constructor(stepsWizard) {
    this.step = null;
    this.stepsWizard = stepsWizard;
  }

  setStep(step) {
    this.step = step;
    return this;
  }

  getResult() {
    return this.stepsWizard;
  }

  storePhoto(incomingPhoto) {
    const { photoId } = incomingPhoto;
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep].photos;

    const isPhotoExists = photosInStep.accessories.find(
      item => item.photoId === photoId,
    );
    if (isPhotoExists) {
      const photoStepListUpdated = photosInStep.accessories.map(item =>
        item.photoId === photoId ? incomingPhoto : item,
      );
      this.stepsWizard[positionStep].photos.accessories = [
        ...photoStepListUpdated,
      ];
    } else {
      photosInStep.accessories.push(incomingPhoto);
      this.stepsWizard[positionStep].photos = photosInStep;
    }

    return this;
  }

  updatePhoto() {
    return this;
  }

  removePhoto(photoId) {
    const positionStep = this.step - 1;
    const photosInStep = this.stepsWizard[positionStep].photos;
    const newAccesoriesArray = photosInStep.accessories.filter(
      photo => photo.photoId !== photoId,
    );
    this.stepsWizard[positionStep].photos.accessories = newAccesoriesArray;
    return this;
  }
}
