import React from 'react';

function SuccessIcon() {
  return (
    <svg width="65" height="65" viewBox="0 0 54 54">
      <defs>
        <filter
          id="Rectángulo_8"
          x="0"
          y="0"
          width="65"
          height="65"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feFlood floodOpacity="0.251" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_2400"
            data-name="Rectángulo 2400"
            width="18.815"
            height="16"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Grupo_11303" data-name="Grupo 11303" transform="translate(4 4)">
        <g id="Grupo_11231" data-name="Grupo 11231">
          <g transform="matrix(1, 0, 0, 1, -4, -4)" filter="url(#Rectángulo_8)">
            <rect
              id="Rectángulo_8-2"
              data-name="Rectángulo 8"
              width="42"
              height="42"
              rx="21"
              transform="translate(4 4)"
              fill="#03ac63"
            />
          </g>
        </g>
        <g
          id="Grupo_11237"
          data-name="Grupo 11237"
          transform="translate(12 13)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Trazado_3422"
            data-name="Trazado 3422"
            d="M7.356,16a2.022,2.022,0,0,1-1.2-.392L0,11.1,1.512,9.038l5.736,4.194L16.736,0l2.081,1.492-9.8,13.67A2.072,2.072,0,0,1,7.356,16"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SuccessIcon;
