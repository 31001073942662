import React from 'react';
import PropTypes from 'prop-types';
import { ViewerVideo } from '../..';

export const PreviewVideo = ({ video }) => (
  <div className="preview">
    <div className="preview__container-video">
      <ViewerVideo photos={video} />
    </div>
  </div>
);

PreviewVideo.defaultProps = {
  video: null,
};

PreviewVideo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  video: PropTypes.any,
};
