import { IS_ALREADY_SAW_GUIDED_TOUR_STORAGE } from '../constants/camera';
import { STEP_ITEMS_GUIDED_VISIT } from '../constants/guidedTour/guidedTourPhoto';
import {
  ON_CHANGE_STATE_GUIDED_VISIT,
  ON_CHANGE_STEP_GUIDED_VISIT,
  SET_GUIDED_VISIT_ITEM,
  DESTROY_STEPS_GUIDE,
} from '../actions/ActionTypes';

const showGuidedTour = !!localStorage.getItem(
  IS_ALREADY_SAW_GUIDED_TOUR_STORAGE,
);

export const initialState = {
  isActiveGuidedVisit: !showGuidedTour,
  guidedVisitItems: undefined,
  currentStep: undefined,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_GUIDED_VISIT_ITEM:
      return {
        ...state,
        guidedVisitItems: payload.guidedVisitItems,
      };
    case DESTROY_STEPS_GUIDE:
      return {
        ...state,
        guidedVisitItems: undefined,
      };
    case ON_CHANGE_STATE_GUIDED_VISIT:
      return {
        ...state,
        isActiveGuidedVisit: payload.isActive,
      };
    case ON_CHANGE_STEP_GUIDED_VISIT: {
      const { guidedVisitItemID } = payload;
      if (guidedVisitItemID === STEP_ITEMS_GUIDED_VISIT.FIRST.ID)
        localStorage.setItem(IS_ALREADY_SAW_GUIDED_TOUR_STORAGE, true);
      return {
        ...state,
        currentStep: state.guidedVisitItems[guidedVisitItemID],
      };
    }
    default:
      return state;
  }
};
