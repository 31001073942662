import {
  ON_CHANGE_STATE_FULLSCREEN,
  ON_DISPLAY_CAMERA,
  ON_FLASH_BACKGROUND_PHOTO,
  SET_CURRENT_PHOTO_IN_PREVIEW,
  SET_INCOMING_PHOTO,
  SET_NEXT_INCOMING_PHOTO,
  ON_CHANGE_CAMERA_MODE,
  ON_CHANGE_STATE_OVERLAY,
  ON_PRESS_TAKE_PHOTO,
  ON_UPDATE_LOAD_PHOTO_PERCENTAGE,
  ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO,
} from '../actions/ActionTypes';
import { CAMERA_TYPE } from '../constants/camera';
import { STORAGE_CAMERA_MODE, STORAGE_ID_PHOTO } from '../constants/storage';

export const initialState = {
  displayCamera: false,
  isFlashing: false,
  isFullscreen: false,
  photoInPreview: undefined,
  displayPhotoPreview: false,
  defaultCamera: CAMERA_TYPE.NATIVE,
  overlay: false,
  isPressButtonTakePhoto: false,
  cameraMode: localStorage.getItem(STORAGE_CAMERA_MODE) || undefined,
  incomingPhotoId: localStorage.getItem(STORAGE_ID_PHOTO) || undefined,
  nextIncomingPhotoId: null,
  loadPhotoPercentage: null,
  photoError: undefined,
};

const setIncomingPhotoHandler = photoId => {
  if (photoId) localStorage.setItem(STORAGE_ID_PHOTO, photoId);
  else localStorage.removeItem(STORAGE_ID_PHOTO);
};

const setCameraModeHandler = cameraMode =>
  localStorage.setItem(STORAGE_CAMERA_MODE, cameraMode);

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_INCOMING_PHOTO:
      setIncomingPhotoHandler(payload.photoId);
      return { ...state, incomingPhotoId: payload.photoId };
    case SET_NEXT_INCOMING_PHOTO:
      return { ...state, nextIncomingPhotoId: payload.photoId };
    case ON_DISPLAY_CAMERA:
      return { ...state, displayCamera: payload.displayCamera };
    case ON_FLASH_BACKGROUND_PHOTO:
      return { ...state, isFlashing: payload.isFlashing };
    case ON_CHANGE_STATE_FULLSCREEN:
      return { ...state, isFullscreen: payload.isFullscreen };
    case ON_CHANGE_CAMERA_MODE:
      setCameraModeHandler(payload.cameraMode);
      return { ...state, cameraMode: payload.cameraMode };
    case ON_PRESS_TAKE_PHOTO:
      return { ...state, isPressButtonTakePhoto: payload.status };
    case SET_CURRENT_PHOTO_IN_PREVIEW:
      return {
        ...state,
        photoInPreview: payload.photo,
      };
    case ON_CHANGE_STATE_OVERLAY:
      return { ...state, overlay: payload.overlay };
    case ON_UPDATE_LOAD_PHOTO_PERCENTAGE:
      return { ...state, loadPhotoPercentage: payload.percentage };
    case ON_CHANGE_STATE_ERROR_UPLOADING_PHOTO:
      return {
        ...state,
        photoError: payload.photoError || undefined,
      };
    default:
      return state;
  }
};
