import {
  CONDITIONALLY_MOTORBIKE_REQUIRED_PHOTO_IDS,
  CONDITIONALLY_REQUIRED_PHOTO_IDS,
} from '@lmig-latam/adil-api-common-lib/constants';
import localizedStringsCommon from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import conditionallyRequiredPhotosImages from './conditionallyRequiredPhotosImages';
import localizedStrings from '../utils/localizedStrings';

const { VEHICLE_CLASS_MOTORBIKE } = localizedStringsCommon.getCurrentLanguage();
const { INSPECTION_PHOTO_SPARE_WHEEL } = CONDITIONALLY_REQUIRED_PHOTO_IDS;
const {
  INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER,
} = CONDITIONALLY_MOTORBIKE_REQUIRED_PHOTO_IDS;

export class ConditionallyRequiredPhotoConfig {
  constructor(language, vehicleClass) {
    this.language = language;
    this.vehicleClass = vehicleClass;
  }

  getConfigByVehicleClass() {
    if (this.vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
      return this.getMotorbikeConfig();
    }

    return this.getCarVehicleConfig();
  }

  getMotorbikeConfig() {
    const {
      PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_TITLE,
      PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_SUBTITLE,
      PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_HINT,
    } = localizedStrings(this.language);

    const CONDITIONAL_PHOTOS_MOTORBIKE_CONFIG = {
      INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER: {
        photoId: INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER,
        title: PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_TITLE,
        body: PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_SUBTITLE,
        hints: {
          text: PHOTO_HUB_MOTORBIKE_CONDITIONAL_CHASIS_NUMBER_HINT,
          images: conditionallyRequiredPhotosImages.chasisNumberHint,
        },
      },
    };
    const { countryMotorBikeConditionallyRequiredPhotoIds } = getCountryConfig(
      this.language,
    );

    return countryMotorBikeConditionallyRequiredPhotoIds.map(
      photoId => CONDITIONAL_PHOTOS_MOTORBIKE_CONFIG[photoId],
    );
  }

  getCarVehicleConfig() {
    const {
      PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE,
      PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE,
      PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT,
    } = localizedStrings(this.language);

    const CONDITIONAL_PHOTOS_VEHICLE_CONFIG = {
      INSPECTION_PHOTO_SPARE_WHEEL: {
        photoId: INSPECTION_PHOTO_SPARE_WHEEL,
        title: PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_TITLE,
        body: PHOTO_HUB_CONDTIONAL_SPARE_WHEEL_SUBTITLE,
        hints: {
          text: PHOTO_HUB_CONDITIONAL_SPARE_WHEEL_HINT,
          images: conditionallyRequiredPhotosImages.spareTyreHint,
        },
      },
    };

    const { countryConditionallyRequiredPhotoIds } = getCountryConfig(
      this.language,
    );

    return countryConditionallyRequiredPhotoIds.map(
      photoId => CONDITIONAL_PHOTOS_VEHICLE_CONFIG[photoId],
    );
  }
}
