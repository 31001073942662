/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CAMERA_TYPE } from '@lmig-latam/adil-api-common-lib/constants';
import ProcessStepsList from '../ProcessStepsList';
import CapturePhoto from '../CapturePhoto';
import localizedStrings from '../../utils/localizedStrings';
import history from '../../utils/history';
import { TAKE_PHOTO } from '../../utils/navigationConstants';
import { TakePhotoActions } from '../../actions';

export const RetakeAccessoryList = ({
  photosConfig,
  navigate,
  accessoryPhotosTaken,
}) => {
  const {
    takePhoto: { defaultCamera, incomingPhotoId },
    settings: {
      retake,
      environment: { language },
    },
  } = useSelector(store => store);

  const dispatch = useDispatch();
  const capturePhotoRef = useRef();

  const { PHOTO_HUB_ACCESSORIES_SECTION_TITLE } = localizedStrings(language);

  const retakePhotoConfig = useMemo(() => {
    if (!retake?.accessories) return [];

    const { accessories } = retake;
    const body = PHOTO_HUB_ACCESSORIES_SECTION_TITLE;

    return photosConfig
      .filter(({ photoId }) => accessories.includes(photoId))
      .map(item => ({ ...item, body }));
  }, [photosConfig, PHOTO_HUB_ACCESSORIES_SECTION_TITLE, retake]);

  const startCapturePhoto = photoId => {
    dispatch(TakePhotoActions.setIncomingPhoto(photoId));
    switch (defaultCamera) {
      case CAMERA_TYPE.FRAME:
        history.push({
          pathname: TAKE_PHOTO,
          search: history.location.search,
        });
        break;
      case CAMERA_TYPE.NATIVE:
        capturePhotoRef.current.click();
        break;
      default:
    }
  };

  return (
    <>
      <ProcessStepsList
        navigate={navigate}
        photos={accessoryPhotosTaken}
        photoListConfig={retakePhotoConfig}
        startCapturePhoto={startCapturePhoto}
        capturePhotoRef={capturePhotoRef}
        setIsAllowContinue={() => {
          // Empty function
        }}
        onAllowContinue={() => {
          // Empty function
        }}
      />
      <CapturePhoto photoId={incomingPhotoId} webcamRef={capturePhotoRef} />
    </>
  );
};

RetakeAccessoryList.propTypes = {
  accessoryPhotosTaken: PropTypes.arrayOf(PropTypes.object).isRequired,
  photosConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
};
