export const SMS_LOCKOUT = 'VSMS200';

export const EMAIL_TOKEN_COOKIE_NAME = 'emailToken';
export const AUTH_TOKEN_COOKIE_NAME = 'authToken';
export const RETAKE_DATA_COOKIE_NAME = 'retakeData';
export const RESUME_DATA_COOKIE_NAME = 'resumeData';

export const TOKEN_TIMEOUT = 24; // Hours

export const HISTORY_POP = 'POP';

export const ACCESSORIES_UNAVAILABLE = ['th'];

export const ID_TROUBLE_REQUIRED = ['readOnly_other', 'readOnly_specialist'];

export const IS_VALID_DAMAGE_SECTION = ['ec', 'cl'];
export const IS_VALID_VIDEO_MODULE = ['ec', 'cl'];

// system operator
export const ANDROID = 'Android';
export const WINDOWS_PHONE = 'Windows Phone';
export const IOS = 'iOS';
export const IPHONE = 'Iphone';
export const OPERATION_SYSTEM_NOT_FOUNT = 'unknown';

// browser
export const CHROME = 'Chrome';
export const SAFARI = 'Safari';
export const FIREFOX = 'Firefox';
export const EDGE = 'MS Edge';
export const EXPLORER = 'MS Explorer';
export const OPERA = 'OPERA';
export const OTROS = 'Otros';

export const DAMAGE_PHOTO = 'DAMAGE_PHOTO';

export const PHOTO_TYPES = {
  DAMAGE_PHOTO,
  REQUIRED_PHOTO: 'REQUIRED_PHOTO',
  ACCESSORY_PHOTO: 'ACCESSORY_PHOTO',
  CONDITIONAL_REQUIRED_PHOTO: 'CONDITIONAL_REQUIRED_PHOTO',
  VIDEO: 'VIDEO',
};

export const VEHICLE_FLOW_CLASS = {
  MOTORBIKE: 'MOTORBIKE',
  CAR: 'VEHICLE',
};

export const APP_VERSION = {
  V1: '1.0',
  V2: '2.0',
};
