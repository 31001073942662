import React from 'react';
import PropTypes from 'prop-types';
import localizedStrings from '../../../utils/localizedStrings';
import { PhotoHeaderCard } from '../PhotoHeaderCard/PhotoHeaderCard';
import { BUTTON_ACTION } from '../../../utils/FingerprintButton';
import { RetakeIcon, SuccessIcon, TrashIcon } from '../../Icons';
import Panel from '../../Panel';
import './styles.scss';

const { PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT } = localizedStrings;

const getSuccessIcon = () => (
  <div style={{ marginRight: '-10px' }}>
    <SuccessIcon />
  </div>
);

export const PhotoPreviewCard = ({
  title,
  description,
  previewPhoto,
  onUpdate,
  onDelete,
  buttons,
  photoId,
}) => (
  <div>
    <Panel className="photo-panel">
      <PhotoHeaderCard
        title={title}
        description={description}
        IconComponent={getSuccessIcon()}
      />

      <div className="preview-image">
        <img src={previewPhoto} alt={PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT} />

        {buttons?.includes('update') && (
          <RetakeIcon
            className="photo-panel__retake-icon"
            onClick={() => onUpdate(photoId, BUTTON_ACTION.REPEAT)}
          />
        )}

        {buttons?.includes('delete') && (
          <TrashIcon className="photo-panel__trash-icon" onClick={onDelete} />
        )}
      </div>
    </Panel>
  </div>
);

PhotoPreviewCard.defaultProps = {
  onDelete: () => {
    // empty function
  },
  description: '',
  photoId: null,
};

PhotoPreviewCard.propTypes = {
  previewPhoto: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  photoId: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['update', 'delete'])).isRequired,
};

export default PhotoPreviewCard;
