import { ON_DISPLAY_HEADER, ON_DISPLAY_MENU_FOOTER } from './ActionTypes';

const onDisplayMenuFooter = status => ({
  type: ON_DISPLAY_MENU_FOOTER,
  payload: { status },
});

const onDisplayHeader = status => ({
  type: ON_DISPLAY_HEADER,
  payload: { status },
});

export default {
  onDisplayHeader,
  onDisplayMenuFooter,
};
