import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TooltipFooter from './TooltipFooter';
import TooltipHeader from './TooltipHeader';
import './styles.scss';

function TooltipModal({
  children,
  onClose,
  disable,
  handlerButtons,
  dataTooltip: {
    title,
    name,
    position,
    omitButton,
    primaryButton,
    secundaryButton,
  },
}) {
  const {
    takePhoto: { cameraMode },
  } = useSelector(store => store);

  const classNameType = cameraMode === 'RECORD' ? `${name}_video` : name;

  return (
    <div className={`tooltip ${classNameType}`}>
      <label className="hidnfo__label">
        <input className="hidnfo__checkbox" type="checkbox" defaultChecked />
        <div className={`tooltip__box ${position.container}`}>
          <div className="content-tooltip">
            <TooltipHeader title={title} onClose={onClose} />
            {children}
          </div>
          <TooltipFooter
            disable={disable}
            handlerButtons={handlerButtons}
            primaryButton={primaryButton}
            secundaryButton={secundaryButton}
            omitButton={omitButton}
          />
          <div className={`arrow-box ${position.arrow}`} />
        </div>
      </label>
    </div>
  );
}

TooltipModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disable: PropTypes.bool.isRequired,
  dataTooltip: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    omitButton: PropTypes.string,
    primaryButton: PropTypes.string,
    secundaryButton: PropTypes.string,
    position: PropTypes.shape({
      arrow: PropTypes.string.isRequired,
      container: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handlerButtons: PropTypes.func.isRequired,
};

export default TooltipModal;
