/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalFlowActions } from '../../actions';
import Step from './Step';
import './styles.scss';

function StepFlowMultiple({ steps, settings, isImageLoaded }) {
  const [stepsList, setStepsList] = useState([]);
  const [currentStep, setStep] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (steps && currentStep === 1) {
      dispatch(ModalFlowActions.hiddenSecondaryButtonModal(true));
      const array = steps.hints.map((item, i) => {
        if (i === 0) return { ...item, isActive: true };
        return { ...item, isActive: false };
      });
      setStep(1);
      setStepsList(array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, dispatch]);

  function onNextStep() {
    const total = stepsList.length;
    const arrayTemp = stepsList.map((item, i) => {
      if (currentStep + 1 === total)
        dispatch(ModalFlowActions.hiddenSecondaryButtonModal(false));

      if (i + 1 === currentStep) return { ...item, isActive: false };

      if (i + 1 === currentStep + 1) {
        setStep(currentStep + 1);
        return { ...item, isActive: true };
      }
      return item;
    });
    setStepsList(arrayTemp);
  }

  return stepsList.map(
    (step, index) =>
      step.isActive && (
        <Step
          key={step.text}
          step={step}
          index={index}
          settings={settings}
          onNextStep={onNextStep}
          total={stepsList.length}
          isImageLoaded={isImageLoaded}
        />
      ),
  );
}

StepFlowMultiple.propsTypes = {
  steps: PropTypes.shape({
    hints: PropTypes.array.isRequired,
  }).isRequired,
  isImageLoaded: PropTypes.func.isRequired,
};

export default StepFlowMultiple;
