import React from 'react';
import localizedStrings from '../utils/localizedStrings';

const getFacQuestionConfig = language => {
  const {
    FAQ_1_QUESTION,
    FAQ_1_ANSWER,
    FAQ_2_QUESTION,
    FAQ_2_ANSWER_PART_1,
    FAQ_2_ANSWER_PART_2,
    FAQ_3_QUESTION,
    FAQ_3_ANSWER_PART_1,
    FAQ_3_ANSWER_PART_2,
    FAQ_4_QUESTION,
    FAQ_4_ANSWER,
    FAQ_5_QUESTION,
    FAQ_5_ANSWER,
    FAQ_6_QUESTION,
    FAQ_6_ANSWER,
    FAQ_7_QUESTION,
    FAQ_7_ANSWER_PART_1,
    FAQ_7_ANSWER_PART_2,
    FAQ_7_ANSWER_PART_3,
    FAQ_8_QUESTION,
    FAQ_8_ANSWER,
    FAQ_9_QUESTION,
    FAQ_9_ANSWER,
    FAQ_10_QUESTION,
    FAQ_10_ANSWER,
    FAQ_11_QUESTION,
    FAQ_11_ANSWER,
    FAQ_12_QUESTION,
    FAQ_12_ANSWER,
    FAQ_13_QUESTION,
    FAQ_13_ANSWER,
    FAQ_14_QUESTION,
    FAQ_14_ANSWER,
    FAQ_15_QUESTION,
    FAQ_15_ANSWER,
  } = localizedStrings(language);

  const isAvailableInCountry = text => text.length;

  const faqConfig = [
    {
      questionText: FAQ_1_QUESTION,
      answerText: FAQ_1_ANSWER,
    },
    {
      questionText: FAQ_2_QUESTION,
      answerText: (
        <span>
          {FAQ_2_ANSWER_PART_1}
          {FAQ_2_ANSWER_PART_2}
        </span>
      ),
    },
    {
      questionText: FAQ_3_QUESTION,
      answerText: (
        <span>
          {FAQ_3_ANSWER_PART_1}
          {FAQ_3_ANSWER_PART_2}
        </span>
      ),
    },
    {
      questionText: FAQ_4_QUESTION,
      answerText: FAQ_4_ANSWER,
    },
  ];

  if (FAQ_5_QUESTION) {
    faqConfig.push({
      questionText: FAQ_5_QUESTION,
      answerText: FAQ_5_ANSWER,
    });
  }

  faqConfig.push({
    questionText: FAQ_6_QUESTION,
    answerText: FAQ_6_ANSWER,
  });

  if (FAQ_7_QUESTION) {
    faqConfig.push({
      questionText: FAQ_7_QUESTION,
      answerText: (
        <span>
          {FAQ_7_ANSWER_PART_1}
          <br />
          {FAQ_7_ANSWER_PART_2}
          {FAQ_7_ANSWER_PART_3}
        </span>
      ),
    });
  }

  faqConfig.push(
    {
      questionText: FAQ_8_QUESTION,
      answerText: FAQ_8_ANSWER,
    },
    {
      questionText: FAQ_9_QUESTION,
      answerText: FAQ_9_ANSWER,
    },
    {
      questionText: FAQ_10_QUESTION,
      answerText: FAQ_10_ANSWER,
    },
    {
      questionText: FAQ_11_QUESTION,
      answerText: FAQ_11_ANSWER,
    },
    {
      questionText: FAQ_12_QUESTION,
      answerText: FAQ_12_ANSWER,
    },
    {
      questionText: FAQ_13_QUESTION,
      answerText: FAQ_13_ANSWER,
    },
    {
      questionText: FAQ_14_QUESTION,
      answerText: FAQ_14_ANSWER,
    },
    {
      questionText: FAQ_15_QUESTION,
      answerText: FAQ_15_ANSWER,
    },
  );

  const faqFiltered = faqConfig.filter(faq =>
    isAvailableInCountry(faq.questionText),
  );

  return faqFiltered;
};

export default getFacQuestionConfig;
