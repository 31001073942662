import React, { useCallback, useEffect, useState } from 'react';
import { useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import {
  TakePhotoActions,
  TakeVideoActions,
  GuidedVisitActions,
} from '../../actions';
import { TooltipModal, GuidedVisitStepper, GPSInfo } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { CameraContainer } from '../../components/Camera';
import {
  getVisitGuidedItems,
  STEP_ITEMS_GUIDED_VISIT,
} from '../../constants/guidedTour/guidedTourPhoto';
import {
  getVisitGuidedItemsVideo,
  STEP_ITEMS_GUIDED_VISIT_VIDEO,
} from '../../constants/guidedTour/guidedTourVideo';
import { onManagerDirectionButtons } from '../../constants/guidedTour/guidedTourDirectionButtons';
import ModalUploadFailed from './components/ModalUploadFailed/ModalUploadFailed';
import { isAsianDomain } from '../../utils';
import './styles.scss';

const CapturePhoto = () => {
  const dispatch = useDispatch();
  const {
    settings: {
      retake,
      environment: { language },
    },
    user: { userInspectionStatus },
    permissions: { hasPermissionCamera },
    guidedVisit: { isActiveGuidedVisit, guidedVisitItems, currentStep },
    takePhoto: {
      incomingPhotoId,
      photoInPreview,
      isFlashing,
      cameraMode,
      isFullscreen,
    },
  } = useSelector(store => store);

  const {
    PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE,
    PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE,
    PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL,
  } = localizedStrings(language);

  const handlerFullScreen = useFullScreenHandle();

  const showGuidedTour =
    currentStep && isActiveGuidedVisit && hasPermissionCamera;

  const [
    photoUploadFailureModalDisplayed,
    setPhotoUploadFailureModalDisplayed,
  ] = useState(false);

  const openFullScreen = async () => {
    handlerFullScreen.enter();
    dispatch(TakePhotoActions.onChangeStateFullscreen(true));
  };

  const exitFullScreen = async () => {
    handlerFullScreen.exit();
    dispatch(TakePhotoActions.onChangeStateFullscreen(false));
  };

  const hidePhotoUploadFailureModal = () =>
    setPhotoUploadFailureModalDisplayed(false);

  const showPhotoUploadFailureModal = () =>
    setPhotoUploadFailureModalDisplayed(true);

  const retryPhotoUpload = async () => {
    hidePhotoUploadFailureModal();
    dispatch(TakePhotoActions.setCurrentPhotoPreview(null));
  };

  const setInitTooltipCurrentItem = useCallback(() => {
    // it's necessary this line of eslint due ternary operator
    // eslint-disable-next-line no-unused-expressions
    cameraMode === 'RECORD'
      ? dispatch(
          GuidedVisitActions.onChangeStepGuidadVisit(
            STEP_ITEMS_GUIDED_VISIT_VIDEO.INIT.ID,
          ),
        )
      : dispatch(
          GuidedVisitActions.onChangeStepGuidadVisit(
            STEP_ITEMS_GUIDED_VISIT.INIT.ID,
          ),
        );
  }, [cameraMode, dispatch]);

  const handlerCloseTooltip = () => {
    dispatch(GuidedVisitActions.onChangeStateGuidedVisit(false));
    setInitTooltipCurrentItem();
  };

  const handlerButtonsTooltip = directionButton => {
    const newStep = onManagerDirectionButtons(currentStep.id, cameraMode);

    switch (directionButton) {
      case 'right':
        if (newStep.NEXT)
          dispatch(GuidedVisitActions.onChangeStepGuidadVisit(newStep.NEXT));
        else handlerCloseTooltip();
        break;
      case 'left':
        dispatch(GuidedVisitActions.onChangeStepGuidadVisit(newStep.BACK));
        break;
      default:
        handlerCloseTooltip();
        break;
    }
  };

  const checkGuidedVisitInit = useCallback(() => {
    if (!isActiveGuidedVisit)
      dispatch(TakePhotoActions.setCurrentPhotoPreview(null));
  }, [dispatch, isActiveGuidedVisit]);

  function fullscreen() {
    const de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    } else if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen();
    } else if (de.webkitRequestFullscreen) {
      de.webkitRequestFullscreen();
    } else if (de.msRequestFullscreen) {
      de.msRequestFullscreen();
    }
    dispatch(TakePhotoActions.onChangeStateFullscreen(true));
  }

  useEffect(() => {
    if (!isFullscreen && userInspectionStatus !== '') fullscreen();
    checkGuidedVisitInit();
    dispatch(TakePhotoActions.onFlashInBackgroundPhoto(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkGuidedVisitInit, dispatch, isActiveGuidedVisit]);

  useEffect(() => {
    if (!guidedVisitItems && cameraMode !== 'RECORD') {
      const items = getVisitGuidedItems(language);
      dispatch(GuidedVisitActions.setGuidedVisitItems(items));
      setInitTooltipCurrentItem();
    }
    if (!guidedVisitItems && cameraMode === 'RECORD') {
      const items = getVisitGuidedItemsVideo(language);
      dispatch(GuidedVisitActions.setGuidedVisitItems(items));
      setInitTooltipCurrentItem();
    }
  }, [
    dispatch,
    guidedVisitItems,
    language,
    setInitTooltipCurrentItem,
    cameraMode,
  ]);

  useEffect(
    () => () => dispatch(GuidedVisitActions.setGuidedVisitItems(null)),
    [dispatch],
  );

  useEffect(() => {
    dispatch(TakeVideoActions.resetPhotosPreviewVideo());
    dispatch(TakeVideoActions.onDisplayPreviewVideo(false));
  }, [dispatch]);

  return (
    <>
      {!isAsianDomain() && <GPSInfo delayGetPosition={5000} />}
      <div className="background-capture-photo">
        {photoUploadFailureModalDisplayed && (
          <ModalUploadFailed
            isOpen={photoUploadFailureModalDisplayed}
            title={PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE}
            subtitle={PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE}
            primaryButtonHandler={retryPhotoUpload}
            backButtonHandler={hidePhotoUploadFailureModal}
            primaryButtonLabel={
              PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL
            }
          />
        )}

        <CameraContainer
          retake={retake}
          language={language}
          isFlashing={isFlashing}
          stepId={currentStep ? currentStep.id : null}
          incomingPhotoId={incomingPhotoId}
          photoInPreview={photoInPreview}
          isFullscreen={handlerFullScreen.active}
          handlerFullScreen={{ openFullScreen, exitFullScreen }}
          showPhotoUploadFailureModal={showPhotoUploadFailureModal}
        />

        {showGuidedTour && (
          <TooltipModal
            dataTooltip={currentStep}
            onClose={handlerCloseTooltip}
            handlerButtons={handlerButtonsTooltip}
          >
            <p className="tooltip-paragraph">{currentStep.description}</p>
            {currentStep && currentStep.id > 0 && (
              <GuidedVisitStepper
                numberDots={
                  guidedVisitItems ? Object.keys(guidedVisitItems).length : 0
                }
                stepNumber={currentStep.id}
              />
            )}
          </TooltipModal>
        )}
      </div>
    </>
  );
};

export default CapturePhoto;
