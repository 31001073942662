import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lmig/lmds-react';
import { useDispatch } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import { AuthActions, SessionActions, SettingsActions } from '../../actions';
import { PHOTO_HUB } from '../../utils/navigationConstants';
import { getCountryCode, navigate } from '../../utils/NavigationUtils';
import CloseSession from '../../utils/session/CloseSession';
import { LOGOUT_TYPE, SESSION_STORAGE_KEYS } from '../../constants/session';
import { USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION } from '../../constants/instructions';
import { InactivitySession } from '../../utils/session/InactivitySession';
import './styles.scss';

const { LOGOUT_ISSUE } = SESSION_STORAGE_KEYS;

export default function InstructionsFinishView({
  language,
  vehicleClass,
  onCloseButtonHandler,
  goToInitInstructions,
}) {
  const {
    INSTRUCTIONS_MODAL_BUTTON_SHOW_INSTRUCTIONS_AGAIN,
    INSTRUCTIONS_MODAL_BUTTON_FINISH_INITIAL_PROCESS,
    INSTRUCTIONS_MODAL_TITLE_FINISH_PAGE,
    INSTRUCTIONS_MODAL_SUBTITLE_FINISH_PAGE,
  } = localizedStrings(language);

  const dispatch = useDispatch();

  const BUTTON_ID = {
    primary: `${vehicleClass.toUpperCase()}-LAST-INSTRUCTION-START-INSPECTION`,
    secondary: `${vehicleClass.toUpperCase()}-SEE_INSTRUCTIONS_AGAIN`,
  };

  const getTokenToStartInspection = useCallback(async () => {
    const islogoutByInactivity =
      CloseSession.getLogoutType() === LOGOUT_TYPE.INACTIVITY_SESSION;

    if (!islogoutByInactivity) {
      dispatch(SettingsActions.showAppLoader());

      dispatch(AuthActions.getAuthTokenToStartInspection());

      sessionStorage.removeItem(
        USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION,
      );
    }

    sessionStorage.setItem(
      USER_HAS_SEEN_THE_INSTRUCTIONS_STARTED_INSPECTION,
      true,
    );

    const inactivitySession = new InactivitySession(getCountryCode());
    const nextTimestamp = inactivitySession.getNextTimerTimestamp();

    dispatch(SessionActions.onChangeMainInactivityTimer(nextTimestamp));
    dispatch(SettingsActions.hideAppLoader());

    sessionStorage.removeItem(LOGOUT_ISSUE);
  }, [dispatch]);

  const startInspections = useCallback(async () => {
    await getTokenToStartInspection();

    dispatch(SessionActions.startTimerToExpireSession());
    dispatch(AuthActions.setAuthenticationStatus(true));

    onCloseButtonHandler();
    navigate(PHOTO_HUB);
  }, [dispatch, getTokenToStartInspection, onCloseButtonHandler]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="instructionsModal">
        <h4 className="final-instructions__title">
          {INSTRUCTIONS_MODAL_TITLE_FINISH_PAGE}
        </h4>
        <p className="final-instructions__description">
          {INSTRUCTIONS_MODAL_SUBTITLE_FINISH_PAGE}
        </p>
      </div>

      <div className="last-page-buttons">
        <Button
          id={BUTTON_ID.primary}
          className="custom-buttons"
          onClick={() => startInspections()}
        >
          {INSTRUCTIONS_MODAL_BUTTON_FINISH_INITIAL_PROCESS}
        </Button>

        <Button
          id={BUTTON_ID.secondary}
          className="custom-buttons"
          type="button"
          level="secondary"
          onClick={goToInitInstructions}
        >
          {String(
            INSTRUCTIONS_MODAL_BUTTON_SHOW_INSTRUCTIONS_AGAIN,
          ).toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

InstructionsFinishView.propTypes = {
  language: PropTypes.string.isRequired,
  vehicleClass: PropTypes.string.isRequired,
  onCloseButtonHandler: PropTypes.func.isRequired,
  goToInitInstructions: PropTypes.func.isRequired,
};
