import { useEffect, useRef } from 'react';
import { logcodes, logger } from '../utils/logger';

export const useInterval = (name, callback, delay) => {
  const interval = useRef();

  const tracking = false;

  useEffect(() => {
    if (tracking)
      logger.log(logcodes.SESS030, { name, interval: interval.current });

    interval.current = setInterval(callback, delay);

    return () => clearInterval(interval.current);
  }, [callback, delay, name, tracking]);

  return {
    interval: interval.current,
    stopInterval: () => {
      if (tracking)
        logger.log(logcodes.SESS040, { name, interval: interval.current });
      clearInterval(interval.current);
    },
  };
};
